import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./Static.css";

const TermsCondition = (props) => {

  return (
    <>
     
      <div className="terms-condition-sec">
        <div className="terms-condition-sec-header">
        <Container>
        <Row>
              <Col md={12}>
                <div className="terms-condition-title">
                  <h1>Terms & Condition</h1>
                </div>
              </Col>
            </Row>
        </Container>
        </div>
        <div>
        <Container>
        <Row>
            <Col md={12}>
             <div className="terms-condition-who-we-are">
             <h1>Who we are</h1>
             <p>Our website address is: www.xyz.com</p>
             <h1>What personal data we collect and why we collect it</h1>
             <h3>Comments</h3>
             <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</p>
             <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>
             <p>Media<br/>
              If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</p>
             <p>Contact forms<br/>
                Cookies<br/>
                If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</p>
             <p>If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>
             <p>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
             <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>
             <p>Embedded content from other websites<br/>
                Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
             <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
             <p>Analytics<br/>
               Who we share your data with<br/>
               How long we retain your data<br/>
              If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</p>
             <p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>
             <p>What rights you have over your data<br/>
               If you have an account on this site or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
             <p>Where we send your data<br/>
              Visitor comments may be checked through an automated spam detection service.</p>
             <p>Additional information <br/>
              Itshemp.in recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on https://itshemp.in and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.</p>
             <p>Itshemp.in is a property of Pebblejar Labs (OPC) Private Limited, an Indian Company registered under the Companies Act, 2013 having its registered office at 99E Sevkara, Balaji Vihar, Kangra-176001, Himachal Pradesh</p>
             <p>Information we collect</p>
             <p>Contact information. We might collect your Name, E-mail, Mobile Number, Phone Number, Street, City, State, Pincode,  Country and IP address.</p>
             <p>Payment and billing information. We might collect your billing name, billing address and payment method when you buy a ticket. We NEVER collect your credit card number or credit card expiry date or other details pertaining to your credit card on our website. Credit card information will be obtained and processed by our online payment partner CC Avenue.</p>
             <p>Information you post. We collect the information you post in a public space on our website or on a third-party social media site belonging to Itshemp.in.</p>
             <p>Demographic information. We may collect demographic information about you, events you like, events you intend to participate in, tickets you buy, or any other information provided by you during the use of our website. We might collect this as a part of a survey also.</p>
             <p>Other information. If you use our website, we may collect information about your IP address and the browser you’re using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.</p>
             <p>We collect information in different ways.</p>
             <p>We collect information directly from you. We collect information directly from you when you register for an event or buy tickets. We also collect information if you post a comment on our websites or ask us a question through phone or email.</p>
             <p>We collect information from you passively. We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website.</p>
             <p>We get information about you from third parties. For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.</p>
             <p>Use of your personal information</p>
             <p>We use the information to contact you: We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.</p>
             <p>We use the information to respond to your requests or questions. We might use your information to confirm your registration for an event or contest.</p>
            <p>We use the information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences.</p> 

<p>We use the information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine the information we get from you with information about you we get from third parties.</p>

<p>We use the information for security purposes. We may use the information to protect our company, our customers, or our websites.</p>

<p>We use the information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. Or, for example, if you buy tickets from us we’ll enrol you in our newsletter.</p>

<p>We use the information to send you transactional communications. We might send you emails or SMS about your account or a ticket purchase.</p>

<p>We use information as otherwise permitted by law.</p>

<p>Sharing of information with third-parties</p>

<p>We will share information with third parties who perform services on our behalf. We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. Some vendors may be located outside of India.</p>

<p>We will share information with the event organizers. We share your information with event organizers and other parties responsible for fulfilling the purchase obligation. The event organizers and other parties may use the information we give them as described in their privacy policies.</p>

<p>We will share information with our business partners. This includes a third party who provide or sponsor an event, or who operates a venue where we hold events. Our partners use the information we give them as described in their privacy policies.</p>

<p>We may share information if we think we have to in order to comply with the law or to protect ourselves. We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud.</p>

<p>We may share information with any successor to all or part of our business. For example, if part of our business is sold we may give our customer list as part of that transaction.</p>

<p>We may share your information for reasons not described in this policy. We will tell you before we do this.</p>

<p>Email Opt-Out</p>

<p>You can opt-out of receiving our marketing emails. To stop receiving our promotional emails, please email unsubscriber@itshemp.in. It may take about ten days to process your request. Even if you opt-out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your purchases.</p>


<p>Third-party sites</p>

<p>If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third party sites.</p>

<p>Grievance Officer</p>

<p>In accordance with Information Technology Act 2000 and rules made thereunder, the name and contact details of the Grievance Officer are provided below:</p>

<p>Mr Abhishek Thakur</p>

<p>Sanjay Marg</p>

<p>Dharamshala- 176215</p>

<p>Phone: +91-7650998877</p>

<p>Email: abhishek@secondstudio.in</p>

<p>If you have any questions about this Policy or other privacy concerns, you can also email us at webmaster@itshemp.in</p>

<p>Updates to this policy</p>

<p>This Privacy Policy was last updated on 08.01.2022. From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.</p>

<p>Jurisdiction</p>

<p>If you choose to visit the website, your visit and any dispute over privacy are subject to this Policy and the website’s terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</p>
            
   
              </div>
              </Col>
            </Row>
        </Container>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
