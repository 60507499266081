import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Verification.css";
import OtpInput from "react18-input-otp";
import { useDispatch, useSelector } from "react-redux";
import {
  emailVerificationStart,
  forgotPasswordFailure,
  forgotPasswordStart,
  forgotPasswordVerificationStart,
  resendEmailVerificationStart,
  resendTwoStepAuthStart,
  twoStepAuthStart,
  userLoginFailure,
  userRegisterFailure
} from "../../store/slices/UserSlice";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";

const VerificationIndex = ({ setStep, step, closeAuthModal }) => {
  const t = useTranslation("verification");
  const dispatch = useDispatch();
  const login = useSelector(state => state.user.login);
  const register = useSelector(state => state.user.register);
  const resendTwoStepAuth = useSelector(state => state.user.resendTwoStepAuth)
  const resendEmailVerification = useSelector(state => state.user.resendEmailVerification);
  const forgotPassword = useSelector(state => state.user.forgotPassword);
  const twoStepAuth = useSelector(state => state.user.twoStepAuth);
  const emailVerification = useSelector(state => state.user.emailVerification);
  const forgotPasswordVerification = useSelector(state => state.user.forgotPasswordVerification);


  const [skipRender, setSkipRender] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const handleChange = (otp) => setOTP(otp);

  useEffect(() => {
    if (Object.keys(login.data).length > 0) {
      setEmail(login.data.email);
      dispatch(userLoginFailure());
    } else if (Object.keys(register.data).length > 0) {
      setEmail(register.data.email);
      dispatch(userRegisterFailure());
    } else if (Object.keys(forgotPassword.data).length > 0) {
      setEmail(forgotPassword.data.email);
      dispatch(forgotPasswordFailure());
    } else {
      setStep(1);
    }
  }, []);

  const handleResend = e => {
    e.preventDefault();
    if (step === 4) dispatch(resendTwoStepAuthStart({ email: email }));
    else if (step === 5) dispatch(resendEmailVerificationStart({ email: email }));
    else if (step === 6) dispatch(forgotPasswordStart({ email: email }));
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        if (step === 4) dispatch(twoStepAuthStart({ email: email, verification_code: otp }))
        else if (step === 5) dispatch(emailVerificationStart({ email: email, verification_code: otp }))
        else if (step === 6) dispatch(forgotPasswordVerificationStart({ email: email, verification_code: otp }))
      } else {
        getErrorNotificationMessage(t("the_verification_code_should_be_6_digits"));
      }
    } else {
      getErrorNotificationMessage(t("required"));
    }
  }

  useEffect(() => {
    if (!skipRender && !twoStepAuth.loading && Object.keys(twoStepAuth.data).length > 0)
      closeAuthModal();
  }, [twoStepAuth]);

  useEffect(() => {
    if (!skipRender && !emailVerification.loading && Object.keys(emailVerification.data).length > 0)
      closeAuthModal();
  }, [emailVerification]);

  useEffect(() => {
    if (!skipRender && !forgotPasswordVerification.loading && Object.keys(forgotPasswordVerification.data).length > 0)
      setStep(7);
    setSkipRender(false);
  }, [forgotPasswordVerification]);


  return (
    <>
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <div className="verification-logo-sec">
                <Image
                  className="verification-logo"
                  src={configuration.get("configData.site_logo")}
                  alt={configuration.get("configData.site_name")}
                />
              </div>
              <div className="verification-box">
                <h4>
                  {t("verification.title", {
                    addition: step === 4 ? "to login"
                      : step === 5 ? "to Verify your account"
                        : step === 6 ? "to change password"
                          : ""
                  })}
                </h4>
                <p>
                  {t("verification.message", { email: email })}{" "}
                  <Link to="#" onClick={() => setStep(step === 4 ? 1 : step === 5 ? 2 : 3)}>{t("verification.wrong_email")}</Link>
                </p>
                <Form onSubmit={handleSubmit}>
                  <div className="verification-item">
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      separator={<span>-</span>}
                      containerStyle="justify-content-center"
                    />
                  </div>
                  <div className="verification-btn-sec">
                    <Button
                      type="button"
                      className="download-receipt-btn"
                      onClick={handleResend}
                      disabled={
                        resendTwoStepAuth.buttonDisable ||
                        resendEmailVerification.buttonDisable ||
                        forgotPassword.buttonDisable
                      }
                    >
                      {resendTwoStepAuth.buttonDisable ||
                        resendEmailVerification.buttonDisable ||
                        forgotPassword.buttonDisable
                        ? "Loading" : "Resend"
                      }
                    </Button>
                    <Button
                      type="submit"
                      className="wishlist-btn"
                      disabled={
                        twoStepAuth.buttonDisable ||
                        emailVerification.buttonDisable ||
                        forgotPasswordVerification.buttonDisable
                      }
                    >
                      {twoStepAuth.buttonDisable ||
                        emailVerification.buttonDisable ||
                        forgotPasswordVerification.buttonDisable
                        ? t("login_btn.loading") : t("login_btn.text")
                      }
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default VerificationIndex;
