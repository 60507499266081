import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import NewArrivals from "../LandingPage/NewArrivals";
import NewArrivalsIndex from "./NewArrivalsIndex";
import NewForYou from "./NewForYou";
import "./NewLanding.css";
import NewLandingBannerSlider from "./NewLandingBannerSlider";
import NewLandingCategories from "./NewLandingCategories";
import NewLandingHeader from "./NewLandingHeader";
import ShopByCategory from "./ShopByCategory";
import ShopByCollection from "./ShopByCollection";
import TopSellers from "./TopSellers";

const NewLandingPageIndex = (props) => {

  return (
    <>
     <div className="new-landing-sec">
        <NewLandingHeader/>
        <NewLandingCategories/>
        <NewLandingBannerSlider/>
        <ShopByCategory/>
        <ShopByCollection/>
        <NewArrivalsIndex/>
        <TopSellers/>
        <NewForYou/>
     </div>
    </>
  );
};

export default NewLandingPageIndex;
