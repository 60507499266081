import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';

import {
  reviewsSaveSuccess,
  reviewsSaveFailure,
  reviewsSaveFilesSuccess,
  reviewsSaveFilesFailure,
  reviewsDeleteFilesSuccess,
  reviewsDeleteFilesFailure,
} from "../slices/ReviewSlice";

import {
  fetchProductReviewListSuccess, singleProductSuccess,
} from "../slices/ProductSlice";

function* reviewsSaveAPI(action) {
  const reviewListData = yield select(state => state.product.productReviews.data);
  const singleProductData = yield select(state => state.product.singleProduct.data);
  yield getCommonSaga({
    apiUrl: "reviews_save",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(reviewsSaveSuccess(data))
      if (Object.keys(reviewListData).length > 0) {
        store.dispatch(fetchProductReviewListSuccess({
          ...reviewListData,
          ratings: data.product_reviews.data.ratings,
          reviews: [data.review, ...reviewListData.reviews],
          total_reviews: reviewListData.total_reviews + 1,
          total_avg:data.review_stars,
        }));
      }
      if (Object.keys(singleProductData).length > 0) {
        store.dispatch(singleProductSuccess({
          ...singleProductData,
          product: {
            ...singleProductData.product,
            review_stars: data.review_stars,
            total_reviews: singleProductData.product.total_reviews + 1,
          }
        }));
      }
    },
    failure: reviewsSaveFailure,
  });
}

function* reviewsSaveFilesAPI(action) {
  yield getCommonSaga({
    apiUrl: "review_files_save",
    payload: action.payload,
    successNotify: false,
    success: reviewsSaveFilesSuccess,
    failure: reviewsSaveFilesFailure,
  });
}

function* reviewsDeleteFilesAPI(action) {
  yield getCommonSaga({
    apiUrl: "review_files_delete",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => store.dispatch(reviewsDeleteFilesSuccess({ data: data })),
    failure: reviewsDeleteFilesFailure,
  });
}

export default function* ReviewSaga() {
  yield all([
    yield takeLatest('reviews/reviewsSaveStart', reviewsSaveAPI),
    yield takeLatest('reviews/reviewsSaveFilesStart', reviewsSaveFilesAPI),
    yield takeLatest('reviews/reviewsDeleteFilesStart', reviewsDeleteFilesAPI),
  ]);
}