import React from 'react';
import Skeleton from 'react-loading-skeleton';

const RatingLoader = () => {
  return (
    <>
      <div className="product-review-frame">
        <div className="product-review-head">
          <Skeleton count={1} height={23} width={150} />
        </div>
        <div className="product-rating-point">
          <Skeleton count={1} height={64} width={70} />
          <Skeleton className="mt-3" count={1} height={24} width={150} />
        </div>
        <div className="reviews-bar-wrap">
          <div className="reviews-bar-card mt-2">
            <span><Skeleton count={5} height={24} width={80} /></span>
            <span><Skeleton count={5} height={10} width={540} /></span>
            <span><Skeleton count={5} height={24} width={33} /></span>
          </div>
        </div>
        <div className="review-preview-frame">
          <div className="review-preview-card">
            <div className="review-preview-card-img">
              <Skeleton count={1} circle={true} height={40} width={40} />
            </div>
            <div className="review-preview-card-info">
              <Skeleton count={1} height={23} width={145} />
            </div>
          </div>
          <div className="review-preview-ratings">
            <Skeleton count={1} height={36} width={110} />
          </div>
          <div className="rating-product-review-frame">
            <Skeleton count={1} height={20} width={150} />
          </div>
        </div>
      </div>
    </>
  )
}

export default RatingLoader