
import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Row,
  Col,
  Container,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { fetchBannerListStart } from "../../store/slices/HomePageSlice";
import CustomLazyLoad from '../Helper/CustomLazyLoad';
import { cartSaveStart } from '../../store/slices/CartSlice';
import { useNavigate } from 'react-router-dom';
import AuthModal from '../Auth/AuthModal';
import { Button } from 'react-bootstrap';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const BannerHero = () => {

  const dispatch = useDispatch();
  const t = useTranslation("home_banner");
  const navigate = useNavigate();
  const bannerList = useSelector(state => state.homepage.bannerList);
  const profile = useSelector(state => state.user.profile);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    dispatch(fetchBannerListStart());
  }, []);

  const addCartDirect = (banner) => {
    if (Object.keys(profile.data).length > 0) {
      dispatch(
        cartSaveStart({
          product_attribute_id: banner.product_attribute_id,
          quantity: 1,
          size: banner.sizes[0],
          weight: banner.weight
        })
      );
      navigate("/cart")
    } else {
      setLogin(true);
    }

  }

  const checkoutDirect = (banner) => {
    if (Object.keys(profile.data).length > 0) {
      dispatch(
        cartSaveStart({
          product_attribute_id: banner.product_attribute_id,
          quantity: 1,
          size: banner.sizes[0],
          weight: banner.weight
        })
      );
      navigate("/checkout-address");
    } else {
      setLogin(true);
    }
  }

  const closeLoginModal = () => {
    setLogin(false);
  };


  return (
    <>

      <div className="demo-swiper-one ">
        <Container>
          <Row>
            <Col md={12} xl={12} lg={12}>
              <Swiper
                spaceBetween={30}
                loop={true}
                centeredSlides={true}
                speed={1200}
                autoplay={{
                  delay: 5500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Autoplay, Navigation]}
                className="mySwiper hero-mySwiper"
                mousewheel={true}
                keyboard={true}
              >
                {bannerList.loading ?
                  ""
                  :
                  Object.keys(bannerList.data).length > 0 &&
                    bannerList.data.banners.length > 0
                    ?
                    bannerList.data.banners.map((banner, index) => (
                      <SwiperSlide>
                        <Row>
                          <Col md={12} xl={6} lg={6} className='respon-position'>
                            <div className="banner-content demo-one" key={index}>
                              <h1>  {banner.title}</h1>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: banner.description,
                                }}
                              ></p>
                              <div className="default-btn-frame">
                                {banner.type == 2 ?
                                  !banner.is_carted ?
                                    <>
                                      <Button
                                        className="default-btn-one"
                                        onClick={() => addCartDirect(banner)}
                                      >
                                        {" "}
                                        <svg
                                          className='add-cart-icon'
                                          width="21"
                                          height="20"
                                          viewBox="0 0 21 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g id="cart">
                                            <g id="Wheel">
                                              <path
                                                id="Vector"
                                                d="M8.5 19C8.77614 19 9 18.7761 9 18.5C9 18.2239 8.77614 18 8.5 18C8.22386 18 8 18.2239 8 18.5C8 18.7761 8.22386 19 8.5 19Z"
                                                fill="#303037"
                                                stroke="#303037"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <circle
                                                id="Mask"
                                                cx="8.5"
                                                cy="18.5"
                                                r="0.5"
                                                fill="#303037"
                                              />
                                            </g>
                                            <g id="Wheel_2">
                                              <path
                                                id="Vector_2"
                                                d="M15.5 19C15.7761 19 16 18.7761 16 18.5C16 18.2239 15.7761 18 15.5 18C15.2239 18 15 18.2239 15 18.5C15 18.7761 15.2239 19 15.5 19Z"
                                                fill="#303037"
                                                stroke="#303037"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <circle
                                                id="Mask_2"
                                                cx="15.5"
                                                cy="18.5"
                                                r="0.5"
                                                fill="#303037"
                                              />
                                            </g>
                                            <path
                                              id="Vector_3"
                                              d="M15.005 18.1C15.0078 18.1 15.01 18.0776 15.01 18.05C15.01 18.0224 15.0078 18 15.005 18C15.0022 18 15 18.0224 15 18.05C15 18.0776 15.0022 18.1 15.005 18.1Z"
                                              stroke="#303037"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              id="Vector_4"
                                              d="M4.86035 4.00002H17.0004C17.3168 3.99383 17.6301 4.06281 17.9147 4.20131C18.1993 4.3398 18.4469 4.54385 18.6373 4.79667C18.8276 5.0495 18.9552 5.3439 19.0097 5.65566C19.0641 5.96742 19.0438 6.28764 18.9504 6.59002L18.2504 8.88002C18.0619 9.49407 17.6814 10.0314 17.1648 10.4131C16.6481 10.7947 16.0227 11.0004 15.3804 11H6.86035"
                                              stroke="#303037"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              id="Vector_5"
                                              d="M16 15H10.26C9.60831 14.9987 8.97476 14.7853 8.45515 14.392C7.93553 13.9987 7.55813 13.4469 7.38 12.82L4.41 2.45C4.29064 2.03271 4.03878 1.66557 3.69242 1.404C3.34607 1.14243 2.92403 1.00063 2.49 1H1"
                                              stroke="#303037"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </g>
                                        </svg>
                                        {t("add_to_cart")}
                                      </Button>
                                      <Button
                                        onClick={() => checkoutDirect(banner)}
                                        className="default-btn-two">
                                        <svg
                                          className='add-cart-icon'
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g id="bag">
                                            <path
                                              id="Vector"
                                              d="M19.4901 14.47L18.8501 8.47C18.7211 7.25822 18.154 6.13525 17.2554 5.31213C16.3568 4.48901 15.1885 4.0224 13.9701 4C13.9701 2.93913 13.5486 1.92172 12.7985 1.17157C12.0483 0.421427 11.0309 0 9.97005 0C8.90919 0 7.89177 0.421427 7.14163 1.17157C6.39148 1.92172 5.97005 2.93913 5.97005 4C4.75163 4.0224 3.58334 4.48901 2.68472 5.31213C1.7861 6.13525 1.21902 7.25822 1.09005 8.47L0.450053 14.47C0.37545 15.1697 0.449389 15.8773 0.667037 16.5465C0.884684 17.2157 1.24114 17.8314 1.71308 18.3534C2.18503 18.8754 2.76185 19.2919 3.40581 19.5756C4.04976 19.8593 4.74637 20.004 5.45005 20H14.5501C15.2537 20.004 15.9503 19.8593 16.5943 19.5756C17.2383 19.2919 17.8151 18.8754 18.287 18.3534C18.759 17.8314 19.1154 17.2157 19.3331 16.5465C19.5507 15.8773 19.6247 15.1697 19.5501 14.47H19.4901ZM9.97005 2C10.5005 2 11.0092 2.21071 11.3843 2.58579C11.7593 2.96086 11.9701 3.46957 11.9701 4H7.97005C7.97005 3.46957 8.18077 2.96086 8.55584 2.58579C8.93091 2.21071 9.43962 2 9.97005 2ZM16.7501 17C16.4693 17.3138 16.1257 17.5651 15.7415 17.7374C15.3573 17.9097 14.9411 17.9991 14.5201 18H5.42005C4.99765 18.0024 4.5795 17.9156 4.19298 17.7452C3.80645 17.5749 3.46026 17.3248 3.17705 17.0114C2.89385 16.698 2.68003 16.3283 2.54958 15.9265C2.41913 15.5248 2.37499 15.1 2.42005 14.68L3.06005 8.68C3.13948 7.93931 3.49165 7.25466 4.04801 6.75929C4.60438 6.26392 5.32515 5.99327 6.07005 6H13.8801C14.6232 5.99576 15.3416 6.26754 15.8958 6.76267C16.4501 7.2578 16.8008 7.94104 16.8801 8.68L17.5201 14.68C17.5656 15.0993 17.5205 15.5235 17.3876 15.9238C17.2547 16.3241 17.0373 16.6912 16.7501 17Z"
                                              fill="white"
                                            />
                                            <path
                                              id="Vector_2"
                                              d="M6.97051 10.1C6.77515 10.0902 6.58696 10.0234 6.42921 9.90771C6.27147 9.79206 6.15111 9.63268 6.08303 9.44931C6.01495 9.26594 6.00215 9.06664 6.04619 8.87606C6.09024 8.68549 6.18921 8.51202 6.33085 8.37712C6.47249 8.24222 6.65058 8.15183 6.84308 8.11712C7.03557 8.08242 7.23402 8.10493 7.41385 8.18187C7.59368 8.2588 7.747 8.38679 7.85483 8.54998C7.96266 8.71318 8.02026 8.90441 8.02051 9.10001C7.99858 9.36645 7.8795 9.61555 7.6859 9.79992C7.49231 9.9843 7.2377 10.0911 6.97051 10.1Z"
                                              fill="white"
                                            />
                                            <path
                                              id="Vector_3"
                                              d="M12.9702 10C12.7604 9.99979 12.5556 9.93673 12.382 9.81899C12.2084 9.70125 12.0741 9.53422 11.9963 9.33943C11.9185 9.14464 11.9008 8.93101 11.9455 8.72609C11.9903 8.52117 12.0954 8.33434 12.2472 8.18968C12.3991 8.04503 12.5909 7.94918 12.7977 7.91449C13.0046 7.8798 13.2171 7.90785 13.4079 7.99505C13.5986 8.08224 13.7589 8.22457 13.8681 8.40369C13.9772 8.58281 14.0302 8.79051 14.0202 9.00002C13.9983 9.26647 13.8792 9.51556 13.6856 9.69994C13.492 9.88432 13.2374 9.99111 12.9702 10Z"
                                              fill="white"
                                            />
                                          </g>
                                        </svg>
                                        {t("buy_now")}
                                      </Button>
                                    </>
                                    :
                                    <Link
                                      className="default-btn-one"
                                      to="/cart"
                                    >
                                      {" "}
                                      <svg
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g id="cart">
                                          <g id="Wheel">
                                            <path
                                              id="Vector"
                                              d="M8.5 19C8.77614 19 9 18.7761 9 18.5C9 18.2239 8.77614 18 8.5 18C8.22386 18 8 18.2239 8 18.5C8 18.7761 8.22386 19 8.5 19Z"
                                              fill="#303037"
                                              stroke="#303037"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <circle
                                              id="Mask"
                                              cx="8.5"
                                              cy="18.5"
                                              r="0.5"
                                              fill="#303037"
                                            />
                                          </g>
                                          <g id="Wheel_2">
                                            <path
                                              id="Vector_2"
                                              d="M15.5 19C15.7761 19 16 18.7761 16 18.5C16 18.2239 15.7761 18 15.5 18C15.2239 18 15 18.2239 15 18.5C15 18.7761 15.2239 19 15.5 19Z"
                                              fill="#303037"
                                              stroke="#303037"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <circle
                                              id="Mask_2"
                                              cx="15.5"
                                              cy="18.5"
                                              r="0.5"
                                              fill="#303037"
                                            />
                                          </g>
                                          <path
                                            id="Vector_3"
                                            d="M15.005 18.1C15.0078 18.1 15.01 18.0776 15.01 18.05C15.01 18.0224 15.0078 18 15.005 18C15.0022 18 15 18.0224 15 18.05C15 18.0776 15.0022 18.1 15.005 18.1Z"
                                            stroke="#303037"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            id="Vector_4"
                                            d="M4.86035 4.00002H17.0004C17.3168 3.99383 17.6301 4.06281 17.9147 4.20131C18.1993 4.3398 18.4469 4.54385 18.6373 4.79667C18.8276 5.0495 18.9552 5.3439 19.0097 5.65566C19.0641 5.96742 19.0438 6.28764 18.9504 6.59002L18.2504 8.88002C18.0619 9.49407 17.6814 10.0314 17.1648 10.4131C16.6481 10.7947 16.0227 11.0004 15.3804 11H6.86035"
                                            stroke="#303037"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            id="Vector_5"
                                            d="M16 15H10.26C9.60831 14.9987 8.97476 14.7853 8.45515 14.392C7.93553 13.9987 7.55813 13.4469 7.38 12.82L4.41 2.45C4.29064 2.03271 4.03878 1.66557 3.69242 1.404C3.34607 1.14243 2.92403 1.00063 2.49 1H1"
                                            stroke="#303037"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </g>
                                      </svg>
                                      {t("go_to_cart")}
                                    </Link>
                                  :
                                  <Link to="/products" className="default-btn-two">
                                    {t("shop_now")}
                                  </Link>
                                }

                              </div>
                            </div>
                          </Col>
                          <Col md={12} xl={6} lg={6}>
                            <div className="slide-wrapped">
                              <CustomLazyLoad
                                src={banner.picture}
                                placeholderSrc={window.location.origin + "/assets/img/new/header-banner.png"}
                                className="banner-slider-img"
                                alt={banner.title}
                              />
                            </div>
                          </Col>
                        </Row>
                      </SwiperSlide>
                    ))
                    :
                    null
                }
              </Swiper>
            </Col>
          </Row>
        </Container>
      </div>
      {
        login ?
          <AuthModal closeLoginModal={closeLoginModal} />
          : null
      }

    </>
  );
};

export default BannerHero;