import React, { useState } from "react";
import { useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createOrderFailure } from "../../store/slices/OrderSlice";
import "./CheckOut.css";

const OrderPlaced = (props) => {
  const t = useTranslation("order_placed");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createOrder = useSelector(state => state.order.createOrder);
  const [orderUniqueId, setOrderUniqueId] = useState("");

  useEffect(() => {
    if (Object.keys(createOrder.data) > 0) {
      setOrderUniqueId(createOrder.data.order_unique_id);
      localStorage.removeItem("addessUniqueId");
      dispatch(createOrderFailure());
    }
    else
      navigate("/");
  }, []);


  return (
    <>
      {orderUniqueId ?
        <div className="order-placed-sec">
          <Container>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="order-place-card">
                  <div className="order-placed-img-sec">
                    <Image
                      className="order-placed-img"
                      src={
                        window.location.origin + "/assets/img/orderplaced/teenyicons_tick-circle-solid.png"
                      }
                    />
                  </div>
                  <div className="order-placed-info">
                    <h2>{t("heading")}</h2>
                    <p>{t("message")}</p>
                    <p>{t("order_id")}<span>{orderUniqueId}</span></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        : null
      }
    </>
  );
};

export default OrderPlaced;
