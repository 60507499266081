import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  withdrawRequestSuccess,
  withdrawRequestFailure,
  addUserBillingAccountsSuccess,
  addUserBillingAccountsFailure,
  userBillingAccountsSuccess,
  userBillingAccountsFailure,
  withdrawRequestListSuccess,
  withdrawRequestListFailure,
  cancelWithdrawRequestSuccess,
  cancelWithdrawRequestFailure,
  defaultUserBillingAccountsSuccess,
  defaultUserBillingAccountsFailure,
  deleteUserBillingAccountsSuccess,
  deleteUserBillingAccountsFailure,
  fetchWalletTransactionListFailure,
  fetchWalletTransactionListSuccess,
  generateStripeSuccess,
  generateStripeFailure,
  paymentByStripeSuccess,
  paymentByStripeFailure,
  addCardSuccess,
  addCardFailure,
} from "../slices/WalletSlice";

function* withdrawRequestAPI(action) {
  yield getCommonSaga({
    apiUrl: "withdrawal_requests_send",
    payload: action.payload,
    success: withdrawRequestSuccess,
    failure: withdrawRequestFailure,
  });
}
function* addUserBillingAccountsAPI(action) {
  const userBillingAccountsListData = yield select(state => state.wallet.userBillingAccounts.data)
  yield getCommonSaga({
    apiUrl: "user_billing_accounts_save",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(addUserBillingAccountsSuccess(data))
      if (Object.keys(userBillingAccountsListData).length > 0)
        store.dispatch(userBillingAccountsSuccess({
          ...userBillingAccountsListData,
          user_billing_accounts: userBillingAccountsListData.user_billing_accounts.map(account =>
            account.user_billing_account_id === action.payload.user_billing_account_id
              ? { 
                ...account, is_default: data.is_default
              } : account
          )
        }));
    },
    failure: addUserBillingAccountsFailure,
  });
}
function* userBillingAccountsAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_billing_accounts_list",
    payload: action.payload,
    successNotify: false,
    success: userBillingAccountsSuccess,
    failure: userBillingAccountsFailure,
  });
}
function* setDefaultUserBillingAccountsAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_billing_accounts_make_default",
    payload: action.payload,
    success: defaultUserBillingAccountsSuccess,
    failure: defaultUserBillingAccountsFailure,
  });
}

function* deleteUserBillingAccountsAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_billing_accounts_delete",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(deleteUserBillingAccountsSuccess({ data: data })),
    failure: deleteUserBillingAccountsFailure,
  });
}

function* withdrawMoreRequestListAPI(action) {
  const withdrawRequestListData = yield select(state => state.wallet.withdrawRequestList.data)
  yield getCommonSaga({
    apiUrl: "withdrawal_requests",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(withdrawRequestListSuccess({
        ...data,
        user_withdrawals: [...withdrawRequestListData.user_withdrawals, ...data.user_withdrawals],
        total_withdrawals: data.total_withdrawals,
      }))
    },
    failure: withdrawRequestListFailure,
  });
}

function* cancelWithdrawRequestAPI(action) {
  yield getCommonSaga({
    apiUrl: "withdrawal_requests_cancel",
    payload: action.payload,
    success: cancelWithdrawRequestSuccess,
    failure: cancelWithdrawRequestFailure,
  });
}

function* fetchMoreWalletTransactionAPI(action) {
  const walletTransactionListData = yield select(state => state.wallet.walletTransactionList.data)
  yield getCommonSaga({
    apiUrl: "user_wallet",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => store.dispatch(fetchWalletTransactionListSuccess({
      user_wallet_payments: [...walletTransactionListData.user_wallet_payments, ...data.user_wallet_payments],
      total_wallet_payments: data.total_wallet_payments,
      user_wallet: data.user_wallet,
    })),
    failure: fetchWalletTransactionListFailure,
  });
}

function* generateStripeAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallets_generate_stripe_payment",
    payload: action.payload,
    successNotify: false,
    success: generateStripeSuccess,
    failure: generateStripeFailure,
  });
}

function* paymentByStripeAPI(action) {
  const walletTransactionListData = yield select(state => state.wallet.walletTransactionList.data)
  yield getCommonSaga({
    apiUrl: "user_wallets_stripe_payments_save",
    payload: action.payload,
    success: paymentByStripeSuccess,
    failure: paymentByStripeFailure,
  });
}

export default function* WalletSaga() {
  yield all([
    yield takeLatest('wallet/withdrawRequestStart', withdrawRequestAPI),
    yield takeLatest('wallet/addUserBillingAccountsStart', addUserBillingAccountsAPI),
    yield takeLatest('wallet/userBillingAccountsStart', userBillingAccountsAPI),
    yield takeLatest('wallet/defaultUserBillingAccountsStart', setDefaultUserBillingAccountsAPI),
    yield takeLatest('wallet/deleteUserBillingAccountsStart', deleteUserBillingAccountsAPI),
    yield takeLatest('wallet/withdrawRequestListStart', withdrawMoreRequestListAPI),
    yield takeLatest('wallet/withdrawMoreRequestList', withdrawMoreRequestListAPI),
    yield takeLatest('wallet/cancelWithdrawRequestStart', cancelWithdrawRequestAPI),
    yield takeLatest('wallet/fetchWalletTransactionListStart', fetchMoreWalletTransactionAPI),
    yield takeLatest('wallet/fetchMoreWalletTransaction', fetchMoreWalletTransactionAPI),
    yield takeLatest('wallet/generateStripeStart', generateStripeAPI),
    yield takeLatest('wallet/paymentByStripeStart', paymentByStripeAPI),

  ]);
}

