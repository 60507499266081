import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { setTranslations, setDefaultLanguage, setLanguage } from 'react-multi-lang'
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import ProfileIndex from "../Profile/ProfileIndex";
import ChangePassword from "../Profile/ChangePassword";
import DeleteAccount from "../Profile/DeleteAccount";
import NotificationPreference from "../Profile/NotificationPrefrence";
import TwoStepAuthentication from "../Profile/TwoStepAuthentication";
import MyOrders from "../Profile/MyOrders";
import OrderTracking from "../Order/OrderTracking";
import ManageAddress from "../Profile/ManageAddress";
import OrderTransaction from "../Order/OrderTransaction";
import ContactUs from "../StaticPage/ContactUs";
import OurServices from "../StaticPage/OurServices";
import PrivacyPolicy from "../StaticPage/PrivacyPolicy";
import TermsCondition from "../StaticPage/TermsCondition";
import OrderPlaced from "../CheckOut/OrderPlaced";
import WalletIndex from "../Wallet/WalletIndex";
import CancelOrder from "../Profile/CancelOrder";
import AddMoney from "../Wallet/AddMoney";
import WalletWithdrawMoney from "../Wallet/WalletWithdrawMoney";
import Withdraw from "../Wallet/Withdraw";
import CartIndex from "../CheckOut/CartIndex";
import CheckoutAddress from "../CheckOut/CheckoutAddress";
import CheckOutSummary from "../CheckOut/ChecOutSummary";
import CheckOutPayment from "../CheckOut/CheckOutPayment";
import ProductFilter from "../Product/ProductFilter";
import { useDispatch, useSelector } from "react-redux";
import SingleProductIndex from "../Product/SingelProductIndex";
import StaticIndex from "../StaticPage/StaticIndex";
import WishListIndex from "../WishList/WishListIndex";
import ProductReviewList from "../Product/ProductReviewList";
import CheckoutLayout from "../layouts/CheckoutLayout";

import en from "../translations/en.json";
import es from "../translations/es.json";
import { cartSaveStart } from "../../store/slices/CartSlice";
import NewLandingPageIndex from "../NewLandingPage/NewLandingPageIndex";
import NewSingleProductIndex from "../Product/NewSingleProductIndex";
import LandingPageNew from "../New/LandingPageNew";
import ProductDetail from "../New/ProductDetail";
import Reviews from "../New/Reviews";
import OrderHistory from "../New/OrdersHistory";
import TrackProduct from "../New/TrackProduct";

setTranslations({ en, es });

const $ = window.$;

setTranslations({ en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let authentication =
    userId && accessToken ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  setLanguage("en");
  const dispatch = useDispatch();
  // This state works. dont remove or change
  const [, updateState] = useState();
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    const userLanguage = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    localStorage.setItem("lang", userLanguage);
    setDefaultLanguage(userLanguage);
  }, []);

  useEffect(() => {
    if (!profile.loading) {
      updateState({});
      const local = localStorage.getItem("cart");
      if (Object.keys(profile.data).length > 0 && local && JSON.parse(local).length > 0) {
        dispatch(cartSaveStart({ carts: local }));
        localStorage.removeItem("cart");
      }
    }
  }, [profile]);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>
      <BrowserRouter>
        <Routes>
          {/* <Route
            path={"/"}
            element={
              <AppRoute
                component={LandingPageIndex}
                layout={MainLayout}
              />
            }
          /> */}
          <Route
            path={"/landing"}
            element={
              <AppRoute
                component={NewLandingPageIndex}
                layout={EmptyLayout}
              />
            }
          />
          <Route
            path={"/single-product"}
            element={
              <AppRoute
                component={NewSingleProductIndex}
                layout={EmptyLayout}
              />
            }
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute
                component={ProfileIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/change-password"}
            element={
              <PrivateRoute
                component={ChangePassword}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/delete-account"}
            element={
              <PrivateRoute
                component={DeleteAccount}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/notification-preference"}
            element={
              <PrivateRoute
                component={NotificationPreference}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/two-step-auth"}
            element={
              <PrivateRoute
                component={TwoStepAuthentication}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/orders"}
            element={
              <PrivateRoute
                component={MyOrders}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/orders/:status"}
            element={
              <PrivateRoute
                component={MyOrders}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/cancel-order"}
            element={
              <PrivateRoute
                component={CancelOrder}
                layout={MainLayout}
              />
            }
          />
          {/* <Route
            path={"/orders/tracking/:orderUniqueId/:orderProductUniqueId"}
            element={
              <PrivateRoute
                component={OrderTracking}
                layout={MainLayout}
              />
            }
          /> */}
          <Route
            path={"/order-transactions"}
            element={
              <PrivateRoute
                component={OrderTransaction}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/my-addresses"}
            element={
              <PrivateRoute
                component={ManageAddress}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/contact-us"}
            element={
              <AppRoute
                component={ContactUs}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/our-services"}
            element={
              <AppRoute
                component={OurServices}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/privacy-policy"}
            element={
              <AppRoute
                component={PrivacyPolicy}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/terms-condition"}
            element={
              <AppRoute
                component={TermsCondition}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/order-placed"}
            element={
              <AppRoute
                component={OrderPlaced}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/wallet"}
            element={
              <PrivateRoute
                component={WalletIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/add-money"}
            element={
              <PrivateRoute
                component={AddMoney}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/withdraw-money"}
            element={
              <PrivateRoute
                component={WalletWithdrawMoney}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/withdraw"}
            element={
              <PrivateRoute
                component={Withdraw}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/cart"}
            element={
              <AppRoute
                component={CartIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/checkout-address"}
            element={
              <AppRoute
                component={CheckoutAddress}
                layout={CheckoutLayout}
              />
            }
          />
          <Route
            path={"/checkout-summary"}
            element={
              <AppRoute
                component={CheckOutSummary}
                layout={CheckoutLayout}
              />
            }
          />
          <Route
            path={"/checkout-payment"}
            element={
              <AppRoute
                component={CheckOutPayment}
                layout={CheckoutLayout}
              />
            }
          />
          <Route
            path={"/products"}
            element={
              <AppRoute
                component={ProductFilter}
                layout={MainLayout}
              />
            }
          />
          {/* <Route
            path={"/product/:productUniqueId"}
            element={
              <AppRoute
                component={SingleProductIndex}
                layout={MainLayout}
              />
            }
          /> */}
          {/* <Route
            path={"/product/:productUniqueId/:productAttributeId"}
            element={
              <AppRoute
                component={SingleProductIndex}
                layout={MainLayout}
              />
            }
          /> */}
          <Route
            path={"/page/:static_page_unique_id"}
            element={
              <AppRoute
                component={StaticIndex}
                layout={MainLayout}
                redirect={false}
              />
            }
          />
          <Route
            path={"/wishlist"}
            element={
              <PrivateRoute
                component={WishListIndex}
                layout={MainLayout} />
            }
          />
          {/* <Route
            path={"/product-reviews/:product_unique_id"}
            element={
              <AppRoute
                component={ProductReviewList}
                layout={MainLayout} />
            }
          /> */}

          {/* new  */}
          <Route
            path={"/"}
            element={
              <AppRoute
                component={LandingPageNew}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/product/:productUniqueId"}
            element={
              <AppRoute
                component={ProductDetail}
                layout={MainLayout}
              />
            }
          />
               <Route
            path={"/product-reviews/:product_unique_id"}
            element={
              <AppRoute
              component={Reviews}
                layout={MainLayout} />
            }
          />
          <Route
            path={"/order-details"}
            element={
              <PrivateRoute
                component={OrderHistory}
                layout={MainLayout}
              />
            }
          />
          <Route
          path={"/orders/tracking/:orderUniqueId/:orderProductUniqueId"}
            element={
              <PrivateRoute
                component={TrackProduct}
                layout={MainLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
