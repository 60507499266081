import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import configuration from "react-global-configuration";
import ProfileSidebar from "../Profile/ProfileSidebar";
import "./Wallet.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { generateStripeStart } from "../../store/slices/WalletSlice";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";
import StripePaymentSec from "./StripePaymentSec";


const AddMoney = () => {

  const dispatch = useDispatch();
  const t = useTranslation("add_money");

  const [amount, setAmount] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const [clientSecret, setClientSecret] = useState("");


  const generateStripe = useSelector(state => state.wallet.generateStripe);
  const profile = useSelector(state => state.user.profile);

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount < 1) dispatch(getErrorNotificationMessage(t("message")));
    else dispatch(generateStripeStart({ amount: amount }));
  };

  useEffect(() => {
    if (!skipRender && !generateStripe.loading && Object.keys(generateStripe.data).length > 0) {
      setClientSecret(generateStripe.data.clientSecret);
    }
    setSkipRender(false);
  }, [generateStripe]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                <div className="wallet-sec">
                  <div className="profile-header-sec">
                    <h3>{t("message")}</h3>
                    <p>{t("welcome")} {profile.data.username}</p>
                  </div>
                </div>

                <Form onSubmit={handleSubmit}>
                  <div className="add-money-header-sec">
                    <div className="add-money-amount-details">
                      <Form.Group className="mb-0">
                        <Form.Control
                          value={amount}
                          min="50"
                          placeholder={t("placeholder")}
                          onChange={e => setAmount(e.target.value)}
                        />
                      </Form.Group>
                      <Button className="default-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="#fff"
                        >
                          <path d="M18.5 9.5A1.5 1.5 0 0020 8v-.687A5.32 5.32 0 0014.687 2H13.5v-.5a1.5 1.5 0 00-3 0V2H9.313a5.313 5.313 0 00-1.541 10.4l2.728.746V19H9.313A2.316 2.316 0 017 16.687V16a1.5 1.5 0 00-3 0v.687A5.32 5.32 0 009.313 22H10.5v.5a1.5 1.5 0 003 0V22h1.187a5.313 5.313 0 001.541-10.4l-2.728-.744V5h1.187A2.316 2.316 0 0117 7.313V8a1.5 1.5 0 001.5 1.5zm-3.118 4.979a2.314 2.314 0 01-.7 4.521H13.5v-5.035zM10.5 10.035l-1.882-.514A2.314 2.314 0 019.313 5H10.5z"></path>
                        </svg>
                      </Button>
                    </div>
                    <div className="add-money-btn-sec">
                      {!clientSecret || generateStripe.data.amount != amount ?
                        <Button
                          type="submit"
                          className="default-btn"
                          disabled={generateStripe.buttonDisable}
                        >
                          {generateStripe.buttonDisable ?
                            t("add_money_btn.loading")
                            : t("add_money_btn.text")
                          }
                        </Button>
                        : null
                      }
                    </div>
                  </div>
                </Form>

                {!generateStripe.loading && clientSecret && generateStripe.data.amount == amount ?
                  <Elements options={options} stripe={stripePromise}>
                    <StripePaymentSec clientSecret={clientSecret} wallet={1} />
                  </Elements>
                  : null
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AddMoney;
