import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NumericInput from "react-numeric-input";
import "./Cart.css";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { fetchsingleDeliveryAddressStart } from "../../store/slices/DeliverySlice";
import { cartListStart } from "../../store/slices/CartSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Skeleton from "react-loading-skeleton";

const CheckOutSummary = (props) => {
  const t = useTranslation("checkout");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const cartList = useSelector(state => state.cart.cartList);
  const singleDeliveryAddress = useSelector(state => state.delivery.singleDeliveryAddress);

  const [skipRender, setSkipRender] = useState(true);
  const [showContent, setShowContent] = useState(true);


  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (localStorage.getItem("addessUniqueId")) {
        dispatch(fetchsingleDeliveryAddressStart({
          delivery_address_unique_id: localStorage.getItem("addessUniqueId")
        }));
        dispatch(cartListStart());
      } else {
        navigate("/checkout-address");
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!skipRender &&
      (!singleDeliveryAddress.loading && Object.keys(singleDeliveryAddress.data).length === 0) ||
      (!cartList.loading && Object.keys(cartList.data).length === 0)) {
      setShowContent(false);
    }
    setSkipRender(false);
  }, [singleDeliveryAddress, cartList]);


  return (
    <>
      <div className="cart-sec">
        {profile.loading ?
          <PageLoader />
          : showContent && Object.keys(profile.data).length > 0 ?
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title">
                    <h1>{t("your_cart")}</h1>
                  </div>
                </Col>
              </Row>
              <div className="cart-box">
                <Row className="gy-4 justify-content-center">
                  <Col md={12} xl={8} lg={8}>
                    <div className="checkout-header-sec">
                      <ul className="list-unstyled checkout-progress-bar">
                        <li className="step1">
                          <div className="checkout-flex">
                            <div className="count-no">1</div>
                            <h6>{t("section.address")}</h6>
                          </div>
                        </li>
                        <li className="step2">
                          <div className="checkout-flex active">
                            <div className="count-no">2</div>
                            <h6>{t("section.order_summary")}</h6>
                          </div>
                        </li>
                        <li className="step3">
                          <div className="checkout-flex">
                            <div className="count-no">3</div>
                            <h6>{t("section.payment")}</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="checkout-body">
                      {singleDeliveryAddress.loading
                        ? <Skeleton height={200} />
                        : singleDeliveryAddress.data.delivery_address ?
                          <div className="checkout-delivery-card">
                            <div className="checkout-delivery-address-info">
                              <h4>{singleDeliveryAddress.data.delivery_address.name}</h4>
                              <p className="address-block">
                                {singleDeliveryAddress.data.delivery_address.address}{"\n"}
                                {singleDeliveryAddress.data.delivery_address.city}{" "}
                                {singleDeliveryAddress.data.delivery_address.state}{" "}
                                {singleDeliveryAddress.data.delivery_address.pincode}{"\n"}
                                {singleDeliveryAddress.data.delivery_address.landmark}{"\n"}
                                {singleDeliveryAddress.data.delivery_address.contact_number}
                              </p>
                            </div>
                            <div className="checkout-delivery-default-btn">
                              <Link to="/checkout-address" className="default-btn">{t("change_address")}</Link>
                            </div>
                          </div>
                          : null
                      }
                      {cartList.loading
                        ? <Skeleton count={3} height={200} />
                        : cartList.data.carts.filter(cart => cart.product_attribute.stock_status !== 0).map((cartItem, i) =>
                          <div className="checkout-summary-product-sec">
                            <div className="checkout-summary-product-card">
                              <div className="cart-list-card">
                                <div className="cart-list-left-sec">
                                  <Link to="#">
                                    <div className="cart-list-img-sec">
                                      <Image
                                        className="img cart-list-img"
                                        src={cartItem.product_attribute.file}
                                      />
                                    </div>
                                  </Link>
                                  <div className="cart-list-info">
                                    <Link to={`/product/${cartItem.product.product_unique_id}`}
                                      className="text-decoration-none">
                                      <h4>{cartItem.product.name}</h4>
                                    </Link>
                                    <p>{t("product_details.category")} <span>{
                                      cartItem.product.product_categories.map((category, i) => {
                                        let name = category.category_name;
                                        if (i !== (cartItem.product.product_categories.length - 1))
                                          name += " - ";
                                        return name
                                      })
                                    }</span></p>
                                    {cartItem.attributes.weight ?
                                      <p>{t("product_details.vol")} <span>{cartItem.attributes.weight}</span></p>
                                      : null
                                    }
                                    {cartItem.attributes.size ?
                                      <p>{t("product_details.size")} <span>{cartItem.attributes.size}</span></p>
                                      : null
                                    }
                                    <p>{t("product_details.qty")} <span>{cartItem.quantity}</span></p>
                                  </div>
                                </div>
                                <div className="cart-list-right-sec">
                                  <div className="cart-list-amout-card">
                                    <div className="cart-amount-info">
                                      <h4>{cartItem.product_attribute.selling_price_formatted}</h4>
                                      {cartItem.product_attribute.discount_tag ?
                                        <p>{cartItem.product_attribute.discount_tag} | <del>{cartItem.product_attribute.original_price_formatted}</del></p>
                                        : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="checkout-delivery-status">
                              {t("product_details.delivery_by", { date: cartItem.expected_at_formatted })}
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="checkout-delivery-continue-card">
                      <h4>{t("confirm_order")}</h4>
                      <Link to="/checkout-payment" className="default-btn">{t("continue")}</Link>
                    </div>
                  </Col>
                  {cartList.loading ?
                    <Col md={6} xl={4} lg={4}>
                      <Skeleton height={250} />
                    </Col>
                    : cartList.data.user_cart_amount ?
                      <Col md={6} xl={4} lg={4}>
                        <div className="cart-card">
                          <div className="cart-header">
                            <h4>{t("price_details.heading")}</h4>
                          </div>
                          <div className="cart-body">
                            <div className="cart-summary-info">
                              <div className="cart-summary-header">
                                <p>{t("price_details.cart_total")}<span>{cartList.data.user_cart_amount.cart_total_amount}</span></p>
                                <p>{t("price_details.discount")}<span>-{cartList.data.user_cart_amount.cart_discount_amount}</span></p>
                              </div>
                              <div className="cart-summary-total-amount">
                                <h5>{t("price_details.total_amount")}<span>{cartList.data.user_cart_amount.cart_checkout_amount}</span></h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      : null
                  }
                </Row>
              </div>
            </Container>
            : <div className="text-center">
              <SomethingWentWrong />
              <Button onClick={() => navigate("/")}>{t("go_home")}</Button>
            </div>
        }
      </div>
    </>
  );
};

export default CheckOutSummary;
