import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { metalListStart } from "../../store/slices/ProductSlice";
import { useTranslation } from "react-multi-lang";

const MetalsList = () => {
  const dispatch = useDispatch();
  const t = useTranslation("landing_page");
  const metalList = useSelector((state) => state.product.metalList);

  useEffect(() => {
    dispatch(metalListStart());
  }, []);

  return (
    <div className="thing-frame mt-3">
      <div className="new-section-title">
        <h2>{t("metal_products")}</h2>
        <p>{t("located_in_the_heart_of_the_jeweler_hub")}</p>
      </div>
      <Container>
        <div className="thing-product-main">
          {metalList.loading ? (
            <React.Fragment>
              {[...Array(10)].map((i) => (
                <Skeleton height={40} width={40} />
              ))}
            </React.Fragment>
          ) : metalList.data.metals && metalList.data.metals.length > 0 ? (
            <React.Fragment>
              {metalList.data.metals.map((metal, i) => (
                <Link to={`/products?metal=${metal.metal_id}`}>
                  <div className="thing-frame-card">
                    <div className="thing-image-sec">
                      <Image className="thing-image" src={metal.file} />
                    </div>
                    <div className="thing-info">
                      <h4>{metal.name}</h4>
                    </div>
                  </div>
                </Link>
              ))}
            </React.Fragment>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default MetalsList;
