import UserSlice from "./UserSlice";
import PageSlice from "./PageSlice";
import WalletSlice from "./WalletSlice";
import ProductSlice from "./ProductSlice";
import CartSlice from "./CartSlice";
import OrderSlice from "./OrderSlice";
import DeliverySlice from "./DeliverySlice";
import ReviewSlice from "./ReviewSlice";
import HomePageSlice from "./HomePageSlice";

export const reducers = {
    user: UserSlice,
    page: PageSlice,
    wallet: WalletSlice,
    product: ProductSlice,
    cart: CartSlice,
    order: OrderSlice,
    delivery: DeliverySlice,
    reviews: ReviewSlice,
    homepage: HomePageSlice,
};