import React, { useState, useRef, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./Static.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contactSubmitStart } from "../../store/slices/PageSlice";

const ContactUs = (props) => {

  const contactRef = useRef();
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation("contact_us");

  const dispatch = useDispatch();
  const contactSubmit = useSelector(state => state.page.contactSubmit);

  const contactSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")),
    email: Yup.string().email(t("email.invalid_email")).required(t("email.required")),
    query: Yup.string().required(t("query.query_required")),
    mobile: Yup.string().matches(
      /^(?=.*[0-9])(?=.{9,})/,
      t("mobile_number.invalid_number")
    ),
  });

  const handleSubmit = (values) => {
    dispatch(contactSubmitStart(values));
  };

  useEffect(() => {
     window.scrollTo(0, 0);
    if (
      !skipRender &&
      !contactSubmit.loading &&
      Object.keys(contactSubmit.data).length > 0
    ) {
      contactRef.current.resetForm();
    }
    setSkipRender(false);
  }, [contactSubmit]);

  return (
    <>
      <div className="contact-us-sec">
        <div className="contact-us-header-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="contat-us-title">
                  <h1>{t("heading")}</h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contact-us-form-box">
          <Container>
            <Row>
              <Col md={6}>
                <div className="contact-us-img-sec">
                  <Image
                    className="contact-us-img"
                    src={
                      window.location.origin + "/assets/img/contact-us/contat-us-left-img.png"
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <Formik initialValues={{
                  name: "",
                  mobile: "",
                  email: "",
                  query: ""
                }}
                  validationSchema={contactSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  <FORM className="account-form">
                    <div className="contact-us-form-card">
                      <h2>{t("get_in_touch")}</h2>
                      <p>className aptent taciti sociosqu ad litora torquent per conubia nostr.
                        Mauris in erat justullam ac urna eu felis dapib</p>
                      <Form.Group className="mb-4">
                        <Field
                          type="text"
                          placeholder={t("name.placeholder")}
                          name="name"
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="name"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Field
                          type="number"
                          name="mobile"
                          placeholder={t("mobile_number.placeholder")}
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="mobile"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Field
                          type="email"
                          name="email"
                          placeholder={t("email.placeholder")}
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Field
                          as="textarea"
                          name="query"
                          placeholder={t("query.placeholder")}
                          className="form-control height-auto"
                          rows={5}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="query"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <div className="conta-us-form">
                        <Button className="default-btn text-capitalize"
                          type="submit"
                          disabled={contactSubmit.buttonDisable}
                        >
                          {contactSubmit.buttonDisable ?
                            t("contact_btn.loading") :
                            t("contact_btn.text")}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contact-us-location-grid">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={8} lg={9}>
                <div className="section-title">
                  <h5 className="top-sell">{t("top_selling_products")}</h5>
                  <h2>{t("connect_with_us")}</h2>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              {configuration.get("configData.contact_address") ?
                <Col md={3}>
                  <div className="contact-us-location-card">
                    <div className="contact-us-loaction-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        data-name="Layer 1"
                        fill="#203C3D"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .007a10 10 0 00-7.059 17.082L12 23.994l7.071-6.917A10 10 0 0012 .007zm4.961 14.937L12 19.8l-4.95-4.844a7 7 0 019.9-9.9 7 7 0 01.011 9.887zM16 9.409V13h-3v-2h-2v2H8V9.409a1.5 1.5 0 01.589-1.192l2.5-1.909a1.5 1.5 0 011.822 0l2.5 1.909A1.5 1.5 0 0116 9.409z"></path>
                      </svg>
                    </div>
                    <div className="contact-us-location-info">
                      <h4>{t("address")}</h4>
                      <p>{configuration.get("configData.contact_address")}</p>
                    </div>
                  </div>
                </Col>
                : null
              }
              {configuration.get("configData.contact_mobile") ?
                <Col md={3}>
                  <div className="contact-us-location-card">
                    <div className="contact-us-loaction-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        data-name="Layer 1"
                        fill="#203C3D"
                        viewBox="0 0 24 24"
                      >
                        <path d="M17.5 24C9.351 24.229-5.075 9.787 1.862 1.938c.025-.024 1.092-.954 1.092-.954a3.583 3.583 0 014.908.041l2.152 2.318a3.522 3.522 0 01-.039 4.91l-.388.394a11.6 11.6 0 005.754 5.778l.411-.4a3.585 3.585 0 014.945 0l2.237 2.075a3.515 3.515 0 01.082 4.946s-.93 1.066-.954 1.091A6.39 6.39 0 0117.5 24zM3.95 4.1c-4.859 6.018 10.694 20.673 15.955 15.95 0 0 .924-1.059.949-1.083a.5.5 0 000-.707l-2.238-2.074c-.3-.25-.469-.253-.747-.04l-1.136 1.118a1.5 1.5 0 01-1.587.332 14.852 14.852 0 01-8.732-8.719 1.5 1.5 0 01.323-1.61l1.108-1.126a.485.485 0 00.009-.717L5.7 3.106a.487.487 0 00-.667.04c-.024.025-1.083.954-1.083.954zm12.858 10.986zM24 9.5A9.511 9.511 0 0014.5 0a1.5 1.5 0 000 3A6.508 6.508 0 0121 9.5a1.5 1.5 0 003 0zm-5 0A4.505 4.505 0 0014.5 5a1.5 1.5 0 000 3A1.5 1.5 0 0116 9.5a1.5 1.5 0 003 0z"></path>
                      </svg>
                    </div>
                    <div className="contact-us-location-info">
                      <h4>{t("phone")}</h4>
                      <p>{configuration.get("configData.contact_mobile")}</p>
                    </div>
                  </div>
                </Col>
                : null}
              {configuration.get("configData.contact_email") ?
                <Col md={3}>
                  <div className="contact-us-location-card">
                    <div className="contact-us-loaction-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        data-name="Layer 1"
                        fill="#203C3D"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .007a10 10 0 00-7.059 17.082L12 23.994l7.071-6.917A10 10 0 0012 .007zm4.961 14.937L12 19.8l-4.95-4.844a7 7 0 019.9-9.9 7 7 0 01.011 9.887zM16 9.409V13h-3v-2h-2v2H8V9.409a1.5 1.5 0 01.589-1.192l2.5-1.909a1.5 1.5 0 011.822 0l2.5 1.909A1.5 1.5 0 0116 9.409z"></path>
                      </svg>
                    </div>
                    <div className="contact-us-location-info">
                      <h4>{t("email")}</h4>
                      <p>{configuration.get("configData.contact_email")}</p>
                    </div>
                  </div>
                </Col>
                : null}
              <Col md={3}>
                <div className="contact-us-location-card">
                  <div className="contact-us-loaction-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      data-name="Layer 1"
                      fill="#203C3D"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 .007a10 10 0 00-7.059 17.082L12 23.994l7.071-6.917A10 10 0 0012 .007zm4.961 14.937L12 19.8l-4.95-4.844a7 7 0 019.9-9.9 7 7 0 01.011 9.887zM16 9.409V13h-3v-2h-2v2H8V9.409a1.5 1.5 0 01.589-1.192l2.5-1.909a1.5 1.5 0 011.822 0l2.5 1.909A1.5 1.5 0 0116 9.409z"></path>
                    </svg>
                  </div>
                  <div className="contact-us-location-info">
                    <h4>{t("additional_information")}</h4>
                    <p>{t("open_hours")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {configuration.get("configData.instagram_link") ||
          configuration.get("configData.twitter_link") ||
          configuration.get("configData.linkedin_link") ||
          configuration.get("configData.facebook_link")
          ?
          <div className="contact-us-social-link">
            <Container>
              <hr></hr>
              <Row>
                <Col md={12}>
                  <div className="social-link-sec justify-content-md-center">
                    <hr></hr>
                    <h4>{t("social_links")}</h4>
                    <div className="social-link-media">
                      <ul>
                        <li><Link to={configuration.get("configData.instagram_link")}>
                          <Image className="contact-us-img" src={window.location.origin + "/assets/img/static-page/icons/Instagram.svg"} />
                        </Link></li>
                        <li><Link to={configuration.get("configData.twitter_link")}>
                          <Image className="contact-us-img" src={window.location.origin + "/assets/img/static-page/icons/Twitter.svg"} />
                        </Link></li>
                        <li><Link to={configuration.get("configData.linkedin_link")}>
                          <Image className="contact-us-img" src={window.location.origin + "/assets/img/static-page/icons/Linkedin.svg"} />
                        </Link></li>
                        <li><Link to={configuration.get("configData.facebook_link")}>
                          <Image className="contact-us-img" src={window.location.origin + "/assets/img/static-page/icons/Facebook.svg"} />
                        </Link></li>
                      </ul>
                    </div>
                  </div>
                  <hr></hr>
                </Col>
              </Row>

            </Container>
          </div>
          :
          null
        }
      </div >
    </>
  );
};

export default ContactUs;
