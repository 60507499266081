import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { returnOrderStart } from '../../store/slices/OrderSlice';

const OrderReturnModal = (props) => {
  const dispatch = useDispatch();
  const returnOrder = useSelector(state => state.order.returnOrder);

  const [reason, setReason] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const onReturnOrder = e => {
    e.preventDefault();
    if (reason) {
      dispatch(returnOrderStart({
        order_unique_id: props.orderUniqueId,
        order_product_unique_id: props.orderProductUniqueId,
        cancelled_reason: reason,
      }));
    }
  }

  useEffect(() => {
    if (!skipRender && !returnOrder.loading && Object.keys(returnOrder.data).length > 0) {
      props.closeOrderReturnModal();
    }
    setSkipRender(false);
  }, [returnOrder]);


  return (
    <>
      <Modal
        className="modal-dialog-center write-review-modal"
        size="md"
        centered
        show={true}
        onHide={props.closeOrderReturnModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Return</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="write-review-form" onSubmit={onReturnOrder}>
            <Row>
              <Col className="mb-3">
                <Form.Control
                  type="text"
                  as={"textarea"}
                  className="form-control"
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                  rows={4}
                  placeholder={"Reason"}
                />
              </Col>
              <Button
                className="wishlist-btn"
                type="submit"
                disabled={returnOrder.buttonDisable}
              >
                {returnOrder.loadingButtonContent ?
                  props.returnOrder.loadingButtonContent
                  : "Submit"
                }
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OrderReturnModal;