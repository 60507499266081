import React, { useState } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NumericInput from 'react-numeric-input';
import NoDataFound from '../Helper/NoDataFound';
import { useTranslation } from 'react-multi-lang';
import { localCartList, updateGuestCartListStart } from '../../store/slices/CartSlice';
import AuthModal from '../Auth/AuthModal';
import { useNavigate } from 'react-router-dom';


const GuestCartDetails = () => {
  const t = useTranslation("cart");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localCart = useSelector(state => state.cart.localCart);
  const guestCartList = useSelector(state => state.cart.guestCartList);
  const profile = useSelector(state => state.user.profile);
  const [login, setLogin] = useState(false);

  const updateCartQty = (cartItem, val) => {
    const newCartList = localCart.map(cart =>
      cart.product_attribute_id === cartItem.attributes.product_attribute_id &&
        cart.size === cartItem.attributes.size &&
        cart.weight === cartItem.attributes.weight
        ? { ...cart, quantity: val } : cart
    );
    dispatch(localCartList(newCartList));
    dispatch(updateGuestCartListStart({ carts: JSON.stringify(newCartList) }));
  }

  const removeProduct = (cartItem) => {
    const newCartList = localCart.filter(cart =>
      !(cart.product_attribute_id === cartItem.attributes.product_attribute_id &&
        cart.size === cartItem.attributes.size &&
        cart.weight === cartItem.attributes.weight)
    );
    dispatch(localCartList(newCartList));
    dispatch(updateGuestCartListStart({ carts: JSON.stringify(newCartList) }));
  }

  const wishListDirect = (e) => {
    e.preventDefault();
    if (Object.keys(profile.data).length > 0) {
      navigate("/wishlist");
    } else {
      setLogin(true);
    }
  }
  const closeLoginModal = () => {
    setLogin(false);
  };

  const placeOrder = (e) => {
    e.preventDefault();
     if (Object.keys(profile.data).length > 0) {
      navigate("/checkout-address");
    } else {
      setLogin(true);
    }
  };

  return (
    <>
      <div className="cart-box">
        {guestCartList.data.carts && guestCartList.data.user_cart_amount && guestCartList.data.carts.length > 0 ?
          <Row className="gy-4 justify-content-center">
            <Col md={12} xl={8} lg={8}>
              <div className="cart-card">
                <div className="cart-header">
                  <h4>{t("product_details.heading")} <span>{t("product_details.count", { val: guestCartList.data.carts.length })}</span></h4>
                </div>
                <div className="cart-body">
                  <div className="cart-list-item-box">
                    {guestCartList.data.carts.map((cartItem, i) =>
                      <div className="cart-list-card" key={i}>
                        <div className="cart-list-left-sec">
                          <Link to="#">
                            <div className="cart-list-img-sec">
                              <Image
                                className="img cart-list-img"
                                src={cartItem.product_attribute.file}
                              />
                            </div>
                          </Link>
                          <div className="cart-list-info">
                            <Link to={`/product/${cartItem.product.product_unique_id}/${cartItem.product_attribute_id}`} className="text-decoration-none">
                              <h4>{cartItem.product.name}</h4>
                            </Link>
                            <p>{t("product_details.category")} <span>{
                              cartItem.product.product_categories.map((category, i) => {
                                let name = category.category_name;
                                if (i !== (cartItem.product.product_categories.length - 1))
                                  name += " - ";
                                return name
                              })
                            }</span></p>
                            {cartItem.attributes.weight ?
                              <p>{t("product_details.vol")} <span>{cartItem.attributes.weight}</span></p>
                              : null
                            }
                            {cartItem.attributes.size ?
                              <p>{t("product_details.size")} <span>{cartItem.attributes.size}</span></p>
                              : null
                            }
                            <p>{t("product_details.qty")}
                              <span>
                                <NumericInput
                                  mobile
                                  className="form-control"
                                  value={cartItem.quantity}
                                  onChange={val => updateCartQty(cartItem, val)}
                                  min={1}
                                  max={cartItem.product_attribute.quantity}
                                  strict={true}
                                  onFocus={(e) => e.target.select ? e.target.select() : e.preventDefault()}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="cart-list-right-sec">
                          <div className="cart-list-amout-card">
                            <div className="cart-amount-info">
                              <h4>{cartItem.product_attribute.selling_price_formatted}</h4>
                              <p>{cartItem.product_attribute.discount_tag} | <del>{cartItem.product_attribute.original_price_formatted}</del></p>
                            </div>
                            <div className="cart-action-btn-sec">
                              <Button
                                className="wishlist-btn"
                                onClick={() => removeProduct(cartItem)}

                              >
                                {t("product_details.remove_btn.text")}
                              </Button>
                              <Button
                                onClick={wishListDirect}
                                className="download-receipt-btn">
                                {t("product_details.add_to_wishlist")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
              <div className="cart-card">
                <div className="cart-header">
                  <h4>{t("order_details.heading")}</h4>
                </div>
                <div className="cart-body">
                  <div className="cart-summary-info">
                    <div className="cart-summary-header">
                      <p>{t("order_details.total")} <span>{guestCartList.data.user_cart_amount.cart_total_amount}</span></p>
                      <p>{t("order_details.discount")} <span>-{guestCartList.data.user_cart_amount.cart_discount_amount}</span></p>
                    </div>
                    <div className="cart-summary-total-amount">
                      <h5>{t("order_details.final")} <span>{guestCartList.data.user_cart_amount.cart_checkout_amount}</span></h5>
                    </div>
                    <div className="cart-summary-btn-sec">
                      <Link className="default-btn w-100"
                        onClick={placeOrder}
                        to="#">{t("order_details.place_order")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          : <NoDataFound />
        }
      </div>
      {
        login ?
          <AuthModal closeLoginModal={closeLoginModal} />
          : null
      }
    </>
  );
}

export default GuestCartDetails;