import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";

const NewLandingHeader = (props) => {

  return (
    <>
      <div className="new-landing-header-sec">
        <header className="new-header-nav-center">
          <Navbar expand="lg">
            <Container>
              <Link className="navbar-brand" to="/">
                <Image
                  className="logo"
                  src={
                    window.location.origin + "/assets/img/logo.png"
                  }
                />
              </Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="#404040"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 0H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM7 13H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM20 13h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM14 7h3v3a1 1 0 002 0V7h3a1 1 0 000-2h-3V2a1 1 0 00-2 0v3h-3a1 1 0 000 2z"></path>
                </svg>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav" className="navbar-resp-margin">
                <Nav className="ms-auto">
                  <div className="new-header-search-sec">
                    <InputGroup className="mb-0">
                      <Form.Control
                        placeholder="Recipient's username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Text id="basic-addon2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#404040"
                          x="0"
                          y="0"
                          enableBackground="new 0 0 513.749 513.749"
                          version="1.1"
                          viewBox="0 0 513.749 513.749"
                          xmlSpace="preserve"
                        >
                          <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                        </svg>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <Link to="/products">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#404040"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 8a1.02 1.02 0 00-.038-.275L22.79 3.626A5.022 5.022 0 0017.982 0H6.017a5.023 5.023 0 00-4.808 3.626L.039 7.725A1.02 1.02 0 000 8c0 1.012.378 1.937 1 2.643V20c0 2.206 1.794 4 4 4h6c2.206 0 4-1.794 4-4v-8.537a4.036 4.036 0 001-.82A3.989 3.989 0 0019 12h1c.345 0 .68-.044 1-.127V23a1 1 0 102 0V10.643c.622-.705 1-1.631 1-2.643zM11 22H5c-1.103 0-2-.897-2-2v-2h10v2c0 1.103-.897 2-2 2zm2-6H3v-4.127c.32.083.655.127 1 .127h1a3.994 3.994 0 003-1.357A3.99 3.99 0 0011 12h2v4zm6-6c-1.103 0-2-.897-2-2a1 1 0 10-2 0c0 1.103-.897 2-2 2h-2c-1.103 0-2-.897-2-2a1 1 0 00-2 0c0 1.103-.897 2-2 2H4c-.49 0-.94-.178-1.288-.471a1.99 1.99 0 01-.708-1.402l1.128-3.951A3.013 3.013 0 016.017 2H7v2a1 1 0 002 0V2h6v2a1 1 0 102 0V2h.982c1.332 0 2.519.895 2.885 2.176l1.129 3.951A2.003 2.003 0 0120 10.001h-1z"></path>
                    </svg>
                    <span>
                      Store
                    </span>
                  </Link>
                  <Link to="/profile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#404040"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zM8 21.164V21c0-2.206 1.794-4 4-4s4 1.794 4 4v.164c-1.226.537-2.578.836-4 .836s-2.774-.299-4-.836zm9.925-1.113C17.469 17.192 14.986 15 12 15s-5.468 2.192-5.925 5.051A9.993 9.993 0 012 12C2 6.486 6.486 2 12 2s10 4.486 10 10a9.993 9.993 0 01-4.075 8.051zM12 5C9.794 5 8 6.794 8 9s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                    </svg>
                    <span>
                      Account
                    </span>
                  </Link>
                  <Link to="/wishlist">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#404040"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17.5 1.917a6.4 6.4 0 00-5.5 3.3 6.4 6.4 0 00-5.5-3.3A6.8 6.8 0 000 8.967c0 4.547 4.786 9.513 8.8 12.88a4.974 4.974 0 006.4 0c4.014-3.367 8.8-8.333 8.8-12.88a6.8 6.8 0 00-6.5-7.05zm-3.585 18.4a2.973 2.973 0 01-3.83 0C4.947 16.006 2 11.87 2 8.967a4.8 4.8 0 014.5-5.05 4.8 4.8 0 014.5 5.05 1 1 0 002 0 4.8 4.8 0 014.5-5.05 4.8 4.8 0 014.5 5.05c0 2.903-2.947 7.039-8.085 11.346z"></path>
                    </svg>
                    <span>
                      Wishlist
                    </span>
                  </Link>
                  <Link to="/cart">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#404040"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.713 4.077A2.993 2.993 0 0020.41 3H4.242L4.2 2.649A3 3 0 001.222 0H1a1 1 0 000 2h.222a1 1 0 01.993.883l1.376 11.7A5 5 0 008.557 19H19a1 1 0 000-2H8.557a3 3 0 01-2.82-2h11.92a5 5 0 004.921-4.113l.785-4.354a2.994 2.994 0 00-.65-2.456zM21.4 6.178l-.786 4.354A3 3 0 0117.657 13H5.419l-.941-8H20.41a1 1 0 01.99 1.178z"></path>
                      <circle cx="7" cy="22" r="2"></circle>
                      <circle cx="17" cy="22" r="2"></circle>
                    </svg>
                    <span>
                      Cart
                    </span>
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      </div>
    </>
  );
};

export default NewLandingHeader;
