import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    transactionLists: getCommonInitialState(),
    orderDetails: getCommonInitialState(),
    orderList: getCommonInitialState({ orders: [], total_orders: 0 }),
    cancelOrder: getCommonInitialState(),
    createOrder: getCommonInitialState(),
    returnOrder: getCommonInitialState(),
};

const OrderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {

        // Order transactions
        transactionListsStart: (state) => { state.transactionLists = getCommonStart() },
        transactionListsSuccess: (state, action) => { state.transactionLists = getCommonSuccess(action.payload) },
        transactionListsFailure: (state, action) => { state.transactionLists = getCommonFailure(action.payload) },

        // Order Details
        orderDetailsStart: (state) => { state.orderDetails = getCommonStart() },
        orderDetailsSuccess: (state, action) => { state.orderDetails = getCommonSuccess(action.payload) },
        orderDetailsFailure: (state, action) => { state.orderDetails = getCommonFailure(action.payload) },

        // Order Lists
        orderListStart: (state) => { state.orderList = getCommonStart({ orders: [], total_orders: 0 }) },
        orderListSuccess: (state, action) => { state.orderList = getCommonSuccess(action.payload) },
        orderListFailure: (state, action) => { state.orderList = getCommonFailure(action.payload) },
        moreOrderList: (state, action) => { state.orderList = state.orderList },

        // Order Lists
        cancelOrderStart: (state) => { state.cancelOrder = getCommonStart() },
        cancelOrderSuccess: (state, action) => { state.cancelOrder = getCommonSuccess(action.payload) },
        cancelOrderFailure: (state, action) => { state.cancelOrder = getCommonFailure(action.payload) },

        // Create order
        createOrderStart: (state) => { state.createOrder = getCommonStart() },
        createOrderSuccess: (state, action) => { state.createOrder = getCommonSuccess(action.payload) },
        createOrderFailure: (state, action) => { state.createOrder = getCommonSuccess(action.payload) },

        // Return Order
        returnOrderStart: (state) => { state.returnOrder = getCommonStart() },
        returnOrderSuccess: (state, action) => { state.returnOrder = getCommonSuccess(action.payload) },
        returnOrderFailure: (state, action) => { state.returnOrder = getCommonFailure(action.payload) },


    }
});

export const {
    transactionListsStart,
    transactionListsSuccess,
    transactionListsFailure,
    orderDetailsStart,
    orderDetailsSuccess,
    orderDetailsFailure,
    orderListStart,
    orderListSuccess,
    orderListFailure,
    moreOrderList,
    cancelOrderStart,
    cancelOrderSuccess,
    cancelOrderFailure,
    createOrderStart,
    createOrderSuccess,
    createOrderFailure,
    returnOrderStart,
    returnOrderSuccess,
    returnOrderFailure,

} = OrderSlice.actions;

export default OrderSlice.reducer;