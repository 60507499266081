import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';

import {

    transactionListsSuccess,
    transactionListsFailure,
    orderDetailsSuccess,
    orderDetailsFailure,
    orderListSuccess,
    orderListFailure,
    cancelOrderSuccess,
    cancelOrderFailure,
    createOrderSuccess,
    createOrderFailure,
    returnOrderSuccess,
    returnOrderFailure,
} from "../slices/OrderSlice";

function* orderTransactionAPI(action) {
    yield getCommonSaga({
        apiUrl: "order_payments_list",
        payload: action.payload,
        successNotify: false,
        success: transactionListsSuccess,
        failure: transactionListsFailure,
    });
}
function* orderDetailsAPI(action) {
    yield getCommonSaga({
        apiUrl: "orders_view",
        payload: action.payload,
        successNotify: false,
        success: orderDetailsSuccess,
        failure: orderDetailsFailure,
    });
}

function* orderListAPI(action) {
    const orderListData = yield select(state => state.order.orderList.data)
    yield getCommonSaga({
        apiUrl: "orders_list",
        payload: action.payload,
        successNotify: false,
        successCallback: true,
        success: (data) => {
            store.dispatch(orderListSuccess({
                ...data,
                orders: [...orderListData.orders, ...data.orders],
                total_orders: data.total_orders,
            }))
        },
        failure: orderListFailure
    });
}

function* cancelOrderAPI(action) {
    yield getCommonSaga({
        apiUrl: "orders_send_cancel_request",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(cancelOrderSuccess(data));
            store.dispatch(orderDetailsSuccess(data));
        },
        failure: cancelOrderFailure,
    });
}

function* createOrderAPI(action) {
    yield getCommonSaga({
        apiUrl: "orders_checkout",
        payload: action.payload,
        success: createOrderSuccess,
        failure: createOrderFailure,
    });
}

function* returnOrderAPI(action) {
    yield getCommonSaga({
        apiUrl: "orders_send_return_request",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(returnOrderSuccess(data));
            store.dispatch(orderDetailsSuccess(data));
        },
        failure: returnOrderFailure,
    });
}

export default function* OrderSaga() {
    yield all([
        yield takeLatest('order/transactionListsStart', orderTransactionAPI),
        yield takeLatest('order/orderDetailsStart', orderDetailsAPI),
        yield takeLatest('order/orderListStart', orderListAPI),
        yield takeLatest('order/moreOrderList', orderListAPI),
        yield takeLatest('order/cancelOrderStart', cancelOrderAPI),
        yield takeLatest('order/createOrderStart', createOrderAPI),
        yield takeLatest('order/returnOrderStart', returnOrderAPI),
    ]);
}
