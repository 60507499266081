import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Form,
  Button,
  InputGroup
} from "react-bootstrap";
import "./Static.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchSinglePageStart } from "../../store/slices/PageSlice";
import { useParams } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";

const StaticIndex = (props) => {

  const dispatch = useDispatch();
  const pageData = useSelector(state => state.page.pageData);
  const { static_page_unique_id } = useParams();

  useEffect(() => {
    dispatch(fetchSinglePageStart({
      static_page_unique_id: static_page_unique_id,
    }));
  }, [static_page_unique_id]);

  return (
    <>
      {pageData.loading ?
        <>
          <Skeleton count={1} height={250} className="mb-5" />
          <Skeleton count={4} height={50} />
        </>
        :
        Object.keys(pageData.data).length > 0 &&
          pageData.data.title
          ?
          < div className="privacy-policy-sec">
            <div className="privacy-policy-sec-header">
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="privacy-policy-title">
                      <h1 className="static-txt">{pageData.data.title}</h1>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div>
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="privacy-policy-who-we-are">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: pageData.data.description,
                        }}
                      ></p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          :
          <NoDataFound />
      }
    </>
  );
};

export default StaticIndex;
