import React, { useState, useEffect, Component } from "react";
import { Row, Col, Container, Form, Image, Button, Modal, InputGroup, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./LandingStyle.css";
import "./ProductDetails.css";
import "./OrdersHistory.css"
import { Checkbox } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';
import { orderListStart, moreOrderList } from "../../store/slices/OrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import { apiUrl } from "../../Environment";

const OrderHistory = () => {

  const t = useTranslation("my_order");
  const dispatch = useDispatch();
  const [clearItems, setClearItems] = useState(false);
  const orderList = useSelector(state => state.order.orderList);
  const [downloading, setDownloading] = useState(false);
  const [filterData, setFilterData] = useState({
    search_key: "",
    order_status: "",
    order_time: ""
  });

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    dispatch(orderListStart({
      ...filterData,
      skip: 0,
      take: 12,
    }));
  }, [filterData]);

  const fetchMoreOrder = () => {
    dispatch(moreOrderList({
      skip: orderList.data.orders.length,
      take: 12,
    }));
  };

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      search_key: event.target.value,
    });
    setClearItems(true)
  };

  const clearItem = () => {
    setClearItems(false)
    setFilterData({
      ...filterData,
      search_key: "",
    });
    dispatch(orderListStart());
  }

  const download = async (order) => {
    setDownloading(true);
    const url = apiUrl + "orders_invoice";
    let formData = new FormData();
    formData.append("id", localStorage.getItem("userId"));
    formData.append("token", localStorage.getItem("accessToken"));
    formData.append("order_unique_id", order.order_unique_id);
    formData.append("invoice_via", "download");
    await fetch(url, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `order_${order.order_unique_id}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setDownloading(false);
  };

  const orderStatusHandler = (event) => {
    if (event.target.checked) {
      setFilterData({ ...filterData, order_status: event.target.value })
    } else {
      setFilterData(
        filterData.filter((filterData) => filterData !== event.target.value)
      )
    }
  }

  const orderTimeHandler = (event) => {
    if (event.target.checked) {
      setFilterData({ ...filterData, order_time: event.target.value })
    } else {
      setFilterData(
        filterData.filter((filterData) => filterData !== event.target.value)
      )
    }
  }

  const resetFilter = (e) => {
    e.preventDefault();
    setFilterData({
      order_status: "",
      order_time: "",
      search_key: ""
    });
    setClearItems(false)
  };


  return (
    <>
      <div className="order-history-wrapped">
        <Container>
          <div className="next-page-tab">
            <span>
              <Link to="/">{t("home")}</Link>
            </span>
            <span>
              <Link to="/profile">{t("my_account")}</Link>
            </span>
            <span>
              <Link className="active">{t("my_orders")}</Link>
            </span>
          </div>
          <div className="order-details-wrapped">
            <div className="order-details-filter desk-filetr">
              <div className="order-filter-head">
                <h3>{t("filter")}</h3>
                <span className="filter-clear-btn">
                  <Button
                    onClick={resetFilter}
                  >{t("clear_all")}</Button>
                </span>
              </div>
              <div className="filter-check-wrapped">
                <h4>{t("order_status")}</h4>
                <Form>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderStatusHandler}
                      value="1"
                      checked={filterData.order_status == 1}
                    >{t("pending")}
                    </Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderStatusHandler}
                      value="2"
                      checked={filterData.order_status == 2}
                    >{t("cancelled")}</Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderStatusHandler}
                      value="3"
                      checked={filterData.order_status == 3}
                    >{t("returned")}</Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderStatusHandler}
                      value="4"
                      checked={filterData.order_status == 4}
                    >{t("completed")}</Checkbox>
                  </div>
                </Form>
              </div>
              <div className="filter-check-wrapped">
                <h4>{t("order_time")}</h4>
                <Form>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderTimeHandler}
                      value="last_30_days"
                      checked={filterData.order_time == "last_30_days"}
                    >{t("last_30_days")}</Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderTimeHandler}
                      value="this_month"
                      checked={filterData.order_time == "this_month"}
                    >{t("this_month")}</Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderTimeHandler}
                      value="last_month"
                      checked={filterData.order_time == "last_month"}
                    >{t("last_month")}</Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderTimeHandler}
                      value="current_year"
                      checked={filterData.order_time == "current_year"}
                    >{currentYear}
                    </Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderTimeHandler}
                      value="last_year"
                      checked={filterData.order_time == "last_year"}
                    >{currentYear - 1}
                    </Checkbox>
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox color="success-o"
                      onChange={orderTimeHandler}
                      value="older"
                      checked={filterData.order_time == "older"}
                    >{t("older")}</Checkbox>
                  </div>
                </Form>
              </div>
            </div>
            <div className="order-history-details">
              <div className="order-history-head">
                <div className="order-history-title">
                  <h4>{t("my_orders")}</h4>
                  <p className="desc">{orderList.data.total_orders} {t("total_orders")}</p>
                </div>
                <div className="search-order-history-sec">
                  <InputGroup>
                    <Form.Control
                      name="search"
                      type="text"
                      placeholder={t("search_in_orders")}
                      value={filterData.search_key}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                    <InputGroup.Text>
                      {clearItems ?
                        <svg className="clear-search-btn" onClick={clearItem} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="19" height="20"
                          viewBox="0 0 50 50">
                          <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
                        </svg>
                        :
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="search">
                            <path id="Vector" d="M14.0835 14.5834L17.7502 18.25" stroke="#01151F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path id="Vector_2" d="M8.58333 16.4167C12.6334 16.4167 15.9167 13.1334 15.9167 9.08333C15.9167 5.03324 12.6334 1.75 8.58333 1.75C4.53324 1.75 1.25 5.03324 1.25 9.08333C1.25 13.1334 4.53324 16.4167 8.58333 16.4167Z" stroke="#01151F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                        </svg>
                      }
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              {orderList.loading ?
                <Skeleton height={130} count={3} />
                : Object.keys(orderList.data).length > 0 &&
                  orderList.data.orders.length > 0 ?
                  <InfiniteScroll
                    dataLength={orderList.data.orders.length}
                    next={fetchMoreOrder}
                    hasMore={
                      orderList.data.orders.length <
                      orderList.data.total_orders
                    }
                    loader={<Skeleton count={4} height={100} />}
                  >
                    {orderList.data.orders.map((order, i) => (
                      order.order_products.map((order_product) => (
                        <div className="orders-history-card" key={i}>
                          <div className="orders-history-card-img">
                            <Image
                              className=""
                              src={order_product.product_attribute.file}
                            />
                          </div>
                          <div className="orders-history-card-info">
                            <div className="order-history-head">
                              <h4>
                                <Link
                                  to={`/product/${order_product.product.product_unique_id}`}>
                                  {order_product.product.name}
                                </Link>
                              </h4>
                              <span>
                                <Link className="order-tracking-btn" to={`/orders/tracking/${order.order_unique_id}/${order_product.order_product_unique_id}`}>
                                  {order_product.order_product_unique_id}
                                </Link>
                              </span>
                            </div>
                            <span className="product-name">{order_product.metal}</span>
                            <div className="singale-product-rate">
                              <span className="current-rate">{order_product.selling_price_formatted}</span>
                              <span className="net-rate">{order_product.original_price_formatted}</span>
                              <span className="discount-per" >{order_product.discount_tag}</span>
                            </div>
                            <div className="history-status-wrapped">
                              <div className="history-status-wrapped-left">
                                <h5 className={`${order_product.status == 6 ?
                                  "cancel-text-btn" : ""}`}>  {order_product.status_formatted} </h5>
                                <p>{order.order_payment.paid_date}</p>
                              </div>
                              <div className="history-status-wrapped-right">
                                {order_product.status !== 6 ?
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15" viewBox="0 0 24 15" fill="none">
                                      <path d="M8.47072 13.5922C8.98451 13.5922 9.47726 13.3881 9.84057 13.0248C10.2039 12.6615 10.408 12.1688 10.408 11.655C10.408 11.1412 10.2039 10.6484 9.84057 10.2851C9.47726 9.92182 8.98451 9.71771 8.47072 9.71771C7.95692 9.71771 7.46417 9.92182 7.10086 10.2851C6.73755 10.6484 6.53345 11.1412 6.53345 11.655C6.53345 12.1688 6.73755 12.6615 7.10086 13.0248C7.46417 13.3881 7.95692 13.5922 8.47072 13.5922ZM18.1571 13.5922C18.6709 13.5922 19.1636 13.3881 19.5269 13.0248C19.8902 12.6615 20.0943 12.1688 20.0943 11.655C20.0943 11.1412 19.8902 10.6484 19.5269 10.2851C19.1636 9.92182 18.6709 9.71771 18.1571 9.71771C17.6433 9.71771 17.1505 9.92182 16.7872 10.2851C16.4239 10.6484 16.2198 11.1412 16.2198 11.655C16.2198 12.1688 16.4239 12.6615 16.7872 13.0248C17.1505 13.3881 17.6433 13.5922 18.1571 13.5922Z" stroke="#005479" stroke-width="1.7" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M10.4561 11.655H15.2508V1.58118C15.2508 1.42704 15.1896 1.27922 15.0806 1.17022C14.9716 1.06123 14.8238 1 14.6696 1H1.68994M6.19409 11.655H4.20839C4.13207 11.655 4.05649 11.6399 3.98598 11.6107C3.91547 11.5815 3.8514 11.5387 3.79743 11.4848C3.74347 11.4308 3.70066 11.3667 3.67145 11.2962C3.64224 11.2257 3.62721 11.1501 3.62721 11.0738V6.32749" stroke="#005479" stroke-width="1.7" stroke-linecap="round" />
                                      <path d="M2.65894 3.90588H6.53347" stroke="#005479" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M15.2512 3.90588H20.6853C20.7976 3.90591 20.9075 3.9385 21.0017 3.9997C21.0959 4.0609 21.1704 4.14809 21.2161 4.25072L22.9499 8.15238C22.983 8.22646 23.0001 8.30664 23.0003 8.38775V11.0738C23.0003 11.1501 22.9853 11.2257 22.9561 11.2962C22.9268 11.3667 22.884 11.4308 22.8301 11.4847C22.7761 11.5387 22.712 11.5815 22.6415 11.6107C22.571 11.6399 22.4954 11.655 22.4191 11.655H20.5787M15.2512 11.655H16.2199" stroke="#005479" stroke-width="1.7" stroke-linecap="round" />
                                    </svg>
                                    <Link to={`/orders/tracking/${order.order_unique_id}/${order_product.order_product_unique_id}`}>
                                      {t("track_package")}
                                    </Link>
                                  </span>
                                  :
                                  null
                                }
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                    <g clip-path="url(#clip0_1_2151)">
                                      <path d="M4 16.2961H13.1724" stroke="#005479" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M13.1724 9.11511L8.58619 13.2186L4 9.11511" stroke="#005479" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M8.58618 13.2185V2.29614" stroke="#005479" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1_2151">
                                        <rect width="19" height="17" fill="white" transform="translate(0 0.796143)" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <Link
                                    to="#"
                                    onClick={() => !downloading && download(order)}
                                  >
                                    {!downloading ? t("download_invoice") : t("loading")}
                                  </Link>
                                </span>
                                {order_product.status !== 6 ?
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                      <path d="M7.5 12.8514L12.135 15.7961L10.905 10.2461L15 6.51193L9.6075 6.03035L7.5 0.796143L5.3925 6.03035L0 6.51193L4.095 10.2461L2.865 15.7961L7.5 12.8514Z" fill="#005479" />
                                    </svg>
                                    <Link to={`/product-reviews/${order_product.product.product_unique_id}`}>
                                      {t("rate_review")}
                                    </Link>
                                  </span>
                                  :
                                  null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ))}
                  </InfiniteScroll>
                  :
                  <NoDataFound />
              }
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OrderHistory;
