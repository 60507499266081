import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ReviewLoader = () => {
  return (
    <>
      <div className="next-page-tabs">
        {[...Array(4)].map((val, i) => <Skeleton width={180} height={20} key={i} />)}
      </div>
      <div className="review-product-details">
        <div className="review-product-details-img">
          <Skeleton count={1} width={160} height={160} />
        </div>
        <div className="review-product-details-info">
          <Skeleton count={1} width={300} height={22} />
          <span className="product-name"><Skeleton count={1} width={120} height={25} /></span>
          <div className="singale-product-rate">
            <span className="current-rate"><Skeleton count={1} width={80} height={25} /></span>
            <span className="net-rate"><Skeleton count={1} width={80} height={25} /></span>
            <span className="discount-per" ><Skeleton count={1} width={80} height={25} /></span>
          </div>
          <div className="delivery-main-frame">
            <div className="delivery-right-wrap">
            <Skeleton count={1} />
          </div>
            <div className="delivery-left-wrap">
           <Skeleton count={1} width={130} height={25} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewLoader;