import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CommonCenterLoader from '../../Helper/CommonCenterLoader';
import CustomLazyLoad from '../../Helper/CustomLazyLoad';
import { useTranslation } from 'react-multi-lang'
import Skeleton from 'react-loading-skeleton';

const SearchSuggetion = ({ setShowSuggetions }) => {

  const t = useTranslation("search_suggetion");
  const navigate = useNavigate();
  const productSuggetions = useSelector(state => state.product.productSuggetions);

  const goToProduct = uniqueId => {
    setShowSuggetions(false);
    navigate(`/product/${uniqueId}`);
  }

  return (
    <div className="search-suggetion-sec">
      {productSuggetions.loading ?
        <div className="search-suggetion">
          <div className="lazyLoder">
            {[...Array(3)].map((val, i) =>
              <Skeleton width={455} height={80} key={i} />)}
          </div>
        </div>
        : productSuggetions.data.products &&
          productSuggetions.data.products.length > 0 ?
          productSuggetions.data.products.map((product, i) =>
            <div className="search-suggetion" onClick={e => goToProduct(product.product_unique_id)}>
              <CustomLazyLoad
                src={product.product_attribute.file}
              />
              <p>{product.name}</p>
            </div>
          )
          :
          <div className='search-no-dataicon'>   <Image
          className="search-no-img"
          src={
            window.location.origin + "/assets/img/search-nodata.png"
          }
        /></div>
      }
    </div>
  );
}

export default SearchSuggetion;