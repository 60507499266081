import React, { useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ProfileSidebar from "../Profile/ProfileSidebar";
import { transactionListsStart } from "../../store/slices/OrderSlice";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import { useTranslation } from "react-multi-lang";

const OrderTransaction = () => {

  const dispatch = useDispatch();
  const t = useTranslation("order_transaction");
  const transactionLists = useSelector(state => state.order.transactionLists);

  const onCopy = () => {
    dispatch(getSuccessNotificationMessage(t("message")));
  };
  useEffect(() => {
    dispatch(transactionListsStart());

  }, []);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                <div className="profile-details-sec">
                  <div className="profile-header-sec">
                    <h3>{t("heading")}</h3>
                    <p>{t("transaction_details")}</p>
                  </div>
                  {transactionLists.loading ?
                    <Skeleton count={4} height={80} />
                    :
                    Object.keys(transactionLists.data).length > 0 &&
                      transactionLists.data.order_payments.length > 0
                      ?
                      < div className="order-transaction-sec">
                        <div className="order-list-table">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>{t("table_head.date")}</th>
                                <th>{t("table_head.transaction_id")}</th>
                                <th>{t("table_head.ammount")}</th>
                                <th>{t("table_head.status")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactionLists.data.order_payments.map(
                                (orders, i) => (
                                  <tr key={i}>
                                    <td>
                                      <h5>{orders.paid_date}</h5>
                                    </td>
                                    <td style={{ cursor: "pointer" }}>
                                      {orders.payment_id ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>{orders.payment_id}</Tooltip>
                                          }
                                        >
                                          <div className="transaction-id-sec">
                                            <CopyToClipboard
                                              text={orders.payment_id}
                                              onCopy={() => onCopy()}
                                            >
                                              <span>{orders.payment_id}</span>
                                            </CopyToClipboard>
                                          </div>
                                        </OverlayTrigger>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>  {orders.total_formatted}</td>
                                    <td>{orders.status_formatted}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      :
                      <NoDataFound />
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OrderTransaction;
