import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { cartListStart } from "../../store/slices/CartSlice";
import { fetchsingleDeliveryAddressStart } from "../../store/slices/DeliverySlice";
import { createOrderStart } from "../../store/slices/OrderSlice";
import { fetchWalletTransactionListStart } from "../../store/slices/WalletSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import { confirmAlert } from 'react-confirm-alert';
import "./Cart.css";
import AddMoneyModal from "./AddMoneyModal";

const CheckOutPayment = (props) => {
  const t = useTranslation("checkout");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const cartList = useSelector(state => state.cart.cartList);
  const singleDeliveryAddress = useSelector(state => state.delivery.singleDeliveryAddress);
  const walletTransactionList = useSelector(state => state.wallet.walletTransactionList);
  const paymentByStripe = useSelector(state => state.wallet.paymentByStripe)

  const createOrder = useSelector(state => state.order.createOrder);
  const [addMoneyModal, setAddMoneyModal] = useState(false);

  const [skipRender, setSkipRender] = useState(true);
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (localStorage.getItem("addessUniqueId")) {
        dispatch(fetchsingleDeliveryAddressStart({
          delivery_address_unique_id: localStorage.getItem("addessUniqueId")
        }));
        dispatch(cartListStart());
        dispatch(fetchWalletTransactionListStart());
      } else {
        navigate("/checkout-address");
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!skipRender &&
      (!singleDeliveryAddress.loading && Object.keys(singleDeliveryAddress.data).length === 0) ||
      (!cartList.loading && Object.keys(cartList.data).length === 0) ||
      (!walletTransactionList.loading && Object.keys(walletTransactionList.data).length === 0)) {
      setShowContent(false);
    }
  }, [singleDeliveryAddress, cartList, walletTransactionList]);

  const onCheckout = e => {
    e.preventDefault();
    if (walletTransactionList.data.user_wallet.remaining < cartList.data.user_cart_amount.cart_final_amount)
      // confirmAlert({
      //   title: t("balance_popup.title"),
      //   message: t("balance_popup.message"),
      //   buttons: [
      //     {
      //       label: t("balance_popup.add_balance"),
      //       onClick: () => navigate("/add-money")
      //     },
      //     {
      //       label: t("balance_popup.home"),
      //       onClick: () => navigate("/")
      //     }
      //   ]
      // });
      setAddMoneyModal(true);
    else
      dispatch(createOrderStart({
        delivery_address_id: singleDeliveryAddress.data.delivery_address.delivery_address_id,
        payment_mode: "WALLET",
      }));
  }

  useEffect(() => {
    if (!skipRender && !createOrder.loading && Object.keys(createOrder.data).length > 0)
      navigate("/order-details");
    setSkipRender(false);
  }, [createOrder]);

  useEffect(() => {
    if (!skipRender && !paymentByStripe.loading && Object.keys(paymentByStripe.data).length > 0) {
      dispatch(fetchWalletTransactionListStart())
      setAddMoneyModal(false);
    }
    setSkipRender(false);
  }, [paymentByStripe]);

  return (
    <>
      <div className="cart-sec">
        {profile.loading ?
          <PageLoader />
          : showContent && Object.keys(profile.data).length > 0 ?
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title">
                    <h1>{t("your_cart")}</h1>
                  </div>
                </Col>
              </Row>
              <div className="cart-box">
                <Row className="gy-4 justify-content-center">
                  <Col md={12} xl={8} lg={8}>
                    <div className="checkout-header-sec">
                      <ul className="list-unstyled checkout-progress-bar">
                        <li className="step1">
                          <div className="checkout-flex">
                            <div className="count-no">1</div>
                            <h6>{t("section.address")}</h6>
                          </div>
                        </li>
                        <li className="step2">
                          <div className="checkout-flex">
                            <div className="count-no">2</div>
                            <h6>{t("section.order_summary")}</h6>
                          </div>
                        </li>
                        <li className="step3">
                          <div className="checkout-flex active">
                            <div className="count-no">3</div>
                            <h6>{t("section.payment")}</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="checkout-body">
                      {singleDeliveryAddress.loading
                        ? <Skeleton height={200} />
                        : singleDeliveryAddress.data.delivery_address ?
                          <div className="checkout-delivery-card">
                            <div className="checkout-delivery-address-info">
                              <h4>{singleDeliveryAddress.data.delivery_address.name}</h4>
                              <p className="address-block">
                                {singleDeliveryAddress.data.delivery_address.address}{"\n"}
                                {singleDeliveryAddress.data.delivery_address.city}{" "}
                                {singleDeliveryAddress.data.delivery_address.state}{" "}
                                {singleDeliveryAddress.data.delivery_address.pincode}{"\n"}
                                {singleDeliveryAddress.data.delivery_address.landmark}{"\n"}
                                {singleDeliveryAddress.data.delivery_address.contact_number}
                              </p>
                            </div>
                            <div className="checkout-delivery-default-btn">
                              <Link to="/checkout-address" className="default-btn">{t("change_address")}</Link>
                            </div>
                          </div>
                          : null
                      }
                    </div>
                    <div className="checkout-delivery-continue-card">
                      <h4>{t("order_summary")}<span>{
                        cartList.loading
                          ? "..."
                          : cartList.data.carts ?
                            cartList.data.carts.filter(cart => cart.product_attribute.stock_status === 1).length
                            : ""
                      } {t("product")}</span></h4>
                      <Link to="/cart" className="default-btn">{t("change_address")}</Link>
                    </div>
                    {walletTransactionList.loading ?
                      <Skeleton height={200} />
                      : walletTransactionList.data.user_wallet ?
                        <div className="add-money-card-select-sec">
                          <div className="manage-address-box">
                            <div className="checkbox">
                              <input type="radio" name="manage-address-radio" id="check-box-1" />
                              <div className="manage-address-card" for="check-box-1">
                                <h5>
                                  <div className="wallet-icon-text">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M21 6H5a3.012 3.012 0 01-2.235-.999A2.995 2.995 0 015 4h18a1 1 0 100-2H5a5 5 0 00-5 5v10a5 5 0 005 5h16a3 3 0 003-3V9a3 3 0 00-3-3zm1 13c0 .551-.448 1-1 1H5c-1.654 0-3-1.346-3-3V6.998A5.012 5.012 0 005 8h16c.552 0 1 .449 1 1v10zm-2-5a1 1 0 11-2 0 1 1 0 012 0z"></path>
                                    </svg>
                                    <span>{t("wallet")}</span>
                                  </div>
                                </h5>
                                <h5>{walletTransactionList.data.user_wallet.remaining_formatted}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                    }
                    {!cartList.loading && !walletTransactionList.loading && !singleDeliveryAddress.loading ?
                      <div className="checkout-delivery-continue-card">
                        <h4>{t("checkout")}</h4>
                        <Button
                          className="default-btn"
                          onClick={onCheckout}
                          disabled={createOrder.buttonDissable}
                        >
                          {createOrder.buttonDissable ? t("payment_btn.loading") : t("payment_btn.text")}
                        </Button>
                      </div>
                      : <Skeleton height={100} />
                    }
                  </Col>
                  {cartList.loading ?
                    <Col md={6} xl={4} lg={4}>
                      <Skeleton height={250} />
                    </Col>
                    : cartList.data.user_cart_amount ?
                      <Col md={6} xl={4} lg={4}>
                        <div className="cart-card">
                          <div className="cart-header">
                            <h4>{t("price_details.heading")}</h4>
                          </div>
                          <div className="cart-body">
                            <div className="cart-summary-info">
                              <div className="cart-summary-header">
                                <p>{t("price_details.cart_total")}<span>{cartList.data.user_cart_amount.cart_total_amount}</span></p>
                                <p>{t("price_details.discount")}<span>-{cartList.data.user_cart_amount.cart_discount_amount}</span></p>
                              </div>
                              <div className="cart-summary-total-amount">
                                <h5>{t("price_details.total_amount")}<span>{cartList.data.user_cart_amount.cart_checkout_amount}</span></h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      : null
                  }
                </Row>
              </div>
            </Container>
            : <div className="text-center">
              <SomethingWentWrong />
              <Button onClick={() => navigate("/cart")}>{t("go_cart")}</Button>
            </div>
        }
      </div>
      {addMoneyModal && 
        <AddMoneyModal
          addMoneyModal={addMoneyModal} 
          closeAddMoneyModal={()=> {
            setAddMoneyModal(false)
            dispatch(fetchWalletTransactionListStart());
          }}
        />
      }
    </>
  );
};

export default CheckOutPayment;
