
import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  deliveryAddressesSave: getCommonInitialState(),
  deliveryAddressesList: getCommonInitialState(),
  makeDefaultDeliveryAddresses: getCommonInitialState(),
  deliveryAddressesDelete: getCommonInitialState(),
  singleDeliveryAddress: getCommonInitialState(),
  countries: getCommonInitialState(),
  states: getCommonInitialState(),
};

const DeliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {

    // Delivery Address Save
    deliveryAddressesSaveStart: (state) => { state.deliveryAddressesSave = getCommonStart() },
    deliveryAddressesSaveSuccess: (state, action) => { state.deliveryAddressesSave = getCommonSuccess(action.payload) },
    deliveryAddressesSaveFailure: (state, action) => { state.deliveryAddressesSave = getCommonFailure(action.payload) },

    // Delivery Address List
    fetchdeliveryAddressesListStart: (state) => { state.deliveryAddressesList = getCommonStart() },
    fetchdeliveryAddressesListSuccess: (state, action) => { state.deliveryAddressesList = getCommonSuccess(action.payload) },
    fetchdeliveryAddressesListFailure: (state, action) => { state.deliveryAddressesList = getCommonFailure(action.payload) },

    // Make Default Delivery Address
    makeDefaultDeliveryAddressesStart: (state) => { state.makeDefaultDeliveryAddresses = getCommonStart() },
    makeDefaultDeliveryAddressesSuccess: (state, action) => { state.makeDefaultDeliveryAddresses = getCommonSuccess(action.payload) },
    makeDefaultDeliveryAddressesFailure: (state, action) => { state.makeDefaultDeliveryAddresses = getCommonFailure(action.payload) },

    // Delivery Address Delete
    deliveryAddressesDeleteStart: (state) => { state.deliveryAddressesDelete = getCommonStart() },
    deliveryAddressesDeleteSuccess: (state, action) => { state.deliveryAddressesDelete = getCommonSuccess(action.payload) },
    deliveryAddressesDeleteFailure: (state, action) => { state.deliveryAddressesDelete = getCommonFailure(action.payload) },

    // Single  Delivery Address
    fetchsingleDeliveryAddressStart: (state) => { state.singleDeliveryAddress = getCommonStart() },
    fetchsingleDeliveryAddressSuccess: (state, action) => { state.singleDeliveryAddress = getCommonSuccess(action.payload) },
    fetchsingleDeliveryAddressFailure: (state, action) => { state.singleDeliveryAddress = getCommonFailure(action.payload) },

    // Countries
    getcountriesStart: (state) => { state.countries = getCommonStart({ countries: [], total_countries: 0, }) },
    getcountriesSuccess: (state, action) => { state.countries = getCommonSuccess(action.payload) },
    getcountriesFailure: (state, action) => { state.countries = getCommonFailure(action.payload) },

    // States
    getstatesStart: (state) => { state.states = getCommonStart({ states: {}, total_states: {} }) },
    getstatesSuccess: (state, action) => { state.states = getCommonSuccess(action.payload) },
    getstatesFailure: (state, action) => { state.states = getCommonFailure(action.payload) },


  }
});

export const {
  deliveryAddressesSaveStart,
  deliveryAddressesSaveSuccess,
  deliveryAddressesSaveFailure,
  fetchdeliveryAddressesListStart,
  fetchdeliveryAddressesListSuccess,
  fetchdeliveryAddressesListFailure,
  makeDefaultDeliveryAddressesStart,
  makeDefaultDeliveryAddressesSuccess,
  makeDefaultDeliveryAddressesFailure,
  deliveryAddressesDeleteStart,
  deliveryAddressesDeleteSuccess,
  deliveryAddressesDeleteFailure,
  fetchsingleDeliveryAddressStart,
  fetchsingleDeliveryAddressSuccess,
  fetchsingleDeliveryAddressFailure,
  getcountriesStart,
  getcountriesSuccess,
  getcountriesFailure,
  getstatesStart,
  getstatesSuccess,
  getstatesFailure,

} = DeliverySlice.actions;

export default DeliverySlice.reducer;