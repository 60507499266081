import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';

import {
  cartDeleteFailure,
  cartDeleteSuccess,
  cartListFailure,
  cartListStart,
  cartListSuccess,
  cartSaveFailure,
  cartSaveSuccess,
  guestCartListFailure,
  guestCartListSuccess,
} from "../slices/CartSlice";
import { fetchUserDetailsSuccess } from "../slices/UserSlice";
import { singleProductStart, singleProductSuccess } from "../slices/ProductSlice";
import { fetchBannerListSuccess } from "../slices/HomePageSlice";

function* updateLocalCart(action) {
  localStorage.setItem("cart", JSON.stringify(action.payload));
}

function* guestCartListAPI(action) {
  yield getCommonSaga({
    apiUrl: "guest_carts_list",
    payload: action.payload,
    successNotify: false,
    success: guestCartListSuccess,
    failure: guestCartListFailure,
  });
}

function* cartListAPI(action) {
  yield getCommonSaga({
    apiUrl: "carts_list",
    payload: action.payload,
    successNotify: false,
    success: cartListSuccess,
    failure: cartListFailure,
  });
}

function* cartSaveAPI(action) {
  const profileData = yield select((state) => state.user.profile.data);
  const bannerListData = yield select((state) => state.homepage.bannerList.data);
  const cartList = yield select(state => state.cart.cartList);
  const cartListData = cartList.data;
  const singleProduct = yield select(state => state.product.singleProduct);
  const singleProductData = singleProduct.data;
  yield getCommonSaga({
    apiUrl: "carts_save",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(cartSaveSuccess(data));
      store.dispatch(fetchUserDetailsSuccess({
        ...profileData,
        cart_count: data.cart_count,
      }));
      if (Object.keys(bannerListData).length > 0) {
        store.dispatch(fetchBannerListSuccess({
          ...bannerListData,
          banners: bannerListData.banners.map(banner =>
            banner.product_attribute_id === data.product_attribute_id
              ?
              { ...banner, is_carted: data.cart.product.is_carted }
              : banner
          )
        }))
      }
      if (action.payload.carts) {
        if (Object.keys(cartListData).length > 0 || cartList.buttonDisable)
          store.dispatch(cartListStart());
        if (Object.keys(singleProductData).length > 0 || singleProduct.buttonDisable)
          store.dispatch(singleProductStart({ product_unique_id: singleProductData.product.product_unique_id }))
      } else {
        if (Object.keys(cartListData).length > 0)
          store.dispatch(cartListSuccess({
            total_carts: data.cart_count,
            user_cart_amount: data.user_cart_amount,
            carts: cartListData.carts.map(cartItem =>
              cartItem.product_attribute_id === action.payload.product_attribute_id &&
                cartItem.attributes.size === action.payload.size &&
                cartItem.attributes.weight === action.payload.weight
                ? { ...cartItem, quantity: data.cart.quantity } : cartItem
            )
          }));
        if (Object.keys(singleProductData).length > 0)
          store.dispatch(singleProductSuccess({
            ...singleProductData,
            carts: [
              ...singleProductData.carts,
              { ...data.cart.attributes, product_attribute_id: data.cart.product_attribute_id }
            ]
          }));
      }
    },

  });
}

function* cartDeleteAPI(action) {
  const profileData = yield select((state) => state.user.profile.data);
  const cartListData = yield select(state => state.cart.cartList.data);
  yield getCommonSaga({
    apiUrl: "carts_delete",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(cartDeleteSuccess(data));
      store.dispatch(fetchUserDetailsSuccess({
        ...profileData,
        cart_count: data.cart_count,
      }));
      if (Object.keys(cartListData).length > 0)
        store.dispatch(cartListSuccess({
          total_carts: data.cart_count,
          user_cart_amount: data.user_cart_amount,
          carts: cartListData.carts.filter(cartItem =>
            cartItem.cart_unique_id !== action.payload.cart_unique_id
          ),
        }))

    },
    failure: cartDeleteFailure,
  });
}



export default function* CartSaga() {
  yield all([
    yield takeLatest('cart/localCartList', updateLocalCart),
    yield takeLatest('cart/guestCartListStart', guestCartListAPI),
    yield takeLatest('cart/updateGuestCartListStart', guestCartListAPI),
    yield takeLatest('cart/cartListStart', cartListAPI),
    yield takeLatest('cart/cartSaveStart', cartSaveAPI),
    yield takeLatest('cart/cartDeleteStart', cartDeleteAPI)
  ]);
}