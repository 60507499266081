import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils"

const initialState = {
  withdrawRequest: getCommonInitialState(),
  addUserBillingAccounts: getCommonInitialState(),
  userBillingAccounts: getCommonInitialState(),
  withdrawRequestList: getCommonInitialState(),
  cancelWithdrawRequest: getCommonInitialState(),
  defaultUserBillingAccounts: getCommonInitialState(),
  deleteUserBillingAccounts: getCommonInitialState(),
  walletTransactionList: getCommonInitialState(),
  generateStripe: getCommonInitialState(),
  paymentByStripe: getCommonInitialState(),

};

const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {

    //Withdraw request
    withdrawRequestStart: (state) => { state.withdrawRequest = getCommonStart() },
    withdrawRequestSuccess: (state, action) => { state.withdrawRequest = getCommonSuccess(action.payload) },
    withdrawRequestFailure: (state, action) => { state.withdrawRequest = getCommonFailure(action.payload) },

    //Add Billing Account
    addUserBillingAccountsStart: (state) => { state.addUserBillingAccounts = getCommonStart() },
    addUserBillingAccountsSuccess: (state, action) => { state.addUserBillingAccounts = getCommonSuccess(action.payload) },
    addUserBillingAccountsFailure: (state, action) => { state.addUserBillingAccounts = getCommonFailure(action.payload) },

    //Billing Account list
    userBillingAccountsStart: (state) => { state.userBillingAccounts = getCommonStart() },
    userBillingAccountsSuccess: (state, action) => { state.userBillingAccounts = getCommonSuccess(action.payload) },
    userBillingAccountsFailure: (state, action) => { state.userBillingAccounts = getCommonFailure(action.payload) },

    //Default Account
    defaultUserBillingAccountsStart: (state) => { state.defaultUserBillingAccounts = getCommonStart() },
    defaultUserBillingAccountsSuccess: (state, action) => { state.defaultUserBillingAccounts = getCommonSuccess(action.payload) },
    defaultUserBillingAccountsFailure: (state, action) => { state.defaultUserBillingAccounts = getCommonFailure(action.payload) },

    //Remove Account
    deleteUserBillingAccountsStart: (state) => { state.deleteUserBillingAccounts = getCommonStart() },
    deleteUserBillingAccountsSuccess: (state, action) => { state.deleteUserBillingAccounts = getCommonSuccess(action.payload) },
    deleteUserBillingAccountsFailure: (state, action) => { state.deleteUserBillingAccounts = getCommonFailure(action.payload) },

    //Withdraw request list
    withdrawRequestListStart: (state) => { state.withdrawRequestList = getCommonStart({ user_withdrawals: [], total_withdrawals: 0 }) },
    withdrawRequestListSuccess: (state, action) => { state.withdrawRequestList = getCommonSuccess(action.payload) },
    withdrawRequestListFailure: (state, action) => { state.withdrawRequestList = getCommonFailure(action.payload) },
    withdrawMoreRequestList: (state, action) => { state.withdrawRequestList = state.withdrawRequestList },

    //Cancel request
    cancelWithdrawRequestStart: (state) => { state.cancelWithdrawRequest = getCommonStart() },
    cancelWithdrawRequestSuccess: (state, action) => { state.cancelWithdrawRequest = getCommonSuccess(action.payload) },
    cancelWithdrawRequestFailure: (state, action) => { state.cancelWithdrawRequest = getCommonFailure(action.payload) },

    //Wallet Transaction List[]
    fetchWalletTransactionListStart: (state, action) => { state.walletTransactionList = getCommonStart({ user_wallet_payments: [], total_wallet_payments: 0, user_wallet: {} }) },
    fetchWalletTransactionListSuccess: (state, action) => { state.walletTransactionList = getCommonSuccess(action.payload) },
    fetchWalletTransactionListFailure: (state, action) => { state.walletTransactionList = getCommonFailure(action.payload) },
    fetchMoreWalletTransaction: (state, action) => { state.walletTransactionList = state.walletTransactionList },

    //Generate Stripe Payment
    generateStripeStart: (state) => { state.generateStripe = getCommonStart() },
    generateStripeSuccess: (state, action) => { state.generateStripe = getCommonSuccess(action.payload) },
    generateStripeFailure: (state, action) => { state.generateStripe = getCommonFailure(action.payload) },

    // Payment By Stripe
    paymentByStripeStart: (state) => { state.paymentByStripe = getCommonStart() },
    paymentByStripeSuccess: (state, action) => { state.paymentByStripe = getCommonSuccess(action.payload) },
    paymentByStripeFailure: (state, action) => { state.paymentByStripe = getCommonFailure(action.payload) },


  }
});

export const {
  withdrawRequestStart,
  withdrawRequestSuccess,
  withdrawRequestFailure,
  addUserBillingAccountsStart,
  addUserBillingAccountsSuccess,
  addUserBillingAccountsFailure,
  userBillingAccountsStart,
  userBillingAccountsSuccess,
  userBillingAccountsFailure,
  defaultUserBillingAccountsStart,
  defaultUserBillingAccountsSuccess,
  defaultUserBillingAccountsFailure,
  deleteUserBillingAccountsStart,
  deleteUserBillingAccountsSuccess,
  deleteUserBillingAccountsFailure,
  withdrawRequestListStart,
  withdrawRequestListSuccess,
  withdrawRequestListFailure,
  withdrawMoreRequestList,
  cancelWithdrawRequestStart,
  cancelWithdrawRequestSuccess,
  cancelWithdrawRequestFailure,
  fetchWalletTransactionListStart,
  fetchWalletTransactionListSuccess,
  fetchWalletTransactionListFailure,
  fetchMoreWalletTransaction,
  generateStripeStart,
  generateStripeSuccess,
  generateStripeFailure,
  paymentByStripeStart,
  paymentByStripeSuccess,
  paymentByStripeFailure,

} = WalletSlice.actions;

export default WalletSlice.reducer;