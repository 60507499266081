import React, { useState } from "react";
import { Container, Image, Row, Col, Button, Collapse } from "react-bootstrap";
import ProfileSidebar from "./ProfileSidebar";
import WriteReviewModal from "./WriteReviewModal";
import { Link } from "react-router-dom";

const CancelOrder = (props) => {

    const [order1, setOrder1] = useState(false);

    const [order2, setOrder2] = useState(false);

    const [writeReview, setWriteReview] = useState(false);

    const closeWriteReviewModal = () => {
      setWriteReview(false);
    };

    return (
        <>
            <div className="profile-sec">
                <Container>
                    <Row>
                        <Col xl={3} md={12} lg={4} className="resp-hide">
                            <ProfileSidebar />
                        </Col>
                        <Col xl={9} md={12} lg={8}>
                            <div className="account-main-wrapper">
                                <div className="profile-header-sec">
                                    <h3>Cancel Order</h3>
                                    <p>Total Orders 1</p>
                                </div>
                                <div className="my-order-collapse-sec-box">
                                    <div className="delivery-status" onClick={() => setOrder1(!order1)}
                                        aria-controls="order1"
                                        aria-expanded={order1}>
                                        <div className="deliver-status-card point">
                                            <h4>Order Placed<span>06 Feb 2023 03:07 PM</span></h4>
                                            <h4>Total <span>14868.43 PSCN</span></h4>
                                            <h4>Status <span>Received</span></h4>
                                            <h4>Order ID <span>O-00005</span></h4>
                                            <img src="/assets/img/profile/down-arrow.svg"
                                                type="image/png" className="" />
                                        </div>
                                    </div>
                                    <Collapse in={order1}>
                                        <div id="order1">
                                            <div className="order-payment-details-card">
                                                <h5 className="order-notes">Note:<span>We'll contact you soon for payment and order updates.</span></h5>
                                                <div className="delivery-address">
                                                    <div className="delivery-payment delivery-title">
                                                        <h3>Payment Details</h3>
                                                        <h4>Sub total: <span>84.00 PSCN</span></h4>
                                                        <h4>Total Amount <span>84.00 PSCN</span></h4>
                                                        <h4>Payment Method <span>ADMIN</span></h4>
                                                    </div>
                                                    <div className="deliver-shipping delivery-title">
                                                        <h3>Shipping Details</h3>
                                                        <h4>Work</h4>
                                                        <p>1127,Wharf Street,Downtown,Victoria,Capital,British Columbia,Canada,V8W 1T7 Near Bus StopBritish
                                                            Columbia, 814 2503801322</p>
                                                    </div>
                                                    <div className="deliver-coupon">
                                                        <Button type="button" className="download-receipt-btn btn btn-primary"
                                                        >Get Invoice</Button>
                                                        <a className="wishlist-btn" href="/contact-us">Need Help?</a>
                                                    </div>
                                                </div>
                                                <div className="deliver-status-sec mt-4">
                                                    <h3>Canceled On 10 Oct 2022 09:44 PM</h3>
                                                    <div className="delivery-status-dropdown">
                                                        <Link className="search-btn" to="/order-tracking">Track Shipment</Link>
                                                    </div>
                                                </div>
                                                <div className="other-product-details mt-4">
                                                    <div className="other-product-box">
                                                        <div className="checkout-product-box">
                                                            <div className="checkout-product-img-sec">
                                                                <Image
                                                                    className="checkout-product-img"
                                                                    src={
                                                                    window.location.origin + "/assets/img/product/product-1.png"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkout-product-content">
                                                                <h4>CBN 1:2 NightTime Formula Oil</h4>
                                                                <p>QTY<span>1</span></p>
                                                                <h5>3797.52 PSCN<span>3797.52 PSCN</span></h5>
                                                            </div>
                                                        </div>
                                                        <div className="other-product-view">
                                                            <a className="wishlist-btn" href="#" type="button" onClick={() => setWriteReview(true)}>Write a product View</a>
                                                        </div>
                                                    </div>
                                                    <div className="other-product-box">
                                                        <div className="checkout-product-box">
                                                            <div className="checkout-product-img-sec">
                                                                <Image
                                                                        className="checkout-product-img"
                                                                        src={
                                                                        window.location.origin + "/assets/img/product/product-2.png"
                                                                        }
                                                                    />
                                                            </div>
                                                            <div className="checkout-product-content">
                                                                <h4>Strawberry Alien OG Shatter</h4>
                                                                <p>QTY<span>1</span></p>
                                                                <h5>3108.40 PSCN<span>3532.27 PSCN</span></h5>
                                                            </div>
                                                        </div>
                                                        <div className="other-product-view">
                                                            <a className="wishlist-btn" href="#" type="button" onClick={() => setWriteReview(true)}>Write a product View</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="my-order-collapse-sec-box">
                                    <div className="delivery-status" onClick={() => setOrder2(!order2)}
                                        aria-controls="order2"
                                        aria-expanded={order2}>
                                        <div className="deliver-status-card point">
                                            <h4>Order Placed<span>06 Feb 2023 03:07 PM</span></h4>
                                            <h4>Total <span>14868.43 PSCN</span></h4>
                                            <h4>Status <span>Received</span></h4>
                                            <h4>Order ID <span>O-00005</span></h4>
                                            <img src="/assets/img/profile/down-arrow.svg"
                                                type="image/png" />
                                        </div>
                                    </div>
                                    <Collapse in={order2}>
                                    <div id="order2">
                                            <div className="order-payment-details-card">
                                                <h5 className="order-notes">Note:<span>We'll contact you soon for payment and order updates.</span></h5>
                                                <div className="delivery-address">
                                                    <div className="delivery-payment delivery-title">
                                                        <h3>Payment Details</h3>
                                                        <h4>Sub total: <span>84.00 PSCN</span></h4>
                                                        <h4>Total Amount <span>84.00 PSCN</span></h4>
                                                        <h4>Payment Method <span>ADMIN</span></h4>
                                                    </div>
                                                    <div className="deliver-shipping delivery-title">
                                                        <h3>Shipping Details</h3>
                                                        <h4>Work</h4>
                                                        <p>1127,Wharf Street,Downtown,Victoria,Capital,British Columbia,Canada,V8W 1T7 Near Bus StopBritish
                                                            Columbia, 814 2503801322</p>
                                                    </div>
                                                    <div className="deliver-coupon">
                                                        <Button type="button" className="download-receipt-btn btn btn-primary"
                                                        >Get Invoice</Button>
                                                        <a className="wishlist-btn" href="/contact-us">Need Help?</a>
                                                    </div>
                                                </div>
                                                <div className="deliver-status-sec mt-4">
                                                    <h3>Expected On 29 Oct 2022</h3>
                                                    <div className="delivery-status-dropdown">
                                                        <Link className="search-btn" to="/order-tracking">Track Shipment</Link>
                                                    </div>
                                                </div>
                                                <div className="other-product-details mt-4">
                                                    <div className="other-product-box">
                                                        <div className="checkout-product-box">
                                                            <div className="checkout-product-img-sec">
                                                                    <Image
                                                                        className="checkout-product-img"
                                                                        src={
                                                                        window.location.origin + "/assets/img/product/product-1.png"
                                                                        }
                                                                    />
                                                            </div>
                                                            <div className="checkout-product-content">
                                                                <h4>CBN 1:2 NightTime Formula Oil</h4>
                                                                <p>QTY<span>1</span></p>
                                                                <h5>3797.52 PSCN<span>3797.52 PSCN</span></h5>
                                                            </div>
                                                        </div>
                                                        <div className="other-product-view">
                                                            <a className="wishlist-btn" href="#" type="button" onClick={() => setWriteReview(true)}>Write a product View</a>
                                                        </div>
                                                    </div>
                                                    <div className="other-product-box">
                                                        <div className="checkout-product-box">
                                                            <div className="checkout-product-img-sec">
                                                                <Image
                                                                        className="checkout-product-img"
                                                                        src={
                                                                        window.location.origin + "/assets/img/product/product-2.png"
                                                                        }
                                                                    />
                                                            </div>
                                                            <div className="checkout-product-content">
                                                                <h4>Strawberry Alien OG Shatter</h4>
                                                                <p>QTY<span>1</span></p>
                                                                <h5>3108.40 PSCN<span>3532.27 PSCN</span></h5>
                                                            </div>
                                                        </div>
                                                        <div className="other-product-view">
                                                            <a className="wishlist-btn" href="#" type="button" onClick={() => setWriteReview(true)}>Write a product View</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <WriteReviewModal
        writeReview={writeReview}
        closeWriteReviewModal={closeWriteReviewModal}
        setWriteReview={setWriteReview}
      />
        </>
    );
};

export default CancelOrder;
