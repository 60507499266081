import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';

import {
    deliveryAddressesSaveSuccess,
    deliveryAddressesSaveFailure,
    fetchdeliveryAddressesListSuccess,
    fetchdeliveryAddressesListFailure,
    makeDefaultDeliveryAddressesSuccess,
    makeDefaultDeliveryAddressesFailure,
    deliveryAddressesDeleteSuccess,
    deliveryAddressesDeleteFailure,
    fetchsingleDeliveryAddressSuccess,
    fetchsingleDeliveryAddressFailure,
    getcountriesSuccess,
    getcountriesFailure,
    getstatesSuccess,
    getstatesFailure,
} from "../slices/DeliverySlice";

function* saveDeliveryAddressesAPI(action) {
    yield getCommonSaga({
        apiUrl: "delivery_addresses_save",
        payload: action.payload,
        success: deliveryAddressesSaveSuccess,
        failure: deliveryAddressesSaveFailure,
    });
}

function* fetchDeliveryAddressesListAPI(action) {
    yield getCommonSaga({
        apiUrl: "delivery_addresses_list",
        payload: action.payload,
        success: fetchdeliveryAddressesListSuccess,
        failure: fetchdeliveryAddressesListFailure,
    });
}

function* makeDefaultDeliveryAddressesAPI(action) {
    yield getCommonSaga({
        apiUrl: "delivery_addresses_make_default",
        payload: action.payload,
        success: makeDefaultDeliveryAddressesSuccess,
        failure: makeDefaultDeliveryAddressesFailure,
    });
}

function* deleteDeliveryAddressesAPI(action) {
    yield getCommonSaga({
        apiUrl: "delivery_addresses_delete",
        payload: action.payload,
        successCallback: true,
        success: data => store.dispatch(deliveryAddressesDeleteSuccess({ data: data })),
        failure: deliveryAddressesDeleteFailure,
    });
}


function* fetchSingleDeliveryAddressAPI(action) {
    yield getCommonSaga({
        apiUrl: "delivery_addresses_view",
        payload: action.payload,
        success: fetchsingleDeliveryAddressSuccess,
        failure: fetchsingleDeliveryAddressFailure,
    });
}

function* getCountriesAPI(action) {
    yield getCommonSaga({
        apiUrl: "get_countries",
        payload: action.payload,
        success: getcountriesSuccess,
        failure: getcountriesFailure,
    });
}

function* getStatesAPI(action) {
    yield getCommonSaga({
        apiUrl: "get_states",
        payload: action.payload,
        success: getstatesSuccess,
        failure: getstatesFailure,
    });
}


export default function* DeliverySaga() {
    yield all([
        yield takeLatest('delivery/deliveryAddressesSaveStart', saveDeliveryAddressesAPI),
        yield takeLatest('delivery/fetchdeliveryAddressesListStart', fetchDeliveryAddressesListAPI),
        yield takeLatest('delivery/makeDefaultDeliveryAddressesStart', makeDefaultDeliveryAddressesAPI),
        yield takeLatest('delivery/deliveryAddressesDeleteStart', deleteDeliveryAddressesAPI),
        yield takeLatest('delivery/fetchsingleDeliveryAddressStart', fetchSingleDeliveryAddressAPI),
        yield takeLatest('delivery/getcountriesStart', getCountriesAPI),
        yield takeLatest('delivery/getstatesStart', getStatesAPI),
    ]);
}