import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CheckOutAddressModal from "./CheckOutAddressModal";
import "./Cart.css";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { fetchdeliveryAddressesListStart } from "../../store/slices/DeliverySlice";
import { cartListStart } from "../../store/slices/CartSlice";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import PageLoader from "../Helper/PageLoader";

const CheckoutAddress = (props) => {
  const t = useTranslation("checkout");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const cartList = useSelector(state => state.cart.cartList);
  const deliveryAddressesList = useSelector(state => state.delivery.deliveryAddressesList);

  const [skipRender, setSkipRender] = useState(true);
  const [showContent, setShowContent] = useState(true);
  const [checkOutAddress, setCheckOutAddress] = useState(false);

  const closeCheckOutAddressModal = () => {
    setCheckOutAddress(false);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(fetchdeliveryAddressesListStart());
      dispatch(cartListStart());
    }
  }, [profile]);

  useEffect(() => {
    if ((!skipRender && !deliveryAddressesList.loading && Object.keys(deliveryAddressesList.data) === 0) ||
      (!cartList.loading && Object.keys(cartList.data).length === 0))
      setShowContent(false);
    setSkipRender(false);
  }, [deliveryAddressesList, cartList]);

  const confirmAddress = (addressUniqueId) => {
    localStorage.setItem("addessUniqueId", addressUniqueId);
    navigate("/checkout-summary");
  };

  return (
    <>
      <div className="cart-sec">
        {profile.loading ?
          <PageLoader />
          : showContent && Object.keys(profile.data).length > 0 ?
            <Container>
              <Row>
                <Col md={12}>
                  <div className="order-tracking-header-info mb-3">
                    <Button className="back-btn"
                      onClick={() => navigate(-1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" /></svg></Button>
                      <h4>{t("your_cart")}</h4>
                  </div>
                </Col>
              </Row>
              <div className="cart-box">
                <Row className="gy-4 justify-content-center">
                  <Col md={12} xl={8} lg={8}>
                    <div className="checkout-header-sec">
                      <ul className="list-unstyled checkout-progress-bar">
                        <li className="step1">
                          <div className="checkout-flex active">
                            <div className="count-no">1</div>
                            <h6>{t("section.address")}</h6>
                          </div>
                        </li>
                        <li className="step2">
                          <div className="checkout-flex">
                            <div className="count-no">2</div>
                            <h6>{t("section.order_summary")}</h6>
                          </div>
                        </li>
                        <li className="step3">
                          <div className="checkout-flex">
                            <div className="count-no">3</div>
                            <h6>{t("section.payment")}</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="checkout-body manage-address-tab-sec">
                      <div className="manage-address-box">
                        {deliveryAddressesList.loading ?
                          <Skeleton count={2} height={200} />
                          : deliveryAddressesList.data.delivery_addresses.length > 0 ?
                            deliveryAddressesList.data.delivery_addresses.map((address, i) =>
                              <div className="checkbox">
                                <input type="radio" name="manage-address-radio" id="check-box-1" />
                                <div className="manage-address-card" for="check-box-1">
                                  <div className="manage-address-info">
                                    <h4>{address.name}</h4>
                                    <p className="address-block">
                                      {address.address}
                                      {"\n"}
                                      {address.city} {address.state}{" "}
                                      {address.pincode}
                                      {"\n"}
                                      {address.landmark}
                                      {"\n"}
                                      {address.contact_number}
                                    </p>
                                  </div>
                                  <div className="manage-address-action-btn-sec">
                                    {/* <div className="manage-address-action-btn">
                                    <Button className="edit-btn">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M21 11.5V15h-3a3 3 0 00-3 3v3H4.5A1.5 1.5 0 013 19.5v-15A1.5 1.5 0 014.5 3h9A1.5 1.5 0 0015 1.5 1.5 1.5 0 0013.5 0h-9A4.5 4.5 0 000 4.5v15A4.5 4.5 0 004.5 24h11.984a4.5 4.5 0 003.181-1.317l3.017-3.017A4.5 4.5 0 0024 16.485V11.5a1.5 1.5 0 00-1.5-1.5 1.5 1.5 0 00-1.5 1.5z"></path>
                                        <path d="M17.793 1.793l-12.5 12.5A1 1 0 005 15v3a1 1 0 001 1h3a1 1 0 00.707-.293L22.038 6.376a3.379 3.379 0 00.952-3.17 3.118 3.118 0 00-5.197-1.413z"></path>
                                      </svg>
                                    </Button>
                                    <Button className="delete-btn">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        data-name="Isolation Mode"
                                        viewBox="0 0 24 24"
                                        fill="#dc3545"
                                      >
                                        <path d="M23 3h-5v-.5A2.5 2.5 0 0015.5 0h-7A2.5 2.5 0 006 2.5V3H1v3h2v15a3 3 0 003 3h12a3 3 0 003-3V6h2zm-5 18H6V6h12z"></path>
                                        <path d="M8 9H11V18H8z"></path>
                                        <path d="M13 9H16V18H13z"></path>
                                      </svg>
                                    </Button>
                                  </div> */}
                                    <div className="manage-address-default-btn">
                                      {cartList.data
                                        ? <Button
                                          className="default-btn"
                                          onClick={() =>
                                            confirmAddress(
                                              address.delivery_address_unique_id
                                            )
                                          }
                                        >
                                          {t("use_address")}
                                        </Button>
                                        : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>)
                            :
                            null
                        }
                      </div>
                    </div>
                    <div className="checkout-new-address-card" onClick={() => setCheckOutAddress(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 512 512"
                        version="1.1"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                      >
                        <path d="M174.394 478.877c44.856 43.881 116.497 44.06 161.572.404l84.946-78.499c92.021-90.795 93.014-238.997 2.218-331.017S184.133-23.249 92.113 67.547c-.744.734-1.484 1.474-2.218 2.218-91.403 91.417-91.391 239.622.026 331.025l.569.567 83.904 77.52zM119.983 99.854c74.626-74.955 195.885-75.222 270.84-.596s75.222 195.885.596 270.84l-84.776 78.35c-28.956 27.252-74.174 27.084-102.927-.383l-83.733-77.371c-74.79-74.79-74.791-196.048-.002-270.839l.002-.001zm29.025 135.42c0-11.752 9.527-21.279 21.279-21.279h63.837v-63.837c0-11.752 9.527-21.279 21.279-21.279s21.279 9.527 21.279 21.279v63.837h63.837c11.752 0 21.279 9.527 21.279 21.279s-9.527 21.279-21.279 21.279h-63.837v63.837c0 11.752-9.527 21.279-21.279 21.279s-21.279-9.527-21.279-21.279v-63.837h-63.837c-11.752 0-21.279-9.527-21.279-21.279z"></path>
                      </svg>
                      <h4>{t("add_address")}</h4>
                    </div>
                  </Col>
                  {cartList.loading ?
                    <Col md={6} xl={4} lg={4}>
                      <Skeleton height={250} />
                    </Col>
                    : cartList.data.user_cart_amount ?
                      <Col md={6} xl={4} lg={4}>
                        <div className="cart-card">
                          <div className="cart-header">
                            <h4>{t("price_details.heading")}</h4>
                          </div>
                          <div className="cart-body">
                            <div className="cart-summary-info">
                              <div className="cart-summary-header">
                                <p>{t("price_details.cart_total")}<span>{cartList.data.user_cart_amount.cart_total_amount}</span></p>
                                <p>{t("price_details.discount")}<span>-{cartList.data.user_cart_amount.cart_discount_amount}</span></p>
                              </div>
                              <div className="cart-summary-total-amount">
                                <h5>{t("price_details.total_amount")}<span>{cartList.data.user_cart_amount.cart_checkout_amount}</span></h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      : null
                  }
                </Row>
              </div>
            </Container>
            : <div className="text-center">
              <SomethingWentWrong />
              <Button onClick={() => navigate("/")}>{t("go_home")}</Button>
            </div>
        }
      </div>
      {checkOutAddress ?
        <CheckOutAddressModal
          closeCheckOutAddressModal={closeCheckOutAddressModal}
        />
        : null
      }
    </>
  );
};

export default CheckoutAddress;
