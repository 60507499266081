import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';

import {
    fetchSinglePageSuccess,
    fetchSinglePageFailure,
    contactSubmitSuccess,
    contactSubmitFailure,
} from "../slices/PageSlice";

function* fetchStaticPageAPI(action) {
    yield getCommonSaga({
        apiUrl: "static_pages_view",
        payload: action.payload,
        successNotify: false,
        success: fetchSinglePageSuccess,
        failure: fetchSinglePageFailure,
    });
}

function* contactRequestsSaveAPI(action) {
    yield getCommonSaga({
        apiUrl: "contact_requests_save",
        payload: action.payload,
        successNotify: true,
        success: contactSubmitSuccess,
        failure: contactSubmitFailure,
    });
}

export default function* PageSaga() {
    yield all([
        yield takeLatest('page/fetchSinglePageStart', fetchStaticPageAPI),
        yield takeLatest('page/contactSubmitStart', contactRequestsSaveAPI),
    ]);
}