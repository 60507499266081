import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { customerReviewsListStart } from "../../store/slices/HomePageSlice";
import Skeleton from "react-loading-skeleton";
import ReactStars from "react-rating-stars-component";

const Ratings = () => {
  const dispatch = useDispatch();
  const customerReviewsList = useSelector(
    (state) => state.homepage.customerReviewsList
  );

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(customerReviewsListStart({ order_by: "newest", take: 3 }));
  }, []);

  return (
    <>
      <div className="customer-frame">
        <div className="new-client-title-sec">
          <Container>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="new-section-title">
                  <h2>What People Say</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="new-client-sec">
          <Container>
            <div className="customer-slide-frame">
              {customerReviewsList.loading ? (
                <Slider {...settings}>
                  {[...Array(3)].map((val, i) => (
                    <Skeleton height={200} width={350} key={i} />
                  ))}
                </Slider>
              ) : Object.keys(customerReviewsList.data).length > 0 &&
                customerReviewsList.data.reviews.length > 0 ? (
                <Slider {...settings}>
                  {customerReviewsList.data.reviews.map((reviews, i) => (
                    <div>
                      <div className="customer-card">
                        <div className="customer-quotes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="38"
                            height="27"
                            viewBox="0 0 38 27"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.991211 13.408V26.8161H16.0438V13.408H7.44233C7.44233 9.17689 11.2937 5.7463 16.0438 5.7463V0C7.72975 0 0.991211 6.00269 0.991211 13.408ZM37.5472 5.7463V0C29.2335 0 22.4945 6.00269 22.4945 13.408V26.8161H37.5472V13.408H28.9457C28.9457 9.17689 32.797 5.7463 37.5472 5.7463Z"
                              fill="#FFB52D"
                            />
                          </svg>
                        </div>
                        <div className="customer-card-img-sec mb-3">
                          <Image
                            className="customer-card-img"
                            src={reviews.user.picture}
                          />
                        </div>
                        <ReactStars
                          count={5}
                          value={reviews.star}
                          edit={false}
                          size={24}
                          isHalf={true}
                          emptyIcon={<i class="far fa-star"></i>}
                          halfIcon={<i class="fas fa-star-half-alt"></i>}
                          fullIcon={<i class="fas fa-star"></i>}
                          activeColor="#FFB52D"
                        />
                        <div className="customer-card-info">
                          <p>{reviews.review}</p>
                          <h4>{reviews.user.name}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : null}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Ratings;
