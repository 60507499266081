import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";

const NewLandingCategories = (props) => {

  return (
    <>
      <div className="new-landing-categories-sec">
        <Container>
            <Row>
                <Col md={12}>
                    <div className="new-landing-categories-box">
                        <ul className="list-unstyled">
                            <li>
                                <Link to="#">
                                    White Gold
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    Yellow Gold
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    Rose Gold
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                   18k Gold
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                   22k Gold
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default NewLandingCategories;
