import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from "react-redux";
import {
  defaultUserBillingAccountsStart,
  deleteUserBillingAccountsStart,
  userBillingAccountsStart,
} from '../../store/slices/WalletSlice';
import {
  Image
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const SingleBillingAddressCard = (props) => {

  const t = useTranslation("single_billing");
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);
  const { selectedAccount, setSelectedAccount, account } = props;

  const defaultUserBillingAccounts = useSelector(state => state.wallet.defaultUserBillingAccounts);
  const deleteUserBillingAccounts = useSelector(state => state.wallet.deleteUserBillingAccounts);
  const userBillingAccounts = useSelector(state => state.wallet.userBillingAccounts);

  const setDefaultAccount = (e) => {
    dispatch(
      defaultUserBillingAccountsStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
  };

  const removeAccount = (e) => {
    dispatch(
      deleteUserBillingAccountsStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
    e.stopPropagation();
  };

  const removeAccountConfirmation = (e) => {
    confirmAlert({
      message: t("confirmation"),
      buttons: [
        {

          label: t("confirm_yes"),
          onClick: () => removeAccount(e)
        },
        {
          label: t("confirm_no"),
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }

  useEffect(() => {
    if (
      !skipRender &&
      !defaultUserBillingAccounts.loading &&
      Object.keys(defaultUserBillingAccounts.data).length > 0
    ) {
      dispatch(userBillingAccountsStart());
    }
    setSkipRender(false);
  }, [defaultUserBillingAccounts]);

  useEffect(() => {
    if (
      !skipRender &&
      !deleteUserBillingAccounts.loading &&
      Object.keys(deleteUserBillingAccounts.data).length > 0) {
      dispatch(userBillingAccountsStart());
    }
    setSkipRender(false);
  }, [deleteUserBillingAccounts]);

  return (
    <>
      <div className="checkbox label-bg">
        <input type="radio"
          name="manage-billing-radio"
          id="check-box-1"
          checked={selectedAccount === account.user_billing_account_id}
          className="text-warning"
        />
        <label
          name="manage-billing-radio"
          className="text-warning"
          type="radio"
          style={{ cursor: "pointer" }}
          checked={selectedAccount === account.user_billing_account_id}
          onClick={() => setSelectedAccount(account.user_billing_account_id)}
        >
          <div className="manage-billing-card" for="check-box-1">
            <div className="manage-billing-info">
              <h4>{account.first_name}</h4>
              <ul>
                <li>{t("account_details.first_name")} - {account.first_name}</li>
                <li>{t("account_details.laste_name")} - {account.last_name}</li>
                <li>{t("account_details.route_number")} - {account.route_number}</li>
                <li>{t("account_details.account_number")} - {account.account_number}</li>
                <li>{t("account_details.bank_type")} - {account.bank_type}</li>

                <li>{account.business_name !== null ?
                  t("account_details.bussiness_name", { bussiness_name: account.business_name }) : null}</li>
              </ul>
              <p>  {account.is_default == 1 ? (
                <span className="text-success">{t("default")}</span>)
                : (
                  <span
                    onClick={(e) => setDefaultAccount(e)}
                    className="text-warning"
                    style={{ cursor: "pointer" }}
                  >
                    {defaultUserBillingAccounts.buttonDisable ?
                      t("single_btn.loading") :
                      t("single_btn.text")
                    }
                  </span>
                )}
              </p>
              <p>
                <span
                  onClick={removeAccountConfirmation}
                  className="text-danger card-delete del"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Isolation Mode"
                    viewBox="0 0 24 24"
                    fill="#dc3545"
                  >
                    <path d="M23 3h-5v-.5A2.5 2.5 0 0015.5 0h-7A2.5 2.5 0 006 2.5V3H1v3h2v15a3 3 0 003 3h12a3 3 0 003-3V6h2zm-5 18H6V6h12z"></path>
                    <path d="M8 9H11V18H8z"></path>
                    <path d="M13 9H16V18H13z"></path>
                  </svg>
                </span>

              </p>
            </div>
            <div className="check-box-tick-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#404040"
                  d="M12 24a5.789 5.789 0 01-4.217-1.82 5.816 5.816 0 01-4.269-1.694 5.797 5.797 0 01-1.695-4.269c-2.401-2.149-2.401-6.285 0-8.434a5.797 5.797 0 011.695-4.269 5.752 5.752 0 014.269-1.695c2.148-2.402 6.286-2.402 8.434 0a5.797 5.797 0 015.964 5.963c2.401 2.149 2.401 6.285 0 8.434a5.797 5.797 0 01-1.695 4.269 5.822 5.822 0 01-4.269 1.695A5.787 5.787 0 0112 24zm-3.563-4.897a1.5 1.5 0 011.244.662c1.031 1.628 3.608 1.628 4.639 0a1.494 1.494 0 011.53-.634 2.792 2.792 0 002.515-.767 2.803 2.803 0 00.767-2.515 1.5 1.5 0 01.634-1.529c1.628-1.031 1.628-3.609 0-4.64a1.5 1.5 0 01-.634-1.529 2.797 2.797 0 00-3.282-3.282 1.493 1.493 0 01-1.53-.634c-1.031-1.628-3.608-1.628-4.639 0a1.493 1.493 0 01-1.53.634 2.796 2.796 0 00-3.282 3.282 1.5 1.5 0 01-.634 1.529c-1.628 1.031-1.628 3.609 0 4.64a1.5 1.5 0 01.634 1.529 2.797 2.797 0 003.282 3.282 1.46 1.46 0 01.286-.028zM13 15.198l4-3.58a1.5 1.5 0 00-2-2.235l-4.049 3.638a493.171 493.171 0 00-1.989-1.671 1.5 1.5 0 10-1.924 2.301l1.847 1.544a2.968 2.968 0 002.06.822c.8 0 1.551-.311 2.056-.819z"
                  data-original="#000000"
                ></path>
              </svg>
            </div>
          </div>
        </label>
      </div>
    </>
  )
}

export default SingleBillingAddressCard;