import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Collapse, Button, InputGroup, Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { metalListStart, moreProductListStart, productListStart } from "../../store/slices/ProductSlice";
import NoDataFound from "../Helper/NoDataFound";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import "./Product.css";
import ProductListCard from "./ProductListCard";

const initialCondition = {
  sub_category_id: [],
  order_by: "newest",
  from: "",
  to: "",
  metal_id: "",
};

const ProductFilter = (props) => {
  const t = useTranslation("products");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const productList = useSelector(state => state.product.productList);
  const categoriesList = useSelector(state => state.homepage.categoriesList);
  const metalList = useSelector(state => state.product.metalList);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [conditions, setConditions] = useState(initialCondition);
  const [skipRender, setSkipRender] = useState(true);
  const [price, setPrice] = useState({
    from: "",
    to: "",
  });


  useEffect(() => {
    dispatch(metalListStart());
  }, []);

  useEffect(() => {
    if (!skipRender && (searchParams.get('search') || searchParams.get("category"))) {
      if (searchParams.get("search"))
        setSearch(searchParams.get("search"));
      else
        setSearch("");
      if (!searchParams.get("category")) {
        setConditions({
          ...conditions,
          sub_category_id: [],
        })
      } else {
        const selectedCategory = categoriesList.data.categories.filter(cat => cat.category_unique_id == searchParams.get('category'));
        if (selectedCategory.length > 0) {
          setOpen(`c-${selectedCategory[0].category_id}`)
          const subCategories = selectedCategory[0].sub_category.map(subCategory => subCategory.sub_category_id);
          setConditions({
            ...conditions,
            sub_category_id: subCategories,
          })
        } else {
          if (searchParams.get("metal"))
            setConditions({ ...conditions, metal_id: searchParams.get("metal") });
          else
            fetchProducts(searchParams.get("search") ? searchParams.get("search") : "");
        }
      }
    } else if (!skipRender) {
      setSearch("");
      fetchProducts("");
    }
    window.scrollTo(0, 0);
  }, [searchParams.get("search"), searchParams.get("category"), searchParams.get("metal")]);

  useEffect(() => {
    if (skipRender && (searchParams.get('search') || searchParams.get("category"))) {
      if (searchParams.get("search"))
        setSearch(searchParams.get("search"));
      if (!searchParams.get("category"))
        fetchProducts(searchParams.get("search"))
    } else {
      if (skipRender && searchParams.get("metal"))
        setConditions({ ...conditions, metal_id: searchParams.get("metal") });
      else
        fetchProducts();
    }
    setSkipRender(false);
    window.scrollTo(0, 0);
  }, [conditions]);

  useEffect(() => {
    if (!categoriesList.loading && Object.keys(categoriesList.data).length > 0) {
      if (searchParams.get('category')) {
        const selectedCategory = categoriesList.data.categories.filter(cat => cat.category_unique_id == searchParams.get('category'));
        if (selectedCategory.length > 0) {
          setOpen(`c-${selectedCategory[0].category_id}`)
          const subCategories = selectedCategory[0].sub_category.map(subCategory => subCategory.sub_category_id);
          setConditions({
            ...conditions,
            sub_category_id: subCategories,
          })
        } else {
          fetchProducts();
        }
      }
    }
  }, [categoriesList]);

  const fetchProducts = (searchKey = search) => {
    let data = {
      ...conditions,
      sub_category_id: "",
      search_key: searchKey,
      skip: 0,
      take: 12,
    };
    if (conditions.sub_category_id.length > 0) {
      data = {
        ...data,
        sub_category_id: JSON.stringify(conditions.sub_category_id),
      };
    }
    dispatch(productListStart(data));
  };

  const fetchMoreProducts = () => {
    let data = {
      ...conditions,
      sub_category_id: "",
      search_key: search,
      skip: productList.data.products.length,
      take: 12,
    };
    if (conditions.sub_category_id.length > 0) {
      data = {
        ...data,
        sub_category_id: JSON.stringify(conditions.sub_category_id),
      };
    }
    dispatch(moreProductListStart({
      skip: productList.data.products.length,
      take: 12,
      ...data
    }))
  }

  const setFromPrice = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val > 0) {
      setPrice({ ...price, from: parseInt(val) });
    } else if (val === "") {
      setPrice({ ...price, from: val });
    }
  };

  const setToPrice = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val > 0) {
      setPrice({ ...price, to: parseInt(val) });
    } else if (val === "") {
      setPrice({ ...price, to: val });
    }
  };

  const setPriceFilter = (e) => {
    setConditions({
      ...conditions,
      from: price.to && price.from === "" ? 0 : price.from,
      to: price.to,
    });
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setPrice({
      from: "",
      to: "",
    });
    setSearch("");
    setConditions(initialCondition);
  };

  const [showDetail, setShowDetail] = useState(false);

  const handleToggle = () => setShowDetail(!showDetail);

  return (
    <>
      <div className="product-search-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="product-search-box">
                {showDetail &&
                  <div className="product-search-left mobile-product-search-left">
                    
                    {categoriesList.loading ?
                      <>
                        <Skeleton height={350} className="mb-4" />
                      </>
                      : categoriesList.data.categories &&
                        categoriesList.data.categories.length > 0 ?
                        <div className="product-search-left-card">
                          <div className="product-search-header-card">
                            {t("shop_by_categories")}
                            <div className="product-search-remove-icon" onClick={handleToggle}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24" height="24" fill="#333">
                                   <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256l105.3-105.4z"></path>
                            </svg>
                            </div>
                          </div>
                          {categoriesList.data.categories.map((category, i) => (
                            <Fragment key={i}>
                              <Button
                                onClick={() =>
                                  setOpen(open === `c-${category.category_id}` ? false : `c-${category.category_id}`)
                                }
                                className="btn-categories uppercase"
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                              >
                                {category.name}
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                  >
                                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
                                  </svg>
                                </span>
                              </Button>
                              <Collapse in={open == `c-${category.category_id}`}>
                                <div className="catgories-availablity-sec">
                                  {category.sub_category.map(
                                    (subCategory, j) => (
                                      <div
                                        className="custom-checkbox"
                                        key={j}
                                      >
                                        <input
                                          type="checkbox"
                                          id={`category-${i}${j}`}
                                          checked={conditions.sub_category_id.includes(
                                            subCategory.sub_category_id
                                          )}
                                          onClick={() =>
                                            setConditions({
                                              ...conditions,
                                              sub_category_id:
                                                conditions.sub_category_id.includes(subCategory.sub_category_id)
                                                  ? conditions.sub_category_id.filter((id) => id !== subCategory.sub_category_id)
                                                  : [...conditions.sub_category_id, subCategory.sub_category_id,],
                                            })
                                          }
                                        />
                                        <label htmlFor={`category-${i}${j}`}>
                                          <span>{subCategory.name}</span>
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </Collapse>
                            </Fragment>
                          )
                          )}
                        </div>
                        : null

                    }

                    <div className="product-search-left-card">
                      <div className="product-search-header-card">
                        {t("shop_by_prize")}
                      </div>
                      <div className="categories-search-price-card">
                        <InputGroup className="mb-0">
                          <Form.Control
                            type="number"
                            placeholder={t("from")}
                            value={price.from}
                            onChange={setFromPrice}
                          />
                        </InputGroup>
                        <InputGroup className="mb-0">
                          <Form.Control
                            type="number"
                            placeholder={t("to")}
                            value={price.to}
                            onChange={setToPrice}
                          />
                        </InputGroup>
                        <Button
                          className="default-btn"
                          onClick={setPriceFilter}
                          disabled={price.from >= price.to}
                        >
                          {t("filter")}
                        </Button>
                      </div>
                    </div>
                    {metalList.loading ?
                      <>
                        <Skeleton height={350} className="mb-4" />
                      </>
                      : metalList.data.metals &&
                        metalList.data.metals.length > 0 ?
                        <div className="product-search-left-card">
                          <div className="product-search-header-card">
                            {t("shop_by_metal")}
                          </div>
                          <ul className="list-unstyled product-type-list-sec">
                            {metalList.data.metals.map((metal, i) =>
                              <li key={i}
                                className={`metal-item ${conditions.metal_id === metal.metal_id ? "active" : ""}`}
                                onClick={() => {
                                  setConditions({
                                    ...conditions,
                                    metal_id: conditions.metal_id === metal.metal_id ? "" : metal.metal_id
                                  });
                                }}
                              >
                                {metal.name}
                              </li>
                            )}
                          </ul>
                        </div>
                        : null
                    }
                  </div>
                }
                <div className="product-search-left desktop-product-search-left">
                  {categoriesList.loading ?
                    <>
                      <Skeleton height={350} className="mb-4" />
                    </>
                    : categoriesList.data.categories &&
                      categoriesList.data.categories.length > 0 ?
                      <div className="product-search-left-card">
                        <div className="product-search-header-card">
                          {t("shop_by_categories")}
                        </div>
                        {categoriesList.data.categories.map((category, i) => (
                          <Fragment key={i}>
                            <Button
                              onClick={() =>
                                setOpen(open === `c-${category.category_id}` ? false : `c-${category.category_id}`)
                              }
                              className="btn-categories uppercase"
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >
                              {category.name}
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
                                </svg>
                              </span>
                            </Button>
                            <Collapse in={open == `c-${category.category_id}`}>
                              <div className="catgories-availablity-sec">
                                {category.sub_category.map(
                                  (subCategory, j) => (
                                    <div
                                      className="custom-checkbox"
                                      key={j}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`category-${i}${j}`}
                                        checked={conditions.sub_category_id.includes(
                                          subCategory.sub_category_id
                                        )}
                                        onClick={() =>
                                          setConditions({
                                            ...conditions,
                                            sub_category_id:
                                              conditions.sub_category_id.includes(subCategory.sub_category_id)
                                                ? conditions.sub_category_id.filter((id) => id !== subCategory.sub_category_id)
                                                : [...conditions.sub_category_id, subCategory.sub_category_id,],
                                          })
                                        }
                                      />
                                      <label htmlFor={`category-${i}${j}`}>
                                        <span>{subCategory.name}</span>
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </Collapse>
                          </Fragment>
                        )
                        )}
                      </div>
                      : null

                  }

                  <div className="product-search-left-card">
                    <div className="product-search-header-card">
                      {t("shop_by_prize")}
                    </div>

                    <div className="categories-search-price-card">
                      <InputGroup className="mb-0">
                        <Form.Control
                          type="number"
                          placeholder={t("from")}
                          value={price.from}
                          onChange={setFromPrice}
                        />
                      </InputGroup>
                      <InputGroup className="mb-0">
                        <Form.Control
                          type="number"
                          placeholder={t("to")}
                          value={price.to}
                          onChange={setToPrice}
                        />
                      </InputGroup>
                      <Button
                        className="default-btn"
                        onClick={setPriceFilter}
                        disabled={price.from >= price.to}
                      >
                        {t("filter")}
                      </Button>
                    </div>
                  </div>
                  {metalList.loading ?
                    <>
                      <Skeleton height={350} className="mb-4" />
                    </>
                    : metalList.data.metals &&
                      metalList.data.metals.length > 0 ?
                      <div className="product-search-left-card">
                        <div className="product-search-header-card">
                          {t("shop_by_metal")}
                        </div>
                        <ul className="list-unstyled product-type-list-sec">
                          {metalList.data.metals.map((metal, i) =>
                            <li key={i}
                              className={`metal-item ${conditions.metal_id == metal.metal_id ? "active" : ""}`}
                              onClick={() => {
                                setConditions({
                                  ...conditions,
                                  metal_id: conditions.metal_id == metal.metal_id ? "" : metal.metal_id
                                });
                              }}
                            >
                              {metal.name}
                            </li>
                          )}
                        </ul>
                      </div>
                      : null
                  }
                </div>
                <div className="product-search-right">
                  <div className="product-search-sortby-sec">
                    <div className="product-sortby-box">
                      <h4>
                        {t("products.heading", { count: productList.data.total_products ? productList.data.total_products : 0 })}
                        <span> {JSON.stringify(conditions) !==
                          JSON.stringify(initialCondition) ? (
                          <Link to="" onClick={resetFilter}>
                            {t("clear")}
                          </Link>
                        ) : null}
                        </span>
                      </h4>
                      <div className="product-sortby-icon-sec" onClick={handleToggle}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="#404040"
                          viewBox="0 0 24 24"
                        >
                          <path d="M1 4.75h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2zM7.333 2a1.75 1.75 0 11-1.75 1.75A1.752 1.752 0 017.333 2zM23 11h-2.736a3.727 3.727 0 00-7.194 0H1a1 1 0 000 2h12.07a3.727 3.727 0 007.194 0H23a1 1 0 000-2zm-6.333 2.75a1.75 1.75 0 111.75-1.75 1.752 1.752 0 01-1.75 1.75zM23 19.25H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2zM7.333 22a1.75 1.75 0 111.75-1.75A1.753 1.753 0 017.333 22z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="product-search-sortby-card">
                      <h4>{t("sort_by")}</h4>
                      <Form.Select
                        aria-label="Default select example"
                        value={conditions.order_by}
                        onChange={(e) =>
                          setConditions({
                            ...conditions,
                            order_by: e.target.value,
                          })
                        }
                      >
                        <option value="price_hl"> {t("price_high_to_low")} </option>
                        <option value="price_lh"> {t("price_low_to_high")} </option>
                        <option value="popular"> {t("popular")} </option>
                        <option value="newest">{t("newest")}</option>
                        <option value="oldest">{t("oldest")}</option>
                      </Form.Select>
                    </div>
                  </div>
                  {productList.loading ?
                    <div className="product-list-box">
                      {[...Array(6)].map((val, i) => <Skeleton height={445} key={i} />)}
                    </div>
                    : productList.data.products ?
                      productList.data.products.length > 0 ?
                        <InfiniteScroll
                          dataLength={productList.data.products.length}
                          next={fetchMoreProducts}
                          hasMore={
                            productList.data.products.length < productList.data.total_products
                          }
                          loader={<div className="product-list-box">
                            {[...Array(6)].map((val, i) => <Skeleton height={360} key={i} />)}
                          </div>}
                        >
                          <div className="product-list-box">
                            {productList.data.products.map((product, i) =>
                              <ProductListCard key={i} product={product} />
                            )}
                          </div>
                        </InfiniteScroll>
                        : <NoDataFound />
                      : <SomethingWentWrong />
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProductFilter;
