import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategoriesListStart } from "../../store/slices/HomePageSlice";
import { HashLoader } from "react-spinners";
import { useTranslation } from "react-multi-lang";

const PopularCategories = () => {

  const t = useTranslation("landing_page");
  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.homepage.categoriesList);

  useEffect(() => {
    dispatch(fetchCategoriesListStart());
  }, []);

  return (
    <div className="popular-categories-frame">
      <div className="new-section-title">
        <h2>{t("popular_categories")}</h2>
        <p>{t("located_in_the_heart_of_the_jeweler_hub")}</p>
      </div>
      <Container>
        <div className="popular-categories-main">
          {categoriesList.loading ? (
            <React.Fragment>
              {[...Array(8)].map((i) => (
                <HashLoader className="mt-5" color="#005479" />
              ))}
            </React.Fragment>
          ) : Object.keys(categoriesList.data).length > 0 &&
            categoriesList.data.categories.length > 0 ? (
            <React.Fragment>
              {categoriesList.data.categories.slice(0,7).map((category, i) => (
                <Link
                  to={`/products?category=${category.category_unique_id}`}
                  key={i}
                >
                  <div className="popular-categories-card">
                    <div className="popular-card-img">
                      <Image src={category.picture} />
                    </div>
                    <div className="popular-card-info">
                      <h4> {category.name}</h4>
                    </div>
                  </div>
                </Link>
              ))}
            </React.Fragment>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default PopularCategories;
