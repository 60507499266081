import React, { useState, useEffect, Component } from "react";
import { Row, Col, Container, Form, Image, Button, Modal, InputGroup, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  orderDetailsStart,
  cancelOrderStart,
} from "../../store/slices/OrderSlice";
import { wishlistSaveStart } from "../../store/slices/ProductSlice";
import { useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./LandingStyle.css";
import "./ProductDetails.css";
import "./OrdersHistory.css";
import { apiUrl } from "../../Environment";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import OrderReturnModal from "../Order/OrderReturnModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const TrackProduct = () => {

  const t = useTranslation("order_tracking");
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetails = useSelector(state => state.order.orderDetails);
  const cancelOrder = useSelector(state => state.order.cancelOrder);
  const returnOrder = useSelector(state => state.order.returnOrder);

  const profile = useSelector(state => state.user.profile);

  const [login, setLogin] = useState(false);
  const [orderReturnModal, setOrderReturnModal] = useState(false);

  useEffect(() => {
    dispatch(orderDetailsStart({
      order_unique_id: params.orderUniqueId,
      order_product_unique_id: params.orderProductUniqueId,
    }));
  }, [params.orderUniqueId, params.orderProductUniqueId]);

  const wishListToggle = (product_unique_id) => {
    if (Object.keys(profile.data).length > 0) {
      dispatch(
        wishlistSaveStart({
          product_unique_id: product_unique_id,
        })
      );
    } else {
      setLogin(true);
    }
  };

  const download = () => {
    const url = apiUrl + "orders_invoice";
    let formData = new FormData();
    formData.append("id", localStorage.getItem("userId"));
    formData.append("token", localStorage.getItem("accessToken"));
    formData.append("order_unique_id", orderDetails.data.order.order_unique_id);
    formData.append("invoice_via", "download");
    fetch(url, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `order_${orderDetails.data.order.order_unique_id}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeOrderReturnModal = () => {
    setOrderReturnModal(false);
  }

  const cancelOrderConfirmation = (order_unique_id) => {
    confirmAlert({
      message: t("single_address.confirmation_msg"),
      buttons: [
        {

          label: t("single_address.confirm_yes"),
          onClick: () => dispatch(cancelOrderStart({
            order_unique_id: order_unique_id,
            order_product_unique_id: params.orderProductUniqueId,
          }))
        },
        {
          label: t("single_address.confirm_no"),
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }


  return (
    <>
      <div className="track-product-wrapped">
        <Container>
          {orderDetails.loading ?
            <>
              <Skeleton count={2} height={100} className='mb-2' />
              <Skeleton count={2} height={200} className='mb-2' />
            </>
            :
            Object.keys(orderDetails.data).length > 0 ?
              <>
                <div className="track-header">
                  <div className="next-page-tab">
                    <span>
                      <Link
                        to="/">{t("home")}</Link>
                    </span>
                    <span>
                      <Link
                        to="/profile">{t("my_account")}</Link>
                    </span>
                    <span>
                      <Link
                        to="/order-details">{t("my_orders")}</Link>
                    </span>
                    <span>
                      <Link
                        className="active">{params.orderUniqueId}</Link>
                    </span>

                  </div>
                  <div className="tracking-section">
                    <Button className="default-btn"
                      onClick={() => download()}
                    >
                      {t("download_receipt")}
                    </Button>
                    {orderDetails.data.order_product.order_tracking.status < 4 ?
                      <Button
                        disabled={cancelOrder.buttonDisable}
                        onClick={() => cancelOrderConfirmation(orderDetails.data.order.order_unique_id)}
                        className="download-receipt-btn"
                      >
                        {cancelOrder.buttonDisable ? t("cancel_order_btn.loading") : t("cancel_order_btn.text")}
                      </Button>
                      : orderDetails.data.order_product.order_tracking.status === 5
                        && orderDetails.data.order.is_return_available ?
                        <Button
                          disabled={returnOrder.buttonDisable}
                          onClick={() => setOrderReturnModal(true)}
                          className="download-receipt-btn"
                        >
                          {returnOrder.buttonDisable ?
                            t("return_order_btn.loading") :
                            t("return_order_btn.text")
                          }
                        </Button>
                        : null
                    }
                  </div>
                </div>
                <div className="arriving-status-wrapped">
                  <h3>{t("arriving")} <span> {orderDetails.data.order_product.expected_at_formatted}</span></h3>
                  <div className="arriving-track-grape">
                    <ul className="list-unstyled">
                      <li className="active">
                        <div className="top-grap-title" >{t("order_confirmed")}</div>
                        <div className="grap-tick" >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            fill="none"
                            viewBox="0 0 25 24"
                          >
                            <g clipPath="url(#clip0_226_1339)">
                              <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                              <path
                                fill="#fff"
                                d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_226_1339">
                                <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="bottom-grap-title">
                          {orderDetails.data.order_product.order_tracking.received_at_formatted}
                        </div>
                      </li>
                      {orderDetails.data.order_product.order_tracking.status >= 6 &&
                        !orderDetails.data.order_product.order_tracking.awaiting_shipment_at_formatted ?
                        null
                        :
                        <li className={`${orderDetails.data.order_product.order_tracking.status >= 3 ? "active" : ""}`}>
                          <div className="top-grap-title" >{t("product_dispatched")}</div>
                          <div className="grap-tick" >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <g clipPath="url(#clip0_226_1339)">
                                <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                <path
                                  fill="#fff"
                                  d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_226_1339">
                                  <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="bottom-grap-title">
                            {orderDetails.data.order_product.order_tracking.awaiting_shipment_at_formatted}
                          </div>
                        </li>
                      }
                      {orderDetails.data.order_product.order_tracking.status >= 6 &&
                        !orderDetails.data.order_product.order_tracking.shipped_at_formatted ?
                        null
                        :
                        <li className={`${orderDetails.data.order_product.order_tracking.status >= 4 ? "active" : ""}`}>
                          <div className="top-grap-title" >{t("shipped")}</div>
                          <div className="grap-tick" >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <g clipPath="url(#clip0_226_1339)">
                                <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                <path
                                  fill="#fff"
                                  d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_226_1339">
                                  <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="bottom-grap-title">
                            {orderDetails.data.order_product.order_tracking.shipped_at_formatted}
                          </div>
                        </li>
                      }
                      {orderDetails.data.order_product.order_tracking.status >= 6 &&
                        !orderDetails.data.order_product.order_tracking.completed_at_formatted ?
                        null
                        :
                        <li className={`${orderDetails.data.order_product.order_tracking.status >= 5 ?
                          "active" : ""}`}>
                          <div className="top-grap-title" >{t("delivered")}</div>
                          <div className="grap-tick" >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <g clipPath="url(#clip0_226_1339)">
                                <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                <path
                                  fill="#fff"
                                  d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_226_1339">
                                  <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="bottom-grap-title">
                            {orderDetails.data.order_product.order_tracking.completed_at_formatted}
                          </div>
                        </li>
                      }
                      {orderDetails.data.order_product.order_tracking.status >= 6
                        ? <>
                          {orderDetails.data.order_product.order_tracking.cancelled_at_formatted ?
                            <li className="progress-items cancel active">
                              <div className="top-grap-title">{t("cancelled")}</div>
                              <div className="grap-tick" >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 25 24"
                                >
                                  <g clipPath="url(#clip0_226_1339)">
                                    <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                    <path
                                      fill="#fff"
                                      d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_226_1339">
                                      <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.cancelled_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.return_initiated_at_formatted ?
                            <li className="progress-items return active">
                              <div className="top-grap-title">{t("return")}</div>
                              <div className="grap-tick" >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 25 24"
                                >
                                  <g clipPath="url(#clip0_226_1339)">
                                    <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                    <path
                                      fill="#fff"
                                      d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_226_1339">
                                      <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.return_initiated_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.return_rejected_at_formatted ?
                            <li className="progress-items cancel active">
                              <div className="top-grap-title">{t("return_rejected")}</div>
                              <div className="grap-tick" >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 25 24"
                                >
                                  <g clipPath="url(#clip0_226_1339)">
                                    <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                    <path
                                      fill="#fff"
                                      d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_226_1339">
                                      <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.return_rejected_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.return_accepted_at_formatted ?
                            <li className="progress-items success active">
                              <div className="top-grap-title">{t("return_accepted")}</div>
                              <div className="grap-tick" >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 25 24"
                                >
                                  <g clipPath="url(#clip0_226_1339)">
                                    <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                    <path
                                      fill="#fff"
                                      d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_226_1339">
                                      <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.return_accepted_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.return_user_damage_at_formatted ?
                            <li className="progress-items cancel active">
                              <div className="top-grap-title">{t("pickup_rejected")}</div>
                              <div className="grap-tick" >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 25 24"
                                >
                                  <g clipPath="url(#clip0_226_1339)">
                                    <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                    <path
                                      fill="#fff"
                                      d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_226_1339">
                                      <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.return_user_damage_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.return_success_at_formatted ?
                            <li className="progress-items success active">
                              <div className="top-grap-title">{t("pickup_completed")}</div>
                              <div className="grap-tick" >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 25 24"
                                >
                                  <g clipPath="url(#clip0_226_1339)">
                                    <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                    <path
                                      fill="#fff"
                                      d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_226_1339">
                                      <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.return_success_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.refund_initiated_at_formatted ?
                            <li className="progress-items return active">
                              <div className="top-grap-title">{t("refund_initiated")}</div>
                              <div className="grap-tick" >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 25 24"
                                >
                                  <g clipPath="url(#clip0_226_1339)">
                                    <rect width="24" height="24" x="0.521" fill="#E6E6E6" rx="2"></rect>
                                    <path
                                      fill="#fff"
                                      d="M11.126 16.222l-.018.018-3.4-3.4 1.433-1.433 1.985 1.985L15.9 8.618l1.433 1.432-6.19 6.19-.017-.018z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_226_1339">
                                      <path fill="#fff" d="M0.521 0H24.521V24H0.521z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.refund_initiated_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.refund_failed_at_formatted ?
                            <li className="progress-item cancel">
                              <div className="top-grap-title">{t("refund_failed")}</div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.refund_failed_at_formatted}</div>
                            </li>
                            : null
                          }
                          {orderDetails.data.order_product.order_tracking.refund_success_at_formatted ?
                            <li className="progress-items success active">
                              <div className="top-grap-title">{t("refund_completed")}</div>
                              <div className="bottom-grap-title">{orderDetails.data.order_product.order_tracking.refund_success_at_formatted}</div>
                            </li>
                            : null
                          }
                        </>
                        : null
                      }
                    </ul>
                  </div>
                  <div className="review-product-details">
                    <div className="review-product-details-img">
                      <Image
                        src={orderDetails.data.order_product.product_attribute.file}
                      />
                    </div>
                    <div className="review-product-details-info">
                      <div className="review-product-details-head">
                        <h4>
                          <Link
                            to={`/product/${orderDetails.data.order_product.product.product_unique_id}`}>
                            {orderDetails.data.order_product.product.name}
                          </Link>
                        </h4>
                        <div className={`card-fav-icon ${orderDetails.data.is_wishlisted ? "active" : ""}`} onClick={() =>
                          wishListToggle(orderDetails.data.order_product.product.product_unique_id)}>
                          {orderDetails.data.is_wishlisted ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="#005479"
                            >
                              <path
                                d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                stroke="#252525"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      <span className="product-name">{orderDetails.data.order_product.product.metal}</span>
                      <div className="singale-product-rate">
                        <span className="current-rate">{orderDetails.data.order_product.product_attribute.selling_price_formatted}</span>
                        <span className="net-rate">{orderDetails.data.order_product.product_attribute.original_price_formatted}</span>
                        <span className="discount-per" >{orderDetails.data.order_product.product_attribute.discount_tag}</span>
                      </div>
                      <div className="delivery-main-frame">
                        <div className="delivery-right-wrap">
                          {/* <div className="delivery-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 25 15" fill="none">
                              <path d="M8.5 14C9.03043 14 9.53914 13.7893 9.91421 13.4142C10.2893 13.0391 10.5 12.5304 10.5 12C10.5 11.4696 10.2893 10.9609 9.91421 10.5858C9.53914 10.2107 9.03043 10 8.5 10C7.96957 10 7.46086 10.2107 7.08579 10.5858C6.71071 10.9609 6.5 11.4696 6.5 12C6.5 12.5304 6.71071 13.0391 7.08579 13.4142C7.46086 13.7893 7.96957 14 8.5 14ZM18.5 14C19.0304 14 19.5391 13.7893 19.9142 13.4142C20.2893 13.0391 20.5 12.5304 20.5 12C20.5 11.4696 20.2893 10.9609 19.9142 10.5858C19.5391 10.2107 19.0304 10 18.5 10C17.9696 10 17.4609 10.2107 17.0858 10.5858C16.7107 10.9609 16.5 11.4696 16.5 12C16.5 12.5304 16.7107 13.0391 17.0858 13.4142C17.4609 13.7893 17.9696 14 18.5 14Z" stroke="#005479" stroke-width="1.5" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.55 12H15.5V1.6C15.5 1.44087 15.4368 1.28826 15.3243 1.17574C15.2117 1.06321 15.0591 1 14.9 1H1.5M6.15 12H4.1C4.02121 12 3.94319 11.9845 3.87039 11.9543C3.79759 11.9242 3.73145 11.88 3.67574 11.8243C3.62002 11.7685 3.57583 11.7024 3.54567 11.6296C3.51552 11.5568 3.5 11.4788 3.5 11.4V6.5" stroke="#005479" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M2.5 4H6.5" stroke="#005479" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M15.5 4H21.11C21.226 4.00003 21.3395 4.03367 21.4367 4.09685C21.534 4.16003 21.6108 4.25005 21.658 4.356L23.448 8.384C23.4821 8.46048 23.4998 8.54326 23.5 8.627V11.4C23.5 11.4788 23.4845 11.5568 23.4543 11.6296C23.4242 11.7024 23.38 11.7685 23.3243 11.8243C23.2685 11.88 23.2024 11.9242 23.1296 11.9543C23.0568 11.9845 22.9788 12 22.9 12H21M15.5 12H16.5" stroke="#005479" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                          </div>
                          <div className="delivery-info">
                            <h5>{t("delivery")}</h5>
                            <span>{orderDetails.data.order_product.expected_at_formatted}</span>
                          </div> */}
                        </div>
                        <div className="delivery-left-wrap">
                          <p>{t("tracking_id")} :<span> {orderDetails.data.order.order_unique_id}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="address-details-wrap">
                    {orderDetails.data.order.delivery_address ?
                      <div className="address-full-details">
                        <h4>{orderDetails.data.order.delivery_address.name}</h4>
                        <p>
                          {orderDetails.data.order.delivery_address.address}{"\n"}
                          {orderDetails.data.order.delivery_address.city}{" "}
                          {orderDetails.data.order.delivery_address.state}{" "}
                          {orderDetails.data.order.delivery_address.pincode}{"\n"}
                          {orderDetails.data.order.delivery_address.landmark}{"\n"}
                        </p>
                        <p className="address-number">{t("mobile")}: <span>{orderDetails.data.order.delivery_address.contact_number}</span>
                        </p>
                      </div>
                      :
                      null
                    }
                    <div className="address-details-head">
                      <h4>{t("order_summary")}</h4>
                      <div className="cart-summary-info">
                        <div className="cart-summary-header">
                          <p>
                            {t("cart_total")} <span>{orderDetails.data.order.order_amount.order_total_amount}</span>
                          </p>
                          <p>
                            {t("discount")} <span>{orderDetails.data.order.order_amount.order_discount_total}</span>
                          </p>
                        </div>
                        <div className="cart-summary-total-amount">
                          <h5>
                            {t("order_total")} <span>{orderDetails.data.order.order_amount.order_checkout_amount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <SomethingWentWrong />
          }
        </Container>
      </div>
      {orderReturnModal ?
        <OrderReturnModal
          closeOrderReturnModal={closeOrderReturnModal}
          orderUniqueId={params.orderUniqueId}
          orderProductUniqueId={params.orderProductUniqueId}
        />
        : null
      }
    </>
  );
};

export default TrackProduct;
