import React, { useState, useEffect } from 'react';
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import NewLandingHeader from "../NewLandingPage/NewLandingHeader";
import Magnifier from "react-magnifier";
// import Slider from "react-slick";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
import ShopByCollection from '../NewLandingPage/ShopByCollection';
import NewArrivalsIndex from '../NewLandingPage/NewArrivalsIndex';
import TopSellers from '../NewLandingPage/TopSellers';
import NewForYou from '../NewLandingPage/NewForYou';
import CustomerReviewModal from './CustomerReviewModal';

const NewSingleProductIndex = (props) => {


  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const [showMore, setShowMore] = useState(false);
  const text = "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorein their infancy. Various versions have evolved over the years, sometimes by";


  const [customerReview, setCustomerReview] = useState(false);

  const closeCustomerReviewModal = () => {
    setCustomerReview(false);
  };

  return (
    <>
      <div className="new-single-product-sec new-landing-sec">
        <NewLandingHeader />
        <Container>
          <div className="new-single-product-box">
            <Row>
              <Col md={6}>
                {/* <Magnifier src={
                    window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                  } width={500} />; */}

                <Slider
                  asNavFor={nav2} ref={(slider1) => setNav1(slider1)}
                  arrows={false}
                >
                  <div>
                    <Magnifier src={
                      window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                    } className="new-single-product-img" mgWidth={300} mgHeight={300} mgShape="square" zoomFactor={1.2} />
                  </div>
                  <div>
                    <Magnifier src={
                      window.location.origin + "/assets/img/landing-page/left-favourites-img.png"
                    } className="new-single-product-img" mgWidth={300} mgHeight={300} mgShape="square" zoomFactor={1.2} />
                  </div>
                  <div>
                    <Magnifier src={
                      window.location.origin + "/assets/img/landing-page/right-favourites-img.png"
                    } className="new-single-product-img" mgWidth={300} mgHeight={300} mgShape="square" zoomFactor={1.2} />
                  </div>
                  <div>
                    <Magnifier src={
                      window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                    } className="new-single-product-img" mgWidth={300} mgHeight={300} mgShape="square" zoomFactor={1.2} />
                  </div>
                </Slider>


                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={4}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  className="slider-thumbnails-sec"
                  arrows={false}
                >
                  <div>
                    <Image src={
                      window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                    } width={100} className="new-single-product-thumbnails-img" />
                  </div>
                  <div>
                    <Image src={
                      window.location.origin + "/assets/img/landing-page/left-favourites-img.png"
                    } width={100} className="new-single-product-thumbnails-img" />
                  </div>
                  <div>
                    <Image src={
                      window.location.origin + "/assets/img/landing-page/right-favourites-img.png"
                    } width={100} className="new-single-product-thumbnails-img" />
                  </div>
                  <div>
                    <Image src={
                      window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                    } width={100} className="new-single-product-thumbnails-img" />
                  </div>
                </Slider>

              </Col>
              <Col md={6}>
                <div className="new-single-product-right-sec">
                  <div className="new-single-product-info-sec">
                    <h4>Diamond Ring 2. R25702 - 763 -1, 30032023</h4>
                    <h3>₹356,342</h3>
                  </div>
                  <ul className="list-unstyled new-single-product-size">
                    <li>
                      1X
                    </li>
                    <li>
                      2X
                    </li>
                    <li>
                      3X
                    </li>
                    <li>
                      4X
                    </li>
                  </ul>
                  <div className="new-single-product-metal-type-sec">
                    <div className="new-single-product-metal-type-box">
                      <div className="new-single-product-metal-type-card">
                        <h4>Base Metal</h4>
                        <p>Gold</p>
                      </div>
                      <div className="new-single-product-metal-type-card">
                        <h4>Purity</h4>
                        <p>22K</p>
                      </div>
                      <div className="new-single-product-metal-type-card">
                        <h4>Gross Weight</h4>
                        <p>49.60gm</p>
                      </div>
                      <div className="new-single-product-metal-type-card">
                        <h4>Net Weight</h4>
                        <p>49.60gm</p>
                      </div>
                    </div>
                  </div>
                  <div className="new-single-product-rating-sec">
                    <ul className="list-unstyled new-single-product-star">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          fill="none"
                          viewBox="0 0 27 27"
                        >
                          <path
                            fill="#FFE70F"
                            d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                          ></path>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          fill="none"
                          viewBox="0 0 27 27"
                        >
                          <path
                            fill="#FFE70F"
                            d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                          ></path>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          fill="none"
                          viewBox="0 0 27 27"
                        >
                          <path
                            fill="#FFE70F"
                            d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                          ></path>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          fill="none"
                          viewBox="0 0 27 27"
                        >
                          <path
                            fill="#DEDEDE"
                            d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                          ></path>
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          fill="none"
                          viewBox="0 0 27 27"
                        >
                          <path
                            fill="#DEDEDE"
                            d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                          ></path>
                        </svg>
                      </li>
                    </ul>
                    <div className="new-single-product-rating-count-sec">
                      <span>3</span> / 5
                    </div>
                    <Link to="#" className="rating-link" type="submit" onClick={() => setCustomerReview(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#0d6efd"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 2H5C2.24 2 0 4.24 0 7v10c0 2.76 2.24 5 5 5h7c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.65 0-3-1.35-3-3V9h20v5c0 .55.45 1 1 1s1-.45 1-1V7c0-2.76-2.24-5-5-5zM2 7c0-1.65 1.35-3 3-3h14c1.65 0 3 1.35 3 3H2zm14.41 5.17A3.962 3.962 0 0013.58 11h-1.59c-.55 0-1 .45-1 1v1.59c0 1.07.42 2.07 1.17 2.83l6.71 6.71c.57.57 1.32.88 2.12.88s1.55-.31 2.12-.88.88-1.32.88-2.12-.31-1.55-.88-2.12l-6.71-6.71zm5.29 9.54c-.38.38-1.04.38-1.41 0L13.58 15c-.38-.38-.59-.88-.59-1.41V13h.59c.53 0 1.04.21 1.41.59l6.71 6.71c.19.19.29.44.29.71s-.1.52-.29.71z"></path>
                      </svg>
                      <span>Write Review</span>
                    </Link>
                  </div>
                  <div className="new-single-product-description-sec">
                    <h4>Description</h4>
                      <p>
                        {showMore ? text : `${text.substring(0, 350)}`}
                      </p>
                      <Button className="show-more-btn" onClick={() => setShowMore(!showMore)}>Show More</Button>
                  </div>
                  <div className="new-single-product-action-btn">
                    <Button className="default-btn">
                      Add to Wishlist
                    </Button>
                    <Button className="default-btn-outline">
                      Place an Order
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="single-product-shop-by-collection-sec">
          <ShopByCollection/>
          <NewArrivalsIndex/>
          <TopSellers/>
          <NewForYou/>
        </div>
      </div>
      <CustomerReviewModal
        customerReview={customerReview}
        closeCustomerReviewModal={closeCustomerReviewModal}
        setCustomerReview={setCustomerReview}
      />
    </>
  );
};

export default NewSingleProductIndex;
