import React, { useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ProfileSidebar from "../Profile/ProfileSidebar";
import WalletTransaction from "./WalletTransaction";
import {
  fetchWalletTransactionListStart,
  fetchMoreWalletTransaction,
} from "../../store/slices/WalletSlice";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import InfiniteScroll from "react-infinite-scroll-component";

const WalletIndex = () => {

  const dispatch = useDispatch();
  const t = useTranslation("wallet");

  const walletTransactionList = useSelector(state => state.wallet.walletTransactionList);
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    dispatch(fetchWalletTransactionListStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreTransactions = () => {
    dispatch(fetchMoreWalletTransaction({
      skip: walletTransactionList.data.user_wallet_payments.length,
      take: 12,
    }));
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                {walletTransactionList.loading ?
                  <Skeleton count={4} height={80} />
                  :
                  <div className="wallet-header-sec">
                    <div className="wallet-balance-amount-sec">
                     <h3> {
                        Object.keys(walletTransactionList.data).length > 0 &&
                          walletTransactionList.data.user_wallet ?
                          walletTransactionList.data.user_wallet.remaining_formatted
                          : "0.00 USD"}</h3>
                      <h6>{t("current_wallet_balance")}</h6>
                    </div>
                    <div className="wallet-action-btn-sec">
                      <Link className="download-receipt-btn" to="/add-money">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="#fff"
                          className="mr-2"
                        >
                          <path d="M22 8.28V8a5 5 0 00-5-5H6a5 5 0 00-5 5v4a1 1 0 002 0V8a3 3 0 013-3h11a3 3 0 013 3h-1a4 4 0 000 8h1a3 3 0 01-3 3h-7a1 1 0 000 2h7a5 5 0 005-5v-.28A2 2 0 0023 14v-4a2 2 0 00-1-1.72zM19 14a2 2 0 010-4h2v4zM7 18a1 1 0 01-1 1H5v1a1 1 0 01-2 0v-1H2a1 1 0 010-2h1v-1a1 1 0 012 0v1h1a1 1 0 011 1z"></path>
                        </svg>
                        {t("wallet.add_btn")}
                      </Link>
                    </div>
                  </div>
                }
                {walletTransactionList.loading ?
                  <Skeleton count={4} height={80} />
                  :
                  Object.keys(walletTransactionList.data).length > 0 &&
                    walletTransactionList.data.user_wallet_payments.length > 0 ?
                    < div className="wallet-transaction-sec">
                      <h4>{t("all_trascation")}</h4>
                      <div className="order-transaction-sec">
                        <div className="order-list-table">
                          <InfiniteScroll
                            dataLength={walletTransactionList.data.user_wallet_payments.length}
                            next={fetchMoreTransactions}
                            hasMore={walletTransactionList.data.user_wallet_payments.length <
                              walletTransactionList.data.total_wallet_payments}
                            loader={<Skeleton count={4} height={100} />}
                          >
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>{t("table.date")}</th>
                                  <th>{t("table.paid_amount")}</th>
                                  <th>{t("table.payment_type")}</th>
                                  <th>{t("table.payment_unique_id")}</th>
                                  <th>{t("table.status")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {walletTransactionList.data.user_wallet_payments.map(
                                  (transaction, i) => (
                                    <tr key={i}>
                                      <td>{transaction.paid_date_formatted}</td>
                                      <td>{transaction.paid_amount_formatted}</td>
                                      <td className="text-capitalize">{transaction.payment_type}</td>
                                      <td>
                                        {transaction.user_wallet_payment_unique_id}
                                      </td>
                                      {transaction.payment_status ?
                                        <td className="transaction-success">
                                          <h5>{t("table.success_btn")}</h5>
                                        </td>
                                        :
                                        <td > - </td>}
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                    :
                    <NoDataFound />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WalletIndex;
