import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./LandingStyle.css";
import Ratings from "./Ratings";
import SpecialProducts from "./SpecialProducts";
import TrendingProducts from "./TrendingProducts";
import PopularCategories from "./PopularCategories"
import MetalsList from "./MetalsList";
import { useTranslation } from "react-multi-lang";
import BannerHero from "./BannerHero";

const LandingPageNew = () => {

  const t = useTranslation("landing_page");

  return (
    <>
      <BannerHero/>
      <MetalsList/>
      <PopularCategories/>
      <TrendingProducts/>
      <div className="ctn-banner-frame">
        <Image
          src={window.location.origin + "/assets/img/new/ctn-banner.png"}
        />
      </div>
      <SpecialProducts/>
      <Container>
        {/* <div className="flow-part-frame">
          <Row>
            <Col md={12} xl={4} lg={4}>
              <div className="flow-part-card">
                <div className="flow-part-img">
                  <Image
                    src={window.location.origin + "/assets/img/new/truck.png"}
                  />
                </div>
                <div className="flow-part-info">
                  <h4>Lorem</h4>
                  <p>
                    Located in the heart of the jeweler hub in Camaca street
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xl={4} lg={4}>
              <div className="flow-part-card">
                <div className="flow-part-img">
                  <Image
                    src={window.location.origin + "/assets/img/new/truck.png"}
                  />
                </div>
                <div className="flow-part-info">
                  <h4>Lorem</h4>
                  <p>
                    Located in the heart of the jeweler hub in Camaca street
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xl={4} lg={4}>
              <div className="flow-part-card">
                <div className="flow-part-img">
                  <Image
                    src={window.location.origin + "/assets/img/new/truck.png"}
                  />
                </div>
                <div className="flow-part-info">
                  <h4>Lorem</h4>
                  <p>
                    Located in the heart of the jeweler hub in Camaca street
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>
      <Ratings/>
    </>
  );
};

export default LandingPageNew;
