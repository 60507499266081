import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./Static.css";
import { withTranslation, useTranslation } from "react-multi-lang";

const OurServices = (props) => {

  const t = useTranslation();

  return (
    <>
      <div className="our-services-sec">
        <div className="our-services-header-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="our-services-title">
                  <h1>{t("our_services")}</h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="our-services-box">
          <Container>
            <Row>
              <Col md={6}>
                <div className="our-services-img-sec">
                  <Image
                    className="our-services-img"
                    src={
                      window.location.origin + "/assets/img/static-page/icons/our-services-img.png"
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="our-services-we-have">
                  <h4>
                    {t("we_have_everything_you_need")}
                  </h4>
                  <p>{t("service_page_content_1")}</p>
                  <p> {t("service_page_content_2")}</p>
                  <p>{t("service_page_content_3")}</p>
                  <div className="our-services-bottom-btn">
                    <Button className="default-btn">
                      {t("send_message")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="our-services-options-grid">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={8} lg={9}>
                <div className="section-title">
                  <h5>{t("top_selling_products")}</h5>
                  <h2>{t("our_services")}</h2>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={4}>
                <div className="our-services-options-card">
                  <div className="our-services-options-icon">
                    <Image
                      className="our-services-options-img"
                      src={
                        window.location.origin + "/assets/img/static-page/icons/wallet (6).png"
                      }
                    />
                  </div>
                  <div className="our-services-options-info">
                    <h4 className="sell-services">{t("global_payments_options")}</h4>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="our-services-options-card">
                  <div className="our-services-options-icon">
                    <Image
                      className="our-services-options-img"
                      src={
                        window.location.origin + "/assets/img/static-page/icons/24-hours-support.png"
                      }
                    />
                  </div>
                  <div className="our-services-options-info">
                    <h4 className="sell-services">24/7 {t("support_staff")}</h4>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={4}>
                <div className="our-services-options-card">
                  <div className="our-services-options-icon">
                    <Image
                      className="our-services-options-img"
                      src={
                        window.location.origin + "/assets/img/static-page/icons/shield (2).png"
                      }
                    />
                  </div>
                  <div className="our-services-options-info">
                    <h4 className="sell-services">100% {t("safe_products")}</h4>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="our-services-options-card">
                  <div className="our-services-options-icon">
                    <Image
                      className="our-services-options-img"
                      src={
                        window.location.origin + "/assets/img/static-page/icons/shield (2).png"
                      }
                    />
                  </div>
                  <div className="our-services-options-info">
                    <h4 className="sell-services">100% {t("safe_products")}</h4>

                  </div>
                </div>
              </Col>

            </Row>

          </Container>
        </div>
        <div className="our-services-box-bottom">
          <Container>
            <Row>

              <Col md={6}>
                <div className="our-services-bottom-we-have">
                  <h4>
                    {t("we_have_everything_you_need")}
                  </h4>
                  <p>{t("service_page_content_1")}</p>
                  <p> {t("service_page_content_2")}</p>
                  <p>{t("service_page_content_3")}</p>
                  <div className="our-services-bottom-btn">
                    <Button className="default-btn">
                      {t("send_message")}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="our-services-bottom-img-sec justify-content-md-end">
                  <Image
                    className="our-services-bottom-img"
                    src={
                      window.location.origin + "/assets/img/static-page/icons/our-services-img-2.png"
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default withTranslation(OurServices);
