import React from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";

const CustomerReviewModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center customer-review-modal write-review-modal"
        size="md"
        centered
        show={props.customerReview}
        onHide={props.closeCustomerReviewtModal}
      >
        <Modal.Header closeButton onClick={() => props.closeCustomerReviewModal()}>
          <Modal.Title>
            Ajita Peacock Short Necklace
            <span>₹356,342</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="customer-review-messages-box">
            <div className="customer-review-messages-card">
              <div className="customer-review-messages-header-sec">
                <div className="customer-review-user-img-sec">
                  <Image
                    className="customer-review-user-img"
                    src={
                        window.location.origin + "/assets/img/product/customer-user-img.png"
                    }
                  />
                </div>
                <div className="customer-review-user-info">
                  <h4>Xavier</h4>
                  <p>15/04/2023</p>
                </div>
              </div>
              <div className="customer-review-messages-rating-sec">
                <ul className="list-unstyled new-single-product-star">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#DEDEDE"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#DEDEDE"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                </ul>
                <div className="new-single-product-rating-count-sec">
                  <span>3</span> / 5
                </div>
              </div>
              <div className="customer-review-messages-description">
                <p>Awesome jewel for gifting girls</p>
              </div>
            </div>
            <div className="customer-review-messages-card">
              <div className="customer-review-messages-header-sec">
                <div className="customer-review-user-img-sec">
                  <Image
                    className="customer-review-user-img"
                    src={
                        window.location.origin + "/assets/img/product/customer-user-img.png"
                    }
                  />
                </div>
                <div className="customer-review-user-info">
                  <h4>Xavier</h4>
                  <p>15/04/2023</p>
                </div>
              </div>
              <div className="customer-review-messages-rating-sec">
                <ul className="list-unstyled new-single-product-star">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#DEDEDE"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#DEDEDE"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                </ul>
                <div className="new-single-product-rating-count-sec">
                  <span>3</span> / 5
                </div>
              </div>
              <div className="customer-review-messages-description">
                <p>Cool little 1 gram .999 fine silver coin replicas. You're paying a bit over market value, but when society crashes & precious medals become the only accepted currency silver will skyrocket in value anyway, & you'll have these cool small 1 gram portions to buy essentials with. Sure the silver eagles are great, & closer to market value, but once that scenario plays out what if you're trying to purchase something that is much less than an ounce of silver? That's why these grams are awesome. I'm definitely going to purchase some more once I can afford to.</p>
              </div>
            </div>
          </div>
          <div className="customer-write-review-card">
              <div className="customer-write-review-header-sec">
                <h4>Write Review</h4>
                <ul className="list-unstyled new-single-product-star">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#FFE70F"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#DEDEDE"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 27 27"
                    >
                      <path
                        fill="#DEDEDE"
                        d="M19.602 16.065a1.204 1.204 0 00-.35 1.061l.973 5.382c.082.456-.11.918-.492 1.182a1.204 1.204 0 01-1.28.087l-4.845-2.527a1.238 1.238 0 00-.547-.143h-.296a.888.888 0 00-.296.098l-4.846 2.54c-.24.12-.51.162-.776.12a1.216 1.216 0 01-.974-1.39l.974-5.383a1.224 1.224 0 00-.35-1.07l-3.95-3.83a1.18 1.18 0 01-.294-1.236c.147-.433.521-.749.973-.82l5.437-.789c.413-.043.776-.294.962-.666l2.396-4.912c.057-.11.13-.21.219-.295l.098-.077a.735.735 0 01.176-.142l.12-.044.186-.076h.46c.411.042.774.289.963.656l2.427 4.89c.175.358.515.606.908.666l5.437.789c.46.066.843.383.995.82.144.439.02.92-.317 1.236l-4.091 3.873z"
                      ></path>
                    </svg>
                  </li>
                </ul>
              </div>
              <div className="customer-write-review-body-sec">
                <Form className="auth-form">
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={3}  placeholder="Give us  a review"/>
                </Form.Group>
                  <div className="customer-write-review-footer-sec">
                    <Button className="default-btn">
                     Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerReviewModal;