import React, { useState } from "react";
import Header from "../New/Header";
import Footer from "../New/Footer";

const MainLayout = (props) => {

  return (
    <>
      <Header />
      <div className="main-wrapper">
        {props.children}
      </div>
      <Footer/>
    </>
  );
};

export default MainLayout;
