import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";

const ShopByCategory = (props) => {

  return (
    <>
      <div className="shop-by-category-sec sm-padding">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                <h2 className="mb-2">Shop Jewellery By Category</h2>
                <p>Browse through your favourite categories. We've got them all!</p>
                <div className="border-style-line">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="#404040"
                      viewBox="0 0 354.046 354.046"
                    >
                      <path d="M5.194 218.577a5.893 5.893 0 003.251 4.626c.702.348 1.457.466 2.213.519l-.006.077L161.65 234.22l-8.456 74.9v.012c-.083.767.029 1.522.242 2.26.053.183.106.342.177.519.277.708.637 1.387 1.186 1.953a5.94 5.94 0 004.266 1.823c.136 0 .271-.006.407-.012l183.913-12.693-.006-.077c.755-.053 1.511-.171 2.213-.519a5.891 5.891 0 003.251-4.626l5.169-45.797c.142-1.269-.165-2.496-.773-3.564l.035-.024-24.123-42.262a6.152 6.152 0 00-.862-1.157l-57.132-59.67a6.155 6.155 0 00-1.115-.915l-41.17-25.934-.024.035c-1.033-.649-2.242-1.009-3.529-.926l-36.875 2.543-8.273-73.324-.047.006c-.083-.755-.271-1.499-.649-2.183a5.901 5.901 0 00-4.768-3.045l-45.98-3.175c-1.275-.083-2.49.277-3.529.932l-.024-.035-41.17 25.94a5.875 5.875 0 00-1.115.915l-57.132 59.67a5.682 5.682 0 00-.862 1.157L.774 169.21l.035.024c-.608 1.068-.921 2.295-.773 3.564zM105.379 93.809l27.144 14.321-62.638 65.417-15.484-26.495zm143.273 79.178l50.978 53.244-15.484 26.489-62.638-65.417zM69.33 189.804l85.71 32.142-124.869-8.621zm215.365 79.178l39.164 23.521-124.874 8.621zm53.444 18.329l-44.805-26.914 15.655-26.784 32.88 20.712zm-63.293-27.239l-104.965 39.365 43.885-103.153zm-105.39 10.197l14.038-124.373 21.798 40.144zm5.105-146.501c-.378.684-.561 1.428-.649 2.183l-.047-.006-3.57 31.611-21.568-50.689 21.798-40.144 6.19 54.831c-.88.532-1.641 1.275-2.154 2.214zm-6.946 57.622l-3.535 31.334-84.89-31.835 61.08-63.788zM45.048 154.429l15.655 26.79-44.805 26.908-3.723-32.986zm274.202 58.165l12.061 21.131-20.582-12.964-56.129-58.626-12.061-21.131 20.582 12.964zm-96.821-83.02l19.26 33.741-27.445 14.475-24.937-45.932zm-57.705-76.888l-24.937 45.932-27.445-14.475 19.26-33.747zm-129.943 80.73L90.91 74.79l20.582-12.964-12.061 21.131-56.129 58.625-20.582 12.964zM224.222 61.956h11.802v11.802h-11.802zM236.024 50.154h11.802v11.802h-11.802zM247.826 61.956h11.802v11.802h-11.802z"></path>
                      <path d="M236.024 73.758h11.802V85.56h-11.802zM253.727 97.361h11.802v11.802h-11.802zM35.394 274.388h11.802v11.802H35.394zM47.196 286.189h11.802v11.802H47.196zM58.998 274.388H70.8v11.802H58.998zM47.196 262.586h11.802v11.802H47.196zM64.899 238.982h11.802v11.802H64.899zM324.537 132.767h11.802v11.802h-11.802z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="shop-by-category-box">
            <Row>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/1.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Gold Coins</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/2.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Earrings</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/3.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Pendants</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/4.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Finger Rings</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/5.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Finger Rings</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/6.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Mangalsutras</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/7.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Engagement Rings</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/8.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Chains</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/9.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Bangles</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/10.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Bracelets</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/11.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Nose Pins</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4} xl={2} lg={3}>
                <Link to="#">
                  <div className="shop-by-category-card">
                    <div className="shop-by-category-img-sec">
                      <Image
                        className="shop-by-category-img"
                        src={
                          window.location.origin + "/assets/img/landing-page/categories/12.png"
                        }
                      />
                    </div>
                    <div className="shop-by-category-info">
                        <h4>Pendant Sets</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ShopByCategory;
