
import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    pageData: getCommonInitialState(),
    contactSubmit: getCommonInitialState(),
};

const PageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {

        // Static page data
        fetchSinglePageStart: (state) => { state.pageData = getCommonStart() },
        fetchSinglePageSuccess: (state, action) => { state.pageData = getCommonSuccess(action.payload) },
        fetchSinglePageFailure: (state, action) => { state.pageData = getCommonFailure(action.payload) },

        // Contact Submit
        contactSubmitStart: (state) => { state.pageData = getCommonStart() },
        contactSubmitSuccess: (state, action) => { state.pageData = getCommonSuccess(action.payload) },
        contactSubmitFailure: (state, action) => { state.pageData = getCommonFailure(action.payload) },
    }
});

export const {
    fetchSinglePageStart,
    fetchSinglePageSuccess,
    fetchSinglePageFailure,
    contactSubmitStart,
    contactSubmitSuccess,
    contactSubmitFailure,

} = PageSlice.actions;

export default PageSlice.reducer;