
import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  productList: getCommonInitialState({ products: [], total_products: 0 }),
  singleProduct: getCommonInitialState(),
  productWishlists: getCommonInitialState({ products: [], total_products: 0 }),
  wishlistSave: getCommonInitialState(),
  productReviews: getCommonInitialState({ reviews: [], total_reviews: 0 }),
  productSuggetions: getCommonInitialState(),
  metalList: getCommonInitialState(),
  topProductList: getCommonInitialState({ products: [], total_products: 0 }),
  specialProductList: getCommonInitialState({ products: [], total_products: 0 }),
  featuredProductList: getCommonInitialState({ products: [], total_products: 0 }),  
};

const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {

    // Product List
    productListStart: (state) => { state.productList = getCommonStart({ products: [], total_products: 0 }) },
    moreProductListStart: (state) => { state.productList = state.productList },
    productListSuccess: (state, action) => { state.productList = getCommonSuccess(action.payload) },
    productListFailure: (state, action) => { state.productList = getCommonFailure(action.payload) },

    // Single product
    singleProductStart: (state) => { state.singleProduct = getCommonStart() },
    singleProductSuccess: (state, action) => { state.singleProduct = getCommonSuccess(action.payload) },
    singleProductFailure: (state, action) => { state.singleProduct = getCommonFailure(action.payload) },

    // Product wishlist
    fetchproductWishlistsStart: (state) => { state.productWishlists = getCommonStart({ products: [], total_products: 0 }) },
    fetchproductWishlistsSuccess: (state, action) => { state.productWishlists = getCommonSuccess(action.payload) },
    fetchproductWishlistsFailure: (state, action) => { state.productWishlists = getCommonFailure(action.payload) },
    fetchMoreproductWishlistsStart: (state) => { state.productWishlists = state.productWishlists },

    // Wishlist Save
    wishlistSaveStart: (state) => { state.wishlistSave = getCommonStart() },
    wishlistSaveSuccess: (state, action) => { state.wishlistSave = getCommonSuccess(action.payload) },
    wishlistSaveFailure: (state, action) => { state.wishlistSave = getCommonFailure(action.payload) },

    // Product reviews
    fetchProductReviewListStart: (state) => { state.productReviews = getCommonStart({ reviews: [], total_reviews: 0 }) },
    fetchMoreProductReviewListStart: (state) => { state.productReviews = state.productReviews },
    fetchProductReviewListSuccess: (state, action) => { state.productReviews = getCommonSuccess(action.payload) },
    fetchProductReviewListFailure: (state, action) => { state.productReviews = getCommonFailure(action.payload) },

    // Product Suggetion on search
    fetchProductSuggetionStart: (state) => { state.productSuggetions = getCommonStart() },
    fetchProductSuggetionSuccess: (state, action) => { state.productSuggetions = getCommonSuccess(action.payload) },
    fetchProductSuggetionFailure: (state, action) => { state.productSuggetions = getCommonFailure(action.payload) },

    // Metal List
    metalListStart: (state) => { state.metalList = getCommonStart() },
    metalListSuccess: (state, action) => { state.metalList = getCommonSuccess(action.payload) },
    metalListFailure: (state, action) => { state.metalList = getCommonFailure(action.payload) },

    // Top Products
    topProductListStart: (state) => { state.topProductList = getCommonStart({ products: [], total_products: 0 }) },
    topProductListSuccess: (state, action) => { state.topProductList = getCommonSuccess(action.payload) },
    topProductListFailure: (state, action) => { state.topProductList = getCommonFailure(action.payload) },

    // Special Products
    specialProductListStart: (state) => { state.specialProductList = getCommonStart({ products: [], total_products: 0 }) },
    specialProductListSuccess: (state, action) => { state.specialProductList = getCommonSuccess(action.payload) },
    specialProductListFailure: (state, action) => { state.specialProductList = getCommonFailure(action.payload) },

    // Featured Products
    featuredProductListStart: (state) => { state.featuredProductList = getCommonStart({ products: [], total_products: 0 }) },
    featuredProductListSuccess: (state, action) => { state.featuredProductList = getCommonSuccess(action.payload) },
    featuredProductListFailure: (state, action) => { state.featuredProductList = getCommonFailure(action.payload) },
  }
});
export const {
  productListStart,
  moreProductListStart,
  productListSuccess,
  productListFailure,
  singleProductStart,
  singleProductSuccess,
  singleProductFailure,
  fetchproductWishlistsStart,
  fetchproductWishlistsSuccess,
  fetchproductWishlistsFailure,
  fetchMoreproductWishlistsStart,
  wishlistSaveStart,
  wishlistSaveSuccess,
  wishlistSaveFailure,
  fetchProductReviewListStart,
  fetchMoreProductReviewListStart,
  fetchProductReviewListSuccess,
  fetchProductReviewListFailure,
  fetchProductSuggetionStart,
  fetchProductSuggetionSuccess,
  fetchProductSuggetionFailure,
  metalListStart,
  metalListSuccess,
  metalListFailure,
  topProductListFailure,
  topProductListStart,
  topProductListSuccess,
  specialProductListFailure,
  specialProductListStart,
  specialProductListSuccess,
  featuredProductListFailure,
  featuredProductListStart,
  featuredProductListSuccess

} = ProductSlice.actions;

export default ProductSlice.reducer;