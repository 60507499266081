import React, { useEffect, useRef, useState } from "react";
import { Container, Image, Nav, Row, Col, Form, Button, InputGroup, Tab } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import {
  fetchdeliveryAddressesListStart,
  deliveryAddressesSaveStart,
  getcountriesStart,
  getstatesStart,
} from "../../store/slices/DeliverySlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const AddressSubmitForm = ({ isModal = false, ...props }) => {

  const t = useTranslation("address_submit_form");
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const deliveryAddressesSave = useSelector(state => state.delivery.deliveryAddressesSave);
  const deliveryAddressesList = useSelector(state => state.delivery.deliveryAddressesList);
  const countries = useSelector(state => state.delivery.countries);
  const states = useSelector(state => state.delivery.states);

  const validationSchema = Yup.object().shape({
    name:Yup.string().required(t("name.required")),
    contact_number: Yup.string().required(t("contact_number.required"))
      .min(10, t("contact_number.min_character"))
      .max(20, t("contact_number.max_character")),
    city: Yup.string().required(t("city.required")),
    state: Yup.string().required(t("state.required")),
    country: Yup.string().required(t("country.required")),
    pincode: Yup.string().required(t("pincode.required")),
    landmark: Yup.string().required(t("landmark.required")),
    address: Yup.string().required(t("address.required")),
  });

  const handleSubmit = (values) => {
    dispatch(
      deliveryAddressesSaveStart((
        props.editAddress.delivery_address_id
          ? {
            ...values,
            delivery_address_id: props.editAddress.delivery_address_id,
          }
          : values
      ))
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !deliveryAddressesSave.loading &&
      Object.keys(deliveryAddressesSave.data).length > 0
    ) {
      if (!isModal) {
        props.setActiveTab("saved_billing");
        dispatch(fetchdeliveryAddressesListStart());
      }
    }
    setSkipRender(false);
  }, [deliveryAddressesSave]);

  return (
    <>
      <div className="new-address-form-sec">
        <Formik
          initialValues={{
            name: props.editAddress.name ? props.editAddress.name : "",
            contact_number: props.editAddress.contact_number
              ? props.editAddress.contact_number
              : "",
            city: props.editAddress.city ? props.editAddress.city : "",
            state: props.editAddress.state ? props.editAddress.state : "",
            country: props.editAddress.country ? props.editAddress.country : "",
            pincode: props.editAddress.pincode ? props.editAddress.pincode : "",
            landmark: props.editAddress.landmark
              ? props.editAddress.landmark
              : "",
            address: props.editAddress.address ? props.editAddress.address : "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue
          }) =>
            <FORM className="account-form">
              <Row>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>{t("name.label")} *</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder={t("name.label")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="name"
                      className="text-danger"
                    />
                  </Form.Group>
                  <div className="select-address-type-btn">
                    <Button
                      type="button"
                      className="default-btn"
                      onClick={e => setFieldValue("name", "Home")}
                    >
                      {t("name.home")}
                    </Button>
                    <Button
                      type="button"
                      className="default-btn"
                      onClick={() => setFieldValue("name", "Work")}
                    >
                      {t("name.work")}
                    </Button>
                    <Button
                      type="button"
                      className="default-btn"
                      onClick={() => setFieldValue("name", "Others")}
                    >
                      {t("name.others")}
                    </Button>
                  </div>
                </Col>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>{t("contact_number.label")}*</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="contact_number"
                      placeholder={t("contact_number.placeholder")}
                      min="0"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="contact_number"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>{t("country.label")} *</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="country"
                      placeholder={t("country.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="country"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>{t("state.label")}*</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="state"
                      placeholder={t("state.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="state"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>{t("city.label")} *</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder={t("city.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="city"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>{t("pincode.label")} *</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="pincode"
                      placeholder={t("pincode.placeholder")}
                      min="0"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="pincode"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} xl={12} lg={12}>
                  <Form.Group className="mb-4">
                    <Form.Label>{t("landmark.label")} *</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="landmark"
                      placeholder={t("landmark.placeholder")}
                      min="0"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="landmark"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} xl={12} lg={12}>
                  <Form.Group className="mb-4">
                    <Form.Label>{t("address.label")}*</Form.Label>
                    <Field
                      as="textarea"
                      className="form-control height-auto"
                      name="address"
                      placeholder={t("address.placeholder")}
                      min="0"
                      row={5}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="address"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="account-btn-sec">
                    <Button
                      className="default-btn"
                      type="submit"
                      disabled={deliveryAddressesSave.buttonDisable}
                    >
                      {deliveryAddressesSave.buttonDisable ?
                        t("loading_btn.loading") :
                        t("loading_btn.text")
                      }
                    </Button>
                  </div>
                </Col>
              </Row>
            </FORM>
          }
        </Formik>
      </div>
    </>
  )
}

export default AddressSubmitForm;