import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ProfileSidebar from "../Profile/ProfileSidebar";
import "./Wallet.css";
import { useDispatch, useSelector } from "react-redux";
import {
  withdrawRequestStart,
  addUserBillingAccountsStart,
  userBillingAccountsStart,
} from "../../store/slices/WalletSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";
import SingleBillingAddressCard from "./SingleBillingAddressCard";
import { useNavigate } from "react-router-dom";
import { defaultUserBillingAccountsStart } from "../../store/slices/WalletSlice";

const WalletWithdrawMoney = (props) => {

  const t = useTranslation("wallet_withdraw");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("saved_billing");
  const [skipRender, setSkipRender] = useState(true);
  const [agree, setAgree] = useState(false);
  const withdrawRequest = useSelector(state => state.wallet.withdrawRequest);
  const addUserBillingAccounts = useSelector(state => state.wallet.addUserBillingAccounts);
  const defaultUserBillingAccounts = useSelector(state => state.wallet.defaultUserBillingAccounts);
  const userBillingAccounts = useSelector(state => state.wallet.userBillingAccounts);
  const profile = useSelector(state => state.user.profile);
  const [selectedAccount, setSelectedAccount] = useState(null);


  const handleSubmit = (values) => {
    dispatch(addUserBillingAccountsStart(values));
  };

  useEffect(() => {
    dispatch(userBillingAccountsStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !addUserBillingAccounts.loading &&
      Object.keys(addUserBillingAccounts.data).length > 0) {
      setActiveTab("saved_billing")
      dispatch(userBillingAccountsStart());
      if (agree) {
        dispatch(
          defaultUserBillingAccountsStart({
            user_billing_account_id: addUserBillingAccounts.data.user_billing_account_id,
          })
        );
      }
    }
    setSkipRender(false);
  }, [addUserBillingAccounts]);

  const addbillingSchema = Yup.object().shape({
    route_number: Yup.string().required(t("route_number.required"))
      .matches(/^(?=.*[0-9])(?=.{7,})/, t("route_number.min_characters")),
    account_number: Yup.string()
      .required(t("account_number.required"))
      .min(11, t("account_number.min_character"))
      .max(20, t("account_number.max_character")),
    first_name: Yup.string().required(t("first_name.required")),
    last_name: Yup.string().required(t("last_name.required")),
    bank_type: Yup.string().required(t("bank_type.required")),
  });

  const addAmountSchema = Yup.object().shape({
    amount: Yup.string().required(t("add_amount.required")),
  });

  const addAmount = (values) => {
    if (values.amount && selectedAccount) {
      dispatch(
        withdrawRequestStart({
          amount: values.amount,
          user_billing_account_id: selectedAccount,
        })
      );
    } else if (!values.amount) {
      dispatch(getErrorNotificationMessage(t("add_amount.please_enter_the_amount")));
    } else if (!selectedAccount) {
      dispatch(getErrorNotificationMessage(t("add_amount.please_add_or_select_an_account"))
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !userBillingAccounts.loading &&
      Object.keys(userBillingAccounts.data).length > 0
    ) {
      if (
        userBillingAccounts.data.user_billing_accounts &&
        userBillingAccounts.data.user_billing_accounts.length > 0
      ) {
        userBillingAccounts.data.user_billing_accounts.map(
          (account, i) => {
            if (account.is_default === 1)
              setSelectedAccount(account.user_billing_account_id);
          }
        );
      } else {
        setSelectedAccount(null);
      }
    }
  }, [userBillingAccounts]);

  useEffect(() => {
    if (
      !skipRender &&
      !withdrawRequest.loading &&
      Object.keys(withdrawRequest.data).length > 0
    ) {
      navigate("/withdraw");
    }
  }, [withdrawRequest]);

  useEffect(() => {
    if (
      !skipRender &&
      !defaultUserBillingAccounts.loading &&
      Object.keys(defaultUserBillingAccounts.data).length > 0
    ) {
      setAgree(false);
    }
  }, [defaultUserBillingAccounts]);


  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                <div className="wallet-sec">
                  <div className="profile-header-sec">
                    <h3>{t("heading")}</h3>
                    <p>{t("welcome", { username: profile.data.username })}</p>
                  </div>
                </div>
                <div className="add-money-header-sec">

                  <Formik
                    initialValues={{
                      amount: "",
                    }}
                    validationSchema={addAmountSchema}
                    onSubmit={addAmount}
                  >
                    <FORM>
                      <div className="add-money-amount-details">
                        <Form.Group className="mb-0">
                          <Field
                            type="number"
                            className="form-control"
                            name="amount"
                            placeholder={t("add_amount.placeholder")}
                            min="0"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="amount"
                            className="text-danger"
                          />
                        </Form.Group>
                        <Button className="default-btn withdraw">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="#fff"
                          >
                            <path d="M18.5 9.5A1.5 1.5 0 0020 8v-.687A5.32 5.32 0 0014.687 2H13.5v-.5a1.5 1.5 0 00-3 0V2H9.313a5.313 5.313 0 00-1.541 10.4l2.728.746V19H9.313A2.316 2.316 0 017 16.687V16a1.5 1.5 0 00-3 0v.687A5.32 5.32 0 009.313 22H10.5v.5a1.5 1.5 0 003 0V22h1.187a5.313 5.313 0 001.541-10.4l-2.728-.744V5h1.187A2.316 2.316 0 0117 7.313V8a1.5 1.5 0 001.5 1.5zm-3.118 4.979a2.314 2.314 0 01-.7 4.521H13.5v-5.035zM10.5 10.035l-1.882-.514A2.314 2.314 0 019.313 5H10.5z"></path>
                          </svg>
                        </Button>
                        <div className="add-money-btn-sec">
                          <Button
                            type="submit"
                            className="default-btn"
                            disabled={withdrawRequest.buttonDisable}
                          >
                            {withdrawRequest.buttonDisable ? t("withdraw_btn.loading") : t("withdraw_btn.text")}
                          </Button>
                        </div>
                      </div>
                    </FORM>
                  </Formik>
                </div>
                <div className="manage-billing-tab-sec withdraw-tab-sec">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => setActiveTab("saved_billing")}
                              className={`${activeTab === "saved_billing" ? "active-tab" : "default-tab"}`}
                            >{t("billing.saved_billing_btn")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => setActiveTab("new_billing")}
                              className={`${activeTab === "new_billing" ? "active-tab" : "default-tab"}`}
                            >{t("billing.new_nilling_btn")}</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          {activeTab === "saved_billing" && (
                            userBillingAccounts.loading ?
                              <Row>
                                <Col sm={6}>
                                  <Skeleton count={3} height={125} />
                                </Col>
                                <Col sm={6}>
                                  <Skeleton count={3} height={125} />
                                </Col>
                              </Row>
                              :
                              Object.keys(userBillingAccounts.data).length > 0 &&
                                userBillingAccounts.data.user_billing_accounts.length > 0
                                ?
                                < div className="manage-billing-box" >
                                  {userBillingAccounts.data.user_billing_accounts.map(
                                    (account, i) => (
                                      <SingleBillingAddressCard
                                        account={account}
                                        selectedAccount={selectedAccount}
                                        setSelectedAccount={
                                          setSelectedAccount
                                        }
                                      />

                                    ))}
                                </div>
                                :
                                <NoDataFound />
                          )}
                          {activeTab === "new_billing" && (
                            <div className="new-billing-form-sec">
                              <Formik
                                initialValues={{
                                  route_number: "",
                                  account_number: "",
                                  first_name: "",
                                  last_name: "",
                                  bank_type: "savings",
                                  business_name: "",
                                }}
                                validationSchema={addbillingSchema}
                                onSubmit={(values) => handleSubmit(values)}
                              >
                                <FORM className="account-form">
                                  <Row>
                                    <Col md={12} xl={6} lg={6}>
                                      <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>{t("route_number.label")} *</Form.Label>
                                        <Field
                                          type="number"
                                          min="0"
                                          className="form-control"
                                          name="route_number"
                                          placeholder={t(
                                            "route_number.placeholder"
                                          )}
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name="route_number"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col md={12} xl={6} lg={6}>
                                      <Form.Group className="mb-4">
                                        <Form.Label>
                                          {t("account_number.label")}*
                                        </Form.Label>
                                        <Field
                                          type="number"
                                          className="form-control"
                                          name="account_number"
                                          placeholder={t(
                                            "account_number.placeholder"
                                          )}
                                          min="0"
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name="account_number"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col md={12} xl={6} lg={6}>
                                      <Form.Group className="mb-4">
                                        <Form.Label>
                                          {t("first_name.label")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="first_name"
                                          placeholder={t(
                                            "first_name.placeholder"
                                          )}
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name="first_name"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col md={12} xl={6} lg={6}>
                                      <Form.Group className="mb-4">
                                        <Form.Label>
                                          {t("last_name.label")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="last_name"
                                          placeholder={t(
                                            "last_name.placeholder"
                                          )}
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name="last_name"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col md={12} xl={6} lg={6}>
                                      <Form.Group className="mb-4">
                                        <Form.Label>
                                          {t("bank_type.label")}
                                        </Form.Label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          name="bank_type"
                                          placeholder={t(
                                            "bank_type.placeholder"
                                          )}
                                        >
                                          <option value="savings">
                                            {t("bank_type.savings")}
                                          </option>
                                          <option value="checking">
                                            {t("bank_type.checking")}
                                          </option>
                                        </Field>
                                        <ErrorMessage
                                          component={"div"}
                                          name="bank_type"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col md={12} xl={6} lg={6}>
                                      <Form.Group className="mb-4">
                                        <Form.Label>
                                          {t("bussiness_name.label")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="business_name"
                                          placeholder={t(
                                            "bussiness_name.placeholder"
                                          )}
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name="business_name"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Form.Group className="mb-0">
                                    <Form.Check
                                      type="checkbox"
                                      label={t("billing.default")}
                                      checked={agree}
                                      onChange={() => (setAgree(!agree))}
                                    />
                                  </Form.Group>
                                  <Row>
                                    <Col md={12}>
                                      <div className="account-btn-sec">
                                        <Button
                                          type="submit"
                                          className="default-btn"
                                          disabled={addUserBillingAccounts.buttonDisable}
                                        >
                                          {addUserBillingAccounts.buttonDisable ? t("save_btn.loading") :
                                            t("save_btn.text")}
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </FORM>
                              </Formik>
                            </div>
                          )}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WalletWithdrawMoney;
