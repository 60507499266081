
import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  localCart: [],
  guestCartList: getCommonInitialState(),
  cartList: getCommonInitialState(),
  cartSave: getCommonInitialState(),
  cartDelete: getCommonInitialState(),
};

const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {

    // Local cart list
    localCartList: (state, action) => { state.localCart = action.payload },

    //Guest cart list
    guestCartListStart: (state) => { state.guestCartList = getCommonStart() },
    updateGuestCartListStart: (state) => { state.guestCartList = state.guestCartList },
    guestCartListSuccess: (state, action) => { state.guestCartList = getCommonSuccess(action.payload) },
    guestCartListFailure: (state, action) => { state.guestCartList = getCommonSuccess(action.payload) },


    // Cart List
    cartListStart: (state) => { state.cartList = getCommonStart() },
    cartListSuccess: (state, action) => { state.cartList = getCommonSuccess(action.payload) },
    cartListFailure: (state, action) => { state.cartList = getCommonFailure(action.payload) },

    // Save or update cart product
    cartSaveStart: (state) => { state.cartSave = getCommonStart() },
    cartSaveSuccess: (state, action) => { state.cartSave = getCommonSuccess(action.payload) },
    cartSaveFailure: (state, action) => { state.cartSave = getCommonFailure(action.payload) },

    // Delete product from cart
    cartDeleteStart: (state, action) => { state.cartDelete = getCommonStart(action.payload) },
    cartDeleteSuccess: (state, action) => { state.cartDelete = getCommonSuccess(action.payload) },
    cartDeleteFailure: (state, action) => { state.cartDelete = getCommonFailure(action.payload) },

  }
});

export const {
  localCartList,
  guestCartListStart,
  updateGuestCartListStart,
  guestCartListSuccess,
  guestCartListFailure,
  cartListStart,
  cartListSuccess,
  cartListFailure,
  cartSaveStart,
  cartSaveSuccess,
  cartSaveFailure,
  cartDeleteStart,
  cartDeleteSuccess,
  cartDeleteFailure,

} = CartSlice.actions;

export default CartSlice.reducer;