import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import ProfileSidebar from "./ProfileSidebar";
import { deleteAccountStart } from "../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useLogout from "../Auth/useLogout";

const DeleteAccount = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("delete_account");
  const deleteAccount = useSelector(state => state.user.deleteAccount);


  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { logout } = useLogout();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("password.invalid"))
      .min(6, t("password.must_have_6_characters")),
  });

  const handleSubmit = (values) => {
    dispatch(deleteAccountStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !deleteAccount.loading &&
      Object.keys(deleteAccount.data).length > 0
    ) {
      logout();
      navigate("/");
    }
    setSkipRender(false);
  }, [deleteAccount]);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                <div className="profile-details-sec">
                  <div className="profile-header-sec">
                    <h3>{t("heading")}</h3>
                    <p>{t("will_see_you_soon")}</p>
                  </div>
                  <div className="caution-sec">
                    <span className="caution-txt">{t("caution")}:</span> {t("caution_txt")}
                  </div>
                  <Formik
                    initialValues={{
                      password: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                  >
                    <FORM className="account-form">
                      <Row>
                        <Col md={6}>
                          <Form.Label>{t("password.label")}*</Form.Label>
                          <InputGroup className="mb-3">
                            <Field
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder={t("password.placeholder")}
                              className="form-control"
                            />
                            <InputGroup.Text className="delete-passwd">
                              <button
                                onClick={() =>
                                  setShowPassword(!showPassword)
                                }
                                className="btn password-eye"
                                type="button"
                              >{showPassword ?
                                <Image
                                  className="pass-eye-open"
                                  src={window.location.origin + "/assets/img/profile/icons/eye-off.svg"}
                                />
                                :
                                <Image
                                  className="pass-eye-open"
                                  src={window.location.origin + "/assets/img/profile/icons/eye.svg"}
                                />
                                }
                              </button>
                            </InputGroup.Text>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger pass-txt"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="account-btn-sec">
                            <Button
                              type="submit"
                              className="default-btn"
                              disabled={deleteAccount.buttonDisable}
                            >
                              {deleteAccount.buttonDisable ? t("delete_btn.loading") :
                                t("delete_btn.text")}

                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </FORM>
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DeleteAccount;
