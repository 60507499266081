import React, { useState } from "react";
import EmptyFooter from "./Footer/EmptyFooter";
import EmptyHeader from "./Header/EmptyHeader";
import {
  Navbar,
  Container,
  Image,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import configuration from "react-global-configuration";
import { withTranslation, useTranslation } from "react-multi-lang";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const CheckoutLayout = (props) => {

  const navigate = useNavigate();
  const t = useTranslation("header");

  const redirectHome = () => {
    confirmAlert({
      message: t("exit_checkout_confirmation"),
      buttons: [
        {

          label: t("confirm_yes"),
          onClick: () => navigate("/")},
        {
          label: t("confirm_no"),
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }

  return (
    <>
      <div className="header">
        <Container>
          <div className="header-section checkout-header">
            <div className="log-frame">
              <Link to="#"
                onClick={redirectHome}
              >
                <Image
                  className="logo-icon"
                  src={configuration.get("configData.site_logo")}
                  alt={configuration.get("configData.site_name")}
                />
              </Link>
            </div>
          </div>
        </Container>
      </div>
      {props.children}
      <EmptyFooter />
    </>
  );
};

export default CheckoutLayout;
