import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import NumericInput from "react-numeric-input";
import { useTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import { cartDeleteStart, cartSaveStart } from "../../store/slices/CartSlice";
import { wishlistSaveStart } from "../../store/slices/ProductSlice";

const UserCartDetails = () => {
  const t = useTranslation("cart");
  const dispatch = useDispatch();

  const cartList = useSelector(state => state.cart.cartList);
  const cartDelete = useSelector(state => state.cart.cartDelete);
  const wishlistSave = useSelector(state => state.product.wishlistSave);

  const removeProduct = (cartItem, cart_unique_id) => {
    dispatch(cartDeleteStart({
      product_attribute_id: cartItem.product_attribute_id,
      cart_unique_id: cart_unique_id,
      ...cartItem.attributes,
    }));
  }

  const updateCartQty = (cartItem, qty) => {
    dispatch(cartSaveStart({
      product_attribute_id: cartItem.product_attribute_id,
      quantity: qty,
      ...cartItem.attributes,
    }));
  }
  const wishListToggle = product_unique_id => {
    dispatch(wishlistSaveStart({
      product_unique_id: product_unique_id,
    })
    );
  };


  return (
    <>
      <div className="cart-box">
        {cartList.data.carts && cartList.data.user_cart_amount && cartList.data.carts.length > 0 ?
          <Row className="gy-4 justify-content-center">
            <Col md={12} xl={8} lg={8}>
              <div className="cart-card">
                <div className="cart-header">
                  <h4>{t("product_details.heading")} <span>{t("product_details.count", { val: cartList.data.carts.length })}</span></h4>
                </div>
                <div className="cart-body">
                  <div className="cart-list-item-box">
                    {cartList.data.carts.map((cartItem, i) =>
                      <div className="cart-list-card" key={i}>
                        <div className="cart-list-left-sec">
                          <Link to="#">
                            <div className="cart-list-img-sec">
                              <Image
                                className="img cart-list-img"
                                src={cartItem.product.file}
                              />
                            </div>
                          </Link>
                          <div className="cart-list-info">
                            <Link to={`/product/${cartItem.product.product_unique_id}`} className="text-decoration-none">
                              <h4>{cartItem.product.name}</h4>
                            </Link>
                            <p>{t("product_details.category")} <span>{
                              cartItem.product.product_categories.map((category, i) => {
                                let name = category.category_name;
                                if (i !== (cartItem.product.product_categories.length - 1))
                                  name += " - ";
                                return name
                              })
                            }</span></p>
                            {cartItem.product_attribute.metal ?
                              <p>{t("product_details.metal")} <span>{cartItem.product_attribute.metal}</span></p>
                              : null
                            }

                            {cartItem.attributes.weight ?
                              <p>{t("product_details.vol")} <span>{cartItem.attributes.weight}</span></p>
                              : null
                            }
                            {cartItem.attributes.size ?
                              <p>{t("product_details.size")} <span>{cartItem.attributes.size}</span></p>
                              : null
                            }
                            <p>{t("product_details.qty")}
                              <span>
                                <NumericInput
                                  mobile
                                  className="form-control"
                                  value={cartItem.quantity}
                                  onChange={val => updateCartQty(cartItem, val)}
                                  min={1}
                                  max={cartItem.product_attribute.quantity}
                                  strict={true}
                                  onFocus={(e) => e.target.select ? e.target.select() : e.preventDefault()}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="cart-list-right-sec">
                          <div className="cart-list-amout-card">
                            <div className="cart-amount-info">
                              <h4>$ {cartItem.product_attribute.selling_price * cartItem.quantity}.00</h4>
                              <p>{cartItem.product_attribute.discount_tag} |
                                <del>$ {cartItem.product_attribute.original_price * cartItem.quantity}.00</del></p>
                            </div>
                            <div className="cart-action-btn-sec">
                              <Button
                                className="wishlist-btn"
                                onClick={() => removeProduct(cartItem, cartItem.cart_unique_id)}
                                disabled={cartDelete.buttonDisable &&
                                  cartDelete.data.product_attribute_id === cartItem.product_attribute_id &&
                                  cartDelete.data.size === cartItem.attributes.size &&
                                  cartDelete.data.weight === cartItem.attributes.weight
                                }
                              >
                                {cartDelete.buttonDisable &&
                                  cartDelete.data.product_attribute_id === cartItem.product_attribute_id &&
                                  cartDelete.data.size === cartItem.attributes.size &&
                                  cartDelete.data.weight === cartItem.attributes.weight
                                  ? t("product_details.remove_btn.loading")
                                  : t("product_details.remove_btn.text")
                                }
                              </Button>
                              <Button className="download-receipt-btn"
                                onClick={() => wishListToggle(cartItem.product.product_unique_id)}
                                disabled={wishlistSave.buttonDisable}
                              >
                                {cartItem.product.is_wishlisted ?
                                  t("product_details.remove_from_wishlist")
                                  : t("product_details.add_to_wishlist")
                                }
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} xl={4} lg={4}>
              <div className="cart-card">
                <div className="cart-header">
                  <h4>{t("order_details.heading")}</h4>
                </div>
                <div className="cart-body">
                  <div className="cart-summary-info">
                    <div className="cart-summary-header">
                      <p>{t("order_details.total")} <span>{cartList.data.user_cart_amount.cart_total_amount}</span></p>
                      <p>{t("order_details.discount")} <span>-{cartList.data.user_cart_amount.cart_discount_amount}</span></p>
                    </div>
                    <div className="cart-summary-total-amount">
                      <h5>{t("order_details.final")} <span>{cartList.data.user_cart_amount.cart_checkout_amount}</span></h5>
                    </div>
                    <div className="cart-summary-btn-sec">
                      <Link className="default-btn w-100" to="/checkout-address">{t("order_details.place_order")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          : <NoDataFound />
        }
      </div>
    </>
  );
}

export default UserCartDetails;