import React, { useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import "./Auth.css";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { userLoginFailure, userLoginStart } from "../../store/slices/UserSlice";
import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";

const LoginIndex = ({ setStep, closeAuthModal }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const login = useSelector(state => state.user.login);

  const [skipRender, setSkipRender] = useState(true);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("login.email.invalid")).required(t("login.email.required")),
    password: Yup.string()
      .required(t("login.password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("login.password.invalid")),
  });

  const handleLogin = (values) => {
    dispatch(userLoginStart({ ...values, login_by: "manual", device_type: "web" }));
  };

  useEffect(() => {
    dispatch(userLoginFailure());
  }, []);

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      if (login.data.is_two_step_auth_enabled == 1) {
        setStep(4);
      } else if (login.data.is_email_verified == 0) {
        setStep(5);
      } else {
        closeAuthModal();
      }
    }
    setSkipRender(false);
  }, [login]);

  return (
    <>
      <div className="step-1">
        <div className="auth-form">
          <div className="login-box">
            <div className="login-header-title-sec">
              <h3>{t("login.heading")}</h3>
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={(values) => handleLogin(values)}
            >
              {({ touched, errors }) =>
                <FORM>
                  <div className="login-body-sec">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>{t("login.email.label")} *</Form.Label>
                      <Field
                        type="email"
                        name="email"
                        placeholder={t("login.email.label")}
                        className="form-control"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="text-danger text-right"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t("login.password.label")} *</Form.Label>
                      <InputGroup className="mb-3">
                        <Field
                          type={loginPasswordVisible ? "text" : "password"}
                          name="password"
                          placeholder={t("login.password.placeholder")}
                          className="form-control"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <Image
                            className="eye-hidden-icon"
                            src={
                              loginPasswordVisible ?
                                window.location.origin + "/assets/img/auth/eye-show-icon.svg"
                                : window.location.origin + "/assets/img/auth/eye-hide-icon.svg"
                            }
                            onClick={() =>
                              setLoginPasswordVisible(!loginPasswordVisible)
                            }
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="password"
                        className="text-danger text-right"
                      />
                    </Form.Group>
                    <div className="lost-password-link-sec">
                      <Link to="#" onClick={() => setStep(3)}>
                        {t("login.forgot_password")}
                      </Link>
                    </div>
                    <div className="auth-btn-sec">
                      <Button
                        type="submit"
                        className="default-btn w-100"
                        disabled={login.buttonDisable}
                      >
                        {login.buttonDisable ? t("login.login_btn.loading") : t("login.login_btn.text")}
                      </Button>
                      <Button type="button" className="default-btn-outline w-100" onClick={() => setStep(2)}>
                        {t("login.create_account")}
                      </Button>
                    </div>
                  </div>
                </FORM>
              }
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginIndex;