
import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    bannerList: getCommonInitialState(),
    categoriesList: getCommonInitialState(),
    topProductList: getCommonInitialState(),
    customerReviewsList: getCommonInitialState(),
};

const HomePageSlice = createSlice({
    name: "homepage",
    initialState,
    reducers: {

        // Banner List
        fetchBannerListStart: (state) => { state.bannerList = getCommonStart() },
        fetchBannerListSuccess: (state, action) => { state.bannerList = getCommonSuccess(action.payload) },
        fetchBannerListFailure: (state, action) => { state.bannerList = getCommonFailure(action.payload) },

        // Categories List
        fetchCategoriesListStart: (state) => { state.categoriesList = getCommonStart() },
        fetchCategoriesListSuccess: (state, action) => { state.categoriesList = getCommonSuccess(action.payload) },
        fetchCategoriesListFailure: (state, action) => { state.categoriesList = getCommonFailure(action.payload) },

        // Top Product List
        fetchTopProductListStart: (state) => { state.topProductList = getCommonStart() },
        fetchTopProductListSuccess: (state, action) => { state.topProductList = getCommonSuccess(action.payload) },
        fetchTopProductListFailure: (state, action) => { state.topProductList = getCommonFailure(action.payload) },

        // Customer review List
        customerReviewsListStart: (state) => { state.customerReviewsList = getCommonStart() },
        customerReviewsListSuccess: (state, action) => { state.customerReviewsList = getCommonSuccess(action.payload) },
        customerReviewsListFailure: (state, action) => { state.customerReviewsList = getCommonFailure(action.payload) },


    }
});

export const {
    fetchBannerListStart,
    fetchBannerListSuccess,
    fetchBannerListFailure,
    fetchCategoriesListStart,
    fetchCategoriesListSuccess,
    fetchCategoriesListFailure,
    fetchTopProductListStart,
    fetchTopProductListSuccess,
    fetchTopProductListFailure,
    customerReviewsListStart,
    customerReviewsListSuccess,
    customerReviewsListFailure,

} = HomePageSlice.actions;

export default HomePageSlice.reducer;