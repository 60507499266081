import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  changePasswordFailure,
  changePasswordSuccess,
  emailVerificationFailure,
  emailVerificationSuccess,
  fetchUserDetailsFailure,
  fetchUserDetailsSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  forgotPasswordVerificationFailure,
  forgotPasswordVerificationSuccess,
  resendEmailVerificationFailure,
  resendEmailVerificationSuccess,
  resendTwoStepAuthFailure,
  resendTwoStepAuthSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  userLoginFailure,
  userLoginSuccess,
  userRegisterFailure,
  userRegisterSuccess,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  twoStepAuthUpdateSuccess,
  twoStepAuthUpdateFailure,
  twoStepAuthSuccess,
  twoStepAuthFailure,
  notificationUpdateFailure,
  notificationUpdateSuccess,
} from "../slices/UserSlice";

function* userLoginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    successCallback: true,
    success: data => {
      if (data.is_email_verified == 0 || data.is_two_step_auth_enabled)
        localStorage.setItem("email", data.email);
      else {
        localStorage.setItem("userId", data.user_id);
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("device_unique_id", data.device_unique_id);
        store.dispatch(fetchUserDetailsSuccess(data));
      }
      store.dispatch(userLoginSuccess(data));
    },
    failure: userLoginFailure,
  });
}

function* userRegisterAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    successCallback: true,
    success: data => {
      if (data.is_email_verified == 0 || data.is_two_step_auth_enabled)
        localStorage.setItem("email", data.email);
      else {
        localStorage.setItem("userId", data.user_id);
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("device_unique_id", data.device_unique_id);
        store.dispatch(fetchUserDetailsSuccess(data));
      }
      store.dispatch(userRegisterSuccess(data))
    },
    failure: userRegisterFailure,
  });
}

function* resendEmailVerificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "resend_email_verification_code",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(resendEmailVerificationSuccess({ ...data, email: action.payload.email })),
    failure: resendEmailVerificationFailure,
  })
}

function* emailVerificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_email",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(emailVerificationSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
      localStorage.setItem("userId", data.user_id);
      localStorage.setItem("accessToken", data.token);
      localStorage.setItem("device_unique_id", data.device_unique_id);
      localStorage.removeItem("email");
    },
    failure: emailVerificationFailure,
  })
}

function* resendTwoStepAuthAPI(action) {
  yield getCommonSaga({
    apiUrl: "resend_two_step_auth_code",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(resendTwoStepAuthSuccess({ ...data, email: action.payload.email })),
    failure: resendTwoStepAuthFailure,
  })
}

function* twoStepAuthAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_email",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(twoStepAuthSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
      localStorage.setItem("userId", data.user_id);
      localStorage.setItem("accessToken", data.token);
      localStorage.setItem("device_unique_id", data.device_unique_id);
      localStorage.removeItem("email");
    },
    failure: twoStepAuthFailure,
  })
}

function* forgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.payload,
    success: forgotPasswordSuccess,
    failure: forgotPasswordFailure,
  })
}

function* forgotPasswordVerificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_forgot_password_code",
    payload: action.payload,
    success: forgotPasswordVerificationSuccess,
    failure: forgotPasswordVerificationFailure,
  })
}

function* resetPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "reset_password",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(resetPasswordSuccess({ ...data, email: action.payload.email })),
    failure: resetPasswordFailure,
  })
}

function* fetchUserDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.payload,
    success: fetchUserDetailsSuccess,
    failure: fetchUserDetailsFailure,
  })
}

function* changePasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "change_password",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(changePasswordSuccess({ data: data })),
    failure: changePasswordFailure,
  })
}

function* deleteAccountAPI(action) {
  yield getCommonSaga({
    apiUrl: "delete_account",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(deleteAccountSuccess({ data: data })),
    failure: deleteAccountFailure,
  })
}

function* updateProfileDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateUserDetailsSuccess(data))
      store.dispatch(fetchUserDetailsSuccess(data))
    },
    failure: updateUserDetailsFailure,
  })
}


function* twoStepAuthUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "two_step_auth_update",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(twoStepAuthUpdateSuccess(data))
      store.dispatch(fetchUserDetailsSuccess(data))
    },
    failure: twoStepAuthUpdateFailure,
  })
}

function* notificationUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_notification",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(notificationUpdateSuccess(data))
      store.dispatch(fetchUserDetailsSuccess(data.user))
    },
    failure: notificationUpdateFailure,
  })
}

export default function* UserSaga() {
  yield all([
    yield takeLatest('user/userLoginStart', userLoginAPI),
    yield takeLatest('user/userRegisterStart', userRegisterAPI),
    yield takeLatest('user/resendEmailVerificationStart', resendEmailVerificationAPI),
    yield takeLatest('user/emailVerificationStart', emailVerificationAPI),
    yield takeLatest('user/resendTwoStepAuthStart', resendTwoStepAuthAPI),
    yield takeLatest('user/twoStepAuthStart', twoStepAuthAPI),
    yield takeLatest('user/forgotPasswordStart', forgotPasswordAPI),
    yield takeLatest('user/forgotPasswordVerificationStart', forgotPasswordVerificationAPI),
    yield takeLatest('user/resetPasswordStart', resetPasswordAPI),
    yield takeLatest('user/fetchUserDetailsStart', fetchUserDetailsAPI),
    yield takeLatest('user/changePasswordStart', changePasswordAPI),
    yield takeLatest('user/deleteAccountStart', deleteAccountAPI),
    yield takeLatest('user/updateUserDetailsStart', updateProfileDetailsAPI),
    yield takeLatest('user/twoStepAuthUpdateStart', twoStepAuthUpdateAPI),
    yield takeLatest('user/notificationUpdateStart', notificationUpdateAPI)
  ]);
}