import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ProfileSidebar from "../Profile/ProfileSidebar";
import WalletTransaction from "./WalletTransaction";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import {
  withdrawRequestListStart,
  withdrawMoreRequestList,
  cancelWithdrawRequestStart,
} from "../../store/slices/WalletSlice";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";

const Withdraw = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("withdraw");

  const [skipRender, setSkipRender] = useState(true);

  const withdrawRequestList = useSelector(state => state.wallet.withdrawRequestList);
  const cancelWithdrawRequest = useSelector(state => state.wallet.cancelWithdrawRequest);
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    dispatch(
      withdrawRequestListStart({
        skip: 0,
        take: 12,
      }));
  }, []);

  const fetchMoreTransactions = () => {
    dispatch(withdrawMoreRequestList({
      skip: withdrawRequestList.data.user_withdrawals.length,
      take: 12,
    }));
  };

  const cancelWithdraw = (e, user_withdrawal_unique_id) => {
    e.preventDefault();
    dispatch(
      cancelWithdrawRequestStart({
        user_withdrawal_unique_id: user_withdrawal_unique_id,
      })
    );
  };

  const cancelWithdrawConfirmation = (e, user_withdrawal_unique_id) => {
    confirmAlert({
      message: t("confirmation"),
      buttons: [
        {

          label: t("confirm_yes"),
          onClick: () => cancelWithdraw(e, user_withdrawal_unique_id)
        },
        {
          label: t("confirm_no"),
        }
      ]
    });
  }

  useEffect(() => {
    if (!skipRender && !cancelWithdrawRequest.loading &&
      Object.keys(cancelWithdrawRequest.data).length > 0) {
      dispatch(
        withdrawRequestListStart());
    }
    setSkipRender(false);
  }, [cancelWithdrawRequest]);

  const onCopy = () => {
    dispatch(getSuccessNotificationMessage(t("transaction_msg")));
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                {withdrawRequestList.loading ?
                  <Skeleton height={100}
                  />
                  :
                  <div className="wallet-header-sec">
                    <div className="wallet-balance-amount-sec">
                      <h3>{
                        Object.keys(withdrawRequestList.data).length > 0 &&
                          withdrawRequestList.data.user_wallet
                          ? withdrawRequestList.data.user_wallet.remaining_formatted
                          : "0.00 USD"}
                          </h3>
                      <h6>{t("current_wallet_balance")}</h6>
                    </div>
                    <div className="wallet-action-btn-sec">
                      <Link className="default-btn text-uppercase" to="/withdraw-money">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="#fff"
                          className="mr-2"
                        >
                          <g fill="#fff">
                            <path d="M18 7h.75a.75.75 0 00-.75-.75zm0 4h-.75zM6 7v-.75a.75.75 0 00-.75.75zm11.25 0v4h1.5V7zm0 4v8h1.5v-8zM16 20.25H8v1.5h8zM6.75 19V7h-1.5v12zM6 7.75h12v-1.5H6zm0-4h12v-1.5H6zM2.75 7A3.25 3.25 0 016 3.75v-1.5A4.75 4.75 0 001.25 7zM6 10.25A3.25 3.25 0 012.75 7h-1.5A4.75 4.75 0 006 11.75zM21.25 7A3.25 3.25 0 0118 10.25v1.5A4.75 4.75 0 0022.75 7zm1.5 0A4.75 4.75 0 0018 2.25v1.5A3.25 3.25 0 0121.25 7zm-5.5 12c0 .69-.56 1.25-1.25 1.25v1.5A2.75 2.75 0 0018.75 19zM8 20.25c-.69 0-1.25-.56-1.25-1.25h-1.5A2.75 2.75 0 008 21.75zM14.53 15.53a.75.75 0 10-1.06-1.06zm-3.237.763l.53-.53zm-.763-1.823a.75.75 0 10-1.06 1.06zM12.75 11a.75.75 0 00-1.5 0zm-1.5 5a.75.75 0 001.5 0zm2.22-1.53l-1.293 1.293 1.06 1.06 1.293-1.293zm-1.647 1.293L10.53 14.47l-1.06 1.06 1.293 1.293zm.354 0a.25.25 0 01-.354 0l-1.06 1.06a1.75 1.75 0 002.474 0zM11.25 11v5h1.5v-5z"></path>
                          </g>
                        </svg>
                        {t("heading")}
                      </Link>
                    </div>
                  </div>
                }
                <div className="wallet-transaction-sec">
                  <h4>{t("all_trascation")}</h4>
                  {withdrawRequestList.loading ?
                    <Skeleton count={4} height={80} />
                    :
                    Object.keys(withdrawRequestList.data).length > 0 &&
                      withdrawRequestList.data.user_withdrawals.length > 0 ?

                      <div className="order-transaction-sec">
                        <div className="order-list-table">
                          <InfiniteScroll
                            dataLength={withdrawRequestList.data.user_withdrawals.length}
                            next={fetchMoreTransactions}
                            hasMore={withdrawRequestList.data.user_withdrawals.length <
                              withdrawRequestList.data.total_withdrawals}
                            loader={<Skeleton count={4} height={100} />}
                          >
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>{t("table_head.date")}</th>
                                  <th>{t("table_head.transaction_id")}</th>
                                  <th>{t("table_head.account_name")}</th>
                                  <th>{t("table_head.amount")}</th>
                                  <th>{t("table_head.status")}</th>
                                  <th>{t("table_head.action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {withdrawRequestList.data.user_withdrawals.map(
                                  (withdraw, i) => (
                                    <tr key={i}>
                                      <td>  {withdraw.created_formatted} </td>
                                      <td>
                                        {withdraw.payment_id ? (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                {withdraw.payment_id}
                                              </Tooltip>
                                            }
                                          >
                                            <div
                                              className="transaction-id-sec"
                                              title={withdraw.payment_id}
                                            >
                                              <CopyToClipboard
                                                text={withdraw.payment_id}
                                                onCopy={() => onCopy()}
                                              >
                                                <span>{withdraw.payment_id}</span>
                                              </CopyToClipboard>
                                            </div>
                                          </OverlayTrigger>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td>
                                        {withdraw.user_billing_account_name}
                                      </td>
                                      <td>
                                        {withdraw.requested_amount_formatted}
                                      </td>
                                      <td>{withdraw.status_formatted}</td>

                                      <td className="transaction-success">
                                        {withdraw.status === 0 ? (
                                          <Button
                                            variant="danger"
                                            onClick={(e) =>
                                              cancelWithdrawConfirmation(
                                                e, withdraw.user_withdrawal_unique_id
                                              )
                                            }

                                          >
                                            {cancelWithdrawRequest.data
                                              .user_withdrawal_unique_id ===
                                              withdraw.user_withdrawal_unique_id
                                              ? t("withdraw_btn.loading")
                                              : t("withdraw_btn.text")
                                            }
                                          </Button>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </InfiniteScroll>
                        </div>
                      </div>
                      :
                      <NoDataFound />
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Withdraw;
