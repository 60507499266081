import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
  Dropdown,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./LandingStyle.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesListStart } from "../../store/slices/HomePageSlice";
import Skeleton from "react-loading-skeleton";
import AuthModal from "../Auth/AuthModal";
import useLogout from "../Auth/useLogout";
import { useTranslation } from "react-multi-lang";
import { localCartList } from "../../store/slices/CartSlice";
import { fetchUserDetailsStart } from "../../store/slices/UserSlice";
import SearchSuggetion from "../layouts/Header/SearchSuggetion";
import { fetchProductSuggetionStart } from "../../store/slices/ProductSlice";
import configuration from "react-global-configuration";

const Header = () => {
  const dispatch = useDispatch("");
  const t = useTranslation("header");
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const categoriesList = useSelector((state) => state.homepage.categoriesList);
  const [login, setLogin] = useState(false);
  const { logout } = useLogout();
  const profile = useSelector((state) => state.user.profile);
  const localCart = useSelector((state) => state.cart.localCart);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(null);
  const [showSuggetions, setShowSuggetions] = useState(false);

  const closeLoginModal = () => {
    setLogin(false);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          // infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      dispatch(fetchUserDetailsStart());
    }
    const local = localStorage.getItem("cart");
    dispatch(localCartList(local && local.length > 0 ? JSON.parse(local) : []));
    dispatch(fetchCategoriesListStart());
  }, []);

  const getSuggetions = (searchValue, category) => {
    searchValue = searchValue.trimLeft();
    setSearch(searchValue);
    setCategory(category);
    if (searchValue) {
      dispatch(
        fetchProductSuggetionStart({
          search_key: searchValue,
          category_unique_id: category && category.category_unique_id ? category.category_unique_id : "",
        })
      );
      setShowSuggetions(true);
    } else {
      setShowSuggetions(false);
    }
  };

  const searchProduct = (e) => {
    e.preventDefault();
    setShowSuggetions(false);
    const searchVal = search.trim();
    if (searchVal || category)
      navigate(`/products?${category && category.category_unique_id
        ? `category=${category.category_unique_id}&`
        : ``}${searchVal ? `search=${searchVal}` : ``}`);
    else
      navigate("/products");
  };

  const searchEmpty = () => {
    console.log(location.pathname);
    if (search) {
      if (location.pathname === "/products") {
        if (category)
          navigate(`/products?${category && category.category_unique_id
            ? `category=${category.category_unique_id}` : ``}`);
        else
          navigate(`/products`)
        setSearch("");
      } else {
        setSearch("");
        setCategory(null);
      }
    } else {
      const searchVal = search.trim();
      navigate(`/products?${category && category.category_unique_id
        ? `category=${category.category_unique_id}&`
        : ``}${searchVal ? `search=${searchVal}` : ``}`);
    }
  }

  return (
    <>
      <div className="header">
        <Container>
          <div className="header-section">
            <div className="log-frame">
              <Link to="/">
                <Image
                  className="logo-icon"
                  src={configuration.get("configData.site_logo")}
                  alt={configuration.get("configData.site_name")}
                />
              </Link>
            </div>
            <div className="search-frame-head">
              <Form onSubmit={searchProduct}>
                <InputGroup>
                  <Form.Control
                    value={search}
                    onChange={(e) => getSuggetions(e.target.value, category)}
                    type="text"
                    placeholder={t("what_do_you_need")}
                    onBlur={() => {
                      setTimeout(() => {
                        setShowSuggetions(false);
                      }, 500);
                    }}
                  />
                  <InputGroup.Text className="search-icon" onClick={() => searchEmpty()}>
                    {!search ? (
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="search">
                          <path
                            id="Vector"
                            d="M14.0835 14.5834L17.7502 18.25"
                            stroke="#01151F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M8.58333 16.4167C12.6334 16.4167 15.9167 13.1334 15.9167 9.08333C15.9167 5.03324 12.6334 1.75 8.58333 1.75C4.53324 1.75 1.25 5.03324 1.25 9.08333C1.25 13.1334 4.53324 16.4167 8.58333 16.4167Z"
                            stroke="#01151F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#01151F"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                      </svg>
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form>
              {showSuggetions ? (
                <SearchSuggetion setShowSuggetions={setShowSuggetions} />
              ) : null}
            </div>
            <div className="auth-frame">
              <div className="card-fav-head">
                {localStorage.getItem("userId") ||
                  localStorage.getItem("accessToken") ?
                  <div className="fav-frame">
                    <Link to="/wishlist">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 31 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Group 4">
                          <path
                            id="Vector"
                            d="M14.2218 4.5751L15.0342 5.70739L15.8467 4.5751C17.1739 2.72556 19.3315 1.52124 21.7742 1.52124C25.8023 1.52124 29.0685 4.78726 29.0685 8.81634C29.0685 9.38099 28.997 9.93255 28.8699 10.4715L28.8616 10.5066L28.8559 10.5423C28.325 13.8458 26.1913 17.3463 23.4155 20.3124C20.7611 23.1488 17.6331 25.375 15.0342 26.4087C12.435 25.375 9.30699 23.1489 6.65243 20.3123C3.87663 17.3462 1.74262 13.8453 1.2109 10.5413L1.20539 10.507L1.19751 10.4732C1.07152 9.93249 1 9.38014 1 8.81634C1 4.78655 4.26602 1.52124 8.2951 1.52124C10.7369 1.52124 12.8946 2.72552 14.2218 4.5751Z"
                            stroke="white"
                            stroke-width="2"
                          />
                          <path
                            id="Vector 2"
                            d="M20.7607 5.01407C22.6863 4.37223 25.9618 6.01453 25.5415 9.37695"
                            stroke="white"
                            stroke-width="1.41881"
                            stroke-linecap="round"
                          />
                        </g>
                      </svg>
                      {/* <span className="add-fav-count">1</span> */}
                    </Link>
                  </div>
                  :
                  null
                }
                <div className="card-fav">
                  <Link to="/cart">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 35 32"
                    >
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1.898 1.393h1.819c.396 0 .756.234.917.596L6.199 5.51m0 0L10.69 15.62c.161.362.52.596.917.596h13.676c.311 0 .605-.145.795-.392l3.816-4.96 2.874-3.737a1.004 1.004 0 00-.795-1.616H6.199z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M28.245 22.979a1 1 0 100-2v2zm-16.468-7.764c-2.63 0-4.147 1.89-4.31 3.797-.08.943.166 1.936.814 2.709.664.792 1.674 1.258 2.923 1.258v-2c-.727 0-1.149-.255-1.39-.543-.26-.309-.396-.756-.353-1.255.083-.976.828-1.966 2.316-1.966v-2zm-.573 7.764H28.245v-2H11.204v2z"
                      ></path>
                      <circle
                        cx="12.603"
                        cy="27.923"
                        r="2.47"
                        stroke="#fff"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="25.775"
                        cy="27.923"
                        r="2.47"
                        stroke="#fff"
                        strokeWidth="2"
                      ></circle>
                    </svg>
                    {profile.buttonDisable ?
                      null
                      : Object.keys(profile.data).length > 0 ?
                        <span class="add-fav-count">{profile.data.cart_count}</span>
                        : <span class="add-fav-count">{localCart.length}</span>
                    }
                  </Link>
                  {/* <span className="card-notifi">5</span> */}
                </div>
              </div>
              {profile.buttonDisable ? (
                "Loading"
              ) : Object.keys(profile.data).length > 0 ? (
                <div className="user-dropdown-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="32"
                          viewBox="0 0 26 32"
                          fill="none"
                        >
                          <path
                            d="M1.66846 27.1356V25.4967C1.66846 22.4394 3.37993 18.2978 6.46173 17.0828C7.6046 16.6322 8.84733 17.1011 9.86615 17.7875V17.7875C11.8766 19.142 14.4938 19.1938 16.5563 17.92L16.8545 17.7359C17.9146 17.0812 19.1851 16.6196 20.3342 17.1016C23.2865 18.3399 24.6685 22.455 24.6685 25.4967V27.1356"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                          <path
                            d="M13.793 14.2656C10.203 14.2656 7.29297 11.3555 7.29297 7.76563C7.29297 4.17578 10.203 1.26562 13.793 1.26562C17.3829 1.26562 20.293 4.17578 20.293 7.76563C20.293 11.3555 17.3829 14.2656 13.793 14.2656Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                          <path
                            d="M14.9953 5.14567C13.3122 4.06551 10.6153 6.03614 11.0026 7.87036"
                            stroke="white"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Link to="/cart">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#404040"
                            viewBox="0 0 24 24"
                          >
                            <circle cx="7" cy="22" r="2"></circle>
                            <circle cx="17" cy="22" r="2"></circle>
                            <path d="M23.685 1.336a1 1 0 00-1.414 0L17.112 6.5l-1.551-1.619a1 1 0 00-1.442 1.386l1.614 1.679a1.872 1.872 0 001.345.6h.033a1.873 1.873 0 001.335-.553l5.239-5.243a1 1 0 000-1.414z"></path>
                            <path d="M21.9 9.016a1 1 0 00-1.162.807l-.128.709A3 3 0 0117.657 13H5.418l-.94-8H11a1 1 0 000-2H4.242L4.2 2.648A3 3 0 001.222 0H1a1 1 0 000 2h.222a1 1 0 01.993.883l1.376 11.7A5 5 0 008.557 19H19a1 1 0 000-2H8.557a3 3 0 01-2.829-2h11.929a5 5 0 004.921-4.113l.128-.71a1 1 0 00-.806-1.161z"></path>
                          </svg>
                        </span>
                        {t("view_cart")}
                      </Link>
                      <Link to="/products">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#404040"
                            viewBox="0 0 24 24"
                          >
                            <path d="M22.766 4.566a1.994 1.994 0 00-2.18.434L18 7.586 13.414 3a2 2 0 00-2.828 0L6 7.586 3.414 5A2 2 0 000 6.414V17a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6.414a2 2 0 00-1.234-1.848zM22 17a3 3 0 01-3 3H5a3 3 0 01-3-3V6.414l3.293 3.293a1 1 0 001.414 0L12 4.414l5.293 5.293a1 1 0 001.414 0L22 6.414z"></path>
                          </svg>
                        </span>
                        {t("products")}
                      </Link>
                      <Link to="/wishlist">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#404040"
                            viewBox="0 0 24 24"
                          >
                            <path d="M17.5 1.917a6.4 6.4 0 00-5.5 3.3 6.4 6.4 0 00-5.5-3.3A6.8 6.8 0 000 8.967c0 4.547 4.786 9.513 8.8 12.88a4.974 4.974 0 006.4 0c4.014-3.367 8.8-8.333 8.8-12.88a6.8 6.8 0 00-6.5-7.05zm-3.585 18.4a2.973 2.973 0 01-3.83 0C4.947 16.006 2 11.87 2 8.967a4.8 4.8 0 014.5-5.05 4.8 4.8 0 014.5 5.05 1 1 0 002 0 4.8 4.8 0 014.5-5.05 4.8 4.8 0 014.5 5.05c0 2.903-2.947 7.039-8.085 11.346z"></path>
                          </svg>
                        </span>
                        {t("wishlist")}
                      </Link>
                      <Link to="/profile">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#404040"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V5a5.006 5.006 0 00-5-5zM7 22v-1a5 5 0 0110 0v1zm15-3a3 3 0 01-3 3v-1a7 7 0 00-14 0v1a3 3 0 01-3-3V5a3 3 0 013-3h14a3 3 0 013 3z"></path>
                            <path d="M12 4a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                          </svg>
                        </span>
                        {t("profile")}
                      </Link>
                      <Link to="#" onClick={() => logout()}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#404040"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M11.476 15a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2.476a3 3 0 013 3v3a1 1 0 002 0V5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h2.476a5.006 5.006 0 005-5v-3a1 1 0 00-1-1z"></path>
                            <path d="M22.867 9.879l-4.586-4.586a1 1 0 10-1.414 1.414l4.262 4.263L6 11a1 1 0 000 2l15.188-.031-4.323 4.324a1 1 0 101.414 1.414l4.586-4.586a3 3 0 00.002-4.242z"></path>
                          </svg>
                        </span>
                        {t("logout")}
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <div className="login-link-sec">
                  <Link to="#" onClick={() => setLogin(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="32"
                      viewBox="0 0 26 32"
                      fill="none"
                    >
                      <path
                        d="M1.66846 27.1356V25.4967C1.66846 22.4394 3.37993 18.2978 6.46173 17.0828C7.6046 16.6322 8.84733 17.1011 9.86615 17.7875V17.7875C11.8766 19.142 14.4938 19.1938 16.5563 17.92L16.8545 17.7359C17.9146 17.0812 19.1851 16.6196 20.3342 17.1016C23.2865 18.3399 24.6685 22.455 24.6685 25.4967V27.1356"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M13.793 14.2656C10.203 14.2656 7.29297 11.3555 7.29297 7.76563C7.29297 4.17578 10.203 1.26562 13.793 1.26562C17.3829 1.26562 20.293 4.17578 20.293 7.76563C20.293 11.3555 17.3829 14.2656 13.793 14.2656Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M14.9953 5.14567C13.3122 4.06551 10.6153 6.03614 11.0026 7.87036"
                        stroke="white"
                        stroke-linecap="round"
                      />
                    </svg>
                    {t("login")}/ {t("register")}
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="moblie-header-section">
            <div className="header-sub-frame">
              <div className="log-frame">
                <Link to="/">
                  <Image
                    className="logo-icon"
                    src={window.location.origin + "/assets/img/new/logo.png"}
                  />
                </Link>
              </div>
              <div className="card-fav-head">
                {/* <div className="fav-frame">
                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Group 4">
                                    <path id="Vector" d="M14.2218 4.5751L15.0342 5.70739L15.8467 4.5751C17.1739 2.72556 19.3315 1.52124 21.7742 1.52124C25.8023 1.52124 29.0685 4.78726 29.0685 8.81634C29.0685 9.38099 28.997 9.93255 28.8699 10.4715L28.8616 10.5066L28.8559 10.5423C28.325 13.8458 26.1913 17.3463 23.4155 20.3124C20.7611 23.1488 17.6331 25.375 15.0342 26.4087C12.435 25.375 9.30699 23.1489 6.65243 20.3123C3.87663 17.3462 1.74262 13.8453 1.2109 10.5413L1.20539 10.507L1.19751 10.4732C1.07152 9.93249 1 9.38014 1 8.81634C1 4.78655 4.26602 1.52124 8.2951 1.52124C10.7369 1.52124 12.8946 2.72552 14.2218 4.5751Z" stroke="white" stroke-width="2" />
                                    <path id="Vector 2" d="M20.7607 5.01407C22.6863 4.37223 25.9618 6.01453 25.5415 9.37695" stroke="white" stroke-width="1.41881" stroke-linecap="round" />
                                </g>
                            </svg>
                        </div> */}
              </div>
              <div className="auth-frame">
                <div className="login-link-sec-1">
                  <Link class="" onClick={() => setLogin(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="32"
                      viewBox="0 0 26 32"
                      fill="none"
                    >
                      <path
                        d="M1.66846 27.1356V25.4967C1.66846 22.4394 3.37993 18.2978 6.46173 17.0828C7.6046 16.6322 8.84733 17.1011 9.86615 17.7875V17.7875C11.8766 19.142 14.4938 19.1938 16.5563 17.92L16.8545 17.7359C17.9146 17.0812 19.1851 16.6196 20.3342 17.1016C23.2865 18.3399 24.6685 22.455 24.6685 25.4967V27.1356"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M13.793 14.2656C10.203 14.2656 7.29297 11.3555 7.29297 7.76563C7.29297 4.17578 10.203 1.26562 13.793 1.26562C17.3829 1.26562 20.293 4.17578 20.293 7.76563C20.293 11.3555 17.3829 14.2656 13.793 14.2656Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M14.9953 5.14567C13.3122 4.06551 10.6153 6.03614 11.0026 7.87036"
                        stroke="white"
                        stroke-linecap="round"
                      />
                    </svg>
                    {/* {t("login")}/ {t("register")} */}
                  </Link>
                  <Link to="/wishlist">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Group 4">
                        <path
                          id="Vector"
                          d="M14.2218 4.5751L15.0342 5.70739L15.8467 4.5751C17.1739 2.72556 19.3315 1.52124 21.7742 1.52124C25.8023 1.52124 29.0685 4.78726 29.0685 8.81634C29.0685 9.38099 28.997 9.93255 28.8699 10.4715L28.8616 10.5066L28.8559 10.5423C28.325 13.8458 26.1913 17.3463 23.4155 20.3124C20.7611 23.1488 17.6331 25.375 15.0342 26.4087C12.435 25.375 9.30699 23.1489 6.65243 20.3123C3.87663 17.3462 1.74262 13.8453 1.2109 10.5413L1.20539 10.507L1.19751 10.4732C1.07152 9.93249 1 9.38014 1 8.81634C1 4.78655 4.26602 1.52124 8.2951 1.52124C10.7369 1.52124 12.8946 2.72552 14.2218 4.5751Z"
                          stroke="white"
                          stroke-width="2"
                        />
                        <path
                          id="Vector 2"
                          d="M20.7607 5.01407C22.6863 4.37223 25.9618 6.01453 25.5415 9.37695"
                          stroke="white"
                          stroke-width="1.41881"
                          stroke-linecap="round"
                        />
                      </g>
                    </svg>
                    {/* <span className="add-fav-count">1</span> */}
                  </Link>
                  <div className="card-fav">
                  <Link to="/cart">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 35 32"
                    >
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M1.898 1.393h1.819c.396 0 .756.234.917.596L6.199 5.51m0 0L10.69 15.62c.161.362.52.596.917.596h13.676c.311 0 .605-.145.795-.392l3.816-4.96 2.874-3.737a1.004 1.004 0 00-.795-1.616H6.199z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M28.245 22.979a1 1 0 100-2v2zm-16.468-7.764c-2.63 0-4.147 1.89-4.31 3.797-.08.943.166 1.936.814 2.709.664.792 1.674 1.258 2.923 1.258v-2c-.727 0-1.149-.255-1.39-.543-.26-.309-.396-.756-.353-1.255.083-.976.828-1.966 2.316-1.966v-2zm-.573 7.764H28.245v-2H11.204v2z"
                      ></path>
                      <circle
                        cx="12.603"
                        cy="27.923"
                        r="2.47"
                        stroke="#fff"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="25.775"
                        cy="27.923"
                        r="2.47"
                        stroke="#fff"
                        strokeWidth="2"
                      ></circle>
                    </svg>
                    {profile.buttonDisable ?
                      null
                      : Object.keys(profile.data).length > 0 ?
                        <span class="add-fav-count">{profile.data.cart_count}</span>
                        : <span class="add-fav-count">{localCart.length}</span>
                    }
                  </Link>
                  {/* <span className="card-notifi">5</span> */}
                </div>
                </div>
              </div>
            </div>

            <div className="header-search-moblie">
              <div className="search-frame-head">
                <Form onSubmit={searchProduct}>
                  <InputGroup>
                    <Form.Control
                      value={search}
                      onChange={(e) => getSuggetions(e.target.value, category)}
                      type="text"
                      placeholder={t("what_do_you_need")}
                      onBlur={() => {
                        setTimeout(() => {
                          setShowSuggetions(false);
                        }, 500);
                      }}
                    />
                    <InputGroup.Text onClick={() => searchEmpty()}>
                      {!search ? (
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="search">
                            <path
                              id="Vector"
                              d="M14.0835 14.5834L17.7502 18.25"
                              stroke="#01151F"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M8.58333 16.4167C12.6334 16.4167 15.9167 13.1334 15.9167 9.08333C15.9167 5.03324 12.6334 1.75 8.58333 1.75C4.53324 1.75 1.25 5.03324 1.25 9.08333C1.25 13.1334 4.53324 16.4167 8.58333 16.4167Z"
                              stroke="#01151F"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#01151F"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                        </svg>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Form>
                {showSuggetions ? (
                  <SearchSuggetion setShowSuggetions={setShowSuggetions} />
                ) : null}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="header-sub-scroll-bar">
        <Container>
          <div className="nav-scroll-slide">
            <>
              {categoriesList.loading ? (
                <Slider {...settings}>
                  {[...Array(5)].map((i) => (
                    <Skeleton height={20} width={140} key={i} />
                  ))}
                </Slider>
              ) : Object.keys(categoriesList.data).length > 0 &&
                categoriesList.data.categories.length > 0 ? (
                <Slider {...settings}>
                  {categoriesList.data.categories.map((category, i) => (
                    <div key={i}>
                      <Link
                      className={`${searchParams.get("category") == category.category_unique_id ? 
                      "category-active" :
                       "category-default"}`}
                        to={`/products?category=${category.category_unique_id}`}
                        key={i}
                      >
                        {category.name}
                      </Link>
                    </div>
                  ))}
                </Slider>
              ) : null}
            </>
          </div>
        </Container>
      </div>
      {login ? <AuthModal closeLoginModal={closeLoginModal} /> : null}
    </>
  );
};

export default Header;
