import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { specialProductListStart } from "../../store/slices/ProductSlice";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { useTranslation } from "react-multi-lang";

const SpecialProducts = () => {
  const dispatch = useDispatch();
  const t = useTranslation("landing_page");

  const specialProductList = useSelector(
    (state) => state.product.specialProductList
  );

  useEffect(() => {
    dispatch(
      specialProductListStart({
        order_by: "newest",
        skip: 0,
        take: 12,
      })
    );
  }, []);

  return (
    <div className="special-product-frame">
      <Container>
        <div className="new-section-title">
          <h2>{t("special_products")}</h2>
          <p>{t("located_in_the_heart_of_the_jeweler hub")} </p>
        </div>
        <div className="special-product-main-frame">
          {specialProductList.loading ? (
            <React.Fragment>
              {[...Array(12)].map((val, i) => (
                <Skeleton height={250} width={250} key={i} />
              ))}
            </React.Fragment>
          ) : specialProductList.data.products &&
            specialProductList.data.products.length > 0 ? (
            <React.Fragment>
              {specialProductList.data.products.map((product, i) => (
                <Link to={`/product/${product.product_unique_id}`}>
                  <div className="popular-categories-card">
                    <div className="popular-card-img">
                      <CustomLazyLoad src={product.product_attribute.file} />
                    </div>
                    <div className="popular-card-info">
                      <h5>{product.name}</h5>
                      <span>{product.product_attribute.discount_tag}</span>
                    </div>
                  </div>
                </Link>
                // <Link to="#">
                //   <div className="trending-product-card">
                //     <div className="product-card-img-overlay">
                //       <div className="product-card-img">
                //         <CustomLazyLoad
                //           src={product.product_attribute.file}
                //         />
                //       </div>
                //       <div className="product-overlay">
                //         <div className="product-overlay-left">
                //           <Image
                //             src={
                //               window.location.origin +
                //               "/assets/img/new/discount-rib.png"
                //             }
                //           />
                //         </div>
                //         <div className="product-overlay-right">
                //           <div className="card-fav-icon">
                //             <svg
                //               xmlns="http://www.w3.org/2000/svg"
                //               width="18"
                //               height="18"
                //               viewBox="0 0 18 18"
                //               fill="none"
                //             >
                //               <path
                //                 d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                //                 stroke="#fff"
                //                 stroke-linecap="round"
                //                 stroke-linejoin="round"
                //               />
                //             </svg>
                //           </div>
                //           <div className="card-cart-icon">
                //             <svg
                //               xmlns="http://www.w3.org/2000/svg"
                //               width="18"
                //               height="18"
                //               viewBox="0 0 18 18"
                //               fill="none"
                //             >
                //               <path
                //                 d="M5.625 6.1875V5.0625C5.625 4.16739 5.98058 3.30895 6.61351 2.67601C7.24645 2.04308 8.10489 1.6875 9 1.6875C9.89511 1.6875 10.7536 2.04308 11.3865 2.67601C12.0194 3.30895 12.375 4.16739 12.375 5.0625V6.1875M2.8125 6.1875C2.66332 6.1875 2.52024 6.24676 2.41475 6.35225C2.30926 6.45774 2.25 6.60082 2.25 6.75V14.3438C2.25 15.4069 3.15563 16.3125 4.21875 16.3125H13.7812C14.8444 16.3125 15.75 15.4508 15.75 14.3877V6.75C15.75 6.60082 15.6907 6.45774 15.5852 6.35225C15.4798 6.24676 15.3367 6.1875 15.1875 6.1875H2.8125Z"
                //                 stroke="#fff"
                //                 stroke-linecap="round"
                //                 stroke-linejoin="round"
                //               />
                //               <path
                //                 d="M5.625 7.875V8.4375C5.625 9.33261 5.98058 10.1911 6.61351 10.824C7.24645 11.4569 8.10489 11.8125 9 11.8125C9.89511 11.8125 10.7536 11.4569 11.3865 10.824C12.0194 10.1911 12.375 9.33261 12.375 8.4375V7.875"
                //                 stroke="#fff"
                //                 stroke-linecap="round"
                //                 stroke-linejoin="round"
                //               />
                //             </svg>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //     <div className="product-card-info">
                //       <h5>{product.name}</h5>
                //       <p>{product.description_formatted}</p>
                //       <div className="product-rate">
                //         <span className="dis-rate">
                //           {
                //             product.product_attribute
                //               .original_price_formatted
                //           }
                //         </span>
                //         <span>
                //           {
                //             product.product_attribute
                //               .selling_price_formatted
                //           }
                //         </span>
                //       </div>
                //     </div>
                //   </div>
                // </Link>
              ))}
            </React.Fragment>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default SpecialProducts;
