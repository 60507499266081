import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Cart.css";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { cartListStart, guestCartListStart, guestCartListSuccess, localCartList } from "../../store/slices/CartSlice";
import UserCartDetails from "./UserCartDetails";
import Skeleton from "react-loading-skeleton";
import GuestCartDetails from "./GuestCartDetails";

const CartIndex = () => {
  const t = useTranslation("cart");
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const cartList = useSelector(state => state.cart.cartList);
  const guestCartList = useSelector(state => state.cart.guestCartList);

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      if (!profile.loading && Object.keys(profile.data).length > 0 && firstRender) {
        dispatch(cartListStart());
        setFirstRender(false);
      }
    } else {
      const local = localStorage.getItem("cart");
      dispatch(localCartList(local && local.length > 0 ? JSON.parse(local) : []))
      if (local && JSON.parse(local).length > 0)
        dispatch(guestCartListStart({
          carts: local,
        }));
      else
        dispatch(guestCartListSuccess({}));
    }
  }, [profile]);

  return (
    <>
      <div className="cart-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                <h1>{t("heading")}</h1>
              </div>
            </Col>
          </Row>
          {profile.buttonDisable ?
            <div className="cart-box">
              <Row className="gy-4 justify-content-center">
                <Col md={12} xl={8} lg={8}>
                  <Skeleton height={250} />
                </Col>
                <Col md={6} xl={4} lg={4}>
                  <Skeleton height={250} />
                </Col>
              </Row>
            </div>
            : Object.keys(profile.data).length > 0 ?
              cartList.loading ?
                <div className="cart-box">
                  <Row className="gy-4 justify-content-center">
                    <Col md={12} xl={8} lg={8}>
                      <Skeleton height={250} />
                    </Col>
                    <Col md={6} xl={4} lg={4}>
                      <Skeleton height={250} />
                    </Col>
                  </Row>
                </div>
                : <UserCartDetails />
              : guestCartList.loading ?
                <div className="cart-box">
                  <Row className="gy-4 justify-content-center">
                    <Col md={12} xl={8} lg={8}>
                      <Skeleton height={250} />
                    </Col>
                    <Col md={6} xl={4} lg={4}>
                      <Skeleton height={250} />
                    </Col>
                  </Row>
                </div>
                : <GuestCartDetails />
          }
        </Container>
      </div>
    </>
  );
};

export default CartIndex;
