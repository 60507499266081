import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Container,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchCategoriesListStart } from "../../store/slices/HomePageSlice";
import { useDispatch, useSelector } from "react-redux";
import "./LandingStyle.css";
import Skeleton from "react-loading-skeleton";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";

const Footer = () => {

  const dispatch = useDispatch();
  const t = useTranslation("footer");
  const categoriesList = useSelector((state) => state.homepage.categoriesList);


  useEffect(() => {
    dispatch(fetchCategoriesListStart());
  }, []);

  return (
    <>
      <div className="shipping-flow-frame">
        <Container>
          <Row>
            <Col lg={4} className="resp-marg-btm-xs">
              <div className="shipping-flow-card">
                <div className="shipping-flow-img">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.4249 8.65734H17.4829V4.73462H3.75335C2.6746 4.73462 1.79199 5.61723 1.79199 6.69598V17.4835H3.75335C3.75335 19.1114 5.06747 20.4255 6.69539 20.4255C8.32332 20.4255 9.63744 19.1114 9.63744 17.4835H15.5215C15.5215 19.1114 16.8356 20.4255 18.4636 20.4255C20.0915 20.4255 21.4056 19.1114 21.4056 17.4835H23.367V12.5801L20.4249 8.65734ZM6.69539 18.9545C5.88143 18.9545 5.22437 18.2974 5.22437 17.4835C5.22437 16.6695 5.88143 16.0124 6.69539 16.0124C7.50936 16.0124 8.16642 16.6695 8.16642 17.4835C8.16642 18.2974 7.50936 18.9545 6.69539 18.9545ZM19.9346 10.1284L21.8567 12.5801H17.4829V10.1284H19.9346ZM18.4636 18.9545C17.6496 18.9545 16.9925 18.2974 16.9925 17.4835C16.9925 16.6695 17.6496 16.0124 18.4636 16.0124C19.2775 16.0124 19.9346 16.6695 19.9346 17.4835C19.9346 18.2974 19.2775 18.9545 18.4636 18.9545Z"
                      fill="#3781D8"
                    />
                  </svg>
                </div>
                <div className="shipping-flow-info">
                  <h4>{t("fast_delivery")}</h4>
                  <p>
                    {t("we_know_the_time_importance_para")} {" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} className="resp-marg-btm-xs">
              <div className="shipping-flow-card">
                <div className="shipping-flow-img">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="none"
                    viewBox="0 0 23 21"
                  >
                    <path
                      fill="#FFB800"
                      d="M11.5 0l3.38 6.848 7.557 1.098-5.468 5.33 1.29 7.528L11.5 17.25l-6.76 3.554 1.291-7.527-5.468-5.33L8.12 6.847 11.5 0z"
                    ></path>
                  </svg>
                </div>
                <div className="shipping-flow-info">
                  <h4>{t("five_star_quality")}</h4>
                  <p>
                    {t("with_a_sleek_and_elegant_para")} {" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="shipping-flow-card">
                <div className="shipping-flow-img">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M18.3333 0H11.4061C10.9478 0 10.3073 0.27875 9.98356 0.61846L0.486361 10.5905C-0.16212 11.2706 -0.16212 12.3857 0.486361 13.065L7.55779 20.4898C8.20481 21.1699 9.26579 21.1699 9.91378 20.489L19.411 10.5185C19.7345 10.1789 20 9.5053 20 9.02517V1.75007C20 0.787751 19.2497 0 18.3333 0ZM14.9997 7.00003C14.0792 7.00003 13.333 6.21567 13.333 5.24996C13.333 4.28267 14.0792 3.49989 14.9997 3.49989C15.9202 3.49989 16.6667 4.28267 16.6667 5.24996C16.6668 6.21567 15.9202 7.00003 14.9997 7.00003Z"
                      fill="#54BA3A"
                    />
                  </svg>
                </div>
                <div className="shipping-flow-info">
                  <h4>{t("reasonable_prices")}</h4>
                  <p>
                    {t("we_understand_the_importance_para")}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="new-footer-sec">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="footer-quick-link">
                <h5>{t("quick_links")}</h5>
                <ul className="list-unstyled text-capitalize">
                  {configuration.get("configData.footer_section_1").map((item, i) => (
                    <li><a target="_blank" rel="noreferrer" href={`/page/${item.static_page_unique_id}`}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col lg={3}>
              <div className="footer-info-link">
                <h5>{t("information")}</h5>
                <ul className="list-unstyled text-capitalize">
                  {configuration.get("configData.footer_section_2").map((item, i) => (
                    <li><a target="_blank" rel="noreferrer" href={`/page/${item.static_page_unique_id}`}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col lg={3}>
              <div className="footer-app-link">
                <h5>{t("download_app")}</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href={configuration.get("configData.appstore_user")} target="_blank" rel="noreferrer">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/applestore.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a href={configuration.get("configData.playstore_user")} target="_blank" rel="noreferrer">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/appstore.svg"
                        }
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            {configuration.get("configData.facebook_link") ||
              configuration.get("configData.telegram_link") ||
              configuration.get("configData.instagram_link") ||
              configuration.get("configData.twitter_link") ||
              configuration.get("configData.linkedin_link")
              ?
              <Col lg={3}>
                < div className="footer-media-link">
                  <h5>{t("follow_us")}</h5>
                  <ul className="list-unstyled">
                    {configuration.get("configData.facebook_link") ?
                      <li>
                        <a href={configuration.get("configData.facebook_link")}
                          target="_blank" rel="noreferrer">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/new/social-media/facebook.svg"
                            }
                          />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.instagram_link") ?
                      <li>
                        <a href={configuration.get("configData.instagram_link")} target="_blank" rel="noreferrer">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/new/social-media/instagram.svg"
                            }
                          />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.linkedin_link") ?
                      <li>
                        <a href={configuration.get("configData.linkedin_link")} target="_blank" rel="noreferrer">                      <Image
                          src={
                            window.location.origin +
                            "/assets/img/new/social-media/linkedin.svg"
                          }
                        />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.telegram_link") ?
                      <li>
                        <a href={configuration.get("configData.telegram_link")} target="_blank" rel="noreferrer">                      <Image
                          src={
                            window.location.origin +
                            "/assets/img/new/social-media/telegram.svg"
                          }
                        />
                        </a>
                      </li>
                      :
                      null
                    }
                    {configuration.get("configData.twitter_link") ?
                      <li>
                        <a href={configuration.get("configData.twitter_link")} target="_blank" rel="noreferrer">                      <Image
                          src={
                            window.location.origin +
                            "/assets/img/new/social-media/twitter.svg"
                          }
                        />
                        </a>
                      </li>
                      :
                      null
                    }
                  </ul>
                </div>
              </Col>
              :
              null
            }
            <Col lg={12}>
              <div className="footer-accept-link">
                <h5>{t("we_accept")}</h5>
                <ul className="list-unstyled footer-accept-link-list">
                  <li>
                    <Link>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/we-accept/visaa.svg"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/we-accept/visa.svg"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/we-accept/adhi.svg"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/we-accept/disover.svg"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/we-accept/rupay.svg"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/we-accept/net-banking.svg"
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/new/we-accept/payPal.svg"
                        }
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={12}>
              <div className="popular-link">
                <h5>{t("popular_searches")}</h5>
                <ul className="list-unstyled">
                  {categoriesList.loading ? (
                    <React.Fragment>
                      {[...Array(20)].map((i) => (
                        <Skeleton height={20} width={140} key={i} />
                      ))}
                    </React.Fragment>
                  ) : Object.keys(categoriesList.data).length > 0 &&
                    categoriesList.data.categories.length > 0 ? (
                    <React.Fragment>
                      {categoriesList.data.categories.map((category, i) => (
                        <li key={i}>
                          <Link
                            to={`/products?category=${category.category_unique_id}`}
                            key={i}
                          >
                            {category.name}
                          </Link>
                        </li>
                      ))}
                    </React.Fragment>
                  ) : null}
                </ul>
              </div>
            </Col>
            <Col lg={12}>
              <div className="footer-contact">
                <p>
                  {t("for_any_concern")}, <Link to="/contact-us"> {t("contact")}</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer >
      <div className="foot-last">
        <p>{configuration.get("configData.copyright_content")}</p>
      </div>
    </>
  );
};

export default Footer;
