import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Profile.css";
import { useTranslation } from "react-multi-lang";

const ProfileSidebar = (props) => {

  const location = useLocation();
  const t = useTranslation("Profile Details");

  return (
    <>
      <div className="sidebar-box">
        <ul className="list-unstyled sidebar-list-sec">
          <li className={location.pathname === "/profile" ? "active" : ""}>
            <Link to="/profile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M19 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V5a5.006 5.006 0 00-5-5zM7 22v-1a5 5 0 0110 0v1zm15-3a3 3 0 01-3 3v-1a7 7 0 00-14 0v1a3 3 0 01-3-3V5a3 3 0 013-3h14a3 3 0 013 3z"></path>
                <path d="M12 4a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
              </svg>
              <span>{t("my_profile")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/order-details" ? "active" : ""}>
            <Link to="/order-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M7 0H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM7 13H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM22.293 19.049L20 21.339V2.633l2.293 2.29a1 1 0 101.414-1.415L21.12.925a3 3 0 00-4.24 0l-2.587 2.583a1 1 0 101.414 1.415L18 2.633v18.706l-2.293-2.29a1 1 0 10-1.414 1.415l2.587 2.583a3 3 0 004.24 0l2.587-2.583a1 1 0 10-1.414-1.415z"></path>
              </svg>
              <span>{t("my_orders")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/orders/cancel" ? "active" : ""}>
            <Link to="/orders/cancel">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M19 3h-6.53c-.15 0-.31-.04-.45-.11L8.86 1.31C8.45 1.1 7.98.99 7.52.99H4.99A5.015 5.015 0 000 6v12c0 2.76 2.24 5 5 5h7c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.65 0-3-1.35-3-3V9h20v3c0 .55.45 1 1 1s1-.45 1-1V8c0-2.76-2.24-5-5-5zM2 6c0-1.65 1.35-3 3-3h2.53c.15 0 .31.04.45.11l3.16 1.58c.41.21.88.32 1.34.32h6.53c1.3 0 2.42.84 2.83 2H2v-1zm21.71 10.71l-2.79 2.81 2.79 2.78A.996.996 0 0123 24c-.26 0-.51-.1-.71-.29l-2.79-2.78-2.76 2.77c-.2.2-.45.29-.71.29s-.51-.1-.71-.29a.996.996 0 010-1.41l2.76-2.77-2.79-2.78a.996.996 0 111.41-1.41l2.79 2.78 2.79-2.81a.996.996 0 111.41 1.41z"></path>
              </svg>
              <span>{t("cancel_order")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/order-transactions" ? "active" : ""}>
            <Link to="/order-transactions">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M17 24H7c-2.757 0-5-2.243-5-5V5c0-2.757 2.243-5 5-5h10c2.757 0 5 2.243 5 5v14c0 2.757-2.243 5-5 5zM7 2C5.346 2 4 3.346 4 5v14c0 1.654 1.346 3 3 3h10c1.654 0 3-1.346 3-3V5c0-1.654-1.346-3-3-3H7zm11 4a1 1 0 00-1-1h-4a1 1 0 000 2h4a1 1 0 001-1zm0 6a1 1 0 00-1-1h-4a1 1 0 000 2h4a1 1 0 001-1zm0 6a1 1 0 00-1-1h-4a1 1 0 000 2h4a1 1 0 001-1zM10 7V5a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1zm0 6v-2a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1zm0 6v-2a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1z"></path>
              </svg>
              <span>{t("order_transactions")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/my-addresses" ? "active" : ""}>
            <Link to="/my-addresses">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M23.121 9.069l-7.585-7.586a5.008 5.008 0 00-7.072 0L.879 9.069A2.978 2.978 0 000 11.19v9.817a3 3 0 003 3h18a3 3 0 003-3V11.19a2.978 2.978 0 00-.879-2.121zM15 22.007H9v-3.934a3 3 0 016 0zm7-1a1 1 0 01-1 1h-4v-3.934a5 5 0 00-10 0v3.934H3a1 1 0 01-1-1V11.19a1.008 1.008 0 01.293-.707L9.878 2.9a3.008 3.008 0 014.244 0l7.585 7.586a1.008 1.008 0 01.293.704z"></path>
              </svg>
              <span>{t("my_addresses")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/wallet" ? "active" : ""}>
            <Link to="/wallet">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M21 6H5a3.012 3.012 0 01-2.235-.999A2.995 2.995 0 015 4h18a1 1 0 100-2H5a5 5 0 00-5 5v10a5 5 0 005 5h16a3 3 0 003-3V9a3 3 0 00-3-3zm1 13c0 .551-.448 1-1 1H5c-1.654 0-3-1.346-3-3V6.998A5.012 5.012 0 005 8h16c.552 0 1 .449 1 1v10zm-2-5a1 1 0 11-2 0 1 1 0 012 0z"></path>
              </svg>
              <span>{t("wallet")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/withdraw" || location.pathname === "/withdraw-money" ? "active" : ""}>
            <Link to="/withdraw">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M18 7h.75a.75.75 0 00-.75-.75zm0 4h-.75zM6 7v-.75a.75.75 0 00-.75.75zm11.25 0v4h1.5V7zm0 4v8h1.5v-8zM16 20.25H8v1.5h8zM6.75 19V7h-1.5v12zM6 7.75h12v-1.5H6zm0-4h12v-1.5H6zM2.75 7A3.25 3.25 0 016 3.75v-1.5A4.75 4.75 0 001.25 7zM6 10.25A3.25 3.25 0 012.75 7h-1.5A4.75 4.75 0 006 11.75zM21.25 7A3.25 3.25 0 0118 10.25v1.5A4.75 4.75 0 0022.75 7zm1.5 0A4.75 4.75 0 0018 2.25v1.5A3.25 3.25 0 0121.25 7zm-5.5 12c0 .69-.56 1.25-1.25 1.25v1.5A2.75 2.75 0 0018.75 19zM8 20.25c-.69 0-1.25-.56-1.25-1.25h-1.5A2.75 2.75 0 008 21.75zM14.53 15.53a.75.75 0 10-1.06-1.06zm-3.237.763l.53-.53zm-.763-1.823a.75.75 0 10-1.06 1.06zM12.75 11a.75.75 0 00-1.5 0zm-1.5 5a.75.75 0 001.5 0zm2.22-1.53l-1.293 1.293 1.06 1.06 1.293-1.293zm-1.647 1.293L10.53 14.47l-1.06 1.06 1.293 1.293zm.354 0a.25.25 0 01-.354 0l-1.06 1.06a1.75 1.75 0 002.474 0zM11.25 11v5h1.5v-5z"></path>
              </svg>
              <span>{t("withdraw")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/two-step-auth" ? "active" : ""}>
            <Link to="/two-step-auth">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M18.581 2.14L12.316.051a1 1 0 00-.632 0L5.419 2.14A4.993 4.993 0 002 6.883V12c0 7.563 9.2 11.74 9.594 11.914a1 1 0 00.812 0C12.8 23.74 22 19.563 22 12V6.883a4.993 4.993 0 00-3.419-4.743zM20 12c0 5.455-6.319 9.033-8 9.889-1.683-.853-8-4.42-8-9.889V6.883a3 3 0 012.052-2.846L12 2.054l5.948 1.983A3 3 0 0120 6.883z"></path>
                <path d="M15.3 8.3l-4.188 4.2-2.244-2.34a1 1 0 10-1.441 1.386l2.306 2.4a1.872 1.872 0 001.345.6h.033a1.873 1.873 0 001.335-.553l4.272-4.272A1 1 0 0015.3 8.3z"></path>
              </svg>
              <span>{t("two_step_authentication")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/notification-preference" ? "active" : ""}>
            <Link to="/notification-preference">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M22.555 13.662l-1.9-6.836A9.321 9.321 0 002.576 7.3l-1.471 6.615A5 5 0 005.986 20H7.1a5 5 0 009.8 0h.838a5 5 0 004.818-6.338zM12 22a3 3 0 01-2.816-2h5.632A3 3 0 0112 22zm8.126-5.185A2.977 2.977 0 0117.737 18H5.986a3 3 0 01-2.928-3.651l1.47-6.616a7.321 7.321 0 0114.2-.372l1.9 6.836a2.977 2.977 0 01-.502 2.618z"></path>
              </svg>
              <span>{t("notification_preferences")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/change-password" ? "active" : ""}>
            <Link to="/change-password">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M19 8.424V7A7 7 0 005 7v1.424A5 5 0 002 13v6a5.006 5.006 0 005 5h10a5.006 5.006 0 005-5v-6a5 5 0 00-3-4.576zM7 7a5 5 0 0110 0v1H7zm13 12a3 3 0 01-3 3H7a3 3 0 01-3-3v-6a3 3 0 013-3h10a3 3 0 013 3z"></path>
                <path d="M12 14a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z"></path>
              </svg>
              <span>{t("change_password")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/delete-account" ? "active" : ""}>
            <Link to="/delete-account">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
              </svg>
              <span>{t("delete_account")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProfileSidebar;
