import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Form, Button, InputGroup, Tab } from "react-bootstrap";
import ProfileSidebar from "./ProfileSidebar";
import Select from "react-select";
import {
  fetchdeliveryAddressesListStart,
  deliveryAddressesSaveStart,
} from "../../store/slices/DeliverySlice";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import SingleAddressCard from "./SingleAddressCard";
import AddressSubmitForm from "./AddressSubmitForm";

const ManageAddress = (props) => {

  const dispatch = useDispatch();

  const t = useTranslation("manage_address");

  const [editForm, setEditForm] = useState({});
  const [activeTab, setActiveTab] = useState("saved_billing");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [skipRender, setSkipRender] = useState(true);

  const changeActiveTab = (tabName) => {
    setActiveTab(tabName);
    setEditForm({});
  }

  const editAddress = (address) => {
    setEditForm(address);
    setActiveTab("new_billing");
  };

  const deliveryAddressesSave = useSelector(state => state.delivery.deliveryAddressesSave);
  const deliveryAddressesList = useSelector(state => state.delivery.deliveryAddressesList);

  useEffect(() => {
    dispatch(fetchdeliveryAddressesListStart());
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !deliveryAddressesList.loading &&
      Object.keys(deliveryAddressesList.data).length > 0
    ) {
      if (
        deliveryAddressesList.data.delivery_addresses &&
        deliveryAddressesList.data.delivery_addresses.length > 0
      ) {
        deliveryAddressesList.data.delivery_addresses.map((add, i) => {
          if (add.is_default === 1) setSelectedAddress(add.delivery_address_id);
        });
      } else {
        setSelectedAddress(null);
      }
      setSkipRender(false);
    }
  }, [props.deliveryAddressesList]);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                <div className="profile-details-sec">
                  <div className="profile-header-sec">
                    <h3 className="mb-3">{t("heading")}</h3>
                  </div>
                </div>
                <div className="manage-address-tab-sec">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              className={`${activeTab === "saved_billing" ? "active-tab" : "default-tab"}`}
                              onClick={() => changeActiveTab("saved_billing")}
                            >{t("saved_address")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              className={`${activeTab === "new_billing" ? "active-tab" : "default-tab"}`}
                              onClick={() => setActiveTab("new_billing")}
                            >{t("new_address")}</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        {activeTab === "saved_billing" && (
                          <div className="manage-address-box">
                            {deliveryAddressesList.loading ?
                              <Skeleton count={2} height={100} /> :
                              Object.keys(deliveryAddressesList.data) &&
                                deliveryAddressesList.data
                                  .delivery_addresses.length > 0 ?
                                deliveryAddressesList.data.delivery_addresses.map(
                                  (address, i) => (
                                    <SingleAddressCard
                                      key={i}
                                      address={address}
                                      setSelectedAddress={
                                        setSelectedAddress
                                      }
                                      editAddress={editAddress}
                                    />
                                  )
                                )
                                :
                                <NoDataFound />
                            }
                          </div>
                        )}
                        {activeTab === "new_billing" && (
                          < AddressSubmitForm
                            editAddress={editForm}
                            setActiveTab={changeActiveTab}
                          />
                        )}
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ManageAddress;
