import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import configuration from "react-global-configuration";
import { useTranslation, withTranslation } from "react-multi-lang";

const FooterIndex = (props) => {

  const t = useTranslation();

  return (
    <>
      <footer className="footer-sec">
        <div className="footer-left-sec">
          <div className="footer-logo-sec">
            <Image
              className="footer-logo"
              src={configuration.get("configData.dark_logo")}
              alt={configuration.get("configData.site_name")}
            />
          </div>
          <div className="footer-desc">
            <p>{configuration.get("configData.footer_description")}</p>
          </div>
        </div>
        <div className="footer-right-sec">
          <div className="footer-right-box">

            {configuration.get("configData.facebook_link") ||
              configuration.get("configData.pinterest_link") ||
              configuration.get("configData.instagram_link")
              ?
              <div className="footer-right-card">
                <h3>{t("footer.heading.follow_us")}</h3>
                <ul className="list-unstyled social-link-sec">
                  {configuration.get("configData.facebook_link") ? (
                    <li>
                      <Link to={configuration.get("configData.facebook_link")}
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                        <span>{t("footer.social_links.facebook")}</span>
                      </Link>
                    </li>
                  ) :
                    null}
                  <li>
                    {configuration.get("configData.pinterest_link") ? (
                      <Link to={configuration.get("configData.pinterest_link")}
                        target="_blank"
                      >
                        <i className="fab fa-pinterest"></i>
                        <span>
                          {t("footer.social_links.pinterest")}
                        </span>
                      </Link>
                    ) :
                      null}
                  </li>
                  {configuration.get("configData.instagram_link") ?
                    <li>
                      <Link to={configuration.get("configData.instagram_link")}>
                        <i className="fab fa-instagram"></i>
                        <span>
                          {t("footer.social_links.instagram")}
                        </span>
                      </Link>
                    </li>
                    :
                    null
                  }
                </ul>
              </div>
              :
              null
            }
            {configuration.get("configData.footer_section_1") &&
              configuration.get("configData.footer_section_1").length > 0 ?
              <div className="footer-right-card">
                <h3>{t("footer.heading.information")}</h3>
                <ul className="list-unstyled footer-link-sec">
                  <ul className="list-unstyled footer-link-sec">
                    {configuration.get("configData.footer_section_1").map((link, i) =>
                      <li className="static-txt" key={i}>
                        <Link to={`/page/${link.static_page_unique_id}`}>
                          {link.title}
                        </Link>
                      </li>
                    )}
                  </ul>
                </ul>
              </div>
              :
              null
            }
            {configuration.get("configData.footer_section_2") &&
              configuration.get("configData.footer_section_2").length > 0 ?
              <div className="footer-right-card">
                <h3>{t("footer.heading.help")}</h3>
                <ul className="list-unstyled footer-link-sec">
                  {configuration.get("configData.footer_section_2").map((link, i) =>
                    <li className="static-txt" key={i}>
                      <Link to={`/page/${link.static_page_unique_id}`}>
                        {link.title}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              :
              null
            }
            {configuration.get("configData.footer_section_3") &&
              configuration.get("configData.footer_section_3").length > 0 ?
              <div className="footer-right-card">
                <h3>{t("footer.heading.support")}</h3>
                <ul className="list-unstyled footer-link-sec">
                  {configuration.get("configData.footer_section_3").map((link, i) =>
                    <li className="static-txt" key={i}>
                      <Link target="_blank" to={`/page/${link.static_page_unique_id}`}>
                        {link.title}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              :
              null
            }
            {configuration.get("configData.contact_address") ||
              configuration.get("configData.contact_email") ||
              configuration.get("configData.contact_mobile") ?
              <div className="footer-right-card">
                <h3>{t("contact_us")}</h3>
                <ul className="list-unstyled contact-info">
                  {configuration.get("configData.contact_address") ? (
                    <li className="contact-address">
                      <i className="fas fa-home"></i>
                      <span>
                        {configuration.get("configData.contact_address")}
                      </span>
                    </li>
                  )
                    :
                    null
                  }
                  {configuration.get("configData.contact_mobile") ? (
                    <li>
                      <Link to={`tel:${configuration.get(
                        "configData.contact_mobile"
                      )}`} className="footer-call">
                        <i className="fas fa-phone-alt"></i>
                        <span>
                          {configuration.get("configData.contact_mobile")}
                        </span>
                      </Link>
                    </li>
                  ) :
                    null
                  }
                  {configuration.get("configData.contact_email") ?
                    <li>
                      <Link to={`mailto:${configuration.get(
                        "configData.contact_email"
                      )}`} className="footer-mail">
                        <i className="fas fa-envelope"></i>
                        <span>
                          {configuration.get("configData.contact_email")}
                        </span>
                      </Link>
                    </li>
                    :
                    null
                  }
                </ul>
              </div>
              :
              null
            }
          </div>
          <div className="footer-copyrights-box">
            <p> {configuration.get("configData.copyright_content")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default withTranslation(FooterIndex);
