import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col, Form, Button, InputGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchdeliveryAddressesListStart } from "../../store/slices/DeliverySlice";
import AddressSubmitForm from "../Profile/AddressSubmitForm";
import "./Cart.css";

const CheckOutAddressModal = (props) => {
  const dispatch = useDispatch();
  const deliveryAddressesSave = useSelector(state => state.delivery.deliveryAddressesSave);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !deliveryAddressesSave.loading &&
      Object.keys(deliveryAddressesSave.data).length > 0
    ) {
      dispatch(fetchdeliveryAddressesListStart());
      props.closeCheckOutAddressModal();
    }
    setSkipRender(false);
  }, [deliveryAddressesSave]);

  return (
    <>
      <Modal
        className="modal-dialog-center checkout-address-modal"
        size="lg"
        centered
        show={true}
        onHide={props.closeCheckOutAddressModal}
      >
        <Button className="modal-close" onClick={() => props.closeCheckOutAddressModal()}>
          <Image
            className="close-icon"
            src={
              window.location.origin + "/assets/img/modal-close.svg"
            }
          />
        </Button>
        <Modal.Body>
          <AddressSubmitForm editAddress={{}} isModal={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CheckOutAddressModal;