import { Link } from "react-router-dom";
import React, { useState, useCallback, useEffect } from 'react';
import { Form, Button, Image, Modal } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
	reviewsSaveStart,
	reviewsSaveFilesStart,
	reviewsDeleteFilesStart,
} from "../../store/slices/ReviewSlice";

const WriteReviewModal = (props) => {

	const [skipRender, setSkipRender] = useState(true);
	const [requiredMsg, setRequiredMsg] = useState(false);
	const t = useTranslation("write_review_modal");
	const dispatch = useDispatch();

	const reviewsSave = useSelector(state => state.reviews.reviewsSave);
	const reviewsSaveFiles = useSelector(state => state.reviews.reviewsSaveFiles);
	const reviewsDeleteFiles = useSelector(state => state.reviews.reviewsDeleteFiles);

	const [reviewFiles, setReviewFiles] = useState([]);

	const [reviewData, setReviewData] = useState({
		image: "",
		star: 0,
		review: "",
		product_id: props.singleProduct.product_id,
		review_file_ids: [],
	});

	const saveReview = e => {
		e.preventDefault();
		if (reviewData.star > 0) {
			dispatch(reviewsSaveStart(reviewData));
		} else {
			setRequiredMsg(true);
		}
	}
	const ratingChanged = (newRating) => {
		setReviewData({
			...reviewData,
			star: newRating
		});
		setRequiredMsg(false);
	};

	useEffect(() => {
		if (!skipRender && !reviewsSaveFiles.loading &&
			Object.keys(reviewsSaveFiles.data).length > 0) {
			let review_file_ids = [];
			review_file_ids.push(reviewsSaveFiles.data.review_file_id);
			setReviewFiles([
				...reviewFiles,
				reviewsSaveFiles.data
			])
			setReviewData({
				...reviewData,
				review_file_ids: JSON.stringify(review_file_ids),
			})
		}
		setSkipRender(false)
	}, [reviewsSaveFiles]);

  const deleteObject = (idToDelete) => {
    // Create a copy of the state
    const newData = [...reviewFiles];

    // Find the index of the object with the specified ID
    const indexToDelete = newData.findIndex(item => item.review_file_id == idToDelete);
    dispatch(reviewsDeleteFilesStart({
      	review_file_id: idToDelete
      }))

    // If the object is found, remove it from the copy
    if (indexToDelete !== -1) {
      newData.splice(indexToDelete, 1);

      // Update the state with the modified copy
      setReviewFiles(newData);
    }
  };

	useEffect(() => {
		if (!skipRender && !reviewsDeleteFiles.loading &&
			Object.keys(reviewsDeleteFiles.data).length > 0) {
		
			setReviewData({
				...reviewData,
				review_file_ids: [],
			})
		}
		setSkipRender(false)
	}, [reviewsDeleteFiles]);

	useEffect(() => {
		if (!skipRender && !reviewsSave.loading &&
			Object.keys(reviewsSave.data).length > 0) {
			props.closeWriteReviewModal()
			setReviewFiles([])
			setReviewData({
				image: "",
				star: 0,
				review: "",
				product_id: props.singleProduct.product_id,
				review_file_ids: [],
			})
		}
		setSkipRender(false)
	}, [reviewsSave]);

	const onDrop = useCallback(acceptedFiles => {
		dispatch(reviewsSaveFilesStart({ file: acceptedFiles[0] }));
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			'image/jpeg': [],
			'image/png': [],
			'image/jpg': [],
		},
		onDrop
	})

	return (
		<>
			<Modal
				className="modal-dialog-center write-review-modal"
				size="md"
				centered
				show={props.writeReview}
				onHide={props.closeWriteReviewModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("write_review")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={saveReview} className="write-review-form">
						<ReactStars
							count={5}
							onChange={ratingChanged}
							size={24}
							isHalf={true}
							emptyIcon={<i className="far fa-star"></i>}
							halfIcon={<i className="fas fa-star-half-alt"></i>}
							fullIcon={<i className="fas fa-star"></i>}
							activeColor="#ffd700"
						/>
						{requiredMsg
							? <div className="text-danger">{t("required")}</div>
							: null}
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Label>{t("review")} <span>({t("optional")})</span></Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								name="review"
								onChange={(e) => setReviewData({
									...reviewData,
									review: e.target.value
								})}
							/>
						</Form.Group>
						<div className="write-review-dropzone">
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								{
									isDragActive ?
										<p>{t("drop_files")}</p> :
										<>
											<p className="drop-files">{t("drag_drop_files")}
												<span>{t("upload_product_image_para")}</span>
											</p>
										</>}
							</div>
						</div>
						{reviewFiles.length > 0 && (
							reviewFiles.map((preview) => (
								<div
									className="review-preview"
                  onClick={() => deleteObject(preview.review_file_id)}
									// onClick={() => dispatch(reviewsDeleteFilesStart({
									// 	review_file_id: preview.review_file_id
									// }))}
								>
									<img
										src={preview.file}
										className="review-preview-img"

									/>
									<div className="remove-review-image">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" /></svg>
									</div>
								</div>
							))
						)}
						<div className="write-review-btn-sec">
							<Button
								className="wishlist-btn"
								type="submit"
								disabled={reviewsSave.buttonDisable}
							>
								{reviewsSave.buttonDisable ? t("review_btn.loading") : t("review_btn.text")}

							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default WriteReviewModal;

