import React, { useState, useEffect } from 'react';
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordStart } from '../../store/slices/UserSlice';
import { useTranslation } from 'react-multi-lang';

const ResetPassword = ({ setStep, closeAuthModal }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const forgotPasswordVerification = useSelector(state => state.user.forgotPasswordVerification);
  const resetPassword = useSelector(state => state.user.resetPassword);

  const [skipRender, setSkipRender] = useState(true);
  const [token, setToken] = useState("");
  const [isVisible, setIsVisible] = useState({
    password: false,
    confirm: false,
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("reset_password.new_password.required"))
      .test("len", t("reset_password.new_password.invalid"), (val) => val && val.length >= 6),
    password_confirmation: Yup.string()
      .required(t("reset_password.confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], t("reset_password.confirm_password.invalid")),
      }),
  });

  useEffect(() => {
    if (Object.keys(forgotPasswordVerification.data).length > 0)
      setToken(forgotPasswordVerification.data.token);
    else
      setStep(1);
  }, []);

  const handleSubmit = (values) => {
    dispatch(resetPasswordStart({ ...values, reset_token: token, }))
  }

  useEffect(() => {
    if (!skipRender && !resetPassword.loading && Object.keys(resetPassword.data).length > 0)
      closeAuthModal();
    setSkipRender(false);
  }, [resetPassword]);


  return (
    <>
      {token ?
        <div className="step-3">
          <div className="auth-form">
            <div className="login-box">
              <div className="reset-header-title-sec">
                <h3>{t("reset_password.heading")}</h3>
              </div>
              <Formik
                initialValues={{
                  password: "",
                  password_confirmation: ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <FORM>
                  <div className="login-body-sec">
                    <Form.Group>
                      <Form.Label>{t("reset_password.new_password.label")} *</Form.Label>
                      <InputGroup className="mb-3">
                        <Field
                          type={isVisible.password ? "text" : "password"}
                          name="password"
                          placeholder={t("reset_password.new_password.placeholder")}
                          className="form-control"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <Image
                            className="eye-hidden-icon"
                            src={
                              isVisible.password ?
                                window.location.origin + "/assets/img/auth/eye-show-icon.svg"
                                : window.location.origin + "/assets/img/auth/eye-hide-icon.svg"
                            }
                            onClick={() => setIsVisible({ ...isVisible, password: !isVisible.password })}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="password"
                        className="text-danger text-right"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t("reset_password.confirm_password.label")} *</Form.Label>
                      <InputGroup className="mb-3">
                        <Field
                          type={isVisible.confirm ? "text" : "password"}
                          name="password_confirmation"
                          placeholder={t("reset_password.confirm_password.placeholder")}
                          className="form-control"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <Image
                            className="eye-hidden-icon"
                            src={
                              isVisible.confirm ?
                                window.location.origin + "/assets/img/auth/eye-show-icon.svg"
                                : window.location.origin + "/assets/img/auth/eye-hide-icon.svg"
                            }
                            onClick={() => setIsVisible({ ...isVisible, confirm: !isVisible.confirm })}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="password_confirmation"
                        className="text-danger text-right"
                      />
                    </Form.Group>
                    <div className="auth-btn-sec">
                      <Button
                        type="submit"
                        className="default-btn w-100"
                        disabled={resetPassword.buttonDisable}
                      >
                        {resetPassword.buttonDisable ? t("reset_password.reset_btn.loading") : t("reset_password.reset_btn.text")}
                      </Button>
                    </div>
                  </div>
                </FORM>
              </Formik>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );

}

export default ResetPassword;