import React from 'react';
import { useDispatch } from 'react-redux';
import {
    fetchUserDetailsFailure,
    userLoginFailure,
    userRegisterFailure
} from '../../store/slices/UserSlice';

const useLogout = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(fetchUserDetailsFailure());
        localStorage.removeItem("userId");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("device_unique_id");
    }

    return { logout };

}

export default useLogout;