
import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    reviewsSave: getCommonInitialState(),
    reviewsSaveFiles: getCommonInitialState(),
    reviewsDeleteFiles: getCommonInitialState(),
};

const ReviewSlice = createSlice({
    name: "reviews",
    initialState,
    reducers: {

        // Save review
        reviewsSaveStart: (state) => { state.reviewsSave = getCommonStart() },
        reviewsSaveSuccess: (state, action) => { state.reviewsSave = getCommonSuccess(action.payload) },
        reviewsSaveFailure: (state, action) => { state.reviewsSave = getCommonFailure(action.payload) },

        // Save review files
        reviewsSaveFilesStart: (state) => { state.reviewsSaveFiles = getCommonStart() },
        reviewsSaveFilesSuccess: (state, action) => { state.reviewsSaveFiles = getCommonSuccess(action.payload) },
        reviewsSaveFilesFailure: (state, action) => { state.reviewsSaveFiles = getCommonFailure(action.payload) },

        // Delete review files
        reviewsDeleteFilesStart: (state) => { state.reviewsDeleteFiles = getCommonStart() },
        reviewsDeleteFilesSuccess: (state, action) => { state.reviewsDeleteFiles = getCommonSuccess(action.payload) },
        reviewsDeleteFilesFailure: (state, action) => { state.reviewsDeleteFiles = getCommonFailure(action.payload) },

    }
});

export const {
    reviewsSaveStart,
    reviewsSaveSuccess,
    reviewsSaveFailure,
    reviewsSaveFilesStart,
    reviewsSaveFilesSuccess,
    reviewsSaveFilesFailure,
    reviewsDeleteFilesStart,
    reviewsDeleteFilesSuccess,
    reviewsDeleteFilesFailure,

} = ReviewSlice.actions;

export default ReviewSlice.reducer;