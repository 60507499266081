import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  featuredProductListStart,
  productListStart,
  topProductListStart,
  wishlistSaveStart
} from "../../store/slices/ProductSlice";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import AuthModal from "../Auth/AuthModal";
import { useTranslation } from "react-multi-lang";

const TrendingProducts = () => {
  const [key, setKey] = useState("newarrival");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.product.productList);
  const topProductList = useSelector((state) => state.product.topProductList);
  const featuredProductList = useSelector((state) => state.product.featuredProductList);
  const localCart = useSelector(state => state.cart.localCart);
  const profile = useSelector(state => state.user.profile);
  const [login, setLogin] = useState(false);
  const t = useTranslation("landing_page");

  const closeLoginModal = () => {
    setLogin(false);
  };

  useEffect(() => {
    dispatch(
      productListStart({
        order_by: "newest",
        skip: 0,
        take: 8,
      })
    );
    dispatch(
      topProductListStart({
        order_by: "newest",
        skip: 0,
        take: 8,
      })
    );
    dispatch(
      featuredProductListStart({
        order_by: "newest",
        skip: 0,
        take: 8,
      })
    );
  }, []);

  const wishListToggle = (product_unique_id) => {
    if (Object.keys(profile.data).length > 0) {
      dispatch(
        wishlistSaveStart({
          product_unique_id: product_unique_id,
        })
      );
    } else {
      setLogin(true);
    }
  };

  return (
    <>
      <div className="trending-product-frame">
        <div className="new-section-title">
          <h2>{t("trending_products")}</h2>
        </div>
        <Container>
          <div className="trending-product-tabs-frame">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="newarrival" title={t("new_arrival")}>
                <div className="tending-product-card-frame">
                  {productList.loading ? (
                    <React.Fragment>
                      {[...Array(8)].map((val, i) => (
                        <Skeleton height={250} width={250} key={i} />
                      ))}
                    </React.Fragment>
                  ) : productList.data.products &&
                    productList.data.products.length > 0 ? (
                    <React.Fragment>
                      {productList.data.products.map((product, i) => (
                        <>
                          <div className="trending-product-card">
                            <div className="trending-product-item" onClick={() => navigate(`/product/${product.product_unique_id}`)}>
                              <div className="product-card-img" >
                                <CustomLazyLoad
                                  src={product.product_attribute.file}
                                />
                              </div>
                              <div className="product-card-info">
                                <h5>{product.name}</h5>
                                <p>{product.description_formatted}</p>
                                {product.product_attribute.original_price_formatted !==
                                  product.product_attribute.selling_price_formatted ?
                                  <div className="product-rate">
                                    <span className="dis-rate">
                                      {product.product_attribute.original_price_formatted}</span>
                                    <span>
                                      {product.product_attribute.selling_price_formatted}
                                    </span>
                                  </div>
                                  :
                                  <div className="product-rates">
                                    <span>
                                      {product.product_attribute.selling_price_formatted}
                                    </span>
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="product-overlay">
                              <div className="product-overlay-left">
                                <span>{product.product_attribute.discount_tag ? product.product_attribute.discount_tag : null}</span>
                              </div>
                              <div className="product-overlay-right">
                                <div className={`card-fav-icon ${product.is_wishlisted ? "active" : ""}`} onClick={() => wishListToggle(product.product_unique_id)}>
                                  {product.is_wishlisted ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="#005479"
                                    >
                                      <path
                                        d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                        stroke="#252525"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </React.Fragment>
                  ) : null}
                </div>
              </Tab>
              <Tab eventKey="Featured" title={t("best_seller")}>
                <div className="tending-product-card-frame">
                  {topProductList.loading ? (
                    <React.Fragment>
                      {[...Array(8)].map((val, i) => (
                        <Skeleton height={250} width={250} key={i} />
                      ))}
                    </React.Fragment>
                  ) : topProductList.data.products &&
                    topProductList.data.products.length > 0 ? (
                    <React.Fragment>
                      {topProductList.data.products.map((product, i) => (
                        <>
                          <div className="trending-product-card">
                            <div className="trending-product-item" onClick={() => navigate(`/product/${product.product_unique_id}`)}>
                              <div className="product-card-img" >
                                <CustomLazyLoad
                                  src={product.product_attribute.file}
                                />
                              </div>
                              <div className="product-card-info">
                                <h5>{product.name}</h5>
                                <p>{product.description_formatted}</p>
                                {product.product_attribute.original_price_formatted !==
                                  product.product_attribute.selling_price_formatted ?
                                  <div className="product-rate">
                                    <span className="dis-rate">
                                      {product.product_attribute.original_price_formatted}</span>
                                    <span>
                                      {product.product_attribute.selling_price_formatted}
                                    </span>
                                  </div>
                                  :
                                  <div className="product-rates">
                                    <span>
                                      {product.product_attribute.selling_price_formatted}
                                    </span>
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="product-overlay">
                              <div className="product-overlay-left">
                                <span>{product.product_attribute.discount_tag ? product.product_attribute.discount_tag : null}</span>
                              </div>
                              <div className="product-overlay-right">
                                <div className={`card-fav-icon ${product.is_wishlisted ? "active" : ""}`} onClick={() => wishListToggle(product.product_unique_id)}>
                                  {product.is_wishlisted ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="#005479"
                                    >
                                      <path
                                        d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                        stroke="#252525"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </React.Fragment>
                  ) : null}
                </div>
              </Tab>
              <Tab eventKey="featured" title={t("featured")}>
                <div className="tending-product-card-frame">
                  {featuredProductList.loading ? (
                    <React.Fragment>
                      {[...Array(8)].map((val, i) => (
                        <Skeleton height={250} width={250} key={i} />
                      ))}
                    </React.Fragment>
                  ) : featuredProductList.data.products &&
                    featuredProductList.data.products.length > 0 ? (
                    <React.Fragment>
                      {featuredProductList.data.products.map((product, i) => (
                        <>
                          <div className="trending-product-card">
                            <div className="trending-product-item" onClick={() => navigate(`/product/${product.product_unique_id}`)}>
                              <div className="product-card-img">
                                <CustomLazyLoad
                                  src={product.product_attribute.file}
                                />
                              </div>
                              <div className="product-card-info">
                                <h5>{product.name}</h5>
                                <p>{product.description_formatted}</p>
                                {product.product_attribute.original_price_formatted !==
                                  product.product_attribute.selling_price_formatted ?
                                  <div className="product-rate">
                                    <span className="dis-rate">
                                      {product.product_attribute.original_price_formatted}</span>
                                    <span>
                                      {product.product_attribute.selling_price_formatted}
                                    </span>
                                  </div>
                                  :
                                  <div className="product-rates">
                                    <span>
                                      {product.product_attribute.selling_price_formatted}
                                    </span>
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="product-overlay">
                              <div className="product-overlay-left">
                                <span>{product.product_attribute.discount_tag ? product.product_attribute.discount_tag : null}</span>
                              </div>
                              <div className="product-overlay-right">
                                <div className={`card-fav-icon ${product.is_wishlisted ? "active" : ""}`} onClick={() => wishListToggle(product.product_unique_id)}>
                                  {product.is_wishlisted ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="#005479"
                                    >
                                      <path
                                        d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                        stroke="#252525"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </React.Fragment>
                  ) : null}
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </div>
      {
        login ?
          <AuthModal closeLoginModal={closeLoginModal} />
          : null
      }
    </>
  );
};

export default TrendingProducts;
