import React, { useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordStart } from "../../store/slices/UserSlice";
import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";

const ForgotPasswordIndex = ({ setStep }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const forgotPassword = useSelector(state => state.user.forgotPassword);

  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("forgot_password.email.invalid")).required(t("forgot_password.email.required")),
  });

  const handleSubmit = (values) => {
    dispatch(forgotPasswordStart(values));
  };

  useEffect(() => {
    if (!skipRender && !forgotPassword.loading && Object.keys(forgotPassword.data).length > 0) {
      setStep(6);
    }
    setSkipRender(false);
  }, [forgotPassword]);

  return (
    <>
      <div className="step-3">
        <div className="auth-form">
          <div className="login-box">
            <div className="reset-header-title-sec">
              <h3>{t("forgot_password.heading")}</h3>
            </div>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              <FORM>
                <div className="login-body-sec">
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{t("forgot_password.email.label")} *</Form.Label>
                    <Field
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder={t("forgot_password.email.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="email"
                      className="text-danger"
                    />
                  </Form.Group>
                  <div className="auth-btn-sec">
                    <Button
                      type="submit"
                      className="default-btn w-100"
                      disabled={forgotPassword.buttonDisable}
                    >
                      {forgotPassword.buttonDisable ? t("forgot_password.forgot_btn.loading") : t("forgot_password.forgot_btn.text")}
                    </Button>
                    <Button type="button" className="default-btn-outline w-100" onClick={() => setStep(1)}>
                      {t("forgot_password.login")}
                    </Button>
                  </div>
                </div>
              </FORM>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordIndex;