import React, { useRef, useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import ProfileSidebar from "./ProfileSidebar";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordStart } from "../../store/slices/UserSlice";
import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import useLogout from "../Auth/useLogout";
import { useNavigate } from "react-router-dom";

const ChangePassword = (props) => {
  const t = useTranslation("change_password");
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changePassword = useSelector(state => state.user.changePassword);
  const profile = useSelector(state => state.user.profile);
  const changePasswordForm = useRef();

  const [skipRender, setSkipRender] = useState(true);
  const [isVisible, setIsVisible] = useState({
    old: false,
    new: false,
    confirm: false,
  })

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("old_password.invalid")).required(t("old_password.required")),
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("new_password.invalid"))
      .required(t("new_password.required"))
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          t("old_password.no_old")
        ),
      }),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("confirm_password.invalid"))
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.same")
        ),
      }),
  });

  const handleSubmit = (values) => {
    dispatch(changePasswordStart(values));
  }

  useEffect(() => {
    if (!skipRender && !changePassword.loading && Object.keys(changePassword.data).length > 0) {
      logout();
      navigate("/");
    }
    setSkipRender(false);
  }, [changePassword]);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                <div className="profile-details-sec">
                  <div className="profile-header-sec">
                    <h3>{t("heading")}</h3>
                    <p>{t("note")} :{t("you_can_change_your_password_para")}</p>
                  </div>
                  <Formik
                    innerRef={changePasswordForm}
                    initialValues={{
                      old_password: "",
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <FORM className="account-form">
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("old_password.label")} *</Form.Label>
                            <InputGroup>
                              <Field
                                type={isVisible.old ? "text" : "password"}
                                name="old_password"
                                placeholder={t("change_password.old_password.placeholder")}
                                className="form-control"
                              />
                              <InputGroup.Text>
                                <Image
                                  className="pass-eye-icon"
                                  src={
                                    isVisible.old ?
                                      window.location.origin + "/assets/img/auth/eye-show-icon.svg"
                                      : window.location.origin + "/assets/img/auth/eye-hide-icon.svg"
                                  }
                                  onClick={() => setIsVisible({ ...isVisible, old: !isVisible.old })}
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="old_password"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("confirm_password.label")} *</Form.Label>
                            <InputGroup >
                              <Field
                                type={isVisible.confirm ? "text" : "password"}
                                name="password_confirmation"
                                placeholder={t("confirm_password.placeholder")}
                                className="form-control"
                              />
                              <InputGroup.Text>
                                <Image
                                  className="pass-eye-icon"
                                  src={
                                    isVisible.confirm ?
                                      window.location.origin + "/assets/img/auth/eye-show-icon.svg"
                                      : window.location.origin + "/assets/img/auth/eye-hide-icon.svg"
                                  }
                                  onClick={() => setIsVisible({ ...isVisible, confirm: !isVisible.confirm })}
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password_confirmation"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("new_password.label")} *</Form.Label>
                            <InputGroup >
                              <Field
                                type={isVisible.new ? "text" : "password"}
                                name="password"
                                placeholder={t("new_password.placeholder")}
                                className="form-control"
                              />
                              <InputGroup.Text>
                                <Image
                                  className="pass-eye-icon"
                                  src={
                                    isVisible.new ?
                                      window.location.origin + "/assets/img/auth/eye-show-icon.svg"
                                      : window.location.origin + "/assets/img/auth/eye-hide-icon.svg"
                                  }
                                  onClick={() => setIsVisible({ ...isVisible, new: !isVisible.new })}
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="account-btn-sec">
                            <Button type="submit"
                              disabled={changePassword.buttonDisable}
                              className="default-btn">
                              {changePassword.buttonDisable ? t("password_btn.loading") : t("password_btn.text")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </FORM>
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ChangePassword;
