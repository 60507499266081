import React, { useState } from "react";
import FooterIndex from "./Footer/FooterIndex";
import EmptyHeader from "./Header/EmptyHeader";

const EmptyLayout = (props) => {

  return (
    <>
      {/* <EmptyHeader/> */}
        <div className="main-wrapper">
        {props.children}
        </div>
      <FooterIndex/>
    </>
  );
};

export default EmptyLayout;
