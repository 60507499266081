import { all, fork } from "redux-saga/effects";
import PageSaga from "./PageSaga";
import ProductSaga from "./ProductSaga";
import UserSaga from "./UserSaga";
import WalletSaga from "./WalletSaga";
import CartSaga from "./CartSaga";
import OrderSaga from "./OrderSaga";
import DeliverySaga from "./DeliverySaga";
import ReviewSaga from "./ReviewSaga";
import HomePageSaga from "./HomePageSaga";

export default function* rootSaga() {
  yield all([
    fork(UserSaga),
    fork(PageSaga),
    fork(WalletSaga),
    fork(ProductSaga),
    fork(CartSaga),
    fork(OrderSaga),
    fork(DeliverySaga),
    fork(ReviewSaga),
    fork(HomePageSaga),
  ]);

}
