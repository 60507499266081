import React, { useState, useEffect, Component } from "react";
import { Row, Col, Container, Form, Image, Button, Modal, InputGroup, Tab, Tabs, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./LandingStyle.css";
import "./ProductDetails.css";
import {
  fetchProductReviewListStart,
  fetchMoreProductReviewListStart,
  singleProductStart,
  productListStart,
  wishlistSaveStart,
} from '../../store/slices/ProductSlice';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import SimilarProductCard from "./SimilarProductCard";
import Skeleton from "react-loading-skeleton";
import ReviewLoader from "../Helper/ReviewLoader";
import ReactStars from "react-rating-stars-component";
import InfiniteScroll from "react-infinite-scroll-component";
import RatingLoader from "../Helper/RatingLoader";
import WriteReviewModal from "../Profile/WriteReviewModal";

const Reviews = () => {

  const params = useParams();
  const t = useTranslation("single_product");
  const dispatch = useDispatch();
  const productReviews = useSelector(state => state.product.productReviews);
  const singleProduct = useSelector(state => state.product.singleProduct);
  const productList = useSelector(state => state.product.productList);
  const profile = useSelector(state => state.user.profile);

  const [writeReview, setWriteReview] = useState(false);
  const [login, setLogin] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [productAmount, setProductAmount] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      singleProductStart({ product_unique_id: params.product_unique_id })
    );
    dispatch(
      fetchProductReviewListStart({
        product_unique_id: params.product_unique_id,
        skip: 0,
        take: 12,
      })
    );
    dispatch(
      productListStart({
        order_by: "newest",
        skip: 0,
        take: 6,
      })
    );
  }, [params.product_unique_id]);

  const fetchMoreReviews = () => {
    dispatch(
      fetchMoreProductReviewListStart({
        product_unique_id: params.product_unique_id,
        skip: productReviews.data.reviews.length,
        take: 12,
      })
    );
  };

  const writeReviews = () => {
    if (Object.keys(profile.data).length > 0) {
      setWriteReview(true);
    } else {
      setLogin(true);
    }
  }

  const closeWriteReviewModal = () => {
    setWriteReview(false);
  };

  useEffect(() => {
    if (!skipRender && !singleProduct.loading && Object.keys(singleProduct.data).length > 0) {
      setProductAmount(singleProduct.data.product.product_attributes[0])
    }
    setSkipRender(false);
  }, [singleProduct]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const wishListToggle = () => {
    if (Object.keys(profile.data).length > 0) {
      dispatch(
        wishlistSaveStart({
          product_unique_id: singleProduct.data.product.product_unique_id,
        })
      );
    } else {
      setLogin(true);
    }
  };

  return (
    <>
      <div className="review-product-main-wrap">
        <Container>
          <Row className="justify-content-md-center">
            <Col xl={10} lg={10}>

              {singleProduct.loading ?
                <ReviewLoader />
                :
                Object.keys(singleProduct.data).length > 0 &&
                  singleProduct.data.product ?
                  <>
                    <div className="next-page-tab">
                      <span>
                        <Link to="/">{t("home")}</Link>
                      </span>
                      <span>
                        <Link
                          to={`/products?category=${singleProduct.data.product.product_unique_id}`}
                        >{singleProduct.data.product.name}</Link>
                      </span>
                      <span>
                        <Link to="#" className="active">{t("ratings_reviews")}</Link>
                      </span>
                    </div>
                    <div className="review-product-details">
                      <div className="review-product-details-img">
                        <Image
                          src={productAmount.file}
                        />
                      </div>
                      <div className="review-product-details-info">
                        <div className={`card-fav-icon ${singleProduct.data.product.is_wishlisted ? "active" : ""}`} onClick={(event) => wishListToggle(event, singleProduct.data.product.product_unique_id)}>
                          {singleProduct.data.product.is_wishlisted ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="#005479"
                            >
                              <path
                                d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                stroke="#252525"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                        <p>
                          <Link
                            to={`/product/${singleProduct.data.product.product_unique_id}`}>
                            {singleProduct.data.product.name}</Link></p>
                        <span className="product-name">{productAmount.metal}</span>
                        <div className="singale-product-rate">
                          <span className="current-rate">{productAmount.selling_price_formatted}</span>
                          <span className="net-rate">{productAmount.original_price_formatted}</span>
                          <span className="discount-per" >{productAmount.discount_tag}</span>
                        </div>
                        <div className="delivery-main-frame">
                          <div className="delivery-right-wrap">
                          </div>
                          <div className="delivery-left-wrap">
                            <Link
                              onClick={writeReviews}
                              variant="primary" >
                              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                <path d="M14.25 2.12123L13.02 0.913734C12.7838 0.676987 12.5032 0.489156 12.1944 0.360997C11.8855 0.232838 11.5544 0.16687 11.22 0.16687C10.8856 0.16687 10.5544 0.232838 10.2456 0.360997C9.9367 0.489156 9.65615 0.676987 9.41998 0.913734L1.07247 9.26123C0.727408 9.6096 0.454924 10.023 0.270821 10.4775C0.0867177 10.9319 -0.00534392 11.4184 -2.62415e-05 11.9087V13.6637C-2.62415e-05 14.0616 0.158008 14.4431 0.439312 14.7244C0.720617 15.0057 1.10215 15.1637 1.49997 15.1637H3.26247C3.75489 15.1651 4.24265 15.0683 4.69722 14.879C5.1518 14.6897 5.56409 14.4117 5.90997 14.0612L14.25 5.72123C14.4902 5.48596 14.6805 5.20459 14.8094 4.894C14.9383 4.58341 15.0031 4.24999 15 3.91373C15.0011 3.58 14.9353 3.24943 14.8064 2.94156C14.6776 2.63368 14.4884 2.35475 14.25 2.12123ZM4.82247 13.0112C4.3997 13.4306 3.82794 13.6652 3.23247 13.6637H1.49997V11.9087C1.5005 11.3122 1.73789 10.7403 2.15997 10.3187L8.62497 3.85373L11.3175 6.54623L4.82247 13.0112ZM13.17 4.66373L12.3525 5.48873L9.65998 2.79623L10.5 1.97123C10.6997 1.77388 10.9692 1.66337 11.25 1.66373C11.3891 1.66273 11.5271 1.6894 11.6558 1.74219C11.7846 1.79498 11.9016 1.87284 12 1.97123L13.17 3.16373C13.2721 3.26051 13.3539 3.37669 13.4106 3.50547C13.4672 3.63426 13.4976 3.77305 13.5 3.91373C13.4936 4.19756 13.3749 4.46726 13.17 4.66373Z" fill="#005479" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49976 14.4169C7.49976 14.0027 7.83554 13.6669 8.24976 13.6669H14.2498C14.664 13.6669 14.9998 14.0027 14.9998 14.4169C14.9998 14.8311 14.664 15.1669 14.2498 15.1669H8.24976C7.83554 15.1669 7.49976 14.8311 7.49976 14.4169Z" fill="#005479" />
                              </svg>
                              {t("write_review")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <SomethingWentWrong />
              }
              <div className="review-area">
                {productReviews.loading ?
                  <RatingLoader />
                  :
                  Object.keys(productReviews.data).length > 0 &&
                    productReviews.data.reviews.length > 0 ?
                    <div className="product-review-frame">
                      <div className="product-review-head">
                        <h4>{t("product_review")}</h4>
                      </div>
                      <div className="product-rating-point">
                        <h2>{productReviews.data.total_avg} <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="#FFC300"
                              d="M10 15.937l6.18 3.73-1.64-7.03L20 7.907l-7.19-.61L10 .667l-2.81 6.63-7.19.61 5.46 4.73-1.64 7.03 6.18-3.73z"
                            ></path>
                          </svg>
                        </span>
                        </h2>
                        <p>{productReviews.data.total_avg} {t("ratings")} ,
                          {productReviews.data.total_reviews} {t("reviews")}</p>
                      </div>
                      <div className="reviews-bar-wrap">
                        <ul className="list-unstyled">
                          <li>
                            <div className="reviews-bar-card">
                              <span>5 {t("star")}</span>
                              <span><ProgressBar
                                now={productReviews.data.ratings.five_star_percentage}
                                className="five-start" />
                              </span>
                              <span>{productReviews.data.ratings.five_star_percentage + "%"}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="reviews-bar-card">
                              <span>4 {t("star")}</span>
                              <span><ProgressBar
                                now={productReviews.data.ratings.four_star_percentage}
                                className="four-start" /></span>
                              <span>{productReviews.data.ratings.four_star_percentage + "%"}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="reviews-bar-card">
                              <span>3 {t("star")}</span>
                              <span><ProgressBar
                                now={productReviews.data.ratings.three_star_percentage}
                                className="three-start" /></span>
                              <span>{productReviews.data.ratings.three_star_percentage + "%"}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="reviews-bar-card">
                              <span>2 {t("star")}</span>
                              <span><ProgressBar
                                now={productReviews.data.ratings.two_star_percentage}
                                className="two-start" />
                              </span>
                              <span>{productReviews.data.ratings.two_star_percentage + "%"}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="reviews-bar-card">
                              <span>1 {t("star")}</span>
                              <span><ProgressBar
                                now={productReviews.data.ratings.one_star_percentage}
                                className="one-start"
                              /></span>
                              <span>{productReviews.data.ratings.one_star_percentage + "%"}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <InfiniteScroll
                        dataLength={productReviews.data.reviews.length}
                        next={fetchMoreReviews}
                        hasMore={productReviews.data.reviews.length < productReviews.data.total_reviews
                        }
                        loader={[...Array(2)].map((i) => (
                          <div className="review-preview-frame">
                            <div className="review-preview-card" key={i}>
                              <div className="review-preview-card-img">
                                <Skeleton count={1} circle={true} height={40} width={40} />
                              </div>
                              <div className="review-preview-card-info">
                                <Skeleton count={1} height={23} width={145} />
                              </div>
                            </div>
                            <div className="review-preview-ratings">
                              <Skeleton count={1} height={36} width={110} />
                            </div>
                            <div className="rating-product-review-frame">
                              <Skeleton count={1} height={20} width={150} />
                            </div>
                          </div>
                        ))}
                      >
                        <>
                          {productReviews.data.reviews.map(
                            (review, i) => (
                              <div className="review-preview-frame" key={i}>
                                <div className="review-preview-card">
                                  <div className="review-preview-card-img">
                                    <Image
                                      className=""
                                      src={review.user
                                        ? review.user.picture :
                                        window.location.origin + "/assets/img/new/customer.jpeg"
                                      }
                                    />
                                  </div>
                                  <div className="review-preview-card-info">
                                    <h3>     {review.user
                                      ? review.user.name
                                      : t("na")
                                    }</h3>
                                    <span>{review.updated_at_formatted}</span>
                                  </div>
                                </div>
                                <div className="review-preview-ratings">
                                  <ReactStars
                                    count={5}
                                          edit={false}
                                    size={24}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fas fa-star-half-alt"></i>}
                                    fullIcon={<i className="fas fa-star"></i>}
                                    activeColor="#ffd700"
                                    value={review.star}
                                    key={`stars_${review.star}`}
                                  />
                                </div>
                                <div className="rating-product-review-frame">
                                  <p>
                                    {review.review}</p>
                                  {/* <p>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="21"
                                      fill="none"
                                      viewBox="0 0 25 21"
                                      stroke="#005479"
                                    >
                                      <path
                                        d="M.66 18.4a2 2 0 002 2h.426a2 2 0 002-2V9.96a2 2 0 00-2-2H2.66a2 2 0 00-2 2v8.44zM25 8.998c0-1.14-.996-2.073-2.213-2.073H18.3a2 2 0 01-1.953-2.433l.511-2.305.034-.332c0-.425-.189-.819-.487-1.099a1.714 1.714 0 00-2.34.007L7.951 6.499c-.41.373-.653.891-.653 1.462v10.367c0 1.14.996 2.073 2.213 2.073h9.957c.919 0 1.704-.518 2.036-1.265l3.341-7.309c.1-.238.155-.487.155-.756V8.997z"
                                      ></path>
                                    </svg>
                                    {t("helpful")}<span> (78)
                                    </span>
                                  </p> */}
                                </div>
                              </div>
                            )
                          )}
                        </>
                      </InfiniteScroll>
                    </div>
                    :
                    null
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
       <Container>
        <Row>
          <Col lg={12} >
            <div className="similar-product-frame">
              <div className="new-section-title">
                <h2>{t("similar_products")}</h2>
                <div className="view-more-section">
                  <Link to="/products">
                    {t("view_all")}
                  </Link>
                </div>
              </div>
              {productList.loading ?
                <div className="smilar-product-cards">
                  {[...Array(4)].map((val, i) => <Skeleton height={445} key={i} />)}
                </div>
                :
                <Slider {...settings}>
                  {Object.keys(productList.data).length > 0 &&
                    productList.data.products.length > 0 ?
                    productList.data.products.map((product) => (
                      <SimilarProductCard product={product} />
                    ))
                    :
                    null
                  }
                </Slider>
              }
            </div>
          </Col>
        </Row>
      </Container>
      {
        writeReview ?
          <WriteReviewModal
            writeReview={writeReview}
            closeWriteReviewModal={closeWriteReviewModal}
            setWriteReview={setWriteReview}
            singleProduct={singleProduct.data.product}
          />
          :
          null
      }
    </>
  );
};

export default Reviews;
