import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button } from "react-bootstrap";
import ProfileSidebar from "./ProfileSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { updateUserDetailsStart, fetchUserDetailsStart } from "../../store/slices/UserSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../Helper/CustomLazyLoad";

const ProfileIndex = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("profile_index");

  const [profileImg, setProfileImg] = useState(null);
  const [imgPreviewAdd, setImgPreviewAdd] = useState(null);

  const profileInputData = useSelector(state => state.user.profileInputData);
  const profile = useSelector(state => state.user.profile);

  const handleSubmit = (values) => {
    let newValues = values;
    if (profileImg) {
      newValues = {
        ...values,
        picture: profileImg,
      };
    }
    dispatch(updateUserDetailsStart(newValues));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid_email")).required(t("email.required")),
    name: Yup.string().required(t("name.required")),
    mobile: Yup.string().matches(
      /^(?=.*[0-9])(?=.{9,})/,
      t("mobile.must_contain_9_characters")
    ),
  });

  const imgPreview = (e) => {
    setImgPreviewAdd(URL.createObjectURL(e.target.files[0]));
    setProfileImg(e.target.files[0]);
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col md={3}>
              <ProfileSidebar />
            </Col>
            <Col md={9}>
              <div className="account-main-wrapper resp-hide">
                <div className="profile-details-sec">
                  <div className="profile-header-sec">
                    <h3 className="text-uppercase">{t("heading")}</h3>
                    <p> {t("welcome", { username: profile.data.username })}</p>
                  </div>
                  {profile.loading ?
                    <>
                      <Skeleton
                        height={80}
                        width={80}
                        circle={true}
                        className='mb-4'
                      />

                      <Skeleton
                        height={200}
                      />
                    </>
                    :
                    Object.keys(profile.data).length > 0 ?
                      <Formik
                        initialValues={{
                          name: profile.data.name,
                          mobile: profile.data.mobile,
                          email: profile.data.email,
                        }}
                        onSubmit={(values) => handleSubmit(values)}
                        validationSchema={validationSchema}
                      >
                        <FORM className="account-form">
                          <div className="profile-upload-img-sec">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Form.Label>
                                <CustomLazyLoad
                                  src={imgPreviewAdd
                                    ? imgPreviewAdd
                                    : profile.data.picture}
                                  placeholderSrc={window.location.origin + "/assets/img/profile/profile-placeholder.png"}
                                  className="profile-upload-img"
                                />
                                <div className="input-edit-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/edit.svg"
                                    }
                                    type="image/png"
                                  />
                                </div>

                              </Form.Label>
                              <Form.Control
                                type="file"
                                accept="image/*"
                                className="image-input"
                                onChange={(e) => imgPreview(e)}
                              />
                            </Form.Group>
                          </div>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>{t("name.label")} *</Form.Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder={t("name.placeholder")}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="name"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>{t("mobile.label")}*</Form.Label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  name="mobile"
                                  placeholder={t("mobile.placeholder")}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="mobile"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>{t("email.label")} *</Form.Label>
                                <Field
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  placeholder={t("mobile.email")}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="email"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className="account-btn-sec">
                                <Button
                                  type="submit"
                                  className="default-btn"
                                  disabled={profileInputData.buttonDisable}
                                >
                                  {profileInputData.buttonDisable ?
                                    t("profile_btn.loading") :
                                    t("profile_btn.text")
                                  }
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </FORM>
                      </Formik>
                      :
                      null
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfileIndex;
