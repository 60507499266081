import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import ProfileSidebar from "./ProfileSidebar";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { twoStepAuthUpdateStart } from "../../store/slices/UserSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const TwoStepAuthentication = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("two_step");

  const [initialTwoStep, setInitialTwoStep] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const profile = useSelector(state => state.user.profile);
  const twoStepAuthUpdate = useSelector(state => state.user.twoStepAuthUpdate);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      setInitialTwoStep(
        profile.data.is_two_step_auth_enabled === 0 ? false : true
      );
      setTwoStepAuth(
        profile.data.is_two_step_auth_enabled === 0 ? false : true
      );
    }
  }, [profile.data]);

  const changeTwoStepAuth = (values) => {
    dispatch(twoStepAuthUpdateStart(values));
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("password.required")),
  });

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="account-main-wrapper">
                <div className="profile-details-sec">
                  <div className="profile-header-sec">
                    <h3 className="text-uppercase">{t("heading")}</h3>
                    <p>{t("Welcome")} {profile.data.username}</p>
                  </div>
                  <Form className="account-form">
                    <div className="two-step-auth-header-sec">
                      <div className="two-step-auth-text">
                        <p>{t("head_para")}</p>
                      </div>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={twoStepAuth}
                        onChange={() => setTwoStepAuth(!twoStepAuth)}
                      />
                    </div>
                  </Form>
                  {initialTwoStep != twoStepAuth ?
                    <Formik
                      initialValues={{
                        password: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => changeTwoStepAuth(values)}
                    >
                      <FORM className="account-form">
                        <Row>
                          <Col md={6}>
                            <Form.Label>{t("password.label")} *</Form.Label>
                            <InputGroup className="mb-4">
                              <Field
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                placeholder={t("password.placeholder")}
                                name="password"
                              />
                              <InputGroup.Text>
                                <button onClick={() =>
                                  setShowPassword(!showPassword)
                                }
                                  className="btn password-eye"
                                  type="button"
                                >
                                  {showPassword ?
                                    <Image
                                      className="pass-eye-icon"
                                      src={window.location.origin + "/assets/img/profile/icons/eye-off.svg"}
                                    />
                                    :
                                    <Image
                                      className="pass-eye-icon"
                                      src={window.location.origin + "/assets/img/profile/icons/eye.svg"}
                                    />
                                  }
                                </button>
                              </InputGroup.Text>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="text-danger pass-txt"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="account-btn-sec">
                              <Button
                                type="submit"
                                className="default-btn"
                                disabled={twoStepAuthUpdate.buttonDisable}
                              >
                                {twoStepAuthUpdate.buttonDisable ? t("two_step_btn.loading") :
                                  t("two_step_btn.text")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </FORM>
                    </Formik>
                    :
                    null
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TwoStepAuthentication;
