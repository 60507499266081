import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';

import {
  productListFailure,
  productListSuccess,
  singleProductFailure,
  singleProductSuccess,
  fetchproductWishlistsSuccess,
  fetchproductWishlistsFailure,
  wishlistSaveSuccess,
  wishlistSaveFailure,
  fetchProductReviewListSuccess,
  fetchProductReviewListFailure,
  fetchProductSuggetionSuccess,
  fetchProductSuggetionFailure,
  metalListSuccess,
  metalListFailure,
  specialProductListFailure,
  specialProductListSuccess,
  featuredProductListSuccess,
  featuredProductListFailure,
  topProductListSuccess,
  topProductListFailure,
} from "../slices/ProductSlice";
import { cartListSuccess } from "../slices/CartSlice";
import { orderDetailsSuccess } from "../slices/OrderSlice";

function* productListAPI(action) {
  const productListData = yield select(state => state.product.productList.data);
  yield getCommonSaga({
    apiUrl: "products_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(productListSuccess({
        ...data,
        products: [...productListData.products, ...data.products],
      }));
    },
    failure: productListFailure,
  });
}

function* singleProductAPI(action) {
  yield getCommonSaga({
    apiUrl: "products_view",
    payload: action.payload,
    successNotify: false,
    success: singleProductSuccess,
    failure: singleProductFailure,
  });
}

function* fetchproductWishlistsAPI(action) {
  const productWishListData = yield select(state => state.product.productWishlists.data);
  yield getCommonSaga({
    apiUrl: "wishlists_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(fetchproductWishlistsSuccess({
        ...data,
        products: [...productWishListData.products, ...data.products],
        total_products: data.total_products,
      }));
    },
    failure: fetchproductWishlistsFailure,
  });
}

function* wishlistSaveAPI(action) {
  const productListData = yield select(state => state.product.productList.data);
  const productWishlistsData = yield select(state => state.product.productWishlists.data);
  const singleProductData = yield select(state => state.product.singleProduct.data);
  const cartListData = yield select(state => state.cart.cartList.data);
  const orderListData = yield select(state => state.order.orderDetails.data);
  const topProductListData = yield select(state => state.product.topProductList.data);
  const featureProductListData = yield select(state => state.product.featuredProductList.data);
  yield getCommonSaga({
    apiUrl: "wishlists_save",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(wishlistSaveSuccess(data))
      if (Object.keys(orderListData).length > 0 &&
      orderListData.order_product.product.product_unique_id === action.payload.product_unique_id) {
        store.dispatch(orderDetailsSuccess({
            ...orderListData,
            is_wishlisted: data.is_wishlisted
        }));
      }
      if (Object.keys(productListData).length > 0)
      store.dispatch(topProductListSuccess({
        ...topProductListData,
        products: topProductListData.products.map(productItem =>
          productItem.product_unique_id === action.payload.product_unique_id
            ? {
              ...productItem, is_wishlisted: data.is_wishlisted
            } : productItem
        )
      }));
      if (Object.keys(productListData).length > 0)
      store.dispatch(featuredProductListSuccess({
        ...featureProductListData,
        products: featureProductListData.products.map(productItem =>
          productItem.product_unique_id === action.payload.product_unique_id
            ? {
              ...productItem, is_wishlisted: data.is_wishlisted
            } : productItem
        )
      }));
      if (Object.keys(singleProductData).length > 0 &&
      singleProductData.product.product_unique_id === action.payload.product_unique_id) {
      store.dispatch(singleProductSuccess({
        ...singleProductData,
        product: {
          ...singleProductData.product,
          is_wishlisted: data.is_wishlisted
        }
      }));
    }
      if (Object.keys(productListData).length > 0)
        store.dispatch(productListSuccess({
          ...productListData,
          products: productListData.products.map(productItem =>
            productItem.product_unique_id === action.payload.product_unique_id
              ? {
                ...productItem, is_wishlisted: data.is_wishlisted
              } : productItem
          )
        }));
      if (Object.keys(cartListData).length > 0)
        store.dispatch(cartListSuccess({
          ...cartListData,
          carts: cartListData.carts.map(cartItem =>
            cartItem.product.product_unique_id === action.payload.product_unique_id
              ? {
                ...cartItem,
                product: { ...cartItem.product, is_wishlisted: data.is_wishlisted, }
              } : cartItem,
          )
        }));
      if (Object.keys(productWishlistsData).length > 0)
        store.dispatch(fetchproductWishlistsSuccess({
          products: productWishlistsData.products.filter(product =>
            (product.product_unique_id !== action.payload.product_unique_id)),
        }));
    },
    failure: wishlistSaveFailure,
  })
}

function* productReviewAPI(action) {
  const reviewListData = yield select(state => state.product.productReviews.data);
  yield getCommonSaga({
    apiUrl: "product_reviews",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(fetchProductReviewListSuccess({
        ...data,
        reviews: [...reviewListData.reviews, ...data.reviews],
        total_reviews: data.total_reviews,
      }));
    },
    failure: fetchProductReviewListFailure,
  });
}

function* fetchProductSuggetionAPI(action) {
  yield getCommonSaga({
    apiUrl: "products_list",
    payload: action.payload,
    successNotify: false,
    success: fetchProductSuggetionSuccess,
    failure: fetchProductSuggetionFailure,
  });
}

function* metalListAPI(action) {
  yield getCommonSaga({
    apiUrl: "metals_list",
    payload: action.payload,
    successNotify: false,
    success: metalListSuccess,
    failure: metalListFailure,
  });
}

function* topProductListAPI(action) {
  yield getCommonSaga({
    apiUrl: "top_products_list",
    payload: action.payload,
    successNotify: false,
    success: topProductListSuccess,
    failure: topProductListFailure,
  });
}

function* featuredProductListAPI(action) {
  yield getCommonSaga({
    apiUrl: "featured_products_list",
    payload: action.payload,
    successNotify: false,
    success: featuredProductListSuccess,
    failure: featuredProductListFailure,
  });
}

function* specialProductListAPI(action) {
  yield getCommonSaga({
    apiUrl: "special_products_list",
    payload: action.payload,
    successNotify: false,
    success: specialProductListSuccess,
    failure: specialProductListFailure,
  });
}

export default function* ProductSaga() {
  yield all([
    yield takeLatest('product/productListStart', productListAPI),
    yield takeLatest('product/moreProductListStart', productListAPI),
    yield takeLatest('product/singleProductStart', singleProductAPI),
    yield takeLatest('product/fetchproductWishlistsStart', fetchproductWishlistsAPI),
    yield takeLatest('product/fetchMoreproductWishlistsStart', fetchproductWishlistsAPI),
    yield takeLatest('product/wishlistSaveStart', wishlistSaveAPI),
    yield takeLatest('product/fetchProductReviewListStart', productReviewAPI),
    yield takeLatest('product/fetchMoreProductReviewListStart', productReviewAPI),
    yield takeLatest('product/fetchProductSuggetionStart', fetchProductSuggetionAPI),
    yield takeLatest('product/metalListStart', metalListAPI),
    yield takeLatest('product/topProductListStart', topProductListAPI),
    yield takeLatest('product/specialProductListStart', specialProductListAPI),
    yield takeLatest('product/featuredProductListStart', featuredProductListAPI),

  ]);
}
