import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import LoginIndex from "./LoginIndex";
import RegisterIndex from "./RegisterIndex";
import ForgotPasswordIndex from "./ForgotPasswordIndex";
import VerificationIndex from "../Verification/VerificationIndex";
import ResetPassword from "./ResetPassword";

/* 
  Steps
  *************
  1 - Login
  2 - Register
  3 - Forgot Password
  4 - Two Step Authentication
  5 - Email Verification
  6 - Forgot password verification
  7 - Reset Password
*/

const AuthModal = ({ closeLoginModal }) => {

  const [step, setStep] = useState(1);

  return (
    <>
      <Modal
        className="modal-dialog-center login-modal"
        size="md"
        centered
        show={true}
        onHide={closeLoginModal}
      >
        <Button className="modal-close" onClick={() => closeLoginModal()}>
          <Image
            className="close-icon"
            src={
              window.location.origin + "/assets/img/modal-close.svg"
            }
          />
        </Button>
        <Modal.Body>
          {step === 1 ?
            <LoginIndex setStep={setStep} closeAuthModal={closeLoginModal} />
            : step === 2 ?
              <RegisterIndex setStep={setStep} closeAuthModal={closeLoginModal} />
              : step === 3 ?
                <ForgotPasswordIndex setStep={setStep} />
                : step === 4 || step === 5 || step === 6 ?
                  <VerificationIndex setStep={setStep} step={step} closeAuthModal={closeLoginModal} />
                  : step === 7 ?
                    <ResetPassword setStep={setStep} closeAuthModal={closeLoginModal} />
                    : null
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthModal;