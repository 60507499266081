import React, { useState, useEffect, Component } from "react";
import { Row, Col, Container, Form, Image, Button, Modal, InputGroup, Tab, Tabs, ProgressBar } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SliderImage from 'react-zoom-slider';
import Slider from "react-slick";
import "./LandingStyle.css";
import "./ProductDetails.css";
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import ReactStars from "react-rating-stars-component";
import {
  productListStart,
  singleProductStart,
  fetchProductReviewListStart,
  wishlistSaveStart,
} from "../../store/slices/ProductSlice";
import { cartSaveStart, localCartList } from "../../store/slices/CartSlice";
import { } from "../../store/slices/ProductSlice";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Skeleton from "react-loading-skeleton";
import SimilarProductCard from "./SimilarProductCard";
import RatingLoader from "../Helper/RatingLoader";
import AuthModal from "../Auth/AuthModal";

const ProductDetail = () => {

  const t = useTranslation("single_product");
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const singleProduct = useSelector(state => state.product.singleProduct);
  const profile = useSelector(state => state.user.profile);
  const localCart = useSelector(state => state.cart.localCart);
  const productList = useSelector(state => state.product.productList);
  const [login, setLogin] = useState(false);
  const [productAttributes,setProductAttributes]=useState([]);

  const [skipRender, setSkipRender] = useState(true);
  const [materialType, setMaterialType] = useState([]);
  const productReviews = useSelector(state => state.product.productReviews);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedData, setSelectedData] = useState({
    size: "",
    weight: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      singleProductStart({ product_unique_id: params.productUniqueId })
    );
    const local = localStorage.getItem("cart");
    dispatch(localCartList(local && local.length > 0 ? JSON.parse(local) : []))
    dispatch(
      fetchProductReviewListStart({
        product_unique_id: params.productUniqueId,
        skip: 0,
        take: 2,
      })
    );
    dispatch(
      productListStart({
        order_by: "newest",
        skip: 0,
        take: 6,
      })
    );
  }, [params.productUniqueId]);

  useEffect(() => {
    if (!skipRender && !singleProduct.loading && Object.keys(singleProduct.data).length > 0) {
    setProductAttributes(singleProduct.data.product.product_files)
    }
    setSkipRender(false);
  }, [singleProduct]);

  const attributes = productAttributes.slice(0, 6);

  useEffect(() => {
    if (!skipRender && !singleProduct.loading && Object.keys(singleProduct.data).length > 0) {
      // setIsWishlisted(singleProduct.data.product.is_wishlisted ? true : false);
      if (singleProduct.data.product.product_attributes.length > 0)
        if (selectedAttribute)
          setSelectedAttribute(singleProduct.data.product.product_attributes.find(attr => attr.product_attribute_id === selectedAttribute.product_attribute_id));
        else {
          let productAttribute = singleProduct.data.product.product_attributes.find(attribute => attribute.product_attribute_id == params.productAttributeId)
          if (!productAttribute) productAttribute = singleProduct.data.product.product_attributes.slice(-1)[0];
          setSelectedAttribute(productAttribute);
          setSelectedData({
            size: productAttribute.sizes.length > 0 ? productAttribute.sizes[0] : "",
            weight: productAttribute.weights.length > 0 ? productAttribute.weights[0] : "",
          })
        }
    }
    setSkipRender(false);
  }, [singleProduct]);

  useEffect(() => {
    if (selectedAttribute)
      setSelectedData({
        size: selectedAttribute.sizes.length > 0 ? selectedAttribute.sizes[0] : "",
        weight: selectedAttribute.weights.length > 0 ? selectedAttribute.weights[0] : "",
      })
  }, [selectedAttribute]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const onCartClick = (e) => {
    e.preventDefault();
    //User Add to cart
    if (isCarted())
      navigate("/cart");
    else
      dispatch(
        cartSaveStart({
          product_attribute_id: selectedAttribute.product_attribute_id,
          quantity: 1,
          ...selectedData,
        })
      );
  };


  const onGuestCartClick = e => {
    e.preventDefault();
    if (isGuestCarted())
      navigate("/cart");
    else
      dispatch(localCartList([...localCart, {
        quantity: 1,
        product_attribute_id: selectedAttribute.product_attribute_id,
        ...selectedData
      }]))
  }

  const isCarted = () => {
    const value = singleProduct.data.carts.filter(product => product.product_attribute_id === selectedAttribute.product_attribute_id)
      .filter(product => product.size === selectedData.size)
      .filter(product => product.weight === selectedData.weight)
      .length > 0;
    return value;
  }

  const isGuestCarted = () => {
    const value = localCart.filter(product => product.product_attribute_id === selectedAttribute.product_attribute_id)
      .filter(product => product.size === selectedData.size)
      .filter(product => product.weight === selectedData.weight)
      .length > 0;
    return value;
  }

  const checkoutDirect = (e) => {
    e.preventDefault();
    if (Object.keys(profile.data).length > 0) {
      dispatch(
        cartSaveStart({
          product_attribute_id: selectedAttribute.product_attribute_id,
          quantity: 1,
          ...selectedData,
        })
      );
      navigate("/checkout-address");
    } else {
      setLogin(true);
    }
  }
  const closeLoginModal = () => {
    setLogin(false);
  };

  const data = [
    {
      image: '/assets/img/new/similar-product/necklace.png',
      text: 'necklace'
    },
    {
      image: '/assets/img/new/similar-product/necklace.png',
      text: 'necklace'
    },
    {
      image: '/assets/img/new/similar-product/necklace.png',
      text: 'necklace'
    },
  ];

  useEffect(() => {
    if (!skipRender && !singleProduct.loading && Object.keys(singleProduct.data).length > 0) {
      setMaterialType(singleProduct.data.product.product_attributes);
    }
  }, [singleProduct]);

  const materialReverse = materialType.slice(0).reverse();

  return (
    <>
      <div className="product-details-frame" >
        {singleProduct.loading ?
          <Container>
            <Row>
              <Col md={6}>
                <Skeleton className="product-image-loader" />
              </Col>
              <Col md={6}>
                <Skeleton height={100} />
                <Skeleton count={2} width={300} />
                <Skeleton count={4} />
                <Skeleton height={200} />
              </Col>
            </Row>
          </Container>
          :
          Object.keys(singleProduct.data).length > 0 &&
            singleProduct.data.product ?
            <>
              <Container >
                <Row>
                  <Col lg={5}>
                    <div className="product-preview-conatiner">
                      {selectedAttribute?.product_files.length > 0 ?
                        <SliderImage
                          data={selectedAttribute?.product_files.map((product) => ({
                            text: product.originalAlt,
                            image: product.thumbnail,
                          }))}
                          width="100%"
                          showDescription={true}
                          direction="right"
                        />
                        :
                        attributes.length > 0 ?
                          <SliderImage
                            data={attributes.map((product) => ({
                              text: product.originalAlt,
                              image: product.thumbnail,
                            }))}
                            width="100%"
                            showDescription={true}
                            direction="right"
                          />
                          :
                          null
                      }
                      {selectedAttribute ?
                        <div className="default-btn-frame">
                          {profile.buttonDisable ?
                            null
                            :
                            profile.data.user_id ?
                              <Link
                                onClick={onCartClick}
                                className="default-btn-one" to="#"> <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g id="cart">
                                    <g id="Wheel">
                                      <path id="Vector" d="M8.5 19C8.77614 19 9 18.7761 9 18.5C9 18.2239 8.77614 18 8.5 18C8.22386 18 8 18.2239 8 18.5C8 18.7761 8.22386 19 8.5 19Z" fill="#303037" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <circle id="Mask" cx="8.5" cy="18.5" r="0.5" fill="#303037" />
                                    </g>
                                    <g id="Wheel_2">
                                      <path id="Vector_2" d="M15.5 19C15.7761 19 16 18.7761 16 18.5C16 18.2239 15.7761 18 15.5 18C15.2239 18 15 18.2239 15 18.5C15 18.7761 15.2239 19 15.5 19Z" fill="#303037" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <circle id="Mask_2" cx="15.5" cy="18.5" r="0.5" fill="#303037" />
                                    </g>
                                    <path id="Vector_3" d="M15.005 18.1C15.0078 18.1 15.01 18.0776 15.01 18.05C15.01 18.0224 15.0078 18 15.005 18C15.0022 18 15 18.0224 15 18.05C15 18.0776 15.0022 18.1 15.005 18.1Z" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="Vector_4" d="M4.86035 4.00002H17.0004C17.3168 3.99383 17.6301 4.06281 17.9147 4.20131C18.1993 4.3398 18.4469 4.54385 18.6373 4.79667C18.8276 5.0495 18.9552 5.3439 19.0097 5.65566C19.0641 5.96742 19.0438 6.28764 18.9504 6.59002L18.2504 8.88002C18.0619 9.49407 17.6814 10.0314 17.1648 10.4131C16.6481 10.7947 16.0227 11.0004 15.3804 11H6.86035" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="Vector_5" d="M16 15H10.26C9.60831 14.9987 8.97476 14.7853 8.45515 14.392C7.93553 13.9987 7.55813 13.4469 7.38 12.82L4.41 2.45C4.29064 2.03271 4.03878 1.66557 3.69242 1.404C3.34607 1.14243 2.92403 1.00063 2.49 1H1" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                </svg>
                                {isCarted()
                                  ? t("go_to_cart")
                                  : t("cart_btn.text")
                                }</Link>
                              :
                              <Link
                                onClick={onGuestCartClick}
                                className="default-btn-one" to="#">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g id="cart">
                                    <g id="Wheel">
                                      <path id="Vector" d="M8.5 19C8.77614 19 9 18.7761 9 18.5C9 18.2239 8.77614 18 8.5 18C8.22386 18 8 18.2239 8 18.5C8 18.7761 8.22386 19 8.5 19Z" fill="#303037" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <circle id="Mask" cx="8.5" cy="18.5" r="0.5" fill="#303037" />
                                    </g>
                                    <g id="Wheel_2">
                                      <path id="Vector_2" d="M15.5 19C15.7761 19 16 18.7761 16 18.5C16 18.2239 15.7761 18 15.5 18C15.2239 18 15 18.2239 15 18.5C15 18.7761 15.2239 19 15.5 19Z" fill="#303037" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <circle id="Mask_2" cx="15.5" cy="18.5" r="0.5" fill="#303037" />
                                    </g>
                                    <path id="Vector_3" d="M15.005 18.1C15.0078 18.1 15.01 18.0776 15.01 18.05C15.01 18.0224 15.0078 18 15.005 18C15.0022 18 15 18.0224 15 18.05C15 18.0776 15.0022 18.1 15.005 18.1Z" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="Vector_4" d="M4.86035 4.00002H17.0004C17.3168 3.99383 17.6301 4.06281 17.9147 4.20131C18.1993 4.3398 18.4469 4.54385 18.6373 4.79667C18.8276 5.0495 18.9552 5.3439 19.0097 5.65566C19.0641 5.96742 19.0438 6.28764 18.9504 6.59002L18.2504 8.88002C18.0619 9.49407 17.6814 10.0314 17.1648 10.4131C16.6481 10.7947 16.0227 11.0004 15.3804 11H6.86035" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="Vector_5" d="M16 15H10.26C9.60831 14.9987 8.97476 14.7853 8.45515 14.392C7.93553 13.9987 7.55813 13.4469 7.38 12.82L4.41 2.45C4.29064 2.03271 4.03878 1.66557 3.69242 1.404C3.34607 1.14243 2.92403 1.00063 2.49 1H1" stroke="#303037" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                </svg>
                                {isGuestCarted()
                                  ? t("go_to_cart")
                                  : t("cart_btn.text")
                                }
                              </Link>
                          }
                          <Link
                            to="#"
                            onClick={checkoutDirect}
                            className="default-btn-two">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="bag">
                                <path id="Vector" d="M19.4901 14.47L18.8501 8.47C18.7211 7.25822 18.154 6.13525 17.2554 5.31213C16.3568 4.48901 15.1885 4.0224 13.9701 4C13.9701 2.93913 13.5486 1.92172 12.7985 1.17157C12.0483 0.421427 11.0309 0 9.97005 0C8.90919 0 7.89177 0.421427 7.14163 1.17157C6.39148 1.92172 5.97005 2.93913 5.97005 4C4.75163 4.0224 3.58334 4.48901 2.68472 5.31213C1.7861 6.13525 1.21902 7.25822 1.09005 8.47L0.450053 14.47C0.37545 15.1697 0.449389 15.8773 0.667037 16.5465C0.884684 17.2157 1.24114 17.8314 1.71308 18.3534C2.18503 18.8754 2.76185 19.2919 3.40581 19.5756C4.04976 19.8593 4.74637 20.004 5.45005 20H14.5501C15.2537 20.004 15.9503 19.8593 16.5943 19.5756C17.2383 19.2919 17.8151 18.8754 18.287 18.3534C18.759 17.8314 19.1154 17.2157 19.3331 16.5465C19.5507 15.8773 19.6247 15.1697 19.5501 14.47H19.4901ZM9.97005 2C10.5005 2 11.0092 2.21071 11.3843 2.58579C11.7593 2.96086 11.9701 3.46957 11.9701 4H7.97005C7.97005 3.46957 8.18077 2.96086 8.55584 2.58579C8.93091 2.21071 9.43962 2 9.97005 2ZM16.7501 17C16.4693 17.3138 16.1257 17.5651 15.7415 17.7374C15.3573 17.9097 14.9411 17.9991 14.5201 18H5.42005C4.99765 18.0024 4.5795 17.9156 4.19298 17.7452C3.80645 17.5749 3.46026 17.3248 3.17705 17.0114C2.89385 16.698 2.68003 16.3283 2.54958 15.9265C2.41913 15.5248 2.37499 15.1 2.42005 14.68L3.06005 8.68C3.13948 7.93931 3.49165 7.25466 4.04801 6.75929C4.60438 6.26392 5.32515 5.99327 6.07005 6H13.8801C14.6232 5.99576 15.3416 6.26754 15.8958 6.76267C16.4501 7.2578 16.8008 7.94104 16.8801 8.68L17.5201 14.68C17.5656 15.0993 17.5205 15.5235 17.3876 15.9238C17.2547 16.3241 17.0373 16.6912 16.7501 17Z" fill="white" />
                                <path id="Vector_2" d="M6.97051 10.1C6.77515 10.0902 6.58696 10.0234 6.42921 9.90771C6.27147 9.79206 6.15111 9.63268 6.08303 9.44931C6.01495 9.26594 6.00215 9.06664 6.04619 8.87606C6.09024 8.68549 6.18921 8.51202 6.33085 8.37712C6.47249 8.24222 6.65058 8.15183 6.84308 8.11712C7.03557 8.08242 7.23402 8.10493 7.41385 8.18187C7.59368 8.2588 7.747 8.38679 7.85483 8.54998C7.96266 8.71318 8.02026 8.90441 8.02051 9.10001C7.99858 9.36645 7.8795 9.61555 7.6859 9.79992C7.49231 9.9843 7.2377 10.0911 6.97051 10.1Z" fill="white" />
                                <path id="Vector_3" d="M12.9702 10C12.7604 9.99979 12.5556 9.93673 12.382 9.81899C12.2084 9.70125 12.0741 9.53422 11.9963 9.33943C11.9185 9.14464 11.9008 8.93101 11.9455 8.72609C11.9903 8.52117 12.0954 8.33434 12.2472 8.18968C12.3991 8.04503 12.5909 7.94918 12.7977 7.91449C13.0046 7.8798 13.2171 7.90785 13.4079 7.99505C13.5986 8.08224 13.7589 8.22457 13.8681 8.40369C13.9772 8.58281 14.0302 8.79051 14.0202 9.00002C13.9983 9.26647 13.8792 9.51556 13.6856 9.69994C13.492 9.88432 13.2374 9.99111 12.9702 10Z" fill="white" />
                              </g>
                            </svg>
                            {t("buy_now")}
                          </Link>
                        </div>
                        :
                        null
                      }
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div className="product-full-details-frame">
                      <div className="next-page-tab">
                        <span>
                          <Link to="/">{t("home")}</Link>
                        </span>
                        <span>
                          <Link
                            className="active"
                            to={`/products?category=${singleProduct.data.product.product_unique_id}`}
                          >{singleProduct.data.product.name}</Link>
                        </span>
                      </div>
                      <div className="product-details-price-frame">
                        <h3>{singleProduct.data.product.name}</h3>
                        <p className="singale-product-rate">
                          <span className="current-rate">{selectedAttribute?.selling_price_formatted}</span>
                          {selectedAttribute?.original_price_formatted !==
                            selectedAttribute?.selling_price_formatted ?
                            <span className="net-rate">{selectedAttribute?.original_price_formatted}</span>
                            :
                            null
                          }
                          <span className="discount-per" >{selectedAttribute?.discount_tag}</span>
                        </p>
                        {selectedAttribute?.stock_status ?
                          <p className="product-stock-text">
                            {t("in_stock")}
                            <span> ({t("inclusive_of_all_taxes")})</span>
                          </p>
                          :
                          <p className="product-out-stock-text">
                            {t("out_of_stock")}
                          </p>
                        }
                      </div>
                      <div className="material-type-frame">
                        <h4>{t("material")}</h4>
                        <div className="material-btn-box">
                          {materialReverse.map((material, i) =>
                            <Link
                              key={i}
                              className={`${material.product_attribute_id === selectedAttribute?.product_attribute_id ? "active" : ""}`}
                              onClick={() => setSelectedAttribute(material)}
                            >
                              {material.metal ? material.metal : t("n_a")}
                            </Link>
                          )}
                        </div>
                      </div>
                      {selectedAttribute?.sizes.length > 0 ?
                        <div className="material-size-frame">
                          <h4>{t("size")}</h4>
                          <ul className="list-unstyled" >
                            {selectedAttribute.sizes.map((size, i) =>
                              <li>
                                <Link
                                  key={i}
                                  className={`${size === selectedData.size ? "active" : ""}`}
                                  onClick={() => setSelectedData({ ...selectedData, size: size })}
                                >
                                  {size}
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                        : null
                      }
                                            {selectedAttribute?.weights.length > 0 ?
                        <div className="product-specification-frame">
                          <h4>{t("product_wieght")}</h4>
                          <div className="specification-card">
                            <span>{t("weight")}</span>
                            {selectedAttribute.weights.map((weight, i) =>
                              <Button
                                key={i}
                                className={`btn-theme-type-btn weight ${weight === selectedData.weight ? "active" : ""}`}
                                onClick={() => setSelectedData({ ...selectedData, weight: weight })}
                              >
                                {weight}
                              </Button>
                            )}
                          </div>
                        </div>
                        : null
                      }
                      <div className="product-details-variety-frame">
                        <h4>{t("product_details")}</h4>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 0.41687C12.0273 0.41687 15.5 3.88959 15.5 8.16687C15.5 12.4441 12.0273 15.9169 7.75 15.9169C3.47272 15.9169 0 12.4441 0 8.16687C0 3.88959 3.47272 0.41687 7.75 0.41687ZM7.75 1.49827C4.06965 1.49827 1.0814 4.48652 1.0814 8.16687C1.0814 11.8472 4.06965 14.8355 7.75 14.8355C11.4303 14.8355 14.4186 11.8472 14.4186 8.16687C14.4186 4.48652 11.4303 1.49827 7.75 1.49827Z" fill="#005479" />
                            <path d="M7.74998 12.2221C8.14814 12.2221 8.47091 11.8993 8.47091 11.5011C8.47091 11.103 8.14814 10.7802 7.74998 10.7802C7.35182 10.7802 7.02905 11.103 7.02905 11.5011C7.02905 11.8993 7.35182 12.2221 7.74998 12.2221Z" fill="#005479" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84888 5.0128L7.20934 9.33838C7.20934 9.63684 7.45157 9.87908 7.75004 9.87908C8.0485 9.87908 8.29074 9.63684 8.29074 9.33838L8.6512 5.0128C8.6512 4.71433 8.47097 4.11163 7.75004 4.11163C7.02911 4.11163 6.84888 4.71433 6.84888 5.0128Z" fill="#005479" />
                          </svg>
                          {singleProduct.data.product.description_formatted}
                        </span>
                        <div className="product-details-variety-card">
                          <div className="product-details-one">
                            <div className="product-details-card-img">
                              <svg xmlns="http://www.w3.org/2000/svg" width="33" height="35" viewBox="0 0 33 35" fill="none">
                                <path d="M30.9374 31.2244L29.2668 24.3287C29.0978 23.6343 28.7067 23.0188 28.1567 22.5812C27.6067 22.1437 26.9298 21.9097 26.2349 21.9169H24.0074L22.5328 15.8287C22.365 15.1397 21.9785 14.5281 21.4349 14.0911C20.8912 13.6541 20.2214 13.4168 19.5318 13.4169H13.4681C12.7785 13.4168 12.1087 13.6541 11.565 14.0911C11.0213 14.5281 10.6349 15.1397 10.4671 15.8287L8.99244 21.9169H6.76494C6.0754 21.9168 5.4056 22.1541 4.86191 22.5911C4.31822 23.0281 3.9318 23.6397 3.76401 24.3287L2.06244 31.2244C2.02382 31.3812 2.02046 31.545 2.05263 31.7034C2.0848 31.8617 2.15165 32.0104 2.24807 32.1381C2.34836 32.2691 2.47717 32.3739 2.62408 32.444C2.771 32.5142 2.93187 32.5477 3.09369 32.5419H29.9062C30.0628 32.5427 30.2176 32.5068 30.3588 32.4369C30.4999 32.3669 30.6238 32.2647 30.7209 32.1381C30.8226 32.0132 30.8951 31.8657 30.9326 31.7073C30.9702 31.5488 30.9718 31.3836 30.9374 31.2244ZM12.4987 16.3494C12.5552 16.1136 12.6883 15.9049 12.8759 15.758C13.0636 15.6111 13.2944 15.5348 13.5299 15.5419H19.5937C19.8293 15.5348 20.0601 15.6111 20.2477 15.758C20.4354 15.9049 20.5685 16.1136 20.6249 16.3494L21.8831 21.9169H11.1168L12.4987 16.3494ZM17.2012 24.3287L16.4999 27.1019L15.8296 24.3287C15.8296 24.2331 15.7678 24.1375 15.7368 24.0419H17.2631C17.2321 24.1375 17.1909 24.2331 17.1703 24.3287H17.2012ZM4.41369 30.4169L5.76463 24.8494C5.82109 24.6136 5.95422 24.4049 6.14186 24.258C6.32949 24.1111 6.56031 24.0348 6.79588 24.0419H12.8596C13.0952 24.0348 13.326 24.1111 13.5137 24.258C13.7013 24.4049 13.8344 24.6136 13.8909 24.8494L15.1799 30.4169H4.41369ZM17.8199 30.4169L19.1709 24.8494C19.2273 24.6136 19.3605 24.4049 19.5481 24.258C19.7357 24.1111 19.9666 24.0348 20.2021 24.0419H26.2659C26.5015 24.0348 26.7323 24.1111 26.9199 24.258C27.1075 24.4049 27.2407 24.6136 27.2971 24.8494L28.5862 30.4169H17.8199Z" fill="#FFB52D" />
                                <path d="M16.5 8.10437C16.7735 8.10437 17.0358 7.99243 17.2292 7.79317C17.4226 7.59391 17.5312 7.32366 17.5312 7.04187V3.85437C17.5312 3.57258 17.4226 3.30233 17.2292 3.10307C17.0358 2.90381 16.7735 2.79187 16.5 2.79187C16.2265 2.79187 15.9642 2.90381 15.7708 3.10307C15.5774 3.30233 15.4688 3.57258 15.4688 3.85437V7.04187C15.4688 7.32366 15.5774 7.59391 15.7708 7.79317C15.9642 7.99243 16.2265 8.10437 16.5 8.10437Z" fill="#FFB52D" />
                                <path d="M21.6563 9.5281C21.8903 9.67269 22.1703 9.71586 22.4351 9.64815C22.6999 9.58044 22.9279 9.40738 23.0691 9.16685L24.616 6.41498C24.7527 6.16982 24.7894 5.87873 24.7178 5.60575C24.6462 5.33276 24.4724 5.10025 24.2344 4.95935C23.9965 4.81846 23.7139 4.78072 23.449 4.85445C23.184 4.92817 22.9584 5.10732 22.8216 5.35248L21.2747 8.10435C21.1467 8.34632 21.1149 8.63007 21.1861 8.89571C21.2573 9.16135 21.4259 9.38811 21.6563 9.5281Z" fill="#FFB52D" />
                                <path d="M26.3277 13.9481C26.5084 13.9494 26.6862 13.9018 26.8433 13.81L29.5143 12.2162C29.6321 12.1465 29.7354 12.0535 29.8184 11.9426C29.9013 11.8317 29.9622 11.705 29.9976 11.5698C30.0331 11.4347 30.0423 11.2936 30.0248 11.1548C30.0074 11.016 29.9635 10.882 29.8958 10.7606C29.8281 10.6392 29.7379 10.5328 29.6302 10.4473C29.5226 10.3619 29.3996 10.2991 29.2684 10.2626C29.1373 10.2261 29.0004 10.2166 28.8656 10.2346C28.7308 10.2526 28.6008 10.2977 28.483 10.3675L25.7811 11.9612C25.5825 12.0772 25.4268 12.2579 25.3385 12.4748C25.2502 12.6918 25.2343 12.9328 25.2933 13.1601C25.3523 13.3874 25.4828 13.5881 25.6644 13.7307C25.846 13.8733 26.0684 13.9498 26.2968 13.9481H26.3277Z" fill="#FFB52D" />
                                <path d="M9.93083 9.16686C10.0676 9.41202 10.2933 9.59117 10.5582 9.6649C10.8232 9.73862 11.1057 9.70089 11.3436 9.55999C11.5816 9.41909 11.7555 9.18658 11.827 8.9136C11.8986 8.64061 11.862 8.34952 11.7252 8.10436L10.1783 5.32061C10.1106 5.19922 10.0204 5.09276 9.91271 5.00731C9.80507 4.92186 9.68214 4.85909 9.55094 4.82258C9.41975 4.78608 9.28287 4.77655 9.1481 4.79455C9.01334 4.81255 8.88334 4.85772 8.76551 4.92749C8.64769 4.99725 8.54437 5.09025 8.46143 5.20115C8.37849 5.31206 8.31756 5.43872 8.28213 5.57388C8.2467 5.70905 8.23745 5.85008 8.25493 5.98893C8.2724 6.12778 8.31624 6.26172 8.38395 6.38311L9.93083 9.16686Z" fill="#FFB52D" />
                                <path d="M3.48549 12.2162L6.18736 13.81C6.42531 13.9509 6.70784 13.9886 6.97279 13.9149C7.23775 13.8412 7.46342 13.662 7.60017 13.4169C7.73693 13.1717 7.77355 12.8806 7.702 12.6076C7.63044 12.3347 7.45656 12.1021 7.21861 11.9612L4.51674 10.3675C4.27879 10.2266 3.99626 10.1889 3.73131 10.2626C3.46635 10.3363 3.24068 10.5155 3.10393 10.7606C2.96717 11.0058 2.93055 11.2969 3.0021 11.5699C3.07366 11.8428 3.24754 12.0753 3.48549 12.2162Z" fill="#FFB52D" />
                              </svg>
                            </div>
                            <div className="product-details-card-info">
                              <h5>{t("metal")}</h5>
                              <p>{selectedAttribute?.metal}</p>
                            </div>
                          </div>
                          {/* <div className="product-details-two">
                            <div className="product-details-card-img">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M22.9354 18.7709L21.2147 9.12683C20.8585 7.13072 19.1142 5.68195 17.0672 5.68195H14.633C15.001 5.16391 15.218 4.53343 15.218 3.85331C15.2181 2.09633 13.7744 0.66687 12 0.66687C10.2256 0.66687 8.782 2.09633 8.782 3.85336C8.782 4.53348 8.99898 5.16395 9.36704 5.68199H6.93278C4.88576 5.68199 3.14148 7.13077 2.78531 9.12687L1.06466 18.7709C0.847488 19.9882 1.18311 21.2295 1.98536 22.1766C2.78767 23.1237 3.96374 23.6669 5.21217 23.6669H18.7879C20.0364 23.6669 21.2124 23.1237 22.0147 22.1766C22.817 21.2295 23.1525 19.9882 22.9354 18.7709ZM12 2.02472C13.0183 2.02472 13.8467 2.84504 13.8467 3.85336C13.8467 4.86167 13.0182 5.68199 12 5.68199C10.9818 5.68199 10.1533 4.86167 10.1533 3.85336C10.1533 2.84504 10.9817 2.02472 12 2.02472ZM20.9641 21.304C20.423 21.9427 19.6298 22.309 18.7879 22.309H5.21217C4.37027 22.309 3.57708 21.9427 3.03605 21.304C2.49501 20.6652 2.26863 19.8281 2.41512 19.0071L4.13577 9.36312C4.37598 8.01694 5.55233 7.0398 6.93282 7.0398H17.0673C18.4479 7.0398 19.6242 8.01685 19.8644 9.36303L21.585 19.0071C21.7315 19.8281 21.505 20.6652 20.9641 21.304Z" fill="#005479" stroke="#005479" />
                              </svg>
                            </div>
                            <div className="product-details-card-info">
                              <h5>Clasp</h5>
                              <p>Lobster Claw Clasp</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="product-specification-frame">
                        <h4>{t("product_specification")}</h4>
                        {singleProduct.data.product.custom_attributes.map((attribute) => (
                          <div className="specification-card">
                            <span>{attribute.attribute}</span>
                            <div className="weight-btn-box">
                              {attribute.value}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="product-description-frame">
                        <h4> {t("product_description")}</h4>
                        <p>{singleProduct.data.product.description_formatted}</p>
                      </div>
                      {productReviews.loading ?
                        <RatingLoader />
                        :
                        Object.keys(productReviews.data).length > 0 &&
                          productReviews.data.reviews.length > 0 ?
                          <div className="product-review-frame">
                            <div className="product-review-head">
                              <h4>{t("product_review")}</h4>
                            </div>
                            <div className="product-rating-point">
                              <h2>{singleProduct.data.product.review_stars} <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill="#FFC300"
                                    d="M10 15.937l6.18 3.73-1.64-7.03L20 7.907l-7.19-.61L10 .667l-2.81 6.63-7.19.61 5.46 4.73-1.64 7.03 6.18-3.73z"
                                  ></path>
                                </svg>
                              </span>
                              </h2>
                              <p>{singleProduct.data.product.review_stars} {t("ratings")}
                                ,{singleProduct.data.product.total_reviews} {t("reviews")}</p>
                            </div>
                            <div>
                              <div className="reviews-bar-wrap">
                                <ul className="list-unstyled">
                                  <li>
                                    <div className="reviews-bar-card">
                                      <span>5 {t("star")}</span>
                                      <span><ProgressBar
                                        now={productReviews.data.ratings.five_star_percentage}
                                        className="five-start" />
                                      </span>
                                      <span>{productReviews.data.ratings.five_star_percentage + "%"}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="reviews-bar-card">
                                      <span>4 {t("star")}</span>
                                      <span><ProgressBar
                                        now={productReviews.data.ratings.four_star_percentage}
                                        className="four-start" /></span>
                                      <span>{productReviews.data.ratings.four_star_percentage + "%"}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="reviews-bar-card">
                                      <span>3 {t("star")}</span>
                                      <span><ProgressBar
                                        now={productReviews.data.ratings.three_star_percentage}
                                        className="three-start" /></span>
                                      <span>{productReviews.data.ratings.three_star_percentage + "%"}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="reviews-bar-card">
                                      <span>2 {t("star")}</span>
                                      <span><ProgressBar
                                        now={productReviews.data.ratings.two_star_percentage}
                                        className="two-start" />
                                      </span>
                                      <span>{productReviews.data.ratings.two_star_percentage + "%"}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="reviews-bar-card">
                                      <span>1 {t("star")}</span>
                                      <span><ProgressBar
                                        now={productReviews.data.ratings.one_star_percentage}
                                        className="one-start"
                                      /></span>
                                      <span>{productReviews.data.ratings.one_star_percentage + "%"}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {productReviews.data.reviews.map(
                              (review, i) => (
                                <div className="review-preview-frame" key={i}>
                                  <div className="review-preview-card">
                                    <div className="review-preview-card-img">
                                      <Image
                                        className=""
                                        src={review.user
                                          ? review.user.picture :
                                          window.location.origin + "/assets/img/new/customer.jpeg"
                                        }
                                      />
                                    </div>
                                    <div className="review-preview-card-info">
                                      <h3>     {review.user
                                        ? review.user.name
                                        : t("na")
                                      }</h3>
                                      <span>{review.updated_at_formatted}</span>
                                    </div>
                                  </div>
                                  <div className="review-preview-ratings">
                                    <ReactStars
                                      count={5}
                                      edit={false}
                                      size={24}
                                      isHalf={true}
                                      emptyIcon={<i className="far fa-star"></i>}
                                      halfIcon={<i className="fas fa-star-half-alt"></i>}
                                      fullIcon={<i className="fas fa-star"></i>}
                                      activeColor="#ffd700"
                                      value={review.star}
                                      key={`stars_${review.star}`}
                                    />
                                  </div>
                                  <div className="rating-product-review-frame">
                                    <p>
                                      {review.review}</p>
                                    {/* <p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="21"
                                        fill="none"
                                        viewBox="0 0 25 21"
                                        stroke="#005479"
                                      >
                                        <path
                                          d="M.66 18.4a2 2 0 002 2h.426a2 2 0 002-2V9.96a2 2 0 00-2-2H2.66a2 2 0 00-2 2v8.44zM25 8.998c0-1.14-.996-2.073-2.213-2.073H18.3a2 2 0 01-1.953-2.433l.511-2.305.034-.332c0-.425-.189-.819-.487-1.099a1.714 1.714 0 00-2.34.007L7.951 6.499c-.41.373-.653.891-.653 1.462v10.367c0 1.14.996 2.073 2.213 2.073h9.957c.919 0 1.704-.518 2.036-1.265l3.341-7.309c.1-.238.155-.487.155-.756V8.997z"
                                        ></path>
                                      </svg>
                                      {t("helpful")}<span> (78)
                                      </span>
                                    </p> */}
                                  </div>
                                </div>
                              )
                            )}
                            <div className="view-more-section">
                              {productReviews.data.reviews.length <
                                productReviews.data.total_reviews ? (
                                productReviews.loadMore ? (
                                  <Skeleton height={100} count={3} />
                                ) : (
                                  <Link
                                    to={`/product-reviews/${singleProduct.data.product.product_unique_id}`}>
                                    {t("view_all")}
                                  </Link>
                                )
                              ) : null}
                            </div>
                          </div>
                          :
                          null
                      }
                    </div>
                  </Col>
                  <Col lg={12} >
                    <div className="similar-product-frame">
                      <div className="new-section-title">
                        <h2>{t("similar_products")}</h2>
                        <div className="view-more-section">
                          <Link to="/products">
                            {t("view_all")}
                          </Link>
                        </div>
                      </div>
                      {productList.loading ?
                        <div className="smilar-product-cards">
                          {[...Array(4)].map((val, i) => <Skeleton height={445} key={i} />)}
                        </div>
                        :
                        <Slider {...settings}>
                          {Object.keys(productList.data).length > 0 &&
                            productList.data.products.length > 0 ?
                            productList.data.products.map((product) => (
                              <SimilarProductCard product={product} />
                            ))
                            :
                            null
                          }
                        </Slider>
                      }
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
            :
            <SomethingWentWrong />
        }
      </div >
      {
        login ?
          <AuthModal closeLoginModal={closeLoginModal} />
          : null
      }
    </>
  );
};

export default ProductDetail;
