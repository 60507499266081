import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./NewLanding.css";
import { Link } from "react-router-dom";
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

const NewLandingBannerSlider = (props) => {

  function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${props.left ? "arrow--left" : "arrow--right"
          } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      mode: "free",
      duration: 1000,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
    },

    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  )


  return (
    <>
      <div className="new-landing-banner-slider-sec">
        <div className="new-landing-banner-slider-box">
          <div ref={sliderRef} className="keen-slider">
            <div className="keen-slider__slide">
              <div className="new-banner-slider-img-sec">
                <Image
                  className="new-banner-slider-img"
                  src={
                    window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                  }
                />
                <div className="new-banner-slider-info">
                    <h1>Buy Gold Rings Online</h1>
                    <p>What are the benefits of wearing a gold ring?Wearing gold rings or chains helps promote overall blood circulation
                        and oxygen flow to all parts of the body due to the relaxing effect of gold vibrations on the skin. Gold is also
                        proven to help regulate body temperature and promote immunity! People have long believed that gold may alleviate
                        arthritic symptoms. </p>
                    <div class="banner-slider-btn-sec">
                        <Link class="default-btn" to="/products">Shop now</Link>
                    </div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide">
              <div className="new-banner-slider-img-sec">
                <Image
                  className="new-banner-slider-img"
                  src={
                    window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                  }
                />
                <div className="new-banner-slider-info">
                    <h1>Buy Gold Rings Online</h1>
                    <p>What are the benefits of wearing a gold ring?Wearing gold rings or chains helps promote overall blood circulation
                        and oxygen flow to all parts of the body due to the relaxing effect of gold vibrations on the skin. Gold is also
                        proven to help regulate body temperature and promote immunity! People have long believed that gold may alleviate
                        arthritic symptoms. </p>
                    <div class="banner-slider-btn-sec">
                        <Link class="default-btn" to="/products">Shop now</Link>
                    </div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide">
              <div className="new-banner-slider-img-sec">
                <Image
                  className="new-banner-slider-img"
                  src={
                    window.location.origin + "/assets/img/landing-page/banner-slider-img.jpeg"
                  }
                />
                <div className="new-banner-slider-info">
                    <h1>Buy Gold Rings Online</h1>
                    <p>What are the benefits of wearing a gold ring?Wearing gold rings or chains helps promote overall blood circulation
                        and oxygen flow to all parts of the body due to the relaxing effect of gold vibrations on the skin. Gold is also
                        proven to help regulate body temperature and promote immunity! People have long believed that gold may alleviate
                        arthritic symptoms. </p>
                    <div class="banner-slider-btn-sec">
                        <Link class="default-btn" to="/products">Shop now</Link>
                    </div>
                </div>
              </div>
            </div>
          </div>
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
      </div>

    </>
  );
};

export default NewLandingBannerSlider;
