import React, { useEffect, useState } from "react";
import { Container, Image, Row, Col, Button, Collapse } from "react-bootstrap";
import ProfileSidebar from "./ProfileSidebar";
import WriteReviewModal from "./WriteReviewModal";
import { Link } from "react-router-dom";
import { orderListStart, moreOrderList } from "../../store/slices/OrderSlice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import { apiUrl } from "../../Environment";
import { useTranslation } from "react-multi-lang";
import { useParams } from "react-router-dom";

const MyOrders = (props) => {


  const t = useTranslation("my_order");
  const params = useParams();
  const dispatch = useDispatch();
  const orderList = useSelector(state => state.order.orderList);

  const [order1, setOrder1] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const closeWriteReviewModal = () => {
    setSelectedProduct(null);
  }

  const fetchMoreOrder = () => {
    let data = {
      skip: orderList.data.orders.length,
      take: 12,
    };
    if (params.status === "cancel") data = { ...data, order_status: 2 };
    dispatch(moreOrderList(data));
  };


  useEffect(() => {
    let data = {
      skip: 0,
      take: 12,
    };
    if (params.status === "cancel") data = { ...data, order_status: 2 };
    dispatch(orderListStart(data));
  }, [params.status]);


  const download = (order) => {
    const url = apiUrl + "orders_invoice";
    let formData = new FormData();
    formData.append("id", localStorage.getItem("userId"));
    formData.append("token", localStorage.getItem("accessToken"));
    formData.append("order_unique_id", order.order_unique_id);
    formData.append("invoice_via", "download");
    fetch(url, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `order_${order.order_unique_id}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4} className="resp-hide">
              <ProfileSidebar />
            </Col>
            <Col xl={9} md={12} lg={8}>
              {orderList.loading ?
                <Skeleton height={100} count={3} />
                : Object.keys(orderList.data).length > 0 &&
                  orderList.data.orders.length > 0 ?
                  <InfiniteScroll
                    dataLength={orderList.data.orders.length}
                    next={fetchMoreOrder}
                    hasMore={
                      orderList.data.orders.length <
                      orderList.data.total_orders
                    }
                    loader={<Skeleton count={4} height={100} />}
                  >
                    <div className="account-main-wrapper">
                      <div className="profile-header-sec">

                        <h4>
                          {params.status === "cancel"
                            ? t("cancelled_order.heading")
                            : t("heading")}
                        </h4>
                        <p>{t("total_orders")} {orderList.data.total_orders}</p>
                      </div>
                      {orderList.data.orders.map((order, i) => (
                        <div className="my-order-collapse-sec-box" key={i}>
                          <div className="delivery-status" onClick={() => setOrder1(
                            order1 === `${order.order_id}` ? false : `${order.order_id}`)}
                            aria-controls="order1"
                            aria-expanded={order1}>
                            <div className="deliver-status-card point">
                              <h4>{t("table_head.order_placed")}<span>{order.order_payment.paid_date}</span></h4>
                              <h4>{t("table_head.total")} <span>  {order.order_payment.total_formatted}</span></h4>
                              <h4>{t("table_head.order_id")} <span>{order.order_unique_id}</span></h4>
                              <img src="/assets/img/profile/down-arrow.svg"
                                type="image/png" className="" />
                            </div>
                          </div>

                          <Collapse in={order1 === `${order.order_id}`}>
                            <div id="order1">
                              <div className="order-payment-details-card">
                                {order.order_payment.payment_mode == "ADMIN" && order.order_payment.status < 3 && (
                                  <h5 className="order-notes">
                                    {t("note")};
                                    <span>
                                      {t("note_txt")}
                                    </span>
                                  </h5>
                                )}
                                <div className="delivery-address">
                                  <div className="delivery-payment delivery-title">
                                    <h3>{t("payment_details")}</h3>
                                    <h4>{t("sub_total")}:  <span>{order.sub_total_formatted}</span></h4>
                                    <h4>{t("total_amount")}<span>{order.order_payment.total_formatted}</span></h4>
                                    <h4>{t("payment_method")} <span>{order.order_payment.payment_mode}</span></h4>
                                  </div>
                                  <div className="deliver-shipping delivery-title">
                                    <h3>{t("shipping_details")}</h3>
                                    <h4>{order.delivery_address.name}k</h4>
                                    <p>
                                      {order.delivery_address.address}
                                      <br /> {order.delivery_address.landmark}
                                      <br /> {
                                        order.delivery_address.state
                                      }, {order.delivery_address.pincode}{" "}
                                      <br />
                                      {order.delivery_address.contact_number}
                                    </p>
                                  </div>
                                  <div className="deliver-coupon">
                                    <Button type="button"
                                      className="download-receipt-btn btn btn-primary"
                                      onClick={() => download(order)}
                                    >{t("get_invoice")}</Button>
                                    <Link className="wishlist-btn" to="/contact-us">{t("need_help")}</Link>
                                  </div>
                                </div>
                                <div className="deliver-status-sec mt-4">
                                  <h3>{t("products")}</h3>
                                </div>
                                <div className="other-product-details mt-4">
                                  {order.order_products.filter(product => params.status !== "cancel" || product.status === 6).map(
                                    (order_product, i) => (
                                      <div className="other-product-box">
                                        <div className="checkout-product-box">
                                          <div className="checkout-product-img-sec">
                                            <Image
                                              className="checkout-product-img"
                                              src={order_product.product.file}
                                            />
                                          </div>
                                          <div className="checkout-product-content">
                                            <h4>
                                              <Link to={`/product/${order_product.product.product_unique_id}`}>
                                              {order_product.product.name}
                                            </Link>
                                            </h4>
                                            <p>{t("qty")}<span>{order_product.quantity}</span></p>
                                            <h5>
                                              {
                                                order_product.selling_price
                                              }
                                              <span>
                                                {
                                                  order_product.discount_price
                                                }
                                              </span>
                                            </h5>
                                            <p>
                                              {t("table_head.status")}
                                              <span>
                                                {order_product.status_formatted}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="other-product-view">
                                          <Link className="search-btn"
                                            to={`/orders/tracking/${order.order_unique_id}/${order_product.order_product_unique_id}`}>{t("track_shipment")}</Link>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                  :
                  <NoDataFound />
              }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyOrders;
