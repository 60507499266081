import React, { useEffect, useState } from 'react';
import {
  fetchproductWishlistsStart,
  fetchMoreproductWishlistsStart
} from '../../store/slices/ProductSlice';
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from '../Helper/NoDataFound';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Container, Image, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-multi-lang';
import { wishlistSaveStart } from '../../store/slices/ProductSlice';
import { useNavigate } from 'react-router-dom';

const WishListIndex = ({ product }) => {

  const dispatch = useDispatch();
  const t = useTranslation("wish_list");
  const [login, setLogin] = useState(false);
  const profile = useSelector(state => state.user.profile);
  const navigate = useNavigate();
  const productWishlists = useSelector(state => state.product.productWishlists);

  useEffect(() => {
    dispatch(fetchproductWishlistsStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreProducts = () => {
    dispatch(fetchMoreproductWishlistsStart({
      skip: productWishlists.data.products.length,
      take: 12,
    }))
  }

  const closeLoginModal = () => {
    setLogin(false);
  };

  const wishListToggle = (e, product_unique_id) => {
    e.preventDefault();
    if (Object.keys(profile.data).length > 0) {
      dispatch(
        wishlistSaveStart({
          product_unique_id: product_unique_id,
        })
      );
    } else {
      setLogin(true);
    }
  };

  return (

    <div className="product-search-sec">
      <Container>
        <Row>
          <Col md={12}>
            <div className="product-search-right">
              {productWishlists.loading ?
                <div className="product-list-box">
                  {[...Array(6)].map((val, i) => <Skeleton height={445} key={i} />)}
                </div>
                :
                Object.keys(productWishlists.data).length > 0 &&
                  productWishlists.data.products.length > 0 ?
                  <InfiniteScroll
                    dataLength={productWishlists.data.products.length}
                    next={fetchMoreProducts}
                    hasMore={
                      productWishlists.data.products.length < productWishlists.data.total_products
                    }
                    loader={
                      <div className="product-list-box">
                        {[...Array(3)].map((i) => (
                          <Skeleton count={1} height={400} />
                        ))}
                      </div>
                    }
                  >
                    <div className="section-title">
                      <h1 className="">{t("heading", { count: productWishlists.data.products.length })}</h1>
                    </div>

                    <div className="product-list-box">
                      {productWishlists.data.products.map((product, i) =>
                        <div className="trending-product-card">
                          <div className="trending-product-item" onClick={() => navigate(`/product/${product.product_unique_id}`)}>
                            <div className="product-card-img" >
                              <Image
                                className="product-list-img"
                                src={product.product_attribute.file}
                              />
                            </div>
                            <div className="product-card-info">
                              <h5>{product.name}</h5>
                              <p>{product.description_formatted}</p>
                              {product.product_attribute.original_price_formatted !==
                                product.product_attribute.selling_price_formatted ?
                                <div className="product-rate">
                                  <span className="dis-rate">
                                    {product.product_attribute.original_price_formatted}</span>
                                  <span>
                                    {product.product_attribute.selling_price_formatted}
                                  </span>
                                </div>
                                :
                                <div className="product-rates">
                                  <span>
                                    {product.product_attribute.selling_price_formatted}
                                  </span>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="product-overlay">
                            <div className="product-overlay-left">
                              <span className='product-offer'>{product.product_attribute.discount_tag ? product.product_attribute.discount_tag : null}</span>
                            </div>
                            <div className="product-overlay-right">
                              <div className={`card-fav-icon ${product.is_wishlisted ? "active" : ""}`} onClick={(e) => wishListToggle(e, product.product_unique_id)}>
                                {product.is_wishlisted ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="#005479"
                                  >
                                    <path
                                      d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                      stroke="#fff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M5.625 3C3.34687 3 1.5 4.84688 1.5 7.125C1.5 11.25 6.375 15 9 15.8723C11.625 15 16.5 11.25 16.5 7.125C16.5 4.84688 14.6531 3 12.375 3C10.98 3 9.74625 3.69263 9 4.75275C8.61963 4.21095 8.11431 3.76878 7.52682 3.46368C6.93934 3.15858 6.28699 2.99953 5.625 3Z"
                                      stroke="#252525"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          </div>
                        </div >
                      )}
                    </div>
                  </InfiniteScroll>
                  :
                  <NoDataFound />
              }
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default WishListIndex;