import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
  fetchdeliveryAddressesListStart,
  makeDefaultDeliveryAddressesStart,
  deliveryAddressesDeleteStart,
} from '../../store/slices/DeliverySlice';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-multi-lang';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const SingleAddressCard = (props) => {

  const dispatch = useDispatch();
  const { selectedAddress, setSelectedAddress, address, handleEdit, i } = props;
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation("manage_address");

  const deliveryAddressesList = useSelector(state => state.delivery.deliveryAddressesList);
  const makeDefaultDeliveryAddresses = useSelector(state => state.delivery.makeDefaultDeliveryAddresses);
  const deliveryAddressesDelete = useSelector(state => state.delivery.deliveryAddressesDelete);

  const removeAddress = (e) => {
    dispatch(
      deliveryAddressesDeleteStart({
        delivery_address_id: address.delivery_address_id,
      })
    );
    e.stopPropagation();
  };

  const removeAccountConfirmation = (e) => {
    confirmAlert({
      message: t("single_address.confirmation_msg"),
      buttons: [
        {

          label: t("single_address.confirm_yes"),
          onClick: () => removeAddress(e)
        },
        {
          label: t("single_address.confirm_no"),
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }
  useEffect(() => {
    if (
      !skipRender &&
      !deliveryAddressesDelete.loading &&
      Object.keys(deliveryAddressesDelete.data).length > 0) {
      dispatch(fetchdeliveryAddressesListStart());
    }
    setSkipRender(false);
  }, [deliveryAddressesDelete]);

  const setDefaultAddress = (e, i) => {
    setActiveIndex(i);
    dispatch(
      makeDefaultDeliveryAddressesStart({
        delivery_address_id: address.delivery_address_id,
      })
    );
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      !skipRender &&
      !makeDefaultDeliveryAddresses.loading &&
      Object.keys(makeDefaultDeliveryAddresses.data).length > 0
    ) {
      dispatch(fetchdeliveryAddressesListStart());
    }
    setSkipRender(false);
  }, [makeDefaultDeliveryAddresses]);

  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <>
      < div className="checkbox" key={i}>
        <input type="radio" name="manage-address-radio" id="check-box-1"
          checked={selectedAddress === address.delivery_address_id} />
        <label name="manage-address-radio" className="radio" type="radio" for="card"
          checked={selectedAddress === address.delivery_address_id}
          onClick={() => setSelectedAddress(address.delivery_address_id)} >
          <div className="manage-address-card" for="check-box-1">
            <div className="manage-address-info">
              <h4>{address.name}</h4>
              <p>{address.address}</p>
              <p>{address.state}</p>
              <p>{address.pincode}</p>
              <p>{address.contact_number}</p>
            </div>
            <div className="manage-address-action-btn-sec">
              <div className="manage-address-action-btn">
                <Link to="" className="address-action-btn">
                  <span onClick={() => props.editAddress(address)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 11.5V15h-3a3 3 0 00-3 3v3H4.5A1.5 1.5 0 013 19.5v-15A1.5 1.5 0 014.5 3h9A1.5 1.5 0 0015 1.5 1.5 1.5 0 0013.5 0h-9A4.5 4.5 0 000 4.5v15A4.5 4.5 0 004.5 24h11.984a4.5 4.5 0 003.181-1.317l3.017-3.017A4.5 4.5 0 0024 16.485V11.5a1.5 1.5 0 00-1.5-1.5 1.5 1.5 0 00-1.5 1.5z"></path>
                      <path d="M17.793 1.793l-12.5 12.5A1 1 0 005 15v3a1 1 0 001 1h3a1 1 0 00.707-.293L22.038 6.376a3.379 3.379 0 00.952-3.17 3.118 3.118 0 00-5.197-1.413z"></path>
                    </svg>
                  </span>
                </Link>
                {
                  deliveryAddressesDelete.data.delivery_address_id ===
                    address.delivery_address_id ? (
                    t("single_address.loading")
                  ) : (
                    <span onClick={removeAccountConfirmation} className="delete-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        data-name="Isolation Mode"
                        viewBox="0 0 24 24"
                        fill="#dc3545"
                      >
                        <path d="M23 3h-5v-.5A2.5 2.5 0 0015.5 0h-7A2.5 2.5 0 006 2.5V3H1v3h2v15a3 3 0 003 3h12a3 3 0 003-3V6h2zm-5 18H6V6h12z"></path>
                        <path d="M8 9H11V18H8z"></path>
                        <path d="M13 9H16V18H13z"></path>
                      </svg>
                    </span>
                  )}
                {address.is_default == 1 ?
                  (<span className="default-btn">{t("single_address.default_btn")}</span>)
                  :
                  makeDefaultDeliveryAddresses.data.delivery_address_id === address.delivery_address_id ?
                    t("single_address.loading") :
                    (
                      <div className="manage-address-default-btn">
                        <Button className="default-btn"
                          onClick={() => setDefaultAddress(i)}
                          disabled={makeDefaultDeliveryAddresses.buttonDisable || activeIndex === i}
                        >
                          {activeIndex === i && makeDefaultDeliveryAddresses.buttonDisable ?
                            t("single_address.loading") : t("single_address.text")}
                        </Button>
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </label>
      </div>
    </>
  )
}

export default SingleAddressCard;