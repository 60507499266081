import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';

import {
    fetchBannerListSuccess,
    fetchBannerListFailure,
    fetchCategoriesListSuccess,
    fetchCategoriesListFailure,
    fetchTopProductListSuccess,
    fetchTopProductListFailure,
    customerReviewsListSuccess,
    customerReviewsListFailure,
} from "../slices/HomePageSlice";

function* fetchBannerListAPI(action) {
    yield getCommonSaga({
        apiUrl: "banners_list",
        payload: action.payload,
        successNotify: false,
        success: fetchBannerListSuccess,
        failure: fetchBannerListFailure,
    });
}

function* fetchCategoriesListAPI(action) {
    yield getCommonSaga({
        apiUrl: "categories_list",
        payload: action.payload,
        successNotify: false,
        success: fetchCategoriesListSuccess,
        failure: fetchCategoriesListFailure,
    });
}

function* fetchTopProductListAPI(action) {
    yield getCommonSaga({
        apiUrl: "top_products_list",
        payload: action.payload,
        successNotify: false,
        success: fetchTopProductListSuccess,
        failure: fetchTopProductListFailure,
    });
}

function* customerReviewsListAPI(action) {
    yield getCommonSaga({
        apiUrl: "customer_reviews_list",
        payload: action.payload,
        successNotify: false,
        success: customerReviewsListSuccess,
        failure: customerReviewsListFailure,
    });
}


export default function* HomePageSaga() {
    yield all([
        yield takeLatest('homepage/fetchBannerListStart', fetchBannerListAPI),
        yield takeLatest('homepage/fetchCategoriesListStart', fetchCategoriesListAPI),
        yield takeLatest('homepage/fetchTopProductListStart', fetchTopProductListAPI),
        yield takeLatest('homepage/customerReviewsListStart', customerReviewsListAPI),
    ]);
}