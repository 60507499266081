import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  login: getCommonInitialState(),
  register: getCommonInitialState(),
  resendEmailVerification: getCommonInitialState(),
  emailVerification: getCommonInitialState(),
  resendTwoStepAuth: getCommonInitialState(),
  twoStepAuth: getCommonInitialState(),
  forgotPassword: getCommonInitialState(),
  resendForgotPassword: getCommonInitialState(),
  forgotPasswordVerification: getCommonInitialState(),
  resetPassword: getCommonInitialState(),
  profile: getCommonInitialState(),
  changePassword: getCommonInitialState(),
  deleteAccount: getCommonInitialState(),
  profileInputData: getCommonInitialState(),
  twoStepAuthUpdate: getCommonInitialState(),
  notificationUpdate: getCommonInitialState(),
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {

    //Login
    userLoginStart: (state) => { state.login = getCommonStart() },
    userLoginSuccess: (state, action) => { state.login = getCommonSuccess(action.payload) },
    userLoginFailure: (state, action) => { state.login = getCommonFailure(action.payload) },

    //Register
    userRegisterStart: (state) => { state.register = getCommonStart() },
    userRegisterSuccess: (state, action) => { state.register = getCommonSuccess(action.payload) },
    userRegisterFailure: (state, action) => { state.register = getCommonFailure(action.payload) },

    // Resend Email Verification Code
    resendEmailVerificationStart: (state) => { state.resendEmailVerification = getCommonStart() },
    resendEmailVerificationSuccess: (state, action) => { state.resendEmailVerification = getCommonSuccess(action.payload) },
    resendEmailVerificationFailure: (state, action) => { state.resendEmailVerification = getCommonFailure(action.payload) },

    // Email Verification
    emailVerificationStart: (state) => { state.emailVerification = getCommonStart() },
    emailVerificationSuccess: (state, action) => { state.emailVerification = getCommonSuccess(action.payload) },
    emailVerificationFailure: (state, action) => { state.emailVerification = getCommonFailure(action.payload) },

    // Resend Two Step Auth Code
    resendTwoStepAuthStart: (state) => { state.resendTwoStepAuth = getCommonStart() },
    resendTwoStepAuthSuccess: (state, action) => { state.resendTwoStepAuth = getCommonSuccess(action.payload) },
    resendTwoStepAuthFailure: (state, action) => { state.resendTwoStepAuth = getCommonFailure(action.payload) },

    // Two Step Auth
    twoStepAuthStart: (state) => { state.twoStepAuth = getCommonStart() },
    twoStepAuthSuccess: (state, action) => { state.twoStepAuth = getCommonSuccess(action.payload) },
    twoStepAuthFailure: (state, action) => { state.twoStepAuth = getCommonFailure(action.payload) },

    // Forgot password and resend forgot password
    forgotPasswordStart: (state) => { state.forgotPassword = getCommonStart() },
    forgotPasswordSuccess: (state, action) => { state.forgotPassword = getCommonSuccess(action.payload) },
    forgotPasswordFailure: (state, action) => { state.forgotPassword = getCommonFailure(action.payload) },

    // Forgot Password Verification
    forgotPasswordVerificationStart: (state) => { state.forgotPasswordVerification = getCommonStart() },
    forgotPasswordVerificationSuccess: (state, action) => { state.forgotPasswordVerification = getCommonSuccess(action.payload) },
    forgotPasswordVerificationFailure: (state, action) => { state.forgotPasswordVerification = getCommonFailure(action.payload) },

    // Reset Password
    resetPasswordStart: (state) => { state.resetPassword = getCommonStart() },
    resetPasswordSuccess: (state, action) => { state.resetPassword = getCommonSuccess(action.payload) },
    resetPasswordFailure: (state, action) => { state.resetPassword = getCommonFailure(action.payload) },

    // Get user Details
    fetchUserDetailsStart: (state) => { state.profile = getCommonStart() },
    fetchUserDetailsSuccess: (state, action) => { state.profile = getCommonSuccess(action.payload) },
    fetchUserDetailsFailure: (state, action) => { state.profile = getCommonFailure(action.payload) },

    // Change Password
    changePasswordStart: (state) => { state.changePassword = getCommonStart() },
    changePasswordSuccess: (state, action) => { state.changePassword = getCommonSuccess(action.payload) },
    changePasswordFailure: (state, action) => { state.changePassword = getCommonFailure(action.payload) },

    // Delete Account
    deleteAccountStart: (state) => { state.deleteAccount = getCommonStart() },
    deleteAccountSuccess: (state, action) => { state.deleteAccount = getCommonSuccess(action.payload) },
    deleteAccountFailure: (state, action) => { state.deleteAccount = getCommonFailure(action.payload) },

    // Update Profile
    updateUserDetailsStart: (state) => { state.profileInputData = getCommonStart() },
    updateUserDetailsSuccess: (state, action) => { state.profileInputData = getCommonSuccess(action.payload) },
    updateUserDetailsFailure: (state, action) => { state.profileInputData = getCommonFailure(action.payload) },

    // Two step Auth Update
    twoStepAuthUpdateStart: (state) => { state.twoStepAuthUpdate = getCommonStart() },
    twoStepAuthUpdateSuccess: (state, action) => { state.twoStepAuthUpdate = getCommonSuccess(action.payload) },
    twoStepAuthUpdateFailure: (state, action) => { state.twoStepAuthUpdate = getCommonFailure(action.payload) },

    // Update Notification
    notificationUpdateStart: (state) => { state.notificationUpdate = getCommonStart() },
    notificationUpdateSuccess: (state, action) => { state.notificationUpdate = getCommonSuccess(action.payload) },
    notificationUpdateFailure: (state, action) => { state.notificationUpdate = getCommonFailure(action.payload) },

  }

});

export const {
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  userRegisterStart,
  userRegisterSuccess,
  userRegisterFailure,
  resendEmailVerificationStart,
  resendEmailVerificationSuccess,
  resendEmailVerificationFailure,
  emailVerificationStart,
  emailVerificationSuccess,
  emailVerificationFailure,
  resendTwoStepAuthStart,
  resendTwoStepAuthSuccess,
  resendTwoStepAuthFailure,
  twoStepAuthStart,
  twoStepAuthSuccess,
  twoStepAuthFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordVerificationStart,
  forgotPasswordVerificationSuccess,
  forgotPasswordVerificationFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  fetchUserDetailsStart,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  deleteAccountStart,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateUserDetailsStart,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  twoStepAuthUpdateStart,
  twoStepAuthUpdateSuccess,
  twoStepAuthUpdateFailure,
  notificationUpdateStart,
  notificationUpdateSuccess,
  notificationUpdateFailure

} = UserSlice.actions;

export default UserSlice.reducer;