import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { userRegisterFailure, userRegisterStart } from "../../store/slices/UserSlice";
import { useTranslation } from "react-multi-lang";

const RegisterIndex = ({ setStep, closeAuthModal }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const register = useSelector(state => state.user.register);

  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPasswordVisible] = useState({
    password: false,
    password_confirmation: false,
  })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("register.name.required")),
    email: Yup.string().email(t("register.email.invalid")).required(t("register.email.required")),
    password: Yup.string()
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("register.password.invalid"))
      .required(t("register.password.required")),
    // password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("register.confirm_password.must_have_6_characters"))
    // .required(t("register.confirm_password.required"))
    // .when("password", {
    //   is: (val) => (val && val.length > 0 ? true : false),
    //   then: Yup.string().oneOf(
    //     [Yup.ref("password")],
    //     t("register.confirm_password.passwords_need_to_be_same")
    //   ),
    // }),
  });

  const handleRegister = (values) => {
    dispatch(userRegisterStart({ ...values, login_by: "manual", device_type: "web" }));
  };

  useEffect(() => {
    dispatch(userRegisterFailure());
  }, []);

  useEffect(() => {
    if (!skipRender && !register.loading && Object.keys(register.data).length > 0) {
      if (register.data.is_email_verified === 0) {
        setStep(5);
      } else {
        closeAuthModal();
      }
    }
    setSkipRender(false);
  }, [register]);

  return (
    <>
      <div className="step-2">
        <div className="auth-form">
          <div className="login-box">
            <div className="register-header-title-sec">
              <h3>{t("register.heading")}</h3>
            </div>
            <Formik
              initialValues={{
                name: "",
                mobile: "",
                email: "",
                password: "",
                // password_confirmation: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => handleRegister(values)}
            >
              {({ errors, touched }) =>
                <FORM>
                  <div className="login-body-sec">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>{t("register.name.label")} *</Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder={t("register.name.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="name"
                        className="text-danger"
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Mobile Number *</Form.Label>
                      <Field
                        type="number"
                        className="form-control"
                        name="mobile"
                        placeholder={"Enter Mobile Number"}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="mobile"
                        className="text-danger"
                      />
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>{t("register.email.label")} *</Form.Label>
                      <Field
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder={t("register.email.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Label>{t("register.password.label")} *</Form.Label>
                    <InputGroup className="mb-3">
                      <Field
                        type={showPassword.password ? "text" : "password"}
                        name="password"
                        placeholder={t("register.password.placeholder")}
                        className="form-control"
                      />
                      <InputGroup.Text id="basic-addon2">
                        <Image
                          className="eye-hidden-icon"
                          src={
                            showPassword.password ?
                              window.location.origin + "/assets/img/auth/eye-off.svg"
                              : window.location.origin + "/assets/img/auth/eye.svg"
                          }
                          onClick={() =>
                            setShowPasswordVisible({
                              ...showPassword,
                              password: !showPassword.password,

                            })
                          }
                        />
                      </InputGroup.Text>
                      <ErrorMessage
                        component={"div"}
                        name="password"
                        className="text-danger pass-txt"
                      />
                    </InputGroup>
                    {/* <Form.Label>{t("register.confirm_password.label")} *</Form.Label>
                    <InputGroup className="mb-3">
                      <Field
                        type={showPassword.password_confirmation ? "text" : "password"}
                        name="password_confirmation"
                        placeholder={t("register.confirm_password.placeholder")}
                        className="form-control"
                      />
                      <InputGroup.Text id="basic-addon2">
                        <Image
                          className="eye-hidden-icon"
                          src={
                            showPassword.password_confirmation ?
                              window.location.origin + "/assets/img/auth/eye-off.svg"
                              : window.location.origin + "/assets/img/auth/eye.svg"
                          }
                          onClick={() =>
                            setShowPasswordVisible({
                              ...showPassword,
                              password_confirmation: !showPassword.password_confirmation,

                            })
                          }
                        />
                      </InputGroup.Text>
                      <ErrorMessage
                        component={"div"}
                        name="password_confirmation"
                        className="text-danger pass-txt"
                      />
                    </InputGroup> */}
                    <div className="auth-btn-sec">
                      <Button
                        type="submit"
                        className="default-btn w-100"
                        disabled={register.buttonDisable}
                      >
                        {register.buttonDisable ? t("register.register_btn.loading") : t("register.register_btn.text")}
                      </Button>
                      <Button type="button" className="default-btn-outline w-100" onClick={() => setStep(1)}>
                        {t("register.login")}
                      </Button>
                    </div>
                  </div>
                </FORM>
              }
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterIndex;