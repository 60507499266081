import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";


const NewArrivalsIndex = (props) => {
  const t = useTranslation("new_arrival");

  return (
    <>
      <Container>
        <div className="new-arrivalst-sec">
          <Row>
            <Col md={12}>
              <div className="new-arrivals-card">
                <h5>{t("heading")}</h5>
                <h2>{t("exqussite_para")}</h2>
                <Link to="/products" className="download-receipt-btn">
                  {t("explore_now")}
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default NewArrivalsIndex;
