import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { notificationUpdateStart } from "../../store/slices/UserSlice";
import PageLoader from "../Helper/PageLoader";
import ProfileSidebar from "./ProfileSidebar";

const NotificationPreference = (props) => {
  const t = useTranslation("notification");
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);

  return (
    <>
      {profile.loading ?
        <PageLoader />
        : Object.keys(profile.data).length > 0 ?
          <div className="profile-sec">
            <Container>
              <Row>
                <Col xl={3} md={12} lg={4}>
                  <ProfileSidebar />
                </Col>
                <Col xl={9} md={12} lg={8}>
                  <div className="account-main-wrapper">
                    <div className="profile-details-sec">
                      <div className="profile-header-sec mb-4">
                        <h3>{t("heading")}</h3>
                        {/* <p>Welcome John !</p> */}
                      </div>
                      <Form className="account-form">
                        <Row>
                          {/* <Col md={5}>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label>Email Notification</Form.Label>
                          <p className="notify-para">Get email to find out what’s going on when you’re not online. You Can turn these off.</p>
                        </Form.Group>
                      </Col> */}
                          <Col md={12}>
                            <div className="breif-notification-content">
                              <div className="notification-item">
                                <Form.Group className="mb-0" controlId="formBasicEmail">
                                  <Form.Label>{t("email.text")}</Form.Label>
                                  <p className="notification-para">{t("email.message")}</p>
                                </Form.Group>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={profile.data.email_notification == 1}
                                  disabled={profile.data.is_two_step_auth_enabled == 1}
                                  onClick={() => dispatch(notificationUpdateStart({ notification_type: "email" }))}
                                />
                              </div>
                              {/* <div className="notification-item">
                            <Form.Group className="mb-0" controlId="formBasicEmail">
                              <Form.Label>Tips and tutorials</Form.Label>
                              <p className="notification-para">Tips on getting more out of untitled.</p>
                            </Form.Group>
                            <Form.Check
                              type="switch"
                              id="custom-switch-1"
                            />
                          </div>
                          <div className="notification-item">
                            <Form.Group className="mb-0" controlId="formBasicEmail">
                              <Form.Label>User research</Form.Label>
                              <p className="notification-para">Get involved in our beta testing programing or participate in paid product user research.</p>
                            </Form.Group>
                            <Form.Check
                              type="switch"
                              id="custom-switch-2"
                            />
                          </div> */}
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                      <Col md={5}>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label>Push Notification</Form.Label>
                          <p className="notify-para">Get email to find out what’s going on when you’re not online. You Can turn these off.</p>
                        </Form.Group>
                      </Col>
                      <Col md={7}>
                        <div className="breif-notification-content">
                          <div className="notification-item">
                            <Form.Group className="mb-0" controlId="formBasicEmail">
                              <Form.Label>Comments</Form.Label>
                              <p className="notification-para">News about product and features updates.</p>
                            </Form.Group>
                            <Form.Check
                              type="switch"
                              id="custom-switch-3"
                            />
                          </div>
                          <div className="notification-item">
                            <Form.Group className="mb-0" controlId="formBasicEmail">
                              <Form.Label>Reminders</Form.Label>
                              <p className="notification-para">News about product and features updates.</p>
                            </Form.Group>
                            <Form.Check
                              type="switch"
                              id="custom-switch-4"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row> */}
                        {/* <Row>
                      <Col md={12}>
                        <div className="account-btn-sec">
                          <Button className="default-btn">
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row> */}
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          : null
      }
    </>
  );
};

export default NotificationPreference;
